// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { ICacheKey, InjectInfiniteQuery, InjectQuery } from '@services/api/api.types';
import { BffService } from '../../bff.service';
import { BffConstants } from '@services/api/bff.constants';
import { BFFServiceBasic } from '@services/api/bff.basic';
import { InsightsFiltersNew } from '../../filter/filter.types';
import { getOrganizationsLeaderboardQuery } from '@app/shared/queries/organizations-leaderboard/organization-leaderboard-queries';
import { InsightProject } from 'lfx-insights';

export interface IOrganizationLeaderboard {
  ORG_NAME: string;
  ORGANIZATION_ID: string;
  ORG_LOGO_URL: string;
  ALL_ORGANIZATIONS: number;
  ALL_CHANGE_FROM_PREVIOUS: number;
  ROW_NUMBER_BY_CONTRIBUTIONS: number;
  CONTRIBUTIONS: number;
  PERCENT_TOTAL: number;
  DELTA_CONTRIBUTIONS: number;
  ROW_NUMBER_BY_CONTRIBUTORS: number;
  CONTRIBUTORS: number;
  CONTRIBUTORS_PERCENT_TOTAL: number;
  DELTA_CONTRIBUTORS: number;
}

export class OrganizationsBffApiClass extends BFFServiceBasic {
  protected useQuery: InjectQuery;
  protected useInfiniteQuery: InjectInfiniteQuery;

  constructor(bffService: BffService, useQuery: InjectQuery, useInfiniteQuery: InjectInfiniteQuery) {
    super(bffService);
    this.useInfiniteQuery = useInfiniteQuery;
    this.useQuery = useQuery;
  }

  public getOrganizationsLeaderboardPagination(
    filters: InsightsFiltersNew,
    activityType: string,
    isGerrit: boolean,
    project: InsightProject | undefined,
    limit: number,
    isContributorMetric: boolean = false
  ) {
    return this.useInfiniteQuery({
      queryKey: [
        ICacheKey.GET_ORGANIZATION_LEADERBOARD,
        filters.project,
        filters.repositories,
        filters.hideBots,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        activityType,
        isContributorMetric,
        isGerrit
      ] as const,
      queryFn: ({ pageParam }) => {
        const query = getOrganizationsLeaderboardQuery(
          filters,
          activityType,
          isGerrit,
          project!,
          pageParam,
          limit,
          isContributorMetric
        );
        return this.loadData<IOrganizationLeaderboard[]>(BffConstants.ORGANIZATION_LEADERBOARD, query);
      },
      initialPageParam: 0,
      enabled: !!project,
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.length * limit;
        return lastPage.length === limit ? nextOffset : undefined;
      }
    });
  }

  public getOrganizationLeaderboardAllData(
    filters: InsightsFiltersNew,
    activityType: string,
    isGerrit: boolean,
    project: InsightProject | undefined,
    isContributorMetric: boolean = false,
    isDownloadCSV: boolean
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_ORGANIZATION_LEADERBOARD,
        filters.project,
        filters.repositories,
        filters.hideBots,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        activityType,
        isContributorMetric,
        isGerrit,
        isDownloadCSV
      ] as const,
      queryFn: () => {
        const query = getOrganizationsLeaderboardQuery(
          filters,
          activityType,
          isGerrit,
          project!,
          0,
          0,
          isContributorMetric
        );
        return this.loadData<IOrganizationLeaderboard[]>(BffConstants.ORGANIZATION_LEADERBOARD, query);
      },
      enabled: isDownloadCSV && !!project
    });
  }
}
