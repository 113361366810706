// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { forkJoin, map, Observable } from 'rxjs';
import { Query, TimeDimension } from '@cubejs-client/core';

import { CubeServiceBasic, ToTable } from '@services/api/basic';
import { GranularityEnum } from 'lfx-insights';
import { CubeFilters } from '@shared/cubejs/helpers/utils';
import { ICacheKey, InjectQuery } from '@services/api/api.types';
import { CubeService } from '../../cube.service';

export interface IRetentionBreakdownProject {
  ['SnowRetentionBreakdownForProject.activeContributors']: string;
  ['SnowRetentionBreakdownForProject.activity_month']?: string;
  ['SnowRetentionBreakdownForProject.joined_month']?: string;
  ['SnowRetentionBreakdownForProject.activity_week']?: string;
  ['SnowRetentionBreakdownForProject.joinedAtWeek']?: string;
}

export interface IRetentionBreakdown {
  ['SnowRetentionBreakdown.activeContributors']: string;
  ['SnowRetentionBreakdown.activity_month']?: string;
  ['SnowRetentionBreakdown.joined_month']?: string;
  ['SnowRetentionBreakdown.activity_week']?: string;
  ['SnowRetentionBreakdown.joinedAtWeek']?: string;
}

export interface IRetentionSummary {
  ['SnowRetentionContributors.averageContributorTenure']: number;
  ['SnowRetentionContributors.averageNoActivities']: number;
}

// we'll use this as an intermediary type to convert from the types above to the final RetentionBreakdown type
export interface IRetentionBreakdownRaw {
  activeContributors: number;
  activityDate: string; // based on activity_month or activity_week
  joinedDate: string; // based on joined_month or joinedAtWeek
}

export class RetentionApiClass extends CubeServiceBasic {
  protected useQuery: InjectQuery;
  constructor(cubejs: CubeService, useQuery: InjectQuery) {
    super(cubejs);
    this.useQuery = useQuery;
  }

  public getRetentionBreakdown<T extends object, K extends keyof T>(
    queries: Record<K, Query>,
    projectRefs: Array<string | undefined>,
    repoRefs: Array<string[] | undefined>,
    hideBots: Array<boolean | undefined>,
    granularityRefs: Array<GranularityEnum>,
    timeDimensionRefs: Array<TimeDimension[] | undefined>
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_RETENTION_BREAKDOWN,
        projectRefs,
        repoRefs,
        hideBots,
        granularityRefs,
        timeDimensionRefs
      ],
      queryFn: () => {
        const loads = {} as Record<K, Observable<ToTable<IRetentionBreakdownProject | IRetentionBreakdown, never>>>;
        for (const [key, query] of Object.entries(queries)) {
          loads[key as K] = this.load$<IRetentionBreakdownProject | IRetentionBreakdown>(query as Query).pipe(
            map((res) => this.toTable(res))
          );
        }
        return forkJoin(loads);
      }
    });
  }

  public getRetentionSummary(query: Query, cubeFilters: CubeFilters) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_RETENTION_SUMMARY,
        cubeFilters.projectId,
        cubeFilters.repositoryUrl,
        cubeFilters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IRetentionSummary>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }
}
