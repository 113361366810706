// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { ChangeDetectionStrategy, Component, EventEmitter, Output, input } from '@angular/core';
import { InsightProject } from 'lfx-insights';
import { DownloadStyleDirective } from '../../directives/download-style.directive';
import { ProjectLogoComponent } from '../project-logo/project-logo.component';

@Component({
  selector: 'lfx-card-logo',
  templateUrl: './card-logo.component.html',
  styleUrls: ['./card-logo.component.scss'],
  standalone: true,
  imports: [DownloadStyleDirective, ProjectLogoComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardLogoComponent {
  @Output() public readonly logoClicked = new EventEmitter<void>();

  public project = input.required<InsightProject>();


  public onClick() {
    this.logoClicked.emit();
  }
}
