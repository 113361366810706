// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  public get href(): string {
    return window.location.href;
  }

  public set href(value: string) {
    window.location.href = value;
  }

  public get pathname(): string {
    return window.location.pathname;
  }

  public set pathname(value: string) {
    window.location.pathname = value;
  }

  public get search(): string {
    return window.location.search;
  }

  public set search(value: string) {
    window.location.search = value;
  }

  public get hash(): string {
    return window.location.hash;
  }

  public set hash(value: string) {
    window.location.hash = value;
  }
}
