// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input, OnInit } from '@angular/core';
import { NgStyle, NgClass } from '@angular/common';

@Component({
    selector: 'lfx-warning',
    templateUrl: './warning.component.html',
    styleUrls: ['./warning.component.scss'],
    standalone: true,
    imports: [NgStyle, NgClass]
})
export class WarningComponent implements OnInit {
  @Input() public minHeight: number | undefined;

  public ngOnInit(): void {
    if (!this.minHeight) {
      this.minHeight = 300;
    }
  }
}
