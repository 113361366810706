<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->

<div class="metric-select-wrap">
  <label class="text-gray-dark font-semibold pb-1"> Metric</label>
  <lfx-select
    [items]="items()"
    [selectedItem]="selectedItem()"
    [isSearchable]="false"
    (selectedChanged)="onSelectChange($event)" />
</div>
