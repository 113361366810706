import { APP_CONFIG } from '@app/config';
import { getCurrentPreviousPeriodDateRanges } from '@app/shared/utils/cubejs-helpers';
import { DateRangesEnum, GranularityEnum } from 'lfx-insights';

export interface InsightsFiltersNew {
  dateFilters: DateRangesEnum;
  project?: string;
  compare?: string;
  platform?: string[];
  granularity?: GranularityEnum;
  // Info: Use it to calculate diff values between periods
  periods?: {
    currentPeriod: [string, string];
    previousPeriod: [string, string];
  };
  // Info: Use it to calculate Visualization Data
  foundationSlug?: string;
  repositories?: string[];
  hideBots?: boolean;
}

export interface FilterFromParams {
  project?: string;
  repository?: string;
  dateFilters?: DateRangesEnum;
  dateRange?: string;
  compare?: string;
  granularity?: GranularityEnum;
  hideBots?: boolean;
}

export const defaultFilters: InsightsFiltersNew = {
  dateFilters: APP_CONFIG.DATE_RANGE_DEFAULT as DateRangesEnum,
  project: '',
  hideBots: true,
  repositories: ['all'],
  compare: 'PP',
  periods: getCurrentPreviousPeriodDateRanges(APP_CONFIG.DATE_RANGE_DEFAULT),
  granularity: APP_CONFIG.GRANULARITY_DEFAULT as GranularityEnum,
  foundationSlug: ''
};
