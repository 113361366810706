// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { BestPracticeDetail } from 'lfx-insights';

export const metricDetails: BestPracticeDetail[] = [
  {
    metric: 'documentation',
    key: 'documentation',
    metricInfo: [
      {
        key: 'readme',
        label: 'Readme',
        description:
          'The readme file introduces and explains a project. It contains information that is commonly required to understand what the project is about',
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-book-open'
      },
      {
        key: 'roadmap',
        label: 'Roadmap',
        description: 'The project roadmap',
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-book-open'
      },
      {
        key: 'website',
        label: 'Website',
        description: 'A url that users can visit to learn more about your project',
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-globe'
      },
      {
        key: 'adopters',
        label: 'Adopters',
        description: 'List of organizations using this project in production or at stages of testing',
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-building'
      },
      {
        key: 'changelog',
        label: 'Changelog',
        description: 'A curated, chronologically ordered list of notable changes for each version',
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-file-alt'
      },
      {
        key: 'governance',
        label: 'Governance',
        description: ' Document that explains how the governance and committer process works in the repository',
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-diploma'
      },
      {
        key: 'maintainers',
        label: 'Maintainers',
        description: 'The maintainers file contains a list of the current maintainers of the repository',
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-user-cog'
      },
      {
        key: 'contributing',
        label: 'Contributing',
        description:
          'A contributing file in your repository provides potential project contributors with a short guide to how they can help with your project',
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-laptop-code'
      },
      {
        key: 'summary_table',
        label: 'Summary Table',
        description: 'A summary table of the project',
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-laptop-code'
      },
      {
        key: 'code_of_conduct',
        label: 'Code of conduct',
        description:
          'Adopt a code of conduct to define community standards, signal a welcoming and inclusive project, and outline procedures for handling abuse',
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'fal fa-pen-fancy'
      },
      {
        key: 'details',
        type: 'list',
        label: '',
        description: '',
        status: '',
        docUrl: '',
        icon: '',
        data: ['Checks reference documentation', 'Recommended templates: CONTRIBUTING.md and GOVERNANCE.md.']
      }
    ]
  },
  {
    metric: 'license',
    key: 'license',
    metricInfo: [
      {
        key: 'license_spdx_id',
        label: 'Apache-2.0',
        description: `The LICENSE file contains the repository's license`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-balance-scale'
      },
      {
        key: 'license_approved',
        label: 'Approved license',
        description: 'Whether the repository uses an approved license or not',
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-check-double'
      },
      {
        key: 'license_scanning',
        label: 'License scanning',
        description:
          'License scanning software scans and automatically identifies, manages and addresses open source licensing issues',
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-stamp'
      }
      // {
      //   key: 'details',
      //   type: 'list',
      //   data: ['Checks reference documentation']
      // }
    ]
  },
  {
    metric: 'best practices',
    key: 'bestPractices',
    metricInfo: [
      {
        key: 'cla',
        label: 'Contributor License Agreement',
        description: `Defines the terms under which intellectual property has been contributed to a company/project`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-file-medical-alt'
      },
      {
        key: 'dco',
        label: 'Developer Certificate of Origin',
        description: `Mechanism for contributors to certify that they wrote or
        have the right to submit the code they are contributing`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-file-edit'
      },
      {
        key: 'analytics',
        label: 'Analytics',
        description: 'Projects websites should provide some web analytics',
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-chart-bar'
      },
      {
        key: 'openssf_badge',
        label: 'OpenSSF badge',
        description:
          `The Open Source Security Foundation (OpenSSF) Best Practices badge is a way for ` +
          `Free/Libre and Open Source Software (FLOSS) projects to show that they follow best practices.`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-badge-sheriff'
      },
      {
        key: 'recent_release',
        label: 'Recent release',
        description: `The project should have released at least one version in the last year`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-calendar'
      },
      {
        key: 'slack_presence',
        label: 'Slack presence',
        description: `Projects should have presence in the Slack`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'fab fa-slack'
      },
      {
        key: 'artifacthub_badge',
        label: 'Artifact Hub badge',
        description: 'Projects can list their content on Artifact Hub to improve their discoverability',
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-hexagon'
      },
      {
        key: 'community_meeting',
        label: 'Community meeting',
        description: `Community meetings are often held to engage community members,
        hear more voices and get more viewpoints`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-users'
      },
      {
        key: 'github_discussions',
        label: 'GitHub discussions',
        description: `Projects should enable discussions in their repositories`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-comments-alt'
      },
      {
        key: 'openssf_scorecard_badge',
        label: 'OpenSSF Score badge',
        description:
          `Scorecard assesses open source projects for security risks through a series of automated checks. ` +
          `For more information about the Scorecard badge please see ` +
          `<a href="https://github.com/marketplace/actions/ossf-scorecard-action#scorecard-badge." target="_blank">` +
          `https://github.com/marketplace/actions/ossf-scorecard-action#scorecard-badge.</a>`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-badge-sheriff'
      },
      {
        key: 'details',
        type: 'list',
        label: '',
        description: '',
        status: '',
        docUrl: '',
        icon: '',
        data: ['Checks reference documentation']
      }
    ]
  },
  {
    metric: 'security',
    key: 'security',
    metricInfo: [
      {
        key: 'sbom',
        label: 'Software bill of materials (SBOM)',
        description: `List of components in a piece of software, including licenses, versions, etc`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-clipboard-check'
      },
      {
        key: 'maintained',
        label: 'Maintained',
        description: `Whether the project is actively maintained`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-tools'
      },
      {
        key: 'code_review',
        label: 'Code review',
        description: `The project requires code review before pull requests (merge requests) are merged`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-eye'
      },
      {
        key: 'security_policy',
        label: 'Security policy',
        description: `Clearly documented security processes explaining how to report security issues to the project`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-shield-alt'
      },
      {
        key: 'signed_releases',
        label: 'Signed releases',
        description: `The project cryptographically signs release artifacts`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-signature'
      },
      {
        key: 'binary_artifacts',
        label: 'Binary artifacts',
        description: 'Whether the project has generated executable (binary) artifacts in the source repository',
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-terminal'
      },
      {
        key: 'token_permissions',
        label: 'Token permissions',
        description: `Whether the project's automated workflows tokens are set to read-only by default`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-user-secret'
      },
      {
        key: 'dangerous_workflow',
        label: 'Dangerous workflow',
        description: `Whether the project's GitHub Action workflows has dangerous code patterns`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-exclamation-triangle'
      },
      {
        key: 'dependency_update_tool',
        label: 'Dependency update tool',
        description: `The project uses a dependency update tool, specifically dependabot or renovatebot`,
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-robot'
      },
      {
        key: 'details',
        type: 'list',
        label: '',
        description: '',
        status: '',
        docUrl: '',
        icon: '',
        data: ['Checks reference documentation', 'Recommended templates: SECURITY.md']
      }
    ]
  },
  {
    metric: 'legal',
    key: 'legal',
    metricInfo: [
      {
        key: 'trademark_disclaimer',
        label: 'Trademark disclaimer',
        description: 'Projects sites should have the Linux Foundation trademark disclaimer',
        status: 'NONE',
        type: 'normal',
        docUrl: '',
        icon: 'far fa-wave-sine'
      },
      {
        key: 'details',
        type: 'list',
        label: '',
        description: '',
        status: '',
        docUrl: '',
        icon: '',
        data: ['Checks reference documentation']
      }
    ]
  }
];
