// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { ENVIRONMENT_INITIALIZER } from '@angular/core';
import { provideQueryDevTools } from '@ngneat/query-devtools';
import { injectQueryClient, provideQueryClientOptions } from '@ngneat/query';
import { Persister, persistQueryClient, persistQueryClientRestore } from '@tanstack/query-persist-client-core';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { compress, decompress } from 'lz-string';
import { environment } from '@environments/environment';

import { APP_CONFIG } from '../config';

class QueryClientProviderClass {
  private readonly persister: Persister;
  constructor() {
    if (APP_CONFIG.CLIENT_CACHING.PERSISTOR) {
      this.persister = createSyncStoragePersister({
        storage: APP_CONFIG.CLIENT_CACHING.PERSISTOR_STORAGE,
        ...(APP_CONFIG.CLIENT_CACHING.PERSISTOR_COMPRESS
          ? {
              serialize: (data) => compress(JSON.stringify(data)),
              deserialize: (data) => JSON.parse(decompress(data))
            }
          : [])
      });
    }
  }
  public getProvider() {
    const providers = [
      {
        provide: ENVIRONMENT_INITIALIZER,
        multi: true,
        useValue: () => {
          const queryClient = injectQueryClient();
          persistQueryClient({
            queryClient,
            persister: this.persister,
            maxAge: APP_CONFIG.CLIENT_CACHING.PERSISTOR_EXPIRATION
          });
          persistQueryClientRestore({
            queryClient,
            persister: this.persister,
            maxAge: APP_CONFIG.CLIENT_CACHING.PERSISTOR_EXPIRATION
          });
        }
      },
      provideQueryClientOptions({
        defaultOptions: {
          queries: APP_CONFIG.CLIENT_CACHING.QUERY_DEFAULT_CONFIG
        }
      })
    ];
    if (environment.environment === 'dev') providers.push(provideQueryDevTools({buttonPosition: 'bottom-left'}));
    return providers;
  }
}

export const queryClientProvider = new QueryClientProviderClass();
