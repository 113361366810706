// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Injectable, computed, inject, signal, untracked } from '@angular/core';
import { InsightProject, Repositories } from 'lfx-insights';
import { ApiService } from './api/Api.service';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';
import {
  buildFoundationProjects,
  convertUrlToRepositoryObj,
  groupRepositoriesByBaseUrl,
  mapFoundationDataBFF
} from './project-utils.service';
import { FilterParam } from '../cubejs/metrics/foundations/queries';
import { IProjects, IRepositories } from './api/partials/FoundationBFFApiClass';
import { derivedAsync } from 'ngxtension/derived-async';
import { createPendingObserverResult, QueryObserverResult } from '@ngneat/query';
import { combineLatest } from 'rxjs';

/**
 * Projects Service - This new service will replace the project.service.ts and will be used to fetch all the project related data.
 * This will also hold BehaviorSubjects(to be replace with Signals when we upgrade) for the foundation list, project list and repository list.
 * This will also hold the current selected foundation, project and repository.
 */

type IAllProjectsRes = QueryObserverResult<IProjects[], Error>;
type IRepositoriesRes = QueryObserverResult<IRepositories[], Error>;

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root'
})
export class ProjectNewService {
  private apiService: ApiService = inject(ApiService);
  private messageService: MessageService = inject(MessageService);

  allProjectsResponse = derivedAsync<IAllProjectsRes>(
    () => this.getFoundationSegmentsBFF({ column: 'project_type', values: ['project_group', 'subproject'] }),
    {
      initialValue: createPendingObserverResult() as IAllProjectsRes
    }
  );
  // foundation/grandparent projects
  foundationsAndProjects = computed<InsightProject[] | undefined>(() =>
    this.allProjectsResponse().isSuccess
      ? this.mapProjectsRawToInsightsProjects(this.allProjectsResponse().data)
      : undefined
  );
  public isMainListLoaded = computed<boolean>(() => this.allProjectsResponse().isSuccess);

  public selectedFoundation = signal<InsightProject | undefined>(undefined);

  public foundationChildProjects = computed(() => {
    if (this.selectedFoundation()) {
      return this.selectedFoundation()?.projects || [];
    }
    return undefined;
  });

  private repositoriesRes = derivedAsync<IRepositoriesRes>(() => this.getRepositoryBySlug(this.selectedProjectSlug()), {
    initialValue: createPendingObserverResult() as IRepositoriesRes
  });

  public repositories = computed<Repositories[] | undefined>(() =>
    this.repositoriesRes().isSuccess && this.repositoriesRes().data
      ? groupRepositoriesByBaseUrl(this.repositoriesRes().data!)
      : undefined
  );

  public selectedProjectSlug = signal<string | undefined>(undefined);
  // using computed here instead of signal because we are already fetching the project data when we set the selected foundation
  public selectedProject = computed(this.setSelectedProject.bind(this));
  public selectedRepository = signal<Repositories | undefined>(undefined);

  constructor() {}

  public setSelectedFoundationBySlug(slug: string) {
    const foundation = this.foundationsAndProjects()?.find((f) => f.slug === slug);
    if (foundation) {
      this.setSelectedFoundation(foundation);
    } else {
      this.getFoundationBySlugBFF(slug);
    }
  }
  // TODO: defined a function to set projects by slug, this is in the event that the user loaded
  // the page directly to the project overview page. This function should load all the required project info

  // SETTERS
  public setSelectedFoundation(foundation: InsightProject | undefined) {
    this.selectedFoundation.set(foundation);
  }

  public updateSelectedFoundationStartDate(startDate: string) {
    untracked(() => {
      this.selectedFoundation.update((foundation) => {
        return foundation ? { ...foundation, startDate } : foundation;
      });
    });
  }

  public setSelectedProjectSlug(slug: string) {
    this.selectedProjectSlug.set(slug);
  }

  public clearSelectedProjectSlug() {
    this.selectedProjectSlug.set(undefined);
  }

  public clearSelectedFoundation() {
    this.selectedFoundation.set(undefined);
    this.selectedProjectSlug.set(undefined);
  }

  public setRepositoryUrl(repoUrl: string) {
    this.selectedRepository.set(convertUrlToRepositoryObj(repoUrl));
  }

  public clearRepositoryUrl() {
    this.selectedRepository.set(undefined);
  }

  // end - SETTERS

  private setSelectedProject(): InsightProject | undefined {
    const projects = this.foundationChildProjects();
    if (projects && projects?.length > 0) {
      const project = projects.find((p) => p.slug === this.selectedProjectSlug());

      return project;
    }

    return undefined;
  }

  private getFoundationSegmentsBFF(typeFilter?: FilterParam, additionalFilter?: FilterParam) {
    return this.apiService.foundationBFF.getAllProjects(typeFilter, additionalFilter).result$;
  }

  private mapProjectsRawToInsightsProjects(dataRaw: IProjects[] | undefined): InsightProject[] | undefined {
    if (!dataRaw) {
      return undefined;
    }

    const isProd = environment.environment === 'prod';
    const data = this.cleanRawProjectsData(isProd ? this.removeTLF(dataRaw) : dataRaw);

    if (data.length > 0) {
      const allProjects = mapFoundationDataBFF(data);
      const foundations = buildFoundationProjects(
        allProjects.filter((p) => p.projectType === 'project_group'),
        allProjects
      );

      return this.removeFoundationsWithNoProject(foundations);
    }

    return undefined;
  }

  // TODO: remove this, this fix should be done in the backend. However, Lukas is not sure how to fix the duplicate rows
  private cleanRawProjectsData(dataRaw: IProjects[]): IProjects[] {
    const mergedData: { [key: string]: IProjects } = {};

    dataRaw.forEach((project) => {
      const key = `${project.SLUG}-${project.PROJECT_TYPE}`;

      if (!mergedData[key]) {
        mergedData[key] = { ...project };
      } else {
        // Merge the current project with the existing one
        mergedData[key] = {
          ...mergedData[key],
          ...project,
          PROJECT_SLUG: mergedData[key].PROJECT_SLUG || project.PROJECT_SLUG,
          PROJECT_GROUP_SLUG: mergedData[key].PROJECT_GROUP_SLUG || project.PROJECT_GROUP_SLUG
        };
      }
    });

    return Object.values(mergedData);
  }

  // TODO: this should be removed once ticket https://linuxfoundation.atlassian.net/browse/IN-261 is resolved
  private removeProjectLevel(dataRaw: IProjects[]): IProjects[] {
    return dataRaw.filter((d) => d.PROJECT_TYPE !== 'project');
  }

  // TODO: revisit this, it appears the new endpoint also returns foundations that doesn't have any projects. Manually removing them here for now
  private removeFoundationsWithNoProject(data: InsightProject[]): InsightProject[] {
    return data.filter((project) => {
      if (project.isFoundation) {
        // Keep the foundation if it has at least one project
        return project.projects && project.projects.length > 0;
      }
      // Keep all non-foundation projects
      return true;
    });
  }

  private removeTLF(data: IProjects[]): IProjects[] {
    return data.filter((d) => d.SLUG !== 'tlf');
  }

  private getFoundationBySlugBFF(slug: string) {
    // TODO: remove this when the backend is fixed
    combineLatest([
      this.apiService.foundationBFF.getAllProjects({ column: 'slug', values: [slug] }).result$,
      this.apiService.foundationBFF.getFoundationAndProjectsBySlug(slug, {
        column: 'project_type',
        values: ['project_group', 'subproject']
      }).result$
    ])
      .pipe(untilDestroyed(this))
      .subscribe((segmentsRes) => {
        if (segmentsRes[0].isSuccess && segmentsRes[1].isSuccess) {
          const combinedData = this.removeProjectLevel(segmentsRes[0].data).concat(segmentsRes[1].data);
          this.handleGetFoundationBySlug(combinedData);
        }

        if (segmentsRes[0].isError && segmentsRes[1].isError) {
          this.handleFetchFoundationError();
        }
      });
  }

  private handleGetFoundationBySlug(dataRaw: IProjects[]) {
    const data = this.cleanRawProjectsData(dataRaw);

    if (data.length > 0) {
      const allProjects = mapFoundationDataBFF(data);
      const foundations = buildFoundationProjects(
        allProjects.filter((p) => p.projectType === 'project_group'),
        allProjects
      );

      this.setSelectedFoundation(foundations.length > 0 ? foundations[0] : undefined);
    }
  }

  private getRepositoryBySlug(slug: string | undefined) {
    return this.apiService.foundationBFF.getRepositoriesBySlug(slug).result$;
  }

  private handleFetchFoundationError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'An error occurred while fetching Foundation data. Please try again.'
    });
  }
}
