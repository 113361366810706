// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { ValidVarTypePipe } from '../../../../pipes/valid-var-type.pipe';
import { ShortNumberPipe } from '../../../../pipes/short-number.pipe';

@Component({
    selector: 'lfx-table-number',
    templateUrl: './table-number.component.html',
    styleUrls: ['./table-number.component.scss'],
    standalone: true,
    imports: [ShortNumberPipe, ValidVarTypePipe]
})
export class TableNumberComponent {
  @Input() public value: number | null | undefined;
}
