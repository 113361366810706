import { InsightProject, KeyMetricValue, ProjectMetrics, ProjectType, Repositories } from 'lfx-insights';
import {
  IActiveContributorsKeyMetric,
  IFoundationAllTimeStats,
  IFoundationProjectKeyMetric
} from './api/partials/FoundationApiClass';
import { IRepositories } from './api/partials/FoundationBFFApiClass';
import { APP_CONFIG } from '@app/config';
import { formatDate } from './date.service';
import { NoDataPageRoutesEnum, PlatformEnum } from '../interface/common.enum';
import { ToTableCompare } from './api/basic';
import { environment } from '@environments/environment';
import { IFoundationCard, IProjects } from './api/partials/FoundationBFFApiClass';

export interface IProjectKeyMetrics {
  slug: string;
  issueResponseRate: KeyMetricValue;
  averageWait1stReview: KeyMetricValue;
}

export interface IContributorsKeyMetrics {
  slug: string;
  activeContributor: KeyMetricValue;
  participationOrg: KeyMetricValue;
}

export const mapFoundationDataBFF = (segments: IProjects[]): InsightProject[] => {
  return segments.map((segment) => {
    return {
      id: segment.SEGMENT_ID || '',
      name: segment.NAME,
      slug: segment.SLUG,
      foundationSlug: segment.PROJECT_GROUP_SLUG || '',
      parentSlug: segment.PROJECT_SLUG || '',
      projectType: (segment.PROJECT_TYPE || 'project_group') as ProjectType,
      description: segment.DESCRIPTION,
      logo: segment.LOGO,
      website: segment.WEBSITE || undefined,
      projectCategory: segment.PROJECT_TYPE === 'project_group' ? undefined : segment.ADMIN_CATEGORY || '',
      topRepoURL: segment.REPOSITORY_URL,
      foundationDate: segment.START_DATE_AS_TIMESTAMP
        ? formatDate(segment.START_DATE_AS_TIMESTAMP, APP_CONFIG.DATE_PIPE_FORMAT)
        : undefined,
      sources: segment.PLATFORMS.join(',')
    };
  });
};

export const buildFoundationProjects = (
  foundations: InsightProject[],
  allProjects: InsightProject[]
): InsightProject[] => {
  const tmp = [...foundations];

  return tmp.map((f) => {
    const foundationProjects = allProjects.filter(
      (project) => project.foundationSlug === f.slug && project.projectType !== 'project_group'
    );

    const tmpFoundation = {
      ...f,
      projects: foundationProjects
    };
    tmpFoundation.isFoundation = isFoundationProject(tmpFoundation);

    return tmpFoundation;
  });
};

export function isFoundationProject(foundation: InsightProject): boolean {
  return !(foundation.projects?.length === 1 && foundation.slug === foundation.projects[0].slug);
}

export const getProjectOverviewUrl = (project: InsightProject, foundationSlug: string): string => {
  const isGerrit = project?.sources ? project?.sources.indexOf(PlatformEnum.gerrit) >= 0 : false;
  return `/foundation/${foundationSlug}/overview/${isGerrit ? 'gerrit' : 'github'}`;
};

export const getProjectOverviewRoutedFrom = (project: InsightProject): string => {
  const isGerrit = project?.sources ? project?.sources.indexOf(PlatformEnum.gerrit) >= 0 : false;
  return isGerrit ? NoDataPageRoutesEnum.gerrit : NoDataPageRoutesEnum.github;
};

export const isGerritProject = (url: string, project?: InsightProject): boolean => {
  return isPlatformEnabled(project, PlatformEnum.gerrit) && url.includes('gerrit');
};

export const isPlatformEnabled = (
  project: InsightProject | undefined,
  platform: PlatformEnum,
  isOnly: boolean = false
): boolean => {
  if (!project?.sources || project.sources.length === 0) {
    return false;
  }
  // the isOnly flag check should only be used for git, github and gerrit platforms
  const checkPlatforms = [PlatformEnum.git, PlatformEnum.github, PlatformEnum.gerrit];

  if (isOnly && checkPlatforms.includes(platform)) {
    const projectPlatforms = project.sources
      .split(',')
      .filter((source) => checkPlatforms.includes(source as PlatformEnum));
    return projectPlatforms.length === 1 && projectPlatforms[0] === platform;
  }

  return project.sources.includes(platform);
};

export const mapAllTimeStats = (stat: IFoundationAllTimeStats): ProjectMetrics => {
  return {
    activeContributors: Number(stat['SnowSegmentCard.activeContributorsSum']),
    commitsCounts: Number(stat['SnowSegmentCard.commitsCountsSum']),
    forksCounts: Number(stat['SnowSegmentCard.forksCountsSum']),
    starsCounts: Number(stat['SnowSegmentCard.starsCountsSum']),
    issuesCounts: Number(stat['SnowSegmentCard.issuesCountsSum']),
    prCounts: Number(stat['SnowSegmentCard.prCountsSum']),
    lastActivity: stat['SnowSegmentCard.lastActivityAll'],
    topRepoURL: stat['SnowSegmentCard.topGithubOrg'],
    organizations: Number(stat['SnowSegmentCard.activeOrganizationsSum']),
    lineOfCode: Number(stat['SnowSegmentCard.linesCodeSum']),
    changesetCounts: Number(stat['SnowSegmentCard.csetCountsSum']),
    projectSlug: stat['SnowSegmentCard.slug']
  };
};

export const mapAllTimeStatsBff = (stat: IFoundationCard): ProjectMetrics => {
  return {
    activeContributors: Number(stat.CONTRIBUTORS_COUNT),
    commitsCounts: Number(stat.COMMITS_COUNT),
    forksCounts: Number(stat.FORKS_COUNT),
    starsCounts: Number(stat.STARS_COUNT),
    issuesCounts: Number(stat.ISSUES_COUNT),
    prCounts: Number(stat.PRS_COUNT),
    lastActivity: stat.LAST_ACTIVITY,
    topRepoURL: stat.REPOSITORY_URL,
    organizations: Number(stat.ORGANIZATIONS_COUNT),
    lineOfCode: Number(stat.LINES_CODE),
    changesetCounts: Number(stat.CSETS_COUNT),
    projectSlug: stat.SLUG
  };
};

export const projectKeyMetricsMapper = (
  result: ToTableCompare<IFoundationProjectKeyMetric, keyof IFoundationProjectKeyMetric>
): IProjectKeyMetrics[] => {
  const { currentTableData, previousTableData } = result;

  return currentTableData.map((currentData) => {
    const previousData = previousTableData.find(
      (prev) => prev['SnowSubSegments.project_slug'] === currentData['SnowSubSegments.project_slug']
    );
    const issueResponseRate: KeyMetricValue = {
      value: currentData['SnowProjectCardKeyMetrics.issues_time_to_comment_avg'],
      change:
        currentData['SnowProjectCardKeyMetrics.issues_time_to_comment_avg'] -
        Number(previousData ? previousData['SnowProjectCardKeyMetrics.issues_time_to_comment_avg'] : 0),
      direction: ''
    };
    issueResponseRate.direction = issueResponseRate.change > 0 ? 'text-red-dark' : 'text-green';

    const averageWait1stReview: KeyMetricValue = {
      value: currentData['SnowProjectCardKeyMetrics.pr_time_to_first_review_avg'],
      change:
        currentData['SnowProjectCardKeyMetrics.pr_time_to_first_review_avg'] -
        Number(previousData ? previousData['SnowProjectCardKeyMetrics.pr_time_to_first_review_avg'] : 0),
      direction: ''
    };
    averageWait1stReview.direction = averageWait1stReview.change > 0 ? 'text-red-dark' : 'text-green';
    return {
      slug: currentData['SnowSubSegments.project_slug'],
      issueResponseRate,
      averageWait1stReview
    };
  });
};

export const activeContributorsKeyMetricMapper = (
  result: ToTableCompare<IActiveContributorsKeyMetric, keyof IActiveContributorsKeyMetric>
): IContributorsKeyMetrics[] => {
  const { currentTableData, previousTableData } = result;

  return currentTableData.map((currentData) => {
    const previousData = previousTableData.find(
      (prev) => prev['SnowContributions.project'] === currentData['SnowContributions.project']
    );
    const currActiveContributor = Number(currentData['SnowContributions.count_contributors']);
    const prevActiveContributor = previousData ? Number(previousData['SnowContributions.count_contributors']) : 0;
    const activeContributor: KeyMetricValue = {
      value: currActiveContributor,
      change: currActiveContributor - prevActiveContributor,
      direction: ''
    };
    activeContributor.direction = activeContributor.change < 0 ? 'text-red-dark' : 'text-green';

    const participationOrg: KeyMetricValue = {
      value: Number(currentData['SnowContributions.count_organization']),
      change:
        Number(currentData['SnowContributions.count_organization']) -
        Number(previousData ? previousData['SnowContributions.count_organization'] : 0),
      direction: ''
    };
    participationOrg.direction = participationOrg.change < 0 ? 'text-red-dark' : 'text-green';
    return {
      slug: currentData['SnowContributions.project'],
      activeContributor,
      participationOrg
    };
  });
};

export const emptyMetrics = {
  participationOrg: { value: 0, change: 0, direction: 'text-green' },
  activeContributor: { value: 0, change: 0, direction: 'text-green' },
  issueResponseRate: { value: 0, change: 0, direction: 'text-green' },
  averageWait1stReview: { value: 0, change: 0, direction: 'text-green' }
};

export function groupRepositoriesByBaseUrl(repos: IRepositories[]): Repositories[] {
  return repos.map((repo: IRepositories) => {
    const segmentRepository = repo.REPOSITORY_URL;
    const baseUrl = segmentRepository.replace(/\.git$/, '');
    return {
      platform: repo.PLATFORM,
      url: baseUrl,
      id: baseUrl,
      name: tinyUrlName(baseUrl)
    };
  });
}

export const convertUrlToRepositoryObj = (repoUrl: string): Repositories => {
  return {
    id: repoUrl,
    url: repoUrl,
    name: repoUrl === 'all' ? 'All' : tinyUrlName(repoUrl)
  };
};

const tinyUrlName = (url: string) => {
  if (!url || url === 'all') {
    return '';
  }

  const parts = url.split('/');

  return `${parts[parts.length - 2]}/${parts[parts.length - 1]}`;
};
// hide the project if it is a Gerrit enabled for it & the environment is prod
export function isProdAndGerrit(project: InsightProject): boolean {
  return isPlatformEnabled(project, PlatformEnum.gerrit) && environment.environment === 'prod';
}

export * as ProjectUtilsService from './project-utils.service';
