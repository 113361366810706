// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
import { Component, Input } from '@angular/core';
import { PlatformEnum } from '@app/shared/interface/common.enum';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { DownloadStyleDirective } from '../../../../directives/download-style.directive';

@Component({
    selector: 'lfx-table-platform',
    templateUrl: './table-platform.component.html',
    styleUrls: ['./table-platform.component.scss'],
    standalone: true,
    imports: [DownloadStyleDirective, FaIconComponent, InlineSVGModule]
})
export class TablePlatformComponent {

  @Input() public platform: PlatformEnum;
  public platformEnum = PlatformEnum;
  constructor() { }

}
