import { InsightProject } from 'lfx-insights/index.js';

export type ILevels = 'grandparent' | 'parent' | 'child';
export interface IProjectSearchOption {
  label: string;
  value: InsightProject;
  level: ILevels;
}

export const mapToProjectsOptions = (
  projects: InsightProject[],
  level: ILevels = 'grandparent'
): IProjectSearchOption[] => {
  const tmpArr: IProjectSearchOption[] = [];

  projects.forEach((project) => {
    tmpArr.push({
      label: project.name,
      value: project,
      level
    });

    if (project.projects) {
      tmpArr.push(...mapToProjectsOptions(project.projects, level === 'grandparent' ? 'parent' : 'child'));
    }
  });

  return tmpArr;
};

export * as ProjectSearchService from './project-search.service';
