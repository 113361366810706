// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, input, OnInit } from '@angular/core';
import { RadialConfig } from 'lfx-insights';

import colorsPalette from '@shared/styles/color-palette.json';
import { IColorPaletteType } from '@shared/styles/color-palette.types';
import { RadarChartComponent } from '../radar-chart/radar-chart.component';

const colors = colorsPalette as unknown as IColorPaletteType;

@Component({
  selector: 'lfx-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss'],
  standalone: true,
  imports: [RadarChartComponent]
})
export class CircleProgressComponent implements OnInit {
  public chartName = input<string>('');
  public value = input<number>(0);
  public maxValue = input<number>(100);
  public color = input<string>('');
  public size = input<string>('');
  public flip = input<boolean>(false);
  public annotation = input<string | undefined>(undefined);
  public padding = input<number>(-10);
  public innerRadius = input<number>(85);

  public radialConfig: RadialConfig;

  public data: any;

  public ngOnInit(): void {
    this.data = [
      {
        category: 'value',
        value: this.value(),
        full: this.maxValue()
      }
    ];
    this.radialConfig = {
      categoryField: 'category',
      series: [
        {
          name: 'value-series',
          valueField: 'value',
          color: this.color(),
          setAppear: 1000
        },
        {
          name: 'full-series',
          valueField: 'full',
          color: colors.gray[100]
        }
      ],
      innerRadius: this.innerRadius(),
      inversed: this.flip(),
      padding: this.padding(),
      maxValue: this.maxValue()
    };
  }

  public get annotationDefault(): string {
    return (
      this.annotation() ||
      `<div class="font-bold text-[28px] text-center">
      ${this.value}%
    </div>`
    );
  }
}
