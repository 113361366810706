/* eslint-disable no-case-declarations */
// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, signal, inject, effect, untracked, output, input } from '@angular/core';
import { getDaysCountFromTwoDates } from '@app/shared/utils/cubejs-helpers';
import { faTimes, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { DateRangesEnum } from 'lfx-insights';
import { formatDate } from '@app/shared/services/date.service';
import { AuthService } from '@auth0/auth0-angular';
import { IDateFilterValues, IDateRange, IDateRangeFilter } from './date-range-filter.types';
import { getDatesFromRangeEnum } from '@app/shared/components/range-filters/range-filters.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DateRangeComponent } from '../date-range/date-range.component';
import { RangeFiltersComponent } from '../range-filters/range-filters.component';
import { PositionElementDirective } from '../../directives/position-element.directive';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgClass, NgIf, AsyncPipe, DecimalPipe } from '@angular/common';
import { TooltipTriggerDirective } from '../../directives/tooltip-trigger.directive';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { derivedAsync } from 'ngxtension/derived-async';
import { catchError, of } from 'rxjs';

// TODO: refactor this component
@Component({
  selector: 'lfx-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
  standalone: true,
  imports: [
    ClickOutsideDirective,
    TooltipTriggerDirective,
    NgClass,
    FaIconComponent,
    NgIf,
    TooltipComponent,
    PositionElementDirective,
    RangeFiltersComponent,
    DateRangeComponent,
    ReactiveFormsModule,
    FormsModule,
    AsyncPipe,
    DecimalPipe
  ]
})
export class DateRangeFilterComponent {
  public isFoundationOverviewPage = input(false);
  public compactVersion = input(false); // this might be a temporary implementation until we can get feedback from designs.
  public dateRangeFilter = input.required<IDateFilterValues>();

  public readonly selectedFilterChanged = output<IDateFilterValues>();

  private authService: AuthService = inject(AuthService);

  public selectedDateRange = signal<IDateRange>([new Date(), new Date()]);
  public filterRangeSelected = signal<DateRangesEnum>(DateRangesEnum.thisYear);
  public selectedDateDisplay = signal(DateRangesEnum.thisYear.toString());
  public hideBots = signal(true);
  public isDisplayed = signal(false);
  public compare = signal('PP');

  protected readonly icons: { [key: string]: IconDefinition } = {
    times: faTimes
  };

  // public isLoggedIn$: Observable<boolean>;
  public isLoggedIn = derivedAsync<boolean>(() => this.authService.isAuthenticated$);

  constructor() {
    this.reCheckLoginState();

    this.handleRangeFilterEffects();
  }

  public get duration(): number {
    if (this.selectedDateRange().length > 1) {
      const [start, end] = this.selectedDateRange();
      if (start && end) {
        return getDaysCountFromTwoDates(start.toDateString(), end.toDateString());
      }
    }

    return 1;
  }

  public onSelectedRangeChange(rangeEvent: IDateRangeFilter) {
    this.filterRangeSelected.set(rangeEvent.rangeEnum);
    this.selectedDateDisplay.set(rangeEvent.rangeEnum);
    this.selectedDateRange.set([...rangeEvent.dateRange]);
  }

  public onOutsideClick(target?: HTMLElement) {
    if (
      this.isDisplayed() &&
      target &&
      (target.className.indexOf('p-') >= 0 || (target.parentElement?.className || '').indexOf('p-') >= 0)
    ) {
      return;
    }

    this.isDisplayed.set(false);
    // reset value
    this.handleChanges(this.dateRangeFilter());
  }

  public openOptions() {
    this.isDisplayed.set(!this.isDisplayed());
  }

  public onSelectedChange(): void {
    this.filterRangeSelected.set(DateRangesEnum.custom);
  }

  public apply(): void {
    if (this.filterRangeSelected() === DateRangesEnum.custom) {
      this.formatSelectedDate();
    }

    this.selectedFilterChanged.emit({
      rangeEnum: this.filterRangeSelected(),
      dateRange: this.selectedDateRange(),
      hideBots: this.hideBots(),
      compare: this.compare()
    });

    this.isDisplayed.set(false);
  }

  public clear(): void {
    this.onSelectedRangeChange({
      rangeEnum: DateRangesEnum.last12Months,
      dateRange: getDatesFromRangeEnum(DateRangesEnum.last12Months)
    });
  }

  private handleChanges(currentVal: IDateFilterValues) {
    if (this.filterRangeSelected() !== currentVal.rangeEnum) {
      this.filterRangeSelected.set(currentVal.rangeEnum);
    }

    if (this.selectedDateRange() !== currentVal.dateRange) {
      this.selectedDateRange.set([...currentVal.dateRange]);
    }

    if (this.hideBots() !== currentVal.hideBots) {
      this.hideBots.set(currentVal.hideBots);
    }

    if (this.compare() !== currentVal.compare) {
      this.compare.set(currentVal.compare);
    }

    this.handleDateDisplay();
  }

  private handleDateDisplay() {
    if (this.filterRangeSelected() === DateRangesEnum.custom && this.selectedDateRange().length > 0) {
      const [start, end] = this.selectedDateRange();
      this.selectedDateDisplay.set(end ? `${formatDate(start)} to ${formatDate(end)}` : formatDate(start));
    } else {
      this.selectedDateDisplay.set(this.filterRangeSelected());
    }
  }

  private formatSelectedDate(): void {
    const [start, end] = this.selectedDateRange();
    if (this.selectedDateRange().length > 0) {
      let selectedDateDisplayTmp = formatDate(start);

      if (end) {
        selectedDateDisplayTmp += ' to ' + formatDate(end);
      }

      this.selectedDateDisplay.set(selectedDateDisplayTmp);
    } else {
      this.selectedDateDisplay.set(formatDate(start));
    }
  }

  private reCheckLoginState() {
    effect(() => {
      if (this.isLoggedIn() === false) {
        // only check if it's false, otherwise !this.isLoggedIn() will be true for undefined
        untracked(() => {
          // try to refetch the token if the user is not logged in
          this.authService
            .getAccessTokenSilently()
            .pipe(
              catchError((e) => {
                if (e) {
                  console.info('User not logged in.');
                }
                return of(null);
              })
            )
            .subscribe((res) => {
              if (res) {
                console.info('Login fetched.');
              }
            });
        });
      }
    });
  }

  private handleRangeFilterEffects() {
    effect(() => {
      const rangeFilter = this.dateRangeFilter();
      untracked(() => {
        this.handleChanges(rangeFilter);
      });
    });
  }
}
