// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

// INFO for debugging
/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { PivotConfig, Query, ResultSet } from '@cubejs-client/core';
import { catchError, firstValueFrom, map, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from '@environments/environment';
// import { HttpClient } from '@angular/common/http';
import { to } from 'await-to-js';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { IApiMethod } from '@services/api/api.types';
import { flattenColumns, getDisplayedColumns } from '../cubejs/helpers/utils';
import { traceTime } from '../utils';
import { logCubeQueries } from '../utils/debug-cube-queries';
import { CacheService } from './cache.service';

// TODO: need to clean this service. Remove any cubejs related code
@Injectable({
  providedIn: 'root'
})
export class CubeService {
  constructor(
    private httpClient: HttpClient,
    private cacheService: CacheService,
    private activatedRoute: ActivatedRoute
  ) {}

  public tryInPlayground(query: Query | Query[]) {
    if (query && Array.isArray(query)) {
      return query.map((q: Query) => this.getPlaygroundUrl(q));
    }

    return this.getPlaygroundUrl(query);
  }

  public getPlaygroundUrl(query: Query): string {
    const playgroundUrl = environment.cubejs.playgroundUrl;
    const queryUrl = `query=${encodeURIComponent(JSON.stringify(query))}`;
    const url = `${playgroundUrl}?${queryUrl}`;
    return url;
  }

  // @deprecated
  public async load(query: Query | Query[]): Promise<any> {
    if (!query) {
      return null;
    }

    const cached = this.cacheService.getItem(query);

    if (cached) {
      return cached;
    }

    const [err, resultSet] = await to(firstValueFrom(this.cubeQuery$(query)));

    if (err) {
      console.error('CubeService.load', err);
      throw err;
    }

    // INFO: for debugging performance
    // this.logNonAggregatedQueries(resultSet, query);

    this.cacheService.setItem(query, resultSet);

    return resultSet;
  }

  public getBFFCubeQuery<T>(query: Query | Query[], url: string): Observable<ResultSet<T>> {
    // INFO: we can also skip ready if we go with alternative endpoint
    // const url = `${environment.apiURL}/cube-nocache`;
    // const url = `${environment.apiURL}/cube`;
    const queryUrl = `query=${encodeURIComponent(JSON.stringify(query))}`;
    let fullUrl = `${url}?${queryUrl}`;
    const queryParameters = this.activatedRoute.snapshot.queryParamMap;
    if (queryParameters.has('cache-bypass') && queryParameters.get('cache-bypass') === 'true') {
      fullUrl += '&cache-bypass=true';
    }

    return this.httpClient.get(fullUrl).pipe(map((temp: any) => ResultSet.deserialize(temp)));
  }

  public postBFFCubeQuery<T>(
    query: Query | Query[],
    url: string,
    addtlParams?: { [key: string]: object }
  ): Observable<ResultSet<T>> {
    // INFO: we can also skip ready if we go with alternative endpoint
    // const url = `${environment.apiURL}/cube-nocache`;
    // const url = `${environment.apiURL}/cube`;
    let cacheBypass: boolean | undefined;
    const queryParameters = this.activatedRoute.snapshot.queryParamMap;
    if (queryParameters.has('cache-bypass') && queryParameters.get('cache-bypass') === 'true') {
      cacheBypass = true;
    }
    return this.httpClient
      .post(url, {
        query,
        ['cache-bypass']: cacheBypass,
        dynt: false,
        ...addtlParams
      })
      .pipe(map((temp: any) => ResultSet.deserialize(temp)));
  }

  public cubeQuery$<T>(
    query: Query | Query[],
    isMocked: boolean = false,
    filterExclusions?: string[]
  ): Observable<ResultSet<T>> {
    const cached = this.cacheService.getItem(query);

    if (cached) {
      return of(cached);
    }

    // TODO: revert this before starting a pull request
    // return this.cubejs.load(query);
    return this.loadDataFromBFF<T>(query, isMocked, filterExclusions).pipe(
      tap((resultSet) => this.cacheService.setItem(query, resultSet))
    );
  }

  public loadObs(query: Query | Query[]): Observable<ResultSet<any>> {
    const done = traceTime();
    const playgroundUrl = this.tryInPlayground(query);
    let time = 0;
    logCubeQueries(playgroundUrl, time);

    return this.cubeQuery$(query).pipe(
      switchMap((resultSet: ResultSet<any>) => {
        time = done();
        logCubeQueries(playgroundUrl, time, resultSet);

        return of(resultSet);
      }),
      catchError((err: any) => {
        time = done();

        throw err;
      })
    );
  }

  // https://codesandbox.io/s/f7uh84?file=/src/app/app.component.ts
  public toNumber(resultSet: ResultSet<any>): number {
    if (!resultSet) {
      return 0;
    }

    const numericValues = resultSet.seriesNames().map((s) => resultSet.totalRow()[s.key]);
    return numericValues[0];
  }

  public async getNumber(query: Query) {
    const resultSet = await this.load(query);
    return this.toNumber(resultSet);
  }

  public timeSeries$(query: Query) {
    return this.cubeQuery$(query).pipe(
      map((resultSet) => {
        const resultSeries = resultSet.series();

        if (!resultSeries || !resultSeries.length) {
          return [];
        }

        const seriesData = resultSeries[0].series.map((item) => ({
          ...item,
          date: new Date(item.x).getTime()
        }));

        return seriesData;
      })
    );
  }

  public singeDateSeries$(query: Query) {
    return this.multipleTimeSeries$(query, this.handleDefaultSingleDateSeries.bind(this));
  }

  public handleDefaultSingleDateSeries(resultSet: ResultSet<any>) {
    const resultSeries = resultSet.series();

    if (!resultSeries || !resultSeries.length) {
      return [];
    }

    const series = resultSeries[0].series.map((row: any) => ({
      ...row,
      // amchart uses date as unix time
      date: new Date(row.x).getTime()
    }));

    return series;
  }

  public multipleTimeSeries$(query: Query | Query[], resultSetToChart: (resultSet: ResultSet<any>) => any) {
    return this.cubeQuery$(query).pipe(map((resultSet) => resultSetToChart(resultSet)));
  }

  public async getTable(query: Query, pivotConfig?: PivotConfig) {
    const resultSet = await this.load(query);
    return this.toTable(resultSet, pivotConfig);
  }

  public getTableObs(query: Query, pivotConfig?: PivotConfig) {
    return this.loadObs(query).pipe(switchMap((resultSet) => of(this.toTable(resultSet, pivotConfig))));
  }

  public toTable(resultSet: ResultSet<any>, pivotConfig?: PivotConfig) {
    if (!resultSet) {
      return {
        tableData: [],
        displayedColumns: [],
        columnTitles: []
      };
    }
    const tableData = resultSet.tablePivot(pivotConfig);
    const displayedColumns = getDisplayedColumns(resultSet.tableColumns(pivotConfig));
    const columnTitles = flattenColumns(resultSet.tableColumns(pivotConfig));

    return {
      tableData,
      displayedColumns,
      columnTitles
    };
  }

  private loadDataFromBFF<T>(query: Query | Query[], isMocked: boolean = false, filterExclusions?: string[]) {
    const url = isMocked ? 'http://localhost:3004/api/cube' : `${environment.apiURL}/cube`;
    const method = this.getAPIMethod();
    switch (method) {
      case IApiMethod.get:
        return this.getBFFCubeQuery<T>(query, url);
      default:
        return this.postBFFCubeQuery<T>(query, url, isMocked && filterExclusions ? { filterExclusions } : undefined);
    }
  }

  private getAPIMethod() {
    const queryParameters = this.activatedRoute.snapshot.queryParamMap;
    if (queryParameters.has('method')) {
      return queryParameters.get('method');
    }
    return IApiMethod.post;
  }
}
