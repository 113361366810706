/* eslint-disable @typescript-eslint/naming-convention */
// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { MetricDropdownOptions } from '@app/shared/interface/common';
import { PlatformEnum, PlatformsFilterLeaderboard } from '@app/shared/interface/common.enum';
import { InsightsFilters } from '@app/shared/services/filter.service';
import { getDateFilterBusFactor } from '@app/shared/utils/page-helpers';
import { getRepositoryFilterValue } from '@app/shared/cubejs/helpers/utils';
import { InsightProject } from 'lfx-insights';

interface OrganizationLeaderboardRequest {
  fields: string;
  project: string;
  repository: string;
  timeRangeName: string;
  ctr?: string;
  platforms?: string;
  dateFrom?: string;
  dateTo?: string;
  segmentId: string;
  activityType: string;
  orderBy: string;
  filterBots: number;
  asc: boolean;
  limit: number;
  offset: number;
}

export const orgLeaderboardForDropdown: MetricDropdownOptions[] = [
  {
    label: 'Activities',
    value: 'SnowActivities.metric_contributions',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'contributions'
  },
  // We can count either members (profiles) or identities (usernames)
  // only contributor leaderboard counts identities, we count profiles in all other charts
  {
    label: 'Contributors',
    value: 'SnowActivities.metric_identities',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'contributions',
    isContributor: true
  },
  {
    label: 'Commit Activities',
    value: 'SnowActivities.metric_commits',
    displayFor: [PlatformEnum.git, PlatformEnum.github],
    busFactorValue: 'commits'
  },
  // { label: 'Issue Commenters', value: 'SnowActivities.metric_org_issue_commenters', displayFor: [PlatformEnum.github], busFactorValue: '' },
  {
    label: 'Issues Opened',
    value: 'SnowActivities.metric_org_issue_opened',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'issues-opened'
  },
  {
    label: 'Issues Closed',
    value: 'SnowActivities.metric_contributor_issues_closed',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'issues-closed'
  },
  {
    label: 'Pull Request Authors',
    value: 'SnowActivities.count_metric_pr_authors',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pull_request-opened',
    isContributor: true
  },
  {
    label: 'Pull Request Comments',
    value: 'SnowActivities.count_metric_pr_comments',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pr_comments'
  },
  {
    label: 'Pull Request Reviewers',
    value: 'SnowActivities.count_metric_pr_reviewers',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pull_request-reviewed',
    isContributor: true
  },
  {
    label: 'Pull Request Reviews',
    value: 'SnowActivities.count_metric_pr_reviews',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pull_request-reviewed'
  },
  {
    label: 'Pull Requests Closed',
    value: 'SnowActivities.metric_org_pr_closed',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pull_request-closed'
  },
  {
    label: 'Pull Requests Merged',
    value: 'SnowActivities.metric_org_pr_merged',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pull_request-merged'
  },
  {
    label: 'Pull Requests Opened',
    value: 'SnowActivities.metric_org_pr_opened',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pull_request-opened'
  },

  {
    label: 'Activities',
    value: 'SnowGerritActivities.metric_contributions',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'contributions'
  },
  {
    label: 'Patchsets Activities',
    value: 'SnowGerritActivities.metric_commits',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'commits'
  },
  {
    label: 'Contributors',
    value: 'SnowGerritActivities.metric_identities',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'contributions',
    isContributor: true
  },
  {
    label: 'Changeset Authors',
    value: 'SnowGerritActivities.count_metric_chgSet_authors',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'changeset-created',
    isContributor: true
  },
  {
    label: 'Comments',
    value: 'SnowGerritActivities.count_metric_comments',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'gerrit_comments'
  },
  {
    label: 'Changeset Reviewers',
    value: 'SnowGerritActivities.count_metric_chgSet_reviewers',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'changeset-reviews',
    isContributor: true
  },
  {
    label: 'Changeset Reviews',
    value: 'SnowGerritActivities.count_metric_chgSet_reviews',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'changeset-reviews'
  },
  {
    label: 'Changesets Abandoned',
    value: 'SnowGerritActivities.metric_org_chgSet_abandoned',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'changeset-abandoned'
  },
  {
    label: 'Changesets Merged',
    value: 'SnowGerritActivities.metric_org_chgSet_merged',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'changeset-merged'
  },
  {
    label: 'Changesets Opened',
    value: 'SnowGerritActivities.metric_org_chgSet_opened',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'changeset-created'
  }
];

export const getOrganizationsLeaderboardQuery = (
  filters: InsightsFilters,
  activityType: string,
  isGerrit: boolean,
  project: InsightProject,
  offset: number,
  limit: number,
  isContributorMetric: boolean
) => {
  const repositoryUrl = getRepositoryFilterValue(filters.repositories);
  const timeRangeParam = filters.dateFilters ? getDateFilterBusFactor(filters.dateFilters) : '';
  const req: OrganizationLeaderboardRequest = {
    fields:
      'ORG_NAME,ORGANIZATION_ID,ORG_LOGO_URL,ALL_ORGANIZATIONS,ALL_CHANGE_FROM_PREVIOUS,ROW_NUMBER_BY_CONTRIBUTIONS,CONTRIBUTIONS,PERCENT_TOTAL,DELTA_CONTRIBUTIONS,ROW_NUMBER_BY_CONTRIBUTORS,CONTRIBUTORS,CONTRIBUTORS_PERCENT_TOTAL,DELTA_CONTRIBUTORS',
    project: filters.project!,
    repository: [repositoryUrl === '' ? 'all-repos-combined' : repositoryUrl].toString(),
    timeRangeName: timeRangeParam,
    platforms: isGerrit ? PlatformsFilterLeaderboard.gitGerrit : PlatformsFilterLeaderboard.gitGithub,
    segmentId: project.id,
    activityType,
    orderBy: isContributorMetric? 'row_number_by_contributors': 'row_number_by_contributions',
    filterBots: filters.hideBots ? 1 : 0,
    asc: true,
    limit,
    offset
  };

  if (timeRangeParam === 'custom') {
    req.timeRangeName = '';
    req.dateFrom = filters.periods?.currentPeriod[0];
    req.dateTo = filters.periods?.currentPeriod[1];
    req.ctr = 'hll';
  }

  return req;
};
