<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->
<div class="table-name-avatar-container">
  <div class="grid-container">
    <span>
      <lfx-avatar [name]="name || 'Anonymous'" [shape]="roundedAvatar ? 'circle' : 'square'" [image]="avatar || ''" />
    </span>
    <div class="table-name" [appDownloadStyle]="{ marginTop: '-15px' }">
      {{ name || 'Anonymous' }}
    </div>
  </div>
</div>
