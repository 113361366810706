// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, EventEmitter, Output, computed, input } from '@angular/core';
import { DropdownOptions, MetricDropdownOptions } from '@app/shared/interface/common';
import { SelectComponent } from '../select/select.component';

@Component({
    selector: 'lfx-metric-select',
    templateUrl: './metric-select.component.html',
    styleUrls: ['./metric-select.component.scss'],
    standalone: true,
    imports: [SelectComponent]
})
export class MetricSelectComponent {
  @Output() public readonly selectChanged = new EventEmitter<MetricDropdownOptions>();

  public selectedMetric = input.required<MetricDropdownOptions>();
  public metrics = input.required<MetricDropdownOptions[]>();
  public dataId = input<string>('metric-select');

  public items = computed<DropdownOptions[]>(() =>
    this.metrics().map((metric) => ({ label: metric.label, value: metric.value, extraVal: metric.busFactorValue }))
  );
  public selectedItem = computed<string | undefined>(() => this.selectedMetric().value);

  public onSelectChange(val: string): void {
    const selectedMetric = this.findMetricByValue(val);
    if (selectedMetric) {
      this.selectChanged.emit(selectedMetric);
    }
  }

  private findMetricByValue(value: string): MetricDropdownOptions | undefined {
    return this.metrics().find((metric) => metric.value === value);
  }
}
