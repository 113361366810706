// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Query } from '@cubejs-client/core';
import { cubeRepositoryReport } from '../../cubes/ReporitoryReport';

export const bestPracticeScore = (repositories: string[]): Query => {
  const query: Query = {
    measures: [
      cubeRepositoryReport.measures.globalScore,
      cubeRepositoryReport.measures.documentationScore,
      cubeRepositoryReport.measures.licenseScore,
      cubeRepositoryReport.measures.bestPracticesScore,
      cubeRepositoryReport.measures.securityScore,
      cubeRepositoryReport.measures.legalScore,
      cubeRepositoryReport.measures.lastUpdate,
      cubeRepositoryReport.measures.last_clo_update
    ],
    filters: [],
    dimensions: [cubeRepositoryReport.dimensions.projectSlug],
    segments: [cubeRepositoryReport.segments.bestPracticeSource]
  };

  if (query.filters && repositories.length) {
    query.filters.push({
      member: cubeRepositoryReport.dimensions.repository,
      operator: 'equals',
      values: repositories || []
    });
  }

  return query;
};

export const bestPracticeScoreAllQuery = (projectSlugs: string[]): Query => {
  const query: Query = {
    measures: [
      cubeRepositoryReport.measures.globalScore,
      cubeRepositoryReport.measures.documentationScore,
      cubeRepositoryReport.measures.licenseScore,
      cubeRepositoryReport.measures.bestPracticesScore,
      cubeRepositoryReport.measures.securityScore,
      cubeRepositoryReport.measures.legalScore,
      cubeRepositoryReport.measures.lastUpdate,
      cubeRepositoryReport.measures.last_clo_update
    ],
    filters: [
      {
        member: cubeRepositoryReport.dimensions.projectSlug,
        operator: 'equals',
        values: projectSlugs
      }
    ],
    dimensions: [cubeRepositoryReport.dimensions.projectSlug],
    segments: [cubeRepositoryReport.segments.bestPracticeSource]
  };

  return query;
};

// TODO: modify this call to use project slug instead of the repository urls
export const bestPracticeScoreRepoDetail = (
  projectSlug: string,
  repositories: string[] | undefined,
  exactMatch: boolean
): Query => {
  const query: Query = {
    measures: [
      cubeRepositoryReport.measures.globalScore,
      cubeRepositoryReport.measures.documentationScore,
      cubeRepositoryReport.measures.licenseScore,
      cubeRepositoryReport.measures.bestPracticesScore,
      cubeRepositoryReport.measures.securityScore,
      cubeRepositoryReport.measures.legalScore,
      cubeRepositoryReport.measures.lastUpdate,
      cubeRepositoryReport.measures.last_clo_update
    ],
    filters: [
      {
        member: cubeRepositoryReport.dimensions.projectSlug,
        operator: 'equals',
        values: [projectSlug]
      }
    ],
    dimensions: [cubeRepositoryReport.dimensions.repository],
    segments: [cubeRepositoryReport.segments.bestPracticeSource]
  };

  if (query.filters && repositories?.length) {
    query.filters.push({
      member: cubeRepositoryReport.dimensions.repository,
      operator: exactMatch ? 'equals' : 'startsWith',
      values: repositories || []
    });
  }

  return query;
};

// TODO: modify this call to use project slug instead of the repository urls
export const bestPracticeReport = (repositories: string[]): Query => {
  const query: Query = {
    measures: [],
    filters: [],
    dimensions: [
      cubeRepositoryReport.dimensions.repository,
      cubeRepositoryReport.dimensions.documentationReport,
      cubeRepositoryReport.dimensions.licenseReport,
      cubeRepositoryReport.dimensions.bestPracticesReport,
      cubeRepositoryReport.dimensions.securityReport,
      cubeRepositoryReport.dimensions.legalReport
    ],
    segments: [cubeRepositoryReport.segments.bestPracticeSource]
  };

  if (query.filters && repositories?.length) {
    query.filters.push({
      member: cubeRepositoryReport.dimensions.repository,
      operator: 'equals',
      values: repositories || []
    });
  }

  return query;
};
