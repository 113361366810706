/* eslint-disable no-case-declarations */
// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { DateRange } from '@cubejs-client/core';
import { DateRangesEnum } from 'lfx-insights';
import { ParamMap } from '@angular/router';
import { formateDate, getPreviousPeriod, subtractOneYear } from '../cubejs/helpers/utils';
import { RangeFilterService } from '../components/range-filters/range-filters.service';

export function getDaysCountFromTwoDates(from = '', to = '') {
  const date1 = new Date(from);
  const date2 = new Date(to);
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays + 1;
}

export function getCurrentPreviousPeriodDateRanges(filterDateOption: DateRangesEnum | DateRange, compare = 'PP') {
  let dates;
  if (Array.isArray(filterDateOption)) {
    dates = {
      currentPeriod: filterDateOption,
      previousPeriod:
        compare === 'PP'
          ? getPreviousPeriod(filterDateOption[0], filterDateOption[1])
          : subtractOneYear(filterDateOption[0], filterDateOption[1])
    };
    return dates;
  }

  const dateRange = getFirstAndLastDayOfDateRangeType(filterDateOption);
  const currentPeriod: [string, string] = [formateDate(dateRange.startDate), formateDate(dateRange.endDate)];
  const previousPeriod =
    compare === 'PP'
      ? getPreviousPeriod(currentPeriod[0], currentPeriod[1])
      : subtractOneYear(currentPeriod[0], currentPeriod[1]);
  dates = {
    currentPeriod,
    previousPeriod
  };
  return dates;
}

export function getFirstAndLastDayOfDateRangeType(filterDateOption: DateRangesEnum | string) {
  let endDate = new Date();
  let startDate = new Date();

  const range = RangeFilterService.getDatesFromRangeEnum(filterDateOption as DateRangesEnum);
  if (range.length > 1) {
    startDate = range[0];
    endDate = range[1];
  }

  return {
    startDate,
    endDate
  };
}

export function calculatePercentageChange(oldValue = 1, newValue = 2) {
  if (!oldValue && !newValue) {
    return 0;
  }

  if (!oldValue) {
    return 100;
  }

  if (!newValue) {
    return -100;
  }

  if (oldValue === newValue) {
    return 0;
  }

  let diff = ((newValue - oldValue) / oldValue) * 100;
  diff = Math.round(diff * 100) / 100;

  return diff;
}

export function getPercentageOfRounded(part = 1, total = 100) {
  if (!part) {
    return 0;
  }

  if (!total) {
    return 100;
  }

  return Math.round((part / total) * 100);
}

export function getPercentageFixed2(part = 1, total = 100) {
  if (!part) {
    return 0;
  }

  if (!total) {
    return 100;
  }

  return +((part / total) * 100).toFixed(2);
}

export function getPersistedQueryParameter(queryParamMap: ParamMap) {
  const queryParams: any = {};
  if (queryParamMap.has('cache-bypass')) {
    queryParams['cache-bypass'] = queryParamMap.get('cache-bypass');
  }
  if (queryParamMap.has('method')) {
    queryParams.method = queryParamMap.get('method');
  }
  return queryParams;
}
