<div class="card-data" (click)="onCardNavigate()">
  <div class="data-row mb-10">
    <div class="data-col">
      <span>Total Contributors</span>
      <span class="font-semibold">{{ foundation().projectStat?.activeContributors || 0 | lfxShortNumber }}</span>
    </div>
    <div class="data-col">
      <span>Total Organizations</span>
      <span class="font-semibold">{{ foundation().projectStat?.organizations || 0 | lfxShortNumber }}</span>
    </div>
  </div>
  <div class="data-row mb-10">
    @if (foundation().numberOfProjects) {
      <div class="data-col">
        <span>{{ foundation().numberOfProjects! > 1 ? 'Projects' : 'Project' }}</span>
        <span class="font-semibold">{{ foundation().numberOfProjects | lfxShortNumber }}</span>
      </div>
    }
    <div class="data-col">
      <span>Lines of Code</span>
      <span class="font-semibold">{{ foundation().projectStat?.lineOfCode || 0 | lfxShortNumber }}</span>
    </div>
  </div>
  <div class="data-row mb-5">
    @switch (cardType()) {
      @case ('github') {
        <div class="data-col">
          <span>Pull Requests</span>
          <span class="font-semibold">{{ foundation().projectStat?.prCounts || 0 | lfxShortNumber }}</span>
        </div>
      }
      @case ('gerrit') {
        <div class="data-col">
          <span>Changesets</span>
          <span class="font-semibold">{{ foundation().projectStat?.changesetCounts || 0 | lfxShortNumber }}</span>
        </div>
      }
      @default {
        <div class="data-col">
          <span>PRs & Changesets</span>
          <span class="font-semibold">{{
            (foundation().projectStat?.prCounts || 0) + (foundation().projectStat?.changesetCounts || 0)
              | lfxShortNumber
          }}</span>
        </div>
      }
    }
    <div class="data-col">
      <span>Commits</span>
      <span class="font-semibold">{{ foundation().projectStat?.commitsCounts || 0 | lfxShortNumber }}</span>
    </div>
  </div>
</div>
