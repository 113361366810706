// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  { path: '', loadChildren: () => import('./modules/insights-dashboard/insights-dashboard.module').then((m) => m.InsightsDashboardModule) },
  { path: 'playground', loadChildren: () => import('./modules/playground/playground.module').then((m) => m.PlaygroundModule) },
  { path: 'my-dashboard', loadChildren: () => import('./modules/my-dashboard/my-dashboard.module').then((m) => m.MyDashboardModule) },
  { path: 'leaderboard', loadChildren: () => import('./modules/leaderboard/leaderboard.module').then((m) => m.LeaderboardModule) },
  { path: 'opportunities', loadChildren: () => import('./modules/opportunities/opportunities.module').then((m) => m.OpportunitiesModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'ignore',
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
