// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

/* eslint-disable @typescript-eslint/naming-convention */
import { DateRangesEnum } from 'lfx-insights';
import { DropdownOptions, MetricDropdownOptions } from '../interface/common';
import { PlatformEnum } from '../interface/common.enum';

/**
 * Since the bus factor queries uses 2 different Cubes for when the user is filtering custom date or
 * a pre-determined time range, we have 2 "types" being used by those queries.
 *
 * busFactorValue = this is used by the MV version of the Cube
 * value = this is used by the custom time range version of the Cube. Which in turn needs the metricTypeMapping keys
 *
 * TODO: this implementation of multiple Cubes should be fixed by the backend to simplify this
 */
export const metrics: MetricDropdownOptions[] = [
  {
    label: 'Activities',
    value: 'metric_contributions',
    displayFor: [PlatformEnum.github, PlatformEnum.gerrit],
    busFactorValue: 'contributions'
  },
  {
    label: 'Commit Activities',
    value: 'metric_commits',
    displayFor: [PlatformEnum.git, PlatformEnum.github],
    busFactorValue: 'commits'
  },
  {
    label: 'Patchsets Activities',
    value: 'metric_commits',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'commits'
  },
  {
    label: 'Issues Opened',
    value: 'metric_org_issue_opened',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'issues-opened'
  },
  {
    label: 'Issues Closed',
    value: 'metric_contributor_issues_closed',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'issues-closed'
  },
  {
    label: 'Pull Request Comments',
    value: 'count_metric_pr_comments',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pr_comments'
  },
  {
    label: 'Pull Request Reviews',
    value: 'count_metric_pr_reviews',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pull_request-reviewed'
  },
  {
    label: 'Pull Requests Closed',
    value: 'metric_org_pr_closed',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pull_request-closed'
  },
  {
    label: 'Pull Requests Merged',
    value: 'metric_org_pr_merged',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pull_request-merged'
  },
  {
    label: 'Pull Requests Opened',
    value: 'metric_org_pr_opened',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pull_request-opened'
  },
  { label: 'Comments', value: 'gerrit_comments', displayFor: [PlatformEnum.gerrit], busFactorValue: 'gerrit_comments' },
  {
    label: 'Changeset Reviews',
    value: 'changeset-reviews',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'changeset-reviews'
  },
  {
    label: 'Changesets Abandoned',
    value: 'changeset-abandoned',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'changeset-abandoned'
  },
  {
    label: 'Changesets Merged',
    value: 'changeset-merged',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'changeset-merged'
  },
  {
    label: 'Changesets Opened',
    value: 'changeset-created',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'changeset-created'
  }
];
// See usage from comment above
export const metricTypeMapping: { [key: string]: string[] } = {
  metric_contributions: [
    'issue-comment',
    'issues-closed',
    'issues-opened',
    'pull_request-closed',
    'pull_request-comment',
    'pull_request-merged',
    'pull_request-opened',
    'pull_request-review-thread-comment',
    'pull_request-reviewed',
    'committed-commit',
    'co-authored-commit',
    'authored-commit'
  ],
  metric_contributions_gerrit: [
    'committed-commit',
    'co-authored-commit',
    'authored-commit',
    'changeset-created',
    'changeset-merged',
    'changeset-abandoned',
    'patchset-created',
    'patchset_comment-created',
    'patchset_approval-created'
  ],
  metric_commits: ['committed-commit', 'co-authored-commit', 'authored-commit', 'signed-off-commit'],
  metric_org_issue_opened: ['issues-opened'],
  metric_contributor_issues_closed: ['issues-closed'],
  count_metric_pr_comments: ['pull_request-comment', 'pull_request-review-thread-comment'],
  count_metric_pr_reviews: ['pull_request-reviewed'],
  metric_org_pr_closed: ['pull_request-closed'],
  metric_org_pr_merged: ['pull_request-merged'],
  metric_org_pr_opened: ['pull_request-opened'],
  gerrit_comments: ['gerrit_comments'],
  ['changeset-comment']: ['changeset-comment'],
  changeset_reviews: ['changeset_reviews'],
  'changeset-abandoned': ['changeset-abandoned'],
  'changeset-merged': ['changeset-merged'],
  'changeset-reviews': ['changeset-reviews'],
  'changeset-created': ['changeset-created']
};

export const confluenceMetrics: MetricDropdownOptions[] = [
  { label: 'Attachments', value: 'attachments', displayFor: [], busFactorValue: '' },
  { label: 'Blog Posts', value: 'blog_posts', displayFor: [], busFactorValue: '' },
  { label: 'New Pages', value: 'total_new_pages', displayFor: [], busFactorValue: '' },
  { label: 'Page Comments', value: 'page_comments', displayFor: [], busFactorValue: '' }
];

/*TODO: change metrics values naming in SnowConfluenceOrganizationContributions cube to have the same as in SnowConfluence */
export const confluenceOrgContribMetrics: MetricDropdownOptions[] = [
  { label: 'Attachments', value: 'total_attachments', displayFor: [], busFactorValue: '' },
  { label: 'Blog Posts', value: 'total_blog_posts', displayFor: [], busFactorValue: '' },
  { label: 'New Pages', value: 'total_new_pages', displayFor: [], busFactorValue: '' },
  { label: 'Page Comments', value: 'total_comments', displayFor: [], busFactorValue: '' }
];

export const timezones: DropdownOptions[] = [
  { label: 'UTC-12 (U.S. Outlying Islands)', value: 'UTC-12' },
  { label: 'UTC-11 (American Samoa)', value: 'UTC-11' },
  { label: 'UTC-10 (Hawaii)', value: 'UTC-10' },
  { label: 'UTC-9 (Alaska)', value: 'UTC-9' },
  { label: 'UTC-8 (San Francisco)', value: 'UTC-8' },
  { label: 'UTC-7 (Denver)', value: 'UTC-7' },
  { label: 'UTC-6 (Chicago)', value: 'UTC-6' },
  { label: 'UTC-5 (New York)', value: 'UTC-5' },
  { label: 'UTC-4 (Halifax)', value: 'UTC-4' },
  { label: 'UTC-3 (Buenos Aires)', value: 'UTC-3' },
  { label: 'UTC-2 (Noronha)', value: 'UTC-2' },
  { label: 'UTC-1 (Azores)', value: 'UTC-1' },
  { label: 'UTC (Greenwich Mean Time)', value: 'UTC' },
  { label: 'UTC+1 (Amsterdam)', value: 'UTC+1' },
  { label: 'UTC+2 (Helsinki)', value: 'UTC+2' },
  { label: 'UTC+3 (Minsk)', value: 'UTC+3' },
  { label: 'UTC+4 (Samara)', value: 'UTC+4' },
  { label: 'UTC+5 (Karachi)', value: 'UTC+5' },
  { label: 'UTC+6 (Bangladesh)', value: 'UTC+6' },
  { label: 'UTC+7 (Bangkok)', value: 'UTC+7' },
  { label: 'UTC+8 (Hong Kong)', value: 'UTC+8' },
  { label: 'UTC+9 (Tokyo)', value: 'UTC+9' },
  { label: 'UTC+10 (Brisbane)', value: 'UTC+10' },
  { label: 'UTC+11 (Guadalcanal)', value: 'UTC+11' },
  { label: 'UTC+12 (Aukland)', value: 'UTC+12' }
];

// global dropdowns

export const technologySectors: DropdownOptions[] = [
  { label: 'All Technology Sectors', value: 'all' },
  { label: 'AI, ML, Data & Analytics', value: 'ai' },
  { label: 'Blockchain', value: 'blockchain' },
  { label: 'Cloud, Containers & Virt...', value: 'cloud' },
  { label: 'Cross-Technology', value: 'cross' },
  { label: 'Devlops, CI/CD & Site...', value: 'devlops' },
  { label: 'IoT & Embedded', value: 'iot' },
  { label: 'Linux Kernel', value: 'linux' },
  { label: 'Networking & Edge', value: 'networking' },
  { label: 'Open Hardware', value: 'open-hardware' },
  { label: 'Open Source & Compli...', value: 'open-source' },
  { label: 'Privacy & Security', value: 'privacy' },
  { label: 'Storage', value: 'storage' },
  { label: 'System Administration', value: 'system-admin' },
  { label: 'System Engineering', value: 'system-eng' },
  { label: 'Visual Effects', value: 'visual' },
  { label: 'Web & Application Dev...', value: 'web' }
];

export const contributorOrganizations: DropdownOptions[] = [
  { label: 'All Organizations', value: 'all' },
  { label: 'Apple', value: 'Apple' },
  { label: 'Amazon', value: 'Amazon' },
  { label: 'IBM', value: 'IBM' },
  { label: 'Intel', value: 'Intel' },
  { label: 'Microsoft', value: 'Microsoft' }
];

export const foundations: DropdownOptions[] = [
  { label: 'All Foundations', value: 'all' },
  { label: 'Cloud Native Computing...', value: 'CNCF' },
  { label: 'LF AI & Data', value: 'LFAI' },
  { label: 'LF Networking', value: 'LFN' },
  { label: 'PyTorch Foundation', value: 'PyTorch' },
  { label: 'Zephyr', value: 'Zephyr' }
];

export const filterRangeAll: DateRangesEnum[] = [
  DateRangesEnum.lastQuarter,
  DateRangesEnum.last12Months,
  DateRangesEnum.lastYear,
  DateRangesEnum.last3Years,
  DateRangesEnum.last10Years,
  DateRangesEnum.custom
];

export const filterRangeCompact: DateRangesEnum[] = [
  DateRangesEnum.lastQuarter,
  DateRangesEnum.last12Months,
  DateRangesEnum.lastYear,
  DateRangesEnum.last3Years,
  DateRangesEnum.last10Years,
];
