import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { CardBpScoreComponent } from '../card-bp-score/card-bp-score.component';
import { NgIf } from '@angular/common';
import { BestPracticeNoDataComponent } from '../best-practice-no-data/best-practice-no-data.component';
import { ErrorComponent } from '../error/error.component';
import { InsightProject } from 'lfx-insights';
import { BestPracticeScoreService } from '@app/shared/services/best-practice-score.service';

@Component({
  selector: 'lfx-project-card-bp-score',
  standalone: true,
  imports: [NgIf, CardBpScoreComponent, BestPracticeNoDataComponent, ErrorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './project-card-bp-score.component.html',
  styleUrl: './project-card-bp-score.component.scss'
})
export class ProjectCardBpScoreComponent {
  public project = input.required<InsightProject>();
  public isError = input(false);
  public suffix = input('');

  public isEmpty = computed(() =>
    this.project().bestPracticeScores ? this.bpScoreService.checkNoBPData(this.project().bestPracticeScores!) : true
  );

  private bpScoreService: BestPracticeScoreService = inject(BestPracticeScoreService);
}
