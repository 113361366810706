import { InsightsFiltersNew } from '@app/shared/services/filter/filter.types';
import { excludedCountries } from '@app/shared/utils/country-codes';
import { getDateFilterBusFactor } from '@app/shared/utils/page-helpers';
import { GranularityEnum, InsightProject } from 'lfx-insights';

interface TopMailingListRequest {
  project: string;
  timeRangeName: string;
  groupName: string;
  ctr?: string;
  dateFrom?: string;
  dateTo?: string;
  segmentId: string;
  filterBots: number;
  asc: boolean;
  limit: number;
  offset: number;
  granularity?: GranularityEnum;
  excludeCountries?: string;
}

export const getTopMailingListQuery = (
  filter: InsightsFiltersNew,
  project: InsightProject,
  groupName: string = '',
  offset: number,
  limit: number
) => {
  return buildQuery(filter, project, groupName, limit, offset);
};

export const getMailingListCountryQuery = (
  filter: InsightsFiltersNew,
  project: InsightProject,
  groupName: string = ''
) => {
  const query = buildQuery(filter, project, groupName);
  query.excludeCountries = excludedCountries.join(',').toLowerCase();
  return query;
};

export const getMailingTopTilesQuery = (
  filter: InsightsFiltersNew,
  project: InsightProject,
  groupName: string = ''
) => {
  return buildQuery(filter, project, groupName);
};

export const getMailingSummaryChartsQuery = (
  filter: InsightsFiltersNew,
  project: InsightProject,
  groupName: string = ''
) => {
  const query = buildQuery(filter, project, groupName);
  query.granularity = filter.granularity;
  return query;
};

export const getNewContributorsQuery = (
  filter: InsightsFiltersNew,
  project: InsightProject,
  groupName: string = '',
  offset: number,
  limit: number
) => {
  return buildQuery(filter, project, groupName, limit, offset);
};

export const getActiveContributorsQuery = (
  filter: InsightsFiltersNew,
  project: InsightProject,
  groupName: string = '',
  offset: number,
  limit: number
) => {
  return buildQuery(filter, project, groupName, limit, offset);
};

export const getNewOrganizationQuery = (
  filter: InsightsFiltersNew,
  project: InsightProject,
  groupName: string = '',
  offset: number,
  limit: number
) => {
  return buildQuery(filter, project, groupName, limit, offset);
};

export const getActiveOrganizationQuery = (
  filter: InsightsFiltersNew,
  project: InsightProject,
  groupName: string = '',
  offset: number,
  limit: number
) => {
  return buildQuery(filter, project, groupName, limit, offset);
};

export const getPopularThreadsQuery = (
  filter: InsightsFiltersNew,
  project: InsightProject,
  groupName: string = '',
  offset: number,
  limit: number
) => {
  return buildQuery(filter, project, groupName, limit, offset);
};

export const getRecentMessagesQuery = (
  filter: InsightsFiltersNew,
  project: InsightProject,
  groupName: string = '',
  offset: number,
  limit: number
) => {
  return buildQuery(filter, project, groupName, limit, offset);
};

export const getAllMailingListsQuery = (project: InsightProject) => {
  return {
    segmentId: project.id,
    project: project.slug
  };
};

const buildQuery = (
  filter: InsightsFiltersNew,
  project: InsightProject,
  groupName: string = '',
  limit: number = 0,
  offset: number = 0
) => {
  const timeRangeName = filter.dateFilters ? getDateFilterBusFactor(filter.dateFilters) : '';

  const req: TopMailingListRequest = {
    segmentId: project.id,
    project: project.slug,
    groupName,
    timeRangeName,
    asc: false,
    limit,
    offset,
    filterBots: filter.hideBots ? 1 : 0
  };

  if (timeRangeName === 'custom') {
    req.timeRangeName = '';
    req.dateFrom = filter.periods?.currentPeriod[0];
    req.dateTo = filter.periods?.currentPeriod[1];
    req.ctr = 'hll';
  }
  return req;
};
