<div class="source-icons-wrap">
  @if (!isGerritOnly()) {
    <a
      class="link-button"
      [href]="repoUrl()"
      target="_blank"
      [pTooltip]="isGitOnly() ? 'Git' : 'Github'"
      tooltipPosition="top"
      data-role="github-git-link">
      @if (isGitOnly()) {
        <fa-icon class="text-base" [icon]="['fab', 'git-alt']" data-role="git-only-icon"></fa-icon>
      } @else {
        <fa-icon class="text-base" [icon]="['fab', 'github']"></fa-icon>
      }
    </a>
  }

  @if (hasPlatform(platforms.gerrit)) {
    <div pTooltip="Gerrit" tooltipPosition="top" data-role="gerrit-icon">
      <div class="w-[20px] mr-1 link-button no-link" [inlineSVG]="'/assets/icons/gerrit.svg'"></div>
    </div>
  }
  @if (hasPlatform(platforms.groupsio)) {
    <div pTooltip="Mailing List" class="link-button no-link" tooltipPosition="top" data-role="mailing-icon">
      <fa-icon class="text-base" [icon]="['far', 'envelope']"></fa-icon>
    </div>
  }
  @if (hasPlatform(platforms.confluence)) {
    <div pTooltip="Confluence" class="link-button no-link" tooltipPosition="top" data-role="confluence-icon">
      <fa-icon class="text-base" [icon]="['fab', 'confluence']"></fa-icon>
    </div>
  }
  @if (project().website) {
    <a
      class="link-button"
      [href]="websiteUrl()"
      target="_blank"
      [pTooltip]="websiteUrl()"
      tooltipPosition="top"
      data-role="website-link">
      <fa-icon class="text-base" [icon]="['fal', 'globe']"></fa-icon>
    </a>
  } @else {
    <span class="link-button no-link">
      <fa-icon class="text-base" [icon]="['fal', 'globe']"></fa-icon>
    </span>
  }

  <span
    class="link-button no-link mr-2"
    [pTooltip]="acceptanceDateTooltip"
    tooltipPosition="top"
    data-role="acceptance-date-icon">
    <fa-icon class="text-base mr-0.5" [icon]="['fal', 'calendar-alt']"></fa-icon>
  </span>

  @if (isOnboarding()) {
    <div
      class="is-onboarding"
      [pTooltip]="'Data for this ' + (project().isFoundation ? 'foundation' : 'project') + ' is being onboarded…'"
      tooltipPosition="top"
      data-role="onboarding-icon">
      <lfx-warning [minHeight]="24"></lfx-warning>
    </div>
  }
</div>

<ng-template #acceptanceDateTooltip>
  <span>
    Accepted :
    <span class="font-bold">{{ project().foundationDate }}</span>
  </span>
</ng-template>
