// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { CubeServiceBasic } from '@services/api/basic';
import { Query } from '@cubejs-client/core';
import { map } from 'rxjs';
import { ICacheKey, ICompareBase, InjectQuery } from '@services/api/api.types';
import { CubeService } from '@services/cube.service';
import { InsightsFilters } from '@app/shared/services/filter.service';

interface IConfluenceBase {
  ['SnowConfluence.count_attachments']?: string;
  ['SnowConfluence.count_blog_posts']?: string;
  ['SnowConfluence.count_total_new_pages']?: string;
  ['SnowConfluence.count_page_comments']?: string;
}

export interface IContributorActivity extends IConfluenceBase {
  ['SnowConfluence.identity']: string;
  ['SnowConfluence.logo_url']: string;
  ['SnowConfluence.joined_date']?: string;
}

export interface IPopularPages {
  ['SnowConfluence.count_activities']: string;
  ['SnowConfluence.page_id']: string;
  ['SnowConfluence.page_title']: string;
  ['SnowConfluence.page_url']: string;
}

export interface IActivitiesByWeek {
  ['SnowConfluence.day_of_week']: string;
  ['SnowConfluence.count_activities']: string;
}

export interface IConfluenceGeoDistribution {
  ['SnowConfluence.count_contributors']: string;
  ['SnowConfluence.country']: string;
}

export type IActivitiesBreakdownType =
  | 'page-created'
  | 'page-updated'
  | 'attachment-created'
  | 'comment-created'
  | 'blogpost-created'
  | 'blogpost-updated';

export interface IActivitiesBreakdown {
  ['SnowConfluence.type']: IActivitiesBreakdownType;
  ['SnowConfluence.count_activities']: string;
}

export interface IConfluenceCacheKeys {
  project: string | undefined;
  metric?: string;
  hideBots?: boolean;
  dates: string | [string, string] | undefined;
  listType?: string;
}

export interface IOrganizationActivity extends IConfluenceBase {
  ['SnowConfluence.organization_logo']: string;
  ['SnowConfluence.organization_name']: string;
  ['SnowConfluence.joined_date']?: string;
}

export interface IOrganizationDriftingAwayActivity {
  ['SnowConfluenceOrganizationContributions.last_activity']: string;
  ['SnowConfluenceOrganizationContributions.organization_id']: string;
  ['SnowConfluenceOrganizationContributions.organization_logo']: string;
  ['SnowConfluenceOrganizationContributions.organization_name']: string | null;
  ['SnowConfluenceOrganizationContributions.organization_url']: string;
  ['SnowConfluenceOrganizationContributions.total_contributions']: string;
}

export interface IConfluenceNewOrganization {
  ['SnowConfluenceOrganizationContributions.first_activity']: string;
  ['SnowConfluenceOrganizationContributions.organization_id']: string;
  ['SnowConfluenceOrganizationContributions.organization_logo']: string;
  ['SnowConfluenceOrganizationContributions.organization_name']: string;
  ['SnowConfluenceOrganizationContributions.organization_url']: string;
  ['SnowConfluenceOrganizationContributions.total_contributions']: string;
}

export interface IConfluenceNewContributor {
  ['SnowConfluenceFirstContributions.first_contribution_time']: string;
  ['SnowConfluenceFirstContributions.member_id']: string;
  ['SnowConfluenceFirstContributions.username']: string;
  ['SnowConfluenceFirstContributions.logo_url']: string;
  ['SnowConfluenceFirstContributions.total_contributions']: string;
}

export interface IConfluenceDriftContributor {
  ['SnowConfluenceFirstContributions.last_contribution_time']: string;
  ['SnowConfluenceFirstContributions.member_id']: string;
  ['SnowConfluenceFirstContributions.username']: string;
  ['SnowConfluenceFirstContributions.logo_url']: string;
  ['SnowConfluenceFirstContributions.total_contributions']: string;
}

export interface IConfluenceActivitiesTile extends ICompareBase {
  ['SnowConfluence.count_activities']: number;
}

export interface IConfluenceContributorsTile extends ICompareBase {
  ['SnowConfluence.count_contributors']: number;
}

export interface IConfluenceFirstContributorTile extends ICompareBase {
  ['SnowConfluenceFirstContributions.count_contributors']: number;
}

export interface IConfluenceTimestamp {
  ['SnowConfluence.timestamp']?: string;
  ['SnowConfluence.timestamp.day']?: string;
  ['SnowConfluence.timestamp.week']?: string;
  ['SnowConfluence.timestamp.month']?: string;
  ['SnowConfluence.timestamp.year']?: string;
}

export interface IConfluenceSummaryChart extends IConfluenceTimestamp {
  ['SnowConfluence.count_total_new_pages']: string;
  ['SnowConfluence.count_total_pages']: string;
  ['SnowConfluence.count_attachments']: string;
  ['SnowConfluence.count_blog_posts']: string;
  ['SnowConfluence.count_page_comments']: string;
}

export interface IConfluenceSummaryChartCumulative extends IConfluenceTimestamp {
  ['SnowConfluence.count_total_pages_cumulative']: number;
}

export class ConfluenceApiClass extends CubeServiceBasic {
  protected useQuery: InjectQuery;
  constructor(cubejs: CubeService, useQuery: InjectQuery) {
    super(cubejs);
    this.useQuery = useQuery;
  }

  public getConfluenceActivitiesTile(query: Query, filters: InsightsFilters, isMocked: boolean = false) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_CONFLUENCE_ACTIVITIES_TILE,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IConfluenceActivitiesTile>(query, isMocked).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getConfluenceContributorsTile(query: Query, filters: InsightsFilters, isMocked: boolean = false) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_CONFLUENCE_CONTRIBUTORS_TILE,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () =>
        this.load$<IConfluenceContributorsTile>(query, isMocked).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getConfluenceFirstContributorsTile(query: Query, filters: InsightsFilters, isMocked: boolean = false) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_CONFLUENCE_FIRST_CONTRIBUTORS_TILE,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () =>
        this.load$<IConfluenceFirstContributorTile>(query, isMocked).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getConfluenceContributorLeaderboard(
    query: Query,
    keys: IConfluenceCacheKeys,
    isMocked: boolean = false,
    filterExclusions?: string[]
  ) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_CONFLUENCE_CONTRIBUTOR_LEADERBOARD, ...this.getKeysArr(keys)],
      queryFn: () =>
        this.load$<IContributorActivity>(query, isMocked, filterExclusions).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getConfluenceMostPopularPages(
    query: Query,
    keys: IConfluenceCacheKeys,
    isMocked: boolean = false,
    filterExclusions?: string[]
  ) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_CONFLUENCE_MOST_POPULAR_PAGES, ...this.getKeysArr(keys)],
      queryFn: () =>
        this.load$<IPopularPages>(query, isMocked, filterExclusions).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getConfluenceNewContributors(
    query: Query,
    keys: IConfluenceCacheKeys,
    isMocked: boolean = false,
    filterExclusions?: string[]
  ) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_CONFLUENCE_NEW_CONTRIBUTORS, ...this.getKeysArr(keys)],
      queryFn: () =>
        this.load$<IConfluenceNewContributor>(query, isMocked, filterExclusions).pipe(map((res) => this.toTable(res)))
    });
  }

  public getConfluenceDriftContributors(
    query: Query,
    keys: IConfluenceCacheKeys,
    isMocked: boolean = false,
    filterExclusions?: string[]
  ) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_CONFLUENCE_DRIFT_CONTRIBUTORS, ...this.getKeysArr(keys)],
      queryFn: () =>
        this.load$<IConfluenceDriftContributor>(query, isMocked, filterExclusions).pipe(map((res) => this.toTable(res)))
    });
  }

  public getConfluenceOrganizationLeaderboard(
    query: Query,
    keys: IConfluenceCacheKeys,
    isMocked: boolean = false,
    filterExclusions?: string[]
  ) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_CONFLUENCE_ORGANIZATION_LEADERBOARD, ...this.getKeysArr(keys)],
      queryFn: () =>
        this.load$<IOrganizationActivity>(query, isMocked, filterExclusions).pipe(
          map((res) => this.toTableCompare(res))
        )
    });
  }

  public getConfluenceOrganizationDriftingAway(
    query: Query,
    keys: IConfluenceCacheKeys,
    isMocked: boolean = false,
    filterExclusions?: string[]
  ) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_CONFLUENCE_ORGANIZATION_DRIFTING_AWAY, ...this.getKeysArr(keys)],
      queryFn: () =>
        this.load$<IOrganizationDriftingAwayActivity>(query, isMocked, filterExclusions).pipe(
          map((res) => this.toTable(res))
        )
    });
  }

  public getConfluenceNewOrganizations(
    query: Query,
    keys: IConfluenceCacheKeys,
    isMocked: boolean = false,
    filterExclusions?: string[]
  ) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_CONFLUENCE_NEW_ORGANIZATIONS, ...this.getKeysArr(keys)],
      queryFn: () =>
        this.load$<IConfluenceNewOrganization>(query, isMocked, filterExclusions).pipe(map((res) => this.toTable(res)))
    });
  }

  public getConfluenceActivitiesByWeek(
    query: Query,
    filters: InsightsFilters,
    isMocked: boolean = false,
    filterExclusions?: string[]
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_CONFLUENCE_ACTIVITIES_BY_WEEK,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () =>
        this.load$<IActivitiesByWeek>(query, isMocked, filterExclusions).pipe(map((res) => this.toTable(res)))
    });
  }

  public getConfluenceGeoDistribution(
    query: Query,
    keys: IConfluenceCacheKeys,
    isMocked: boolean = false,
    filterExclusions?: string[]
  ) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_CONFLUENCE_GEO_DISTRIBUTION, ...this.getKeysArr(keys)],
      queryFn: () =>
        this.load$<IConfluenceGeoDistribution>(query, isMocked, filterExclusions).pipe(
          map((res) => this.toTableCompare(res))
        )
    });
  }

  public getConfluenceActivitiesBreakdown(
    query: Query,
    filters: InsightsFilters,
    isMocked: boolean = false,
    filterExclusions?: string[]
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_CONFLUENCE_ACTIVITIES_BREAKDOWN,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () =>
        this.load$<IActivitiesBreakdown>(query, isMocked, filterExclusions).pipe(map((res) => this.toTable(res)))
    });
  }

  public getConfluenceSummaryChart(query: Query, filters: InsightsFilters, isMocked: boolean = false) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_CONFLUENCE_SUMMARY_CHART,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IConfluenceSummaryChart>(query, isMocked).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getConfluenceSummaryLegends(query: Query, filters: InsightsFilters, isMocked: boolean = false) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_CONFLUENCE_SUMMARY_LEGENDS,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IConfluenceSummaryChart>(query, isMocked).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getConfluenceSummaryCumulativeChart(query: Query, filters: InsightsFilters, isMocked: boolean = false) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_CONFLUENCE_SUMMARY_CUMULATIVE_CHART,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () =>
        this.load$<IConfluenceSummaryChartCumulative>(query, isMocked).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  private getKeysArr(keys: IConfluenceCacheKeys): any[] {
    return Object.keys(keys).map((key) => (keys as any)[key]);
  }
}
