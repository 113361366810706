// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Component, input } from '@angular/core';
import { FilterService } from '@app/shared/services/filter.service';
import { IntegerSignPipe } from '../../pipes/integer-sign.pipe';
import { ValidVarTypePipe } from '../../pipes/valid-var-type.pipe';
import { SkeletonModule } from 'primeng/skeleton';
import { ErrorComponent } from '../error/error.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgClass } from '@angular/common';
import { ShortNumberPipe } from '@app/shared/pipes/short-number.pipe';

@Component({
  selector: 'lfx-summary-charts-tab',
  templateUrl: './summary-charts-tab.component.html',
  styleUrls: ['./summary-charts-tab.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    FaIconComponent,
    ErrorComponent,
    SkeletonModule,
    ValidVarTypePipe,
    IntegerSignPipe,
    ShortNumberPipe
  ]
})
export class SummaryChartsTabComponent {
  icon = input<IconProp | null>(null);
  title = input<string | null>(null);
  mainValue = input<string | number | null>(null);
  secondaryValue = input<number | null>(null);
  secondaryValueColorType = input('negative');
  chartName = input('chart-contributors-mini');
  active = input(false);
  isLoading = input(false);
  isError = input(false);
  dataId = input<string>();
  isHoverEnabled = input(true);

  constructor(public filterService: FilterService) {}
}
