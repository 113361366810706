// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { isPlatformBrowser, NgIf, NgClass, NgFor } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import { ChartService } from '@app/shared/services/chart.service';
import {
  createChart,
  createSeriesTarget,
  createYAxis,
  mapAnnotations,
  tooltipObj
} from '@app/shared/utils/chart-helpers';
import am5themesMicro from '@amcharts/amcharts5/themes/Micro';
import { BarChartConfig, ColumnSeriesSettings, GranularityEnum } from 'lfx-insights';
import { Percent } from '@amcharts/amcharts5';
import { DownloadService } from '@app/shared/services/download.service';
import { TimeUnit } from '@amcharts/amcharts5/.internal/core/util/Time';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { TooltipTriggerDirective } from '../../directives/tooltip-trigger.directive';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'lfx-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  standalone: true,
  imports: [NgIf, LoadingComponent, NgClass, NgFor, TooltipTriggerDirective, TooltipComponent]
})
export class BarChartComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() public config!: BarChartConfig;
  @Input() public chartName!: string;
  // Changing this to an input property instead of a private one
  // some charts like the Average Wait Time needs to pull both previous and current period to complete this chart
  // See BarChartConfig for changes that will enable this component to function differently
  @Input() public data: any[] = [];
  @Input() public annotations: any = null;

  public isLoading = false;
  public noDataFound = false;
  public errorResponse = false;

  private root!: am5.Root;
  private chartRef!: am5xy.XYChart;
  private noDateFoundModal: am5.Modal;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private zone: NgZone,
    private chartService: ChartService,
    public changeDetectorRef: ChangeDetectorRef,
    private downloadService: DownloadService
  ) {}

  public ngOnInit(): void {
    if (!this.config.type) {
      this.config.type = 'normal';
    }
  }

  // Run the function only in the browser
  public browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes?.data && changes.data.currentValue?.length > 0) {
      // this.changeData();
      this.initChart();
    }
  }

  public ngAfterViewInit() {
    // Chart code goes in here
    this.browserOnly(() => {
      if (this.config.useExternalData) {
        this.initChart();
      }
    });
  }

  public ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  public checkEmptyData(): boolean {
    const check = this.chartRef.series.values.every((se) => {
      const valueField = (se as any).get('valueYField');
      return se.data.values.every((e: any) => !e[valueField]);
    });
    return check;
  }

  public initChart() {
    if (!this.isChartElementVisible()) {
      // This is a workaround for the chart not rendering when the loading state is triggered from the react
      // query devtool. Normally this shouldn't happen as this tool is not present in prod.
      setTimeout(() => {
        this.initChart();
      }, 100);
      return;
    }

    if (this.root) {
      this.root.dispose();
    }
    if (this.noDateFoundModal && this.noDateFoundModal.isOpen()) {
      this.noDateFoundModal.close();
    }

    const root = am5.Root.new(this.chartName);
    const myTheme = am5.Theme.new(root);
    root.locale.firstDayOfWeek = 1;

    // hiding all the grid lines by default
    myTheme.rule('Grid').setAll({
      strokeOpacity: 0
    });

    root.setThemes([myTheme]);

    if (this.config.useMicroTheme) {
      root.setThemes([am5themesMicro.new(root)]);
    }

    // remove footer logo
    // eslint-disable-next-line no-underscore-dangle
    root._logo?.children.clear();

    let padding = false;
    if (this.config.applyPadding) {
      padding = this.config.applyPadding;
    }

    const chart = root.container.children.push(createChart(root, padding));
    const granularity = this.getGranularity();
    const xAxis = this.createXAxis(root, chart, granularity);

    const yAxis = createYAxis(
      root,
      chart,
      { max: this.config.max, label: this.config.yAxisName, color: this.config.yAxisColors?.left },
      this.config.yAxis
    );
    if (this.config.useMicroTheme) {
      chart.plotContainer.set('wheelable', false);
    }
    /*https://github.com/LF-Engineering/lfx-insights-ui/issues/1668*/
    chart.zoomOutButton.set('forceHidden', true);
    // Create series
    this.config.series.forEach((seriesSettings: ColumnSeriesSettings) => {
      if (seriesSettings.plotAsLineChart) {
        const totalYAxis = createYAxis(
          root,
          chart,
          { max: this.config.max, label: this.config.yAxisName, color: this.config.yAxisColors?.right },
          this.config.yAxis,
          true
        );
        const lineXAxis: any = xAxis;
        const lineSeriesSettings: any = {
          ...seriesSettings,
          field: seriesSettings.valueYField
        };

        if (seriesSettings.yAxisTitle) {
          this.addRightYAxisLabel(root, totalYAxis, seriesSettings.yAxisTitle, this.config.yAxisColors?.right);
        }
        this.chartService.createLineSeries(this)(root, chart, lineXAxis, totalYAxis, lineSeriesSettings, granularity);
      } else {
        if (seriesSettings.yAxisTitle) {
          this.addLeftYAxisLabel(root, yAxis, seriesSettings.yAxisTitle, this.config.yAxisColors?.left);
        }
        this.createSeries(root, chart, xAxis, yAxis, seriesSettings, granularity);
      }
    });

    // Add legend
    if (this.config.showLegend) {
      // this.addLegend(chart, root);
    }

    // Add cursor
    chart.set('cursor', am5xy.XYCursor.new(root, {}));
    this.chartRef = chart;

    this.root = root;
    // this.checkNoDataFound(this.chartRef);
  }

  // TODO: these functions need to be refactored as well as this whole component
  public addRightYAxisLabel(root: any, yAxis: any, label: string, color?: string) {
    const yAxisLabel = am5.Label.new(root, {
      rotation: 0,
      text: label,
      position: 'absolute',
      fontSize: 12,
      fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
      fill: am5.color(color || '#807f83'),
      x: -8,
      y: -25,
      paddingTop: 0
    });

    yAxis.children.push(yAxisLabel);
  }

  public addLeftYAxisLabel(root: any, yAxis: any, label: string, color?: string) {
    let x = -5;
    if (label.length > 3) {
      x = -15;
    }
    const yAxisLabel = am5.Label.new(root, {
      rotation: 0,
      text: label,
      position: 'absolute',
      fontSize: 12,
      fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
      fill: am5.color(color || '#807f83'),
      x,
      y: -25,
      paddingTop: 0
    });

    yAxis.children.push(yAxisLabel);
  }

  public handleMissingValue() {
    const totalSeries = this.config.series.find((se) => se.plotAsLineChart);
    if (totalSeries) {
      this.data.forEach((e, index) => {
        if (e[totalSeries.valueYField] === 0 && index !== 0) {
          e[totalSeries.valueYField] = this.data[index - 1][totalSeries.valueYField];
        }
      });
    }
  }

  public getAnnotationHTML(template: string, values: any): string {
    return mapAnnotations(template, values);
  }

  public onToggleSeries(index: number): void {
    if (this.chartRef && this.chartRef.series.length > 1) {
      const series = this.chartRef.series.getIndex(index);
      if (series) {
        this.toggleSeries(series);
      }
    }
  }

  public isVisible(index: number): boolean | undefined {
    if (this.chartRef) {
      const series = this.chartRef.series.getIndex(index);
      if (series) {
        return series.get('visible');
      }

      return true;
    }

    return true;
  }

  private toggleSeries(series: am5xy.XYSeries) {
    if (series.get('visible')) {
      series.hide();
    } else {
      series.show();
    }
  }

  // private changeData(): void {
  //   if (this.chartRef) {
  //     const granularity = this.getGranularity();

  //     this.chartRef.xAxes.each((axis) => {
  //       if (this.config.xAxis?.axisType !== 'category') {
  //         (axis as am5xy.DateAxis<am5xy.AxisRenderer>).set('baseInterval', {
  //           timeUnit: granularity as TimeUnit,
  //           count: 1
  //         });
  //       }
  //       axis.data.setAll([]);
  //       axis.data.setAll(this.data);
  //     });
  //     this.chartRef.series.each((se: am5xy.XYSeries) => {
  //       // createTooltip(this.chartRef.root, this.chartRef, se, granularity);
  //       se.data.setAll(this.data);
  //       se.data.setAll(this.data);
  //     });
  //   }
  // }

  // private addLegend(chart: am5xy.XYChart, root: am5.Root) {
  //   // if config legend is set
  //   const tooltip = am5.Tooltip.new(root, {
  //     autoTextColor: false,
  //     getFillFromSprite: false,
  //     getStrokeFromSprite: true,
  //     pointerOrientation: 'vertical',
  //     labelText: '{dataContext.userData}'
  //   });

  //   tooltip.get('background')?.setAll({
  //     fill: am5.color('#000000')
  //   });

  //   tooltip.label.setAll({
  //     fill: am5.color('#ffffff'),
  //     fontSize: 12,
  //     fontFamily: 'Open Sans, Source Sans Pro, sans-serif'
  //   });

  //   // ----------------

  //   const legend = chart.children.push(
  //     am5.Legend.new(root, {
  //       centerX: am5.p50,
  //       x: am5.p50
  //     })
  //   );
  //   legend.labels.template.setAll({
  //     fontSize: 12,
  //     fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
  //     fill: am5.color('#333333')
  //   });

  //   if (this.config.showLegendTooltip) {
  //     legend.markers.template.setAll({
  //       height: 15,
  //       width: 15,
  //       tooltip,
  //       tooltipText: '{dataContext.userData}',
  //       showTooltipOn: 'hover'
  //     });
  //   } else {
  //     legend.markers.template.setAll({
  //       height: 15,
  //       width: 15
  //     });
  //   }

  //   legend.markerRectangles.template.setAll({
  //     cornerRadiusTL: 10,
  //     cornerRadiusTR: 10,
  //     cornerRadiusBL: 10,
  //     cornerRadiusBR: 10
  //   });

  //   legend.data.setAll(chart.series.values);
  // }

  private getGranularity() {
    let granularity = GranularityEnum.month;
    if (this.config.xAxis?.granularity) {
      granularity = this.config.xAxis?.granularity;
    }
    return granularity;
  }

  private createSeries(
    root: am5.Root,
    chart: am5xy.XYChart,
    xAxis: am5xy.CategoryAxis<am5xy.AxisRenderer> | am5xy.DateAxis<am5xy.AxisRenderer>,
    yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
    s: ColumnSeriesSettings,
    granularity: GranularityEnum
  ): void {
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis,
        yAxis,
        ...s,
        fill: s.color ? am5.color(s.color) : undefined,
        stacked: this.config.type === 'stacked',
        userData: s.legendTooltipText
      })
    );

    series.data.setAll([]);
    series.data.setAll(this.data);

    series.columns.template.setAll({
      // making the barWidth property override this "responsive" width code
      width:
        this.config.barWidth ||
        (this.config.type === 'normal' ? (this.data.length > 7 ? new Percent(70) : new Percent(40)) : new Percent(40)),
      tooltip: tooltipObj(root, chart, granularity, this.config.customTooltipTextAdapter, this.config.customAdapter),
      tooltipText: this.config.customAdapter === 'html' ? undefined : '{valueY}',
      tooltipHTML: this.config.customAdapter === 'html' ? '<div style="color: #fff">{valueY}</div>' : undefined,
      showTooltipOn: 'hover'
    });

    if (this.config.targetValue) {
      // adding the dashed line
      createSeriesTarget(yAxis, series, this.config.targetValue);
    }
  }

  private createXAxis(
    root: am5.Root,
    chart: am5xy.XYChart,
    granularity: GranularityEnum
  ): am5xy.CategoryAxis<am5xy.AxisRenderer> | am5xy.DateAxis<am5xy.AxisRenderer> {
    const { axisType, groupData, dateFormats, cellStartLocation, cellEndLocation, endLocation } =
      this.config.xAxis || {};

    const xRenderer = am5xy.AxisRendererX.new(root, {
      stroke: am5.color('#807f83'),
      minGridDistance: 28,
      strokeOpacity: 1,
      strokeWidth: 1,
      cellStartLocation,
      cellEndLocation
    });

    // if (
    //   (DateRangesEnum.last2Years === this.filterService.currentFilter.dateFilters ||
    //     DateRangesEnum.last30 === this.filterService.currentFilter.dateFilters) &&
    //   xAxisLabelPadding > 0
    // ) {
    //   xRenderer.labels.template.setAll({
    //     paddingLeft: xAxisLabelPadding
    //   });
    // }

    if (this.config.type === 'normal') {
      xRenderer.set('cellStartLocation', 0.2);
      xRenderer.set('cellEndLocation', 0.8);
    }

    const xAxis = chart.xAxes.push(
      axisType === 'category'
        ? am5xy.CategoryAxis.new(root, {
            renderer: xRenderer,
            categoryField: 'category',
            endLocation
          })
        : am5xy.DateAxis.new(root, {
            renderer: xRenderer,
            maxDeviation: 0,
            markUnitChange: false,
            groupData: groupData || true,
            endLocation,
            dateFormats: {
              day: 'MMM\ndd',
              week: 'MMM\ndd',
              month: 'MMM\nyy ',
              year: 'yyyy',
              ...(dateFormats || {})
            },
            baseInterval: {
              timeUnit: granularity as TimeUnit,
              count: 1
            }
          })
    );

    (xAxis as am5xy.DateAxis<am5xy.AxisRenderer>).get('renderer').labels.template.setAll({
      fill: am5.color('#807f83'),
      fontSize: 12,
      textAlign: 'center'
    });
    xAxis.data.setAll([]);
    xAxis.data.setAll(this.data);
    xAxis.appear(1000);

    return xAxis;
  }

  private isChartElementVisible(): boolean {
    return document.getElementById(this.chartName) !== null;
  }

  // since we've removed the old filter service and remove the inheritance of the ChartBaseComponent we need to add this
  // this is a temporary solution to the check no data, since a lot of component seems to rely on this
  private checkNoDataFound(chartConfig: any) {
    if (!chartConfig.series?.length) {
      this.errorResponse = true;
    } else {
      this.errorResponse = false;

      if (this.checkEmptyData()) {
        this.noDataFound = true;
      } else {
        this.noDataFound = false;
      }
    }
    this.changeDetectorRef.detectChanges();
  }
}
