// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'lfx-tools-menu',
    templateUrl: './tools-menu.component.html',
    styleUrls: ['./tools-menu.component.scss'],
    standalone: true,
    imports: [FaIconComponent]
})
export class ToolsMenuComponent {}
