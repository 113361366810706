// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[appDownloadStyle]',
    standalone: true
})
export class DownloadStyleDirective implements OnInit {
  @Input() public appDownloadStyle: { [key: string]: any };

  constructor(public elementRef: ElementRef) {}

  public ngOnInit(): void {
    if (!this.appDownloadStyle) {
      throw new Error('Download Style property cannot be empty');
    }

    this.elementRef.nativeElement.setAttribute('data-style', JSON.stringify(this.appDownloadStyle));
  }
}
