import { APP_CONFIG } from '@app/config';
import { DateRange } from '@cubejs-client/core';
import { GranularityEnum } from 'lfx-insights';
import { DateTime, DateTimeUnit, Duration, DurationLikeObject, DurationUnits, Interval } from 'luxon';

interface IDateRescale {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  milliseconds?: number;
}

export interface IYearMonthDay {
  year: number;
  month: number;
  day: number;
}

export interface ITimeBreakdown {
  hour: number;
  minute: number;
  second: number;
}

export interface IWeekBreakdown {
  weekNumber: number;
  weekYear: number;
  weekday: number;
  weeksInWeekYear: number;
}

/*
 * Luxon specific functions
 * These functions would return values specific to Luxon like DateTime, Duration, etc.
 */
const convertStringToDateTime = (date: string, zone?: string): DateTime => {
  const dt = DateTime.fromISO(date, zone ? { zone } : undefined);
  if (dt.isValid) {
    return dt;
  }

  return DateTime.fromSQL(date, zone ? { zone } : undefined);
};

export const dateFromFormat = (
  date: string,
  format: string = APP_CONFIG.DATE_FORMATTING_SHORT,
  zone?: string
): DateTime => DateTime.fromFormat(date, format, zone ? { zone } : undefined);

export const dateToLuxon = (date: string | number | Date, zone?: string): DateTime => {
  // use switch case here
  switch (true) {
    case typeof date === 'number':
      return DateTime.fromMillis(date as number);
    case typeof date === 'string':
      return convertStringToDateTime(date as string, zone);
    default:
      return DateTime.fromJSDate(date as Date);
  }
};

export const plus = (date: DateTime | string | number | Date, duration: DurationLikeObject): DateTime =>
  date instanceof DateTime ? date.plus(duration) : dateToLuxon(date).plus(duration);
export const minus = (date: DateTime | string | number | Date, duration: DurationLikeObject): DateTime =>
  date instanceof DateTime ? date.minus(duration) : dateToLuxon(date).minus(duration);

// End Luxon specific functions

// Basic Functions
export const formatDate = (
  date: DateTime | string | number | Date,
  format: string = APP_CONFIG.DATE_FORMATTING_SHORT,
  zone?: string
) => {
  if (date instanceof DateTime) {
    return date.toFormat(format);
  }

  return dateToLuxon(date, zone).toFormat(format);
};

export const dateFromFormatToJS = (
  date: string,
  format: string = APP_CONFIG.DATE_FORMATTING_SHORT,
  zone?: string
): Date => toJSDate(dateFromFormat(date, format, zone));

export const dateToISO = (date: string): string | undefined => dateToLuxon(date).toISO() || undefined;

export const toJSDate = (date: DateTime): Date => date.toJSDate();

export const toISODate = (date: DateTime): string => date.toISODate() || ''; // using Luxon ISO Date

export const toJSISOString = (date: DateTime): string => toJSDate(date).toISOString(); // using JS date function that includes the time

// Higher order functions
export const subtractToJSISO = (
  amount: number,
  unit: keyof DurationLikeObject,
  date?: string | number | Date,
  zone?: string
): string => {
  const dateRes = date ? dateToLuxon(date, zone) : DateTime.now();

  return toJSISOString(minus(dateRes, { [unit]: amount }));
};

export const addToJSISO = (
  amount: number,
  unit: keyof DurationLikeObject,
  date?: string | number | Date,
  zone?: string
): string => {
  const dateRes = date ? dateToLuxon(date, zone) : DateTime.now();
  return toJSISOString(plus(dateRes, { [unit]: amount }));
};

export const subtractToJS = (
  amount: number,
  unit: keyof DurationLikeObject,
  date?: string | number | Date,
  zone?: string
): Date => {
  const dateRes = date ? dateToLuxon(date, zone) : DateTime.now();

  return toJSDate(minus(dateRes, { [unit]: amount }));
};

export const addToJS = (
  amount: number,
  unit: keyof DurationLikeObject,
  date?: string | number | Date,
  zone?: string
): Date => {
  const dateRes = date ? dateToLuxon(date, zone) : DateTime.now();
  return toJSDate(plus(dateRes, { [unit]: amount }));
};

export const subtractToFormat = (
  amount: number,
  unit: keyof DurationLikeObject,
  date?: string | number | Date,
  format?: string,
  zone?: string
): string => {
  const dateRes = date ? dateToLuxon(date, zone) : DateTime.now();

  return formatDate(minus(dateRes, { [unit]: amount }), format);
};

export const addToFormat = (
  amount: number,
  unit: keyof DurationLikeObject,
  date?: string | number | Date,
  format?: string,
  zone?: string
): string => {
  const dateRes = date ? dateToLuxon(date, zone) : DateTime.now();
  return formatDate(plus(dateRes, { [unit]: amount }), format);
};

export const dateToISODate = (date: string | number | Date, zone?: string): string =>
  toISODate(dateToLuxon(date, zone));

export const driftingAwayRange: DateRange = [subtractToFormat(6, 'months'), subtractToFormat(3, 'months')];

const durationShiftValue = (duration: Duration, unit: keyof DurationLikeObject): number =>
  duration.shiftTo(unit).get(unit);
const formatDuration = (value: number, unit: string): string => `${value.toFixed(2)} ${unit}${value > 1 ? 's' : ''}`;

// @Sameh, please help review this this section. I've ported your original function here and translated it as I understood it.
export const formatHoursToDaysHoursMin = (totalHours: number): string => {
  const rescaleObj = rescaleDuration({ hours: totalHours });
  const hoursToDuration = Duration.fromObject({ hours: totalHours });
  let durationValue = 0;

  switch (true) {
    case !!rescaleObj.days:
      durationValue = durationShiftValue(hoursToDuration, 'days');
      return formatDuration(durationValue, 'day');
    case !!rescaleObj.hours:
      durationValue = durationShiftValue(hoursToDuration, 'hours');
      return formatDuration(durationValue, 'hour');
    default:
      durationValue = durationShiftValue(hoursToDuration, 'minutes');
      return formatDuration(durationValue, 'min');
  }
};

// another function to get the days, hours, minutes, and seconds is .toHuman() method
export const rescaleDuration = (duration: DurationLikeObject): IDateRescale =>
  Duration.fromObject(duration).rescale().toObject();

export const dateDiff = (
  date1: string | number | Date,
  date2: string | number | Date,
  unit: keyof DurationLikeObject
): number => {
  const dt1 = dateToLuxon(date1);
  const dt2 = dateToLuxon(date2);

  return dt1.diff(dt2, unit).get(unit);
};

export const startOfDT = (date: string | number | Date, unit: DateTimeUnit): DateTime => {
  let dt = dateToLuxon(date);

  if (unit === 'week') {
    dt = dt.plus({ days: 1 });
  }
  return dt.startOf(unit);
};

export const endOfDT = (date: string | number | Date, unit: DateTimeUnit): DateTime => {
  let dt = dateToLuxon(date);

  if (unit === 'week') {
    dt = dt.plus({ days: 1 });
  }
  return dt.endOf(unit);
};

export const today = (format: string = APP_CONFIG.DATE_FORMATTING_SHORT) => DateTime.now().toFormat(format);

export const startOf = (
  date: string | number | Date,
  unit: DateTimeUnit,
  format: string = APP_CONFIG.DATE_FORMATTING_SHORT
): string => formatDate(startOfDT(date, unit), format);

export const endOf = (
  date: string | number | Date,
  unit: DateTimeUnit,
  format: string = APP_CONFIG.DATE_FORMATTING_SHORT
): string => formatDate(endOfDT(date, unit), format);

export const startOfJSDate = (date: string | number | Date, unit: DateTimeUnit): Date =>
  toJSDate(startOfDT(date, unit));

export const endOfJSDate = (date: string | number | Date, unit: DateTimeUnit): Date => toJSDate(endOfDT(date, unit));

export const getYearMonthDay = (date: string | number | Date, zone?: string): IYearMonthDay => {
  const dt = dateToLuxon(date, zone);

  return {
    year: dt.year,
    month: dt.month,
    day: dt.day
  };
};

export const getMilliseconds = (date: string | number | Date): number => dateToLuxon(date).toMillis();

// used in landing page and projects page in the foundation overview
export const getRangeDates = (unit: keyof DurationLikeObject, value: number): [DateRange, DateRange] => {
  const currentJsDate = new Date();
  const currToDate = formatDate(currentJsDate);
  const currFromDate = formatDate(minus(currentJsDate, { [unit]: value }));
  const prevToDate = formatDate(minus(currFromDate, { day: 1 }));
  const prevFromDate = formatDate(minus(currFromDate, { [unit]: value, day: 1 }));
  const currentPeriod: DateRange = [currFromDate, currToDate];
  const previousPeriod: DateRange = [prevFromDate, prevToDate];

  return [currentPeriod, previousPeriod];
};

const timeDurations: DurationUnits = ['years', 'months', 'days', 'hours', 'minutes'];

const getLargestNonZero = (timeDiff: Duration, durationIdx: number): string => {
  let diffValue = '';

  for (let i = durationIdx; i < timeDurations.length; i++) {
    const getVal = timeDiff.get(timeDurations[i] as any);
    if (getVal > 0) {
      diffValue = `${Math.round(getVal)} ${getVal > 1 ? timeDurations[i] : timeDurations[i].slice(0, -1)}`;
      break;
    }
  }
  return diffValue;
};

// used in luxon pipe
export const convertToDateAgo = (
  dt: Date | string | number,
  dateDifference: keyof DurationLikeObject,
  format: string = APP_CONFIG.DATE_FORMATTING_SHORT
): string => {
  const timeDiff = DateTime.now().diff(dateToLuxon(dt), timeDurations);
  let isDiffAllowed = true;
  let diffIdx = 0;

  switch (dateDifference) {
    case 'hours':
      isDiffAllowed = timeDiff.years === 0 && timeDiff.months === 0 && timeDiff.days === 0;
      diffIdx = 3;
      break;
    case 'days':
      isDiffAllowed = timeDiff.years === 0 && timeDiff.months === 0;
      diffIdx = 2;
      break;
    case 'months':
      isDiffAllowed = timeDiff.years === 0;
      diffIdx = 1;
      break;
    default:
      diffIdx = 0;
  }

  return isDiffAllowed ? `${getLargestNonZero(timeDiff, diffIdx)} ago` : formatDate(dt, format);
};

export const isDateInInterval = (dateRange: [string, string], date: string | number | Date): boolean => {
  const interval = Interval.fromDateTimes(dateToLuxon(dateRange[0]), dateToLuxon(dateRange[1]));
  return interval.contains(dateToLuxon(date));
};

export const createDateIntervals = (
  fromDate: string | number | Date,
  toDate: string | number | Date,
  granularity: GranularityEnum
): number[] =>
  Interval.fromDateTimes(dateToLuxon(fromDate), dateToLuxon(toDate))
    .splitBy(granularity === 'month' ? { month: 1 } : { week: 1 })
    .map((interval: Interval) => {
      // return the millisecond start of date
      const startOfDate = interval.start?.startOf(granularity);

      return startOfDate ? startOfDate.toMillis() : 0;
    });

export * as DateService from './date.service';
