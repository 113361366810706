@if (!isError()) {
  @if (!isEmpty()) {
    <lfx-card-bp-score [project]="project()" [suffix]="suffix()" />
  } @else {
    <div class="h-[117px] flex items-center p-4">
      <lfx-best-practice-no-data [isCompact]="true" />
    </div>
  }
} @else {
  <div class="min-h-[117px] flex items-center justify-center">
    <lfx-error />
  </div>
}
