<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->
<div class="circle-wrap" [style]="{ height: size() === '' ? undefined : size(), width: size() === '' ? undefined : size() }">
  <!-- TODO: find all the circle prog and add names to them -->
  <lfx-radar-chart
    [chartName]="chartName() !== '' ? chartName() : 'circleProg' + value()"
    [data]="data"
    [annotation]="annotationDefault"
    [config]="radialConfig"></lfx-radar-chart>
</div>
