<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->
@if (project().keyMetrics) {
  <div class="mt-6 pb-4 px-3 text-xs flex flex-col flex-grow">
    <div class="flex justify-between">
      <span class="font-semibold">Key Metrics</span>
      <span class="italic text-gray">last 3 months</span>
    </div>
    <lfx-key-metric-row [title]="'Active Contributors'" [keyMetric]="project().keyMetrics?.activeContributor" />
    <lfx-key-metric-row [title]="'Participating Organizations'" [keyMetric]="project().keyMetrics?.participationOrg" />

    @if (hasGithubSource()) {
      <lfx-key-metric-row
        [title]="'Issue Response Rate'"
        [keyMetric]="project().keyMetrics?.issueResponseRate"
        [type]="'date'" />

      <lfx-key-metric-row
        [title]="'Avg. Wait Time For 1st Review'"
        [keyMetric]="project().keyMetrics?.averageWait1stReview"
        [type]="'date'" />
    }
  </div>
} @else {
  <lfx-loading [minHeight]="100"></lfx-loading>
}
