// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { InsightsFiltersNew } from '@app/shared/services/filter/filter.types';
import { getRepositoryFilterValue } from '@app/shared/cubejs/helpers/utils';
import { excludedCountries } from '@app/shared/utils/country-codes';

export const getOverviewActiveDaysQuery = (segmentId: string, filter: InsightsFiltersNew, isGerrit: boolean) => {
  const repository = getRepositoryFilterValue(filter.repositories);
  return {
    fields: 'ACTIVE_DAYS,AVG_CONTRIBUTIONS,PREV_ACTIVE_DAYS,PREV_AVG_CONTRIBUTIONS',
    segmentId,
    repository,
    dateFrom: filter.periods?.currentPeriod[0],
    dateTo: filter.periods?.currentPeriod[1],
    filterBots: filter.hideBots ? 1 : 0,
    aggregationType: repository.length > 0 ? 'SRMD' : 'SMD',
    platforms: ['git', isGerrit ? 'gerrit' : 'github']
  };
};

export const getOverviewGeoDistributionQuery = (segmentId: string, filter: InsightsFiltersNew, isGerrit: boolean) => {
  const repository = getRepositoryFilterValue(filter.repositories);
  return {
    segmentId,
    repository,
    dateFrom: filter.periods?.currentPeriod[0],
    dateTo: filter.periods?.currentPeriod[1],
    countAll: 0,
    excludeCountries: excludedCountries,
    platforms: ['git', isGerrit ? 'gerrit' : 'github']
  };
};

export const getOverviewContributionsOutsideWorkHoursQuery = (segmentId: string, filter: InsightsFiltersNew) => {
  const repository = getRepositoryFilterValue(filter.repositories);
  return {
    fields: 'DAY,HOUR,COMMITS,ALL_COMMITS',
    segmentId,
    repository,
    dateFrom: filter.periods?.currentPeriod[0],
    dateTo: filter.periods?.currentPeriod[1],
    tz: 'local'
  };
};
