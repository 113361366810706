// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Pipe, PipeTransform } from '@angular/core';
import { catchError, isObservable, map, Observable, of, startWith } from 'rxjs';

@Pipe({
    name: 'withLoading',
    standalone: true
})
export class WithLoadingPipe implements PipeTransform {
  public transform(val: Observable<any>): Observable<{ loading: boolean; value?: any; error?: any }> {
    return isObservable(val)
      ? val.pipe(
          map((value: any) => ({ loading: false, value })),
          startWith({ loading: true }),
          catchError((error) => of({ loading: false, error }))
        )
      : val;
  }
}
