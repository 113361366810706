/* eslint-disable @typescript-eslint/quotes */
// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { InsightsFilters } from '@app/shared/services/filter.service';
import { tryInPlayground } from '@app/shared/utils/debug-cube-queries';
import { DateRange, Query, TableColumn } from '@cubejs-client/core';
import { DateRangesEnum, GranularityEnum } from 'lfx-insights';
import { ShortNumberPipe } from '../../pipes/short-number.pipe';

export const visualizationPlaygrounds = new Map<string, string[] | { [children: string]: string }>();

/**
 * Usage:
 * addCubeLinkInspector([query], 'name_used_in_lfx-download component')
 *
 * calling this methods multiple times will replace the previous query
 * in this way, it hold the last version of the visualization query
 */
export function addCubeLinkInspector(query: Query | Query[], cardName: string) {
  if (Array.isArray(query)) {
    const urls = query.map((q) => tryInPlayground(q));
    visualizationPlaygrounds.set(cardName, urls);
    return;
  }

  const url = tryInPlayground(query);
  visualizationPlaygrounds.set(cardName, [url]);
}

/**
 *
 * This is useful when you want to have multiple links to the same card,
 * but with different filters or from different component files.
 *
 * This method allow to reuse the same card name, but with different child names.
 *
 * Usage:
 * addCubeLinkInspectorChildren(query, 'name_used_in_lfx-download component', 'unique_name')
 *
 *
 */
export function addCubeLinkInspectorChildren(query: Query, cardName: string, childName: string) {
  const previous = visualizationPlaygrounds.get(cardName) || {};

  const url = tryInPlayground(query);
  visualizationPlaygrounds.set(cardName, {
    ...previous,
    [childName]: url
  });
}

export function getVisualizationPlayground(key: string) {
  return visualizationPlaygrounds.get(key) || '';
}

export function openVisualizationInPlayground(key: string) {
  const urls = getVisualizationPlayground(key);
  if (urls && Array.isArray(urls)) {
    openUrls(urls);
    return;
  }
  if (urls && typeof urls === 'object') {
    openUrls(Object.values(urls));
    return;
  }
}

function openUrls(urlsList: string | string[]) {
  if (typeof urlsList === 'string') {
    window.open(urlsList, '_blank');
    return;
  }

  const [first, ...urls] = urlsList;
  window.open(first, '_blank');

  if (!urls.length) {
    return;
  }

  let i = 0;

  const interval = setInterval(() => {
    window.open(urls[i], '_blank');
    i++;
    if (i >= urls.length) {
      clearInterval(interval);
    }
  }, 1000);
}

function printCubeLinks() {
  // eslint-disable-next-line no-restricted-syntax
  console.log('Cube links', visualizationPlaygrounds);
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.printCubeLinks = printCubeLinks;

export interface MailingListFilters {
  ['SnowSubSegments.project_slug']: string | undefined;
  ['SnowGroupsioProjectGroups.group_name']?: string;
}

export interface MailingListGroupsioFilters {
  ['SnowGroupsioNewContributors.project']: string | undefined;
  ['SnowGroupsioNewContributors.group_name']?: string;
  ['SnowGroupsioNewContributors.is_bot']?: boolean;
}

export interface CubeFilters {
  projectId?: string;
  projectName?: string;
  repositoryUrl?: string;
  dateRange?: DateRangesEnum | DateRange;
  compareDateRange?: [string, string][] | [DateRange, DateRange] | any;
  hideBots?: boolean;
  granularity?: GranularityEnum;
  platform?: string[];
  // use it for logging and cube link generation
  queryName?: string;
}

export function getDisplayedColumns(tableColumns: TableColumn[]): string[] {
  const queue = tableColumns;
  const columns = [];

  while (queue.length) {
    const column = queue.shift();
    if (column && column.dataIndex) {
      columns.push(column.dataIndex);
    }
    if (column && column.children && column.children.length) {
      column.children.map((child) => queue.push(child));
    }
  }

  return columns;
}

export function flattenColumns(columns: TableColumn[] = []): any {
  return columns.reduce((memo, column) => {
    const titles = flattenColumns(column.children);
    return [
      ...memo,
      ...(titles.length ? titles.map((title: string) => column.shortTitle + ', ' + title) : [column.shortTitle])
    ];
  }, [] as any);
}

export function subtractOneYear(startDate: string, endDate: string): [string, string] {
  const d1 = new Date(startDate);
  const d2 = new Date(endDate);

  d1.setFullYear(d1.getFullYear() - 1);
  d2.setFullYear(d2.getFullYear() - 1);

  return [d1.toISOString().substring(0, 10), d2.toISOString().substring(0, 10)];
}

// TODO: refactor and move this to a date service
export function getPreviousPeriod(startDate: string, endDate: string): [string, string] {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const periodDuration = end.getTime() - start.getTime() + 24 * 60 * 60 * 1000;

  const previousPeriodStart = new Date(start);
  previousPeriodStart.setTime(previousPeriodStart.getTime() - periodDuration);

  const previousPeriodEnd = new Date(end);
  previousPeriodEnd.setTime(previousPeriodEnd.getTime() - periodDuration);

  return [previousPeriodStart.toISOString().substring(0, 10), previousPeriodEnd.toISOString().substring(0, 10)];
}

export function formateDate(date: Date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function getPercentageVariationFromToNumbers(from: number, to: number): number {
  if (from === 0 && to === 0) {
    return 0;
  }

  if (from === 0) {
    return 100;
  }

  let variation = Math.round(((to - from) / from) * 100);

  if (isNaN(variation)) {
    variation = 0;
  }

  if (!isFinite(variation)) {
    variation = 0;
  }

  return variation;
}

export function getVariationCategory(variation = 0) {
  if (variation > 0) {
    return 'positive';
  } else if (variation === 0) {
    return 'zero';
  }
  return 'negative';
}

export function getChartDescriptionByVariation(variation = 0) {
  const shortNumberPipe = new ShortNumberPipe();
  if (variation > 0) {
    return ` increased by ${shortNumberPipe.transform(variation)}%`;
  } else if (variation === 0) {
    return ` remained the same as`;
  }

  return ` decreased by ${Math.abs(shortNumberPipe.transform(variation))}%`;
}

export function getRepositoryFilterValue(values: string[] | undefined) {
  if (!values || !values.length) {
    return '';
  }

  const filterValue = values[0] || '';
  if (!filterValue) {
    return '';
  }

  if (filterValue === 'all') {
    return '';
  }

  return filterValue;
}

interface CubeFiltersOptions {
  useCompare?: boolean; // INFO: use compareDateRange and generate both resultSets
  useCurrentPeriod?: boolean; // INFO: use current period as dateRange
  usePreviousPeriod?: boolean; // INFO: use previous period as dateRange
}

export const insightsFilterToCubeFiltersUseCurrentPeriod = (filters: InsightsFilters): CubeFilters =>
  insightsFilterToCubeFilters(filters, { useCurrentPeriod: true });

export const insightsFilterToCubeFiltersUsePreviousPeriod = (filters: InsightsFilters): CubeFilters =>
  insightsFilterToCubeFilters(filters, { usePreviousPeriod: true });

export const buildMailingListsFilters = (
  filters: InsightsFilters,
  selectedMailingList: string
): MailingListFilters => ({
  ['SnowSubSegments.project_slug']: filters.project,
  ...(filters.hideBots ? { ['SnowMembers.is_bot']: false } : {}),
  ...(selectedMailingList.toLowerCase() !== 'all lists'
    ? { ['SnowGroupsioMailigLists.group_name']: selectedMailingList.toLowerCase() }
    : {})
});

// INFO: enforce CompareDateRange and it will returns an array or ResultSet<any>
export const insightsFilterToCubeFilters = (filters: InsightsFilters, options?: CubeFiltersOptions): CubeFilters => {
  const { useCompare = true, useCurrentPeriod = false, usePreviousPeriod = false } = options || {};

  if (!filters.periods?.currentPeriod) {
    throw new Error('Current period is required');
  }

  if (useCompare && !filters.periods?.previousPeriod) {
    throw new Error('Previous period is required');
  }

  const innerPlatformValue = filters.platform && filters.platform[0] === 'all' ? undefined : filters.platform;

  const queryFilters: CubeFilters = {
    projectId: filters.project,
    hideBots: filters.hideBots,
    granularity: filters.granularity,
    repositoryUrl: getRepositoryFilterValue(filters.repositories),
    platform: innerPlatformValue
  };

  if (useCurrentPeriod) {
    queryFilters.dateRange = filters.periods?.currentPeriod;
    return queryFilters;
  }

  if (usePreviousPeriod) {
    queryFilters.dateRange = filters.periods?.previousPeriod;
    return queryFilters;
  }

  if (useCompare) {
    delete queryFilters.dateRange;
    queryFilters.compareDateRange = [filters.periods?.currentPeriod, filters.periods?.previousPeriod];
    return queryFilters;
  }

  return queryFilters;
};

export function updateTimeDimensionsWithCubeFilters(cubeFilters: CubeFilters, query: Query): Query {
  const newQuery: Query = JSON.parse(JSON.stringify(query));

  if (!newQuery.timeDimensions) {
    throw new Error('Query must have timeDimensions');
  }

  if (!newQuery.timeDimensions[0]) {
    throw new Error('Query must have timeDimensions[0]');
  }

  let newTimeDimension: any = newQuery.timeDimensions[0];

  const { dateRange, compareDateRange } = cubeFilters;
  if (compareDateRange) {
    newTimeDimension = {
      ...newTimeDimension,
      compareDateRange
    };

    delete newTimeDimension.dateRange;
  } else {
    newTimeDimension = {
      ...newTimeDimension,
      dateRange
    };

    delete newTimeDimension.compareDateRange;
  }

  newQuery.timeDimensions = [newTimeDimension];

  return newQuery;
}

interface UpdateQueryWithFiltersOpts {
  cubeRepositoryFilter?: string;
  cubeRepositoryFilterOperator?: string;
  cubeProjectFilter?: string;
  cubeProjectFilterOperator?: string;
  cubeMemberFilter?: string;
  useRepoWildcard?: boolean;
  cubePlatformFilter?: string;
}

export function useContributionsCubeFilters(filters: CubeFilters, rawQuery: Query) {
  return updateQueryWithFilters(filters, rawQuery, {
    cubeRepositoryFilter: 'SnowContributions.channel',
    cubeProjectFilter: 'SnowContributions.project',
    cubeMemberFilter: 'SnowContributions.is_bot',
    cubePlatformFilter: 'SnowContributions.platform'
  });
}

export function buildMailingListFiltersQuery(
  query: Query,
  queryFilters: MailingListFilters | MailingListGroupsioFilters
): Query {
  return {
    ...query,
    filters: [
      ...((query?.filters as any) || []),
      ...Object.keys(queryFilters).map((param) => ({
        member: param,
        operator: 'equals',
        values: [queryFilters[param as keyof (MailingListFilters | MailingListGroupsioFilters)] as string]
      }))
    ]
  };
}

export function updateQueryWithFilters(filters: CubeFilters, rawQuery: Query, opts?: UpdateQueryWithFiltersOpts) {
  const {
    cubeRepositoryFilter = 'SnowActivities.repository_url',
    cubeRepositoryFilterOperator = 'equals',
    cubeProjectFilter = 'SnowSubSegments.subproject_slug',
    cubeProjectFilterOperator = 'equals',
    cubeMemberFilter = 'SnowMembers.is_bot',
    cubePlatformFilter = 'SnowActivities.platform',
    useRepoWildcard // pass this if we are using an ad-hoc 'all-repos-combined' to the filter
  } = opts || {};

  const newQuery = JSON.parse(JSON.stringify(rawQuery));

  if (newQuery.filters === undefined) {
    newQuery.filters = [];
  }

  const { projectId, repositoryUrl, hideBots, platform } = filters;

  const existRepository = useRepoWildcard ? false : !!repositoryUrl && repositoryUrl !== 'all';
  const existProject = !!projectId && projectId !== 'all';

  if (!existRepository && newQuery.filters && existProject) {
    newQuery.filters.push({
      member: cubeProjectFilter,
      operator: cubeProjectFilterOperator,
      values: [projectId]
    });
  }

  if (repositoryUrl && newQuery.filters) {
    const filterReposForAllPlatforms = [
      // *INFO
      repositoryUrl, // Count issues , prs  and any data from github integration
      `${repositoryUrl}.git` //  count commits from git integration
    ];
    newQuery.filters.push({
      member: cubeRepositoryFilter,
      operator: cubeRepositoryFilterOperator,
      values: filterReposForAllPlatforms
    });
  }

  if (hideBots && newQuery.filters && cubeMemberFilter !== '') {
    newQuery.filters.push({
      member: cubeMemberFilter,
      operator: 'equals',
      values: ['false']
    });
  }

  if (platform && newQuery.filters) {
    newQuery.filters.push({
      member: cubePlatformFilter,
      operator: 'equals',
      values: platform
    });
  }

  return newQuery;
}

/*
* How to use it:

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
    window.generateDebugQuery(query, {message: 'cube 1'});

 * this will console log 2 values
 * 1. playground Url
 * 2. the final JSON query using all current filters
 * In the case of using compareDateRange , it will log 2 JSON queries (current and previous dateRanges)
*/
export function generateCubeQueriesFromCompareDateRange(query: Query): Query[] {
  return generateDebugQuery(query);
}
export function generateDebugQuery(rawQuery: Query, opts: any = {}): any[] {
  const { message = '', exclude = '', include = '', log = false } = opts || {};

  const toText = JSON.stringify(rawQuery).toLowerCase();
  if (include && toText.includes(include.toLowerCase())) {
    return [];
  }

  if (exclude && !toText.includes(exclude.toLowerCase())) {
    return [];
  }

  const query = JSON.parse(JSON.stringify(rawQuery));

  if (query.timeDimensions) {
    const timeDimension: any = query.timeDimensions[0];
    const { compareDateRange } = timeDimension || {};
    if (compareDateRange) {
      const [current, previous] = compareDateRange || [];

      const currentTimedimensionDateRange: any = query.timeDimensions[0];
      delete currentTimedimensionDateRange.compareDateRange;
      currentTimedimensionDateRange.dateRange = current;

      const currentQuery = JSON.parse(JSON.stringify({ ...query, timeDimensions: [currentTimedimensionDateRange] }));
      const currentUrl = tryInPlayground(currentQuery);

      const previousTimedimensionDateRange: any = query.timeDimensions[0];
      delete previousTimedimensionDateRange.compareDateRange;
      previousTimedimensionDateRange.dateRange = previous;

      const previousQuery = JSON.parse(JSON.stringify({ ...query, timeDimensions: [previousTimedimensionDateRange] }));
      const previousUrl = tryInPlayground(previousQuery);

      if (log) {
        // eslint-disable-next-line no-restricted-syntax
        console.log(JSON.stringify({ msg: `${message}: currentQuery`, debug: { currentUrl, currentQuery } }, null, 2));
        // eslint-disable-next-line no-restricted-syntax
        console.log(
          JSON.stringify({ msg: `${message}: previousQuery`, debug: { previousUrl, previousQuery } }, null, 2)
        );
      }

      return [currentQuery, previousQuery];
    }
  }
  const url = tryInPlayground(query);

  if (log) {
    // eslint-disable-next-line no-restricted-syntax
    console.log(JSON.stringify({ msg: `${message}: debug cube query`, debug: { query, url } }, null, 2));
  }

  return [query];
}

export function generateDebugQueries(rawQueries: Query[], { message = '', exclude = '', include = '' } = {}) {
  rawQueries.forEach((q) => {
    generateDebugQuery(q, { message, exclude, include });
  });
}

export function openURlInNewTab(url: string) {
  if (!url.startsWith('http')) {
    url = '//' + url;
  }
  window.open(url, '_blank');
}

function hex(c: number) {
  const s = '0123456789abcdef';
  let i = c;
  if (i === 0 || isNaN(c)) {
    return '00';
  }
  i = Math.round(Math.min(Math.max(0, i), 255));
  return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16);
}

/* Convert an RGB triplet to a hex string */
function convertToHex(rgb: number[]) {
  return hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2]);
}

/* Remove '#' in color hex string */
function trim(s: string) {
  return s.charAt(0) === '#' ? s.substring(1, 7) : s;
}

/* Convert a hex string to an RGB triplet */
function convertToRGB(hexColor: string) {
  const color = [];
  color[0] = parseInt(trim(hexColor).substring(0, 2), 16);
  color[1] = parseInt(trim(hexColor).substring(2, 4), 16);
  color[2] = parseInt(trim(hexColor).substring(4, 6), 16);
  return color;
}

export function generateGradientColors(colorStart: string, colorEnd: string, colorCount: number) {
  // The beginning of your gradient
  const start = convertToRGB(colorStart);

  // The end of your gradient
  const end = convertToRGB(colorEnd);

  // The number of colors to compute
  const len = colorCount;

  //Alpha blending amount
  let alpha = 0.0;

  const saida = [];

  for (let i = 0; i < len; i++) {
    const c = [];
    alpha += 1.0 / len;

    c[0] = start[0] * alpha + (1 - alpha) * end[0];
    c[1] = start[1] * alpha + (1 - alpha) * end[1];
    c[2] = start[2] * alpha + (1 - alpha) * end[2];

    saida.push(convertToHex(c));
  }

  return saida;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.generateDebugQuery = generateDebugQuery;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.generateDebugQueries = generateDebugQueries;
