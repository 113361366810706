<p-avatar
  [label]="label"
  [styleClass]="styleClass"
  [size]="size"
  [shape]="shape"
  [image]="imgSrc"
  [style]="{
    backgroundColor: avatarBackgroundColor,
    color
  }" />
