// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'firstLetters',
    standalone: true
})
export class FirstLettersPipe implements PipeTransform {
  public transform(name: string): string {
    if (!name) {
      return '';
    }
    const firstLetters = name
      .split(' ')
      .map((x) => {
        if (!x[0]) {
          return x;
        }
        return x[0].toUpperCase();
      })
      .slice(0, 2);

    return firstLetters.join('');
  }
}
