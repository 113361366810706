// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const environment = {
  production: false,
  environment: 'dev',
  apiURL: 'https://insights-bff.dev.platform.linuxfoundation.org/api',
  bffURL: 'https://insights-bff2.dev.platform.linuxfoundation.org/',
  lfxHeader: 'https://cdn.dev.platform.linuxfoundation.org',
  myProfileURL: 'https://myprofile.dev.platform.linuxfoundation.org/',
  linuxFoundationSFID: 'a0941000002wBz9AAE',
  cubejs: {
    playgroundUrl: 'https://lfx-dev.cubecloud.dev/deployments/25/playground',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2ODIwMTk0NjZ9.Zj9QVrylEogrycbSSxp1JmaISJJEpzjRsHGUelRBVkU',
    options: {
      apiUrl: 'https://jade-trout.aws-us-west-2-t-11709.cubecloudapp.dev/cubejs-api/v1'
      // cube server running on CNCF server
      // apiUrl: 'http://147.75.85.27:4000/cubejs-api/v1'
    }
  },
  auth0: {
    domain: 'linuxfoundation-dev.auth0.com',
    clientId: 'Ljg9aIFFxsmWr7f7dD4dE1EmEs9u7h3T',
    redirectUrl: `${window.location.origin}`,
    audience: 'https://api-gw.dev.platform.linuxfoundation.org/'
  },
  datadogEnv: 'development',
  traceOrigins: ['https://insights-bff.dev.platform.linuxfoundation.org'],
  features: {
    gitOnly: true,
    foundationAnalytics: true,
    projectVelocity: true,
    projectMaturity: true,
    mailingList: true,
    confluence: true,
    gerrit: true,
    newSidNav: true,
    projectHealth: true,
    contributionHealth: false
  }
};
