// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, computed, effect, inject, input } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { FilterService } from '@app/shared/services/filter.service';
import { LocationService } from '@app/shared/services/Location.service';
import { getPersistedQueryParameter } from '@app/shared/utils/cubejs-helpers';
import { InsightProject, Repositories } from 'lfx-insights';
import { MessageService } from 'primeng/api';
import { DropdownOptions } from '@app/shared/interface/common';
import { FilterContainer } from '../top-filters/top-filters.component';
import {
  addAllItemToRepos,
  filterReposByPlatform,
  getProjectBySlug,
  mapProjectsToDropdownOptions,
  mapReposToDropdownOptions
} from './project-repo-filter.service';
import {
  getProjectOverviewRoutedFrom,
  getProjectOverviewUrl,
  isGerritProject,
  isPlatformEnabled
} from '@app/shared/services/project-utils.service';
import { ProjectNewService } from '@app/shared/services/project-new.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { PlatformEnum } from '@app/shared/interface/common.enum';
import { NgIf } from '@angular/common';
import { SelectComponent } from '../select/select.component';
import { sortBy } from 'lodash';

@Component({
  selector: 'lfx-project-repo-filter',
  templateUrl: './project-repo-filter.component.html',
  styleUrls: ['./project-repo-filter.component.scss'],
  standalone: true,
  imports: [SelectComponent, NgIf]
})
export class ProjectRepoFilterComponent {
  public repositories = computed<DropdownOptions[]>(() => {
    const repos = this.applyRepositoryFilter(this.projectNewService.repositories() || []);
    return mapReposToDropdownOptions(addAllItemToRepos(repos));
  });

  /**
   * Selected repository of project repo filter component
   * allow to select the first repo if the selected repo is not defined yet
   * the selected repo value changes only when the user select a repo from the dropdown,
   * but for project that has just one repo the selected repo is not defined
   */
  public selectedRepository = computed(
    () => this.projectNewService.selectedRepository()?.url || this.repositories()?.[0]?.value || ''
  );
  public projects = computed<DropdownOptions[]>(() =>
    sortBy(mapProjectsToDropdownOptions(this.projectNewService.foundationChildProjects() || []), 'label')
  );
  public selectedProjectSlug = computed(() => this.projectNewService.selectedProject()?.slug || '');

  public foundation = input.required<InsightProject>();
  public filterContainer = input<FilterContainer>(FilterContainer.foundationOverview);

  public isRepoSelectVisible = computed(
    () =>
      this.filterContainer() === FilterContainer.projectPages || this.filterContainer() === FilterContainer.reportsPages
  );
  public isMailGroupVisible = computed(() => this.filterContainer() === FilterContainer.mailingList);
  public allowFoundationNavigation = computed(
    () =>
      [FilterContainer.foundationOverview, FilterContainer.foundationProjects].indexOf(this.filterContainer()) < 0 &&
      (this.foundation()?.projects?.length || 0) > 1
  );

  public isOverviewPage = input<boolean>(false);

  public projectNewService: ProjectNewService = inject(ProjectNewService);
  private filterService: FilterService = inject(FilterService);
  private router: Router = inject(Router);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private locationService: LocationService = inject(LocationService);
  private messageService: MessageService = inject(MessageService);

  private urlPath = toSignal<string>(
    this.route.url.pipe(map((urlSegments: UrlSegment[]) => urlSegments?.[0]?.path || ''))
  );

  private isGerritProject = computed(() =>
    isGerritProject(this.urlPath() || '', this.projectNewService.selectedProject())
  );
  private isGitOnly = computed(() =>
    isPlatformEnabled(this.projectNewService.selectedProject(), PlatformEnum.git, true)
  );

  constructor() {
    // TODO: revisit this implementation, avoid effect as much as possible
    effect(
      () => {
        this.handleRouterChanges();
      },
      {
        allowSignalWrites: true
      }
    );
  }

  public onSelectProjectChange(slug: string) {
    const selectedProject = getProjectBySlug(this.projectNewService.foundationChildProjects() || [], slug);
    if (selectedProject) {
      this.setSelectedProject(selectedProject);
      this.handleChangeProjectRedirection(selectedProject);
    }
  }

  public goToFoundationView() {
    const foundationSlug = this.route.snapshot.paramMap.get('foundationSlug') || '';

    if (!foundationSlug) {
      this.messageService.add({
        key: 'toast',
        severity: 'error',
        summary: 'Error',
        detail: 'Foundation slug is not defined'
      });
    }

    this.filterService.applyFilterPartially({
      ...this.filterService.defaultFilters,
      foundationSlug
    });
    this.projectNewService.clearSelectedProjectSlug();

    const queryParams = getPersistedQueryParameter(this.route.snapshot.queryParamMap);
    this.router.navigate(['/'], {
      queryParams
    });
    this.router.navigate([`/foundation/${foundationSlug}`], {
      queryParams
    });
  }

  public onSelectRepository(repo: string) {
    const foundationSlug = this.route.snapshot.paramMap.get('foundationSlug');

    if (!foundationSlug) {
      this.messageService.add({
        key: 'toast',
        severity: 'error',
        summary: 'Error',
        detail: 'Foundation slug is not defined'
      });
      return;
    }

    const projectSlug = this.projectNewService.selectedProject()?.slug;

    if (!projectSlug) {
      this.messageService.add({
        key: 'toast',
        severity: 'error',
        summary: 'Error',
        detail: 'Project slug is not defined'
      });
      return;
    }

    this.updateServices(repo);
  }

  private setSelectedProject(selectedProject: InsightProject) {
    this.projectNewService.setSelectedProjectSlug(selectedProject.slug);
    this.projectNewService.clearRepositoryUrl();

    this.filterService.applyFilterPartially({
      project: selectedProject.slug,
      repositories: undefined
    });
  }

  private handleChangeProjectRedirection(selectedProject: InsightProject) {
    // if there isn't any project selected ,
    // we are in foundation page and
    // we need to redirect to the overview page
    const foundationSlug = this.route.snapshot.paramMap.get('foundationSlug');
    const projectSlug = selectedProject.slug;
    const [, FOUNDATION, , foundationSubPage, reportSubPage] = this.locationService.pathname.split('/');
    let newUrl = '';
    if (!foundationSubPage || foundationSubPage === 'overview' || foundationSubPage === 'projects') {
      newUrl = getProjectOverviewUrl(selectedProject, foundationSlug || '');
    } else {
      newUrl = `/${FOUNDATION}/${foundationSlug}/${foundationSubPage}`;
    }

    if (reportSubPage && foundationSubPage !== 'overview') {
      newUrl += `/${reportSubPage}`;
    }

    const routedFrom = getProjectOverviewRoutedFrom(selectedProject);

    this.router.navigate([newUrl], {
      queryParams: { project: projectSlug, repository: '', routedFrom },
      queryParamsHandling: 'merge'
    });
  }

  private updateServices(repoUrl?: string) {
    if (repoUrl) {
      this.filterService.applyFilterPartially({
        repositories: [repoUrl]
      });
      this.projectNewService.setRepositoryUrl(repoUrl);
    }
  }

  private applyRepositoryFilter(allRepos: Repositories[]) {
    let repos = [...allRepos];
    repos = filterReposByPlatform(repos, this.isOverviewPage() && this.isGerritProject(), this.isGitOnly());

    return repos;
  }

  private handleRouterChanges() {
    // check user navigate to Github or Gerrit Page && check user select a repo or not
    if (this.isOverviewPage() && this.selectedRepository() !== '' && this.selectedRepository() !== 'all') {
      const repoPlatform = this.repositories().find((repo) => repo.value === this.selectedRepository())?.extraVal;
      // check the selected repo platform if doesn't fit the current page platform => need to rest the selected repo
      if (!repoPlatform) {
        this.onSelectRepository('all');
      }
    }
  }
}
