// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { BffService } from '../bff.service';
import { BffConstants } from '@services/api/bff.constants';

export class BFFServiceBasic {
  private bffService: BffService;

  constructor(bffService: BffService) {
    this.bffService = bffService;
  }

  protected loadData<T>(
    apiURL: BffConstants,
    query: Record<string, any>,
    options: { signal?: AbortSignal; [key: string]: any } = {},
    isMocked: boolean = false
  ): Promise<T> {
    return this.bffService.loadData<T>(apiURL, query, options, isMocked);
  }
}
