// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { LuxonDatePipe } from '@app/shared/pipes/luxon-date.pipe';
import { InsightProject } from 'lfx-insights';
import { BestPracticeEmbedComponent } from '../best-practice-embed/best-practice-embed.component';
import { NgIf } from '@angular/common';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { TooltipTriggerDirective } from '../../directives/tooltip-trigger.directive';

@Component({
    selector: 'lfx-card-bp-score',
    templateUrl: './card-bp-score.component.html',
    styleUrls: ['./card-bp-score.component.scss'],
    standalone: true,
    imports: [TooltipTriggerDirective, TooltipComponent, NgIf, BestPracticeEmbedComponent]
})
export class CardBpScoreComponent {
  @Input() public project: InsightProject;
  @Input() public suffix = '';

  constructor(private luxonDatePipe: LuxonDatePipe) {}

  public scoreUpdatedDate(dt?: number): string {
    return `<span class='font-bold'>Last Updated:</span> ` + this.luxonDatePipe.transform(dt, undefined, 'hours');
  }
}
