<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<div class="bi-directional-wrapper">
  <div class="labels-wrap" *ngIf="showLabels">
    <div [appDownloadStyle]="{ marginBottom: '10px' }" [innerHTML]="leftLabel" (click)="onLabelClicked(0)"></div>
    <div [appDownloadStyle]="{ marginBottom: '10px' }" [innerHTML]="rightLabel" (click)="onLabelClicked(1)"></div>
  </div>
  <div class="bars-wrap">
    <div class="left-progress-wrap">
      <div class="progress-value" [style]="{ width: leftPercentage + '%', backgroundColor: leftColor }">
        <span [appDownloadStyle]="{ marginBottom: '10px' }">
          {{ leftPercentage > 0 ? leftPercentage + '%' : '' }}
        </span>
      </div>
    </div>
    <div class="mid-wrap">{{ config ? config.title : 'PROGRESS BAR' }}</div>
    <div class="right-progress-wrap">
      <div class="progress-value" [style]="{ width: rightPercentage + '%', backgroundColor: rightColor }">
        <span [appDownloadStyle]="{ marginBottom: '10px' }">
          {{ rightPercentage > 0 ? rightPercentage + '%' : '' }}
        </span>
      </div>
    </div>
  </div>
</div>
