// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { ICacheKey, InjectQuery } from '@services/api/api.types';
import { BffService } from '../../bff.service';
import {
  getContributorDiversificationQuery,
  getProjectHealthForksQuery,
  getProjectHealthScores,
  getProjectHealthStarsQuery
} from '@app/shared/queries/project-health/project-health';
import { InsightProject } from 'lfx-insights';
import { BffConstants } from '@services/api/bff.constants';
import { BFFServiceBasic } from '@services/api/bff.basic';

export interface IContributorDiversification {
  YEAR: string;
  CURR_BUS_FACTOR: number;
  CURR_ELEPHANT_FACTOR: number;
  CURR_TOP_COMMITERS_COMMITS_PERCENT: number;
  CURR_TOP_COMMITING_ORGS_COMMITS_PERCENT: number;
  COMMITS_COUNT: number;
}

export interface IProjectHealthForks {
  YEAR: string;
  FORKS_COUNT: number;
}

export interface IProjectHealthStars {
  YEAR: string;
  STARS_COUNT: number;
}

export interface IProjectHealthScores {
  ENABLED_REPO_COUNTS: number;
  RUN_AT: string;
  SLUG: string;
  PROJECT: string;
  REPOSITORY_URL: string;
  AVG_BINARY_ARTIFACTS_SCORE: number;
  AVG_BRANCH_PROTECTION_SCORE: number;
  AVG_CI_TESTS_SCORE: number;
  AVG_CII_BEST_PRACTICES_SCORE: number;
  AVG_CODE_REVIEW_SCORE: number;
  AVG_CONTRIBUTORS_SCORE: number;
  AVG_DANGEROUS_WORKFLOW_SCORE: number;
  AVG_DEPENDENCY_UPDATE_TOOL_SCORE: number;
  AVG_FUZZING_SCORE: number;
  AVG_SCORE_CARD_LICENSE_SCORE: number;
  AVG_MAINTAINED_SCORE: number;
  AVG_PACKAGING_SCORE: number;
  AVG_PINNED_DEPENDENCIES_SCORE: number;
  AVG_SAST_SCORE: number;
  AVG_SECURITY_POLICY_SCORE: number;
  AVG_SIGNED_RELEASES_SCORE: number;
  AVG_TOKEN_PERMISSIONS_SCORE: number;
  AVG_VULNERABILITIES_SCORE: number;
  AVG_SCORECARD_SCORE: number;
  TOTAL_SCORE: number;
}

export class ProjectHealthApiClass extends BFFServiceBasic {
  protected useQuery: InjectQuery;
  constructor(bffService: BffService, useQuery: InjectQuery) {
    super(bffService);
    this.useQuery = useQuery;
  }

  public getContributorDiversification(
    project: InsightProject | undefined,
    options: { signal?: AbortSignal; [key: string]: any } = {}
  ) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_CONTRIBUTOR_DIVERSIFICATION, project?.slug] as const,
      queryFn: () => {
        const query = getContributorDiversificationQuery(project!.slug, project!.id);
        return this.loadData<IContributorDiversification[]>(BffConstants.GLOBAL_YEARLY_STATS, query, options);
      },
      enabled: !!project?.slug
    });
  }

  public getProjectHealthForks(
    project: InsightProject | undefined,
    options: { signal?: AbortSignal; [key: string]: any } = {}
  ) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_PROJECT_HEALTH_FORKS, project?.slug] as const,
      queryFn: () => {
        const query = getProjectHealthForksQuery(project!.slug, project!.id);
        return this.loadData<IProjectHealthForks[]>(BffConstants.PROJECT_HEALTH_FORKS, query, options);
      },
      enabled: !!project?.slug
    });
  }

  public getProjectHealthStars(
    project: InsightProject | undefined,
    options: { signal?: AbortSignal; [key: string]: any } = {}
  ) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_PROJECT_HEALTH_STARS, project?.slug] as const,
      queryFn: () => {
        const query = getProjectHealthStarsQuery(project!.slug, project!.id);
        return this.loadData<IProjectHealthStars[]>(BffConstants.PROJECT_HEALTH_STARS, query, options);
      },
      enabled: !!project?.slug
    });
  }

  public getProjectHealthScores(
    project: InsightProject | undefined,
    options: { signal?: AbortSignal; [key: string]: any } = {}
  ) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_PROJECT_HEALTH_SCORES, project?.slug] as const,
      queryFn: () => {
        const query = getProjectHealthScores(project!.slug);
        return this.loadData<IProjectHealthScores[]>(BffConstants.PROJECT_HEALTH_SCORES, query, options);
      },
      enabled: !!project?.slug
    });
  }
}
