// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, inject, Input, OnInit } from '@angular/core';
import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { LoadingComponent } from '../loading/loading.component';
import { CommentaryIconComponent } from '../commentary-icon/commentary-icon.component';
import { NgIf } from '@angular/common';
import { ShortNumberPipe } from '../../pipes/short-number.pipe';

@Component({
    selector: 'lfx-variation-card-header',
    templateUrl: './variation-card-header.component.html',
    styleUrls: ['./variation-card-header.component.scss'],
    standalone: true,
    imports: [NgIf, CommentaryIconComponent, LoadingComponent]
})
export class VariationCardHeaderComponent implements OnInit {
  @Input() public beforeVariationText: string;
  @Input() public variation: number | undefined;
  @Input() public variationBoldText: string;
  @Input() public afterVariationText: string;
  @Input() public increasedText: string = 'increased by';
  @Input() public decreasedText: string = 'decreased by';
  @Input() public increasedIcon: IconDefinition | [IconPrefix, IconName] = ['fas', 'chart-line'];
  @Input() public decreasedIcon: IconDefinition | [IconPrefix, IconName] = ['fas', 'chart-line-down'];
  @Input() public noChangeText = 'remained the same as';
  @Input() public formatVariation: any;
  @Input() public hideCommentaryIcon: boolean = false;
  @Input() public isReversedIcons = false; // this option used to show red icon while there is increase in rate
  @Input() public displaySign = true; // to handle display variation sign or not ==> -10%, +14%

  private shortNumberPipe = inject(ShortNumberPipe);
  constructor() {}

  public ngOnInit() {}

  public get variationDirection() {
    if (this.variation === undefined) {
      return;
    }
    let formattedVariation = this.variation;
    if (this.formatVariation) {
      formattedVariation = this.formatVariation(Math.abs(this.variation));
    }
    if (this.variation > 0) {
      return ` ${this.increasedText} ${
        this.formatVariation ? formattedVariation : (this.displaySign ? '+' : '') + this.shortNumberPipe.transform(formattedVariation) + '%'
      }`;
    }
    if (this.variation === 0) {
      return ` ${this.noChangeText}`;
    }

    return ` ${this.decreasedText} ${
      this.formatVariation
        ? formattedVariation
        : (this.displaySign ? this.shortNumberPipe.transform(formattedVariation) : this.shortNumberPipe.transform(Math.abs(formattedVariation))) + '%'
    }`;
  }
}
