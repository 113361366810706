// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input, OnInit } from '@angular/core';
import { bestPracticesScoresKeys, getBestPracticeColor, getScoreValue } from '@app/shared/utils/page-helpers';
import { BestPracticeScores } from 'lfx-insights';
import { LoadingComponent } from '../loading/loading.component';
import { BestPracticesDetailsComponent } from '../best-practices-details/best-practices-details.component';
import { CircleProgressComponent } from '../circle-progress/circle-progress.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'lfx-best-practice-embed',
    templateUrl: './best-practice-embed.component.html',
    styleUrls: ['./best-practice-embed.component.scss'],
    standalone: true,
    imports: [NgIf, CircleProgressComponent, NgFor, BestPracticesDetailsComponent, LoadingComponent]
})
export class BestPracticeEmbedComponent implements OnInit {
  @Input() public scoreData: BestPracticeScores;
  @Input() public inModal: boolean = true;
  @Input() public id: string = '';
  @Input() public hideIcon: boolean = false;

  public bestPracticesScores = bestPracticesScoresKeys;
  public renderChart: boolean = true;

  constructor() {}

  public ngOnInit(): void {
    // work around for modals causing the chart to not render the size properly
    if (this.inModal) {
      setTimeout((): void => {
        this.renderChart = true;
      }, 200);
    } else {
      this.renderChart = true;
    }
  }

  public get annotation(): string | undefined {
    return this.inModal
      ? undefined
      : `<div class="font-bold text-[21px] text-center">${this.getScoreValue('globalScore')}%</div>`;
  }

  public getScoreValue(key: string | string[]): number {
    return getScoreValue(this.scoreData, key);
  }

  public getColor(value: number) {
    return getBestPracticeColor(value);
  }
}
