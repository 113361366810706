<div class="flex justify-center relative" [ngStyle]="{ 'height.px': logoHeight() }">
  @if (isSVGLogo() && !hideSVGTag()) {
    <div
      class="svg-wrap hidden"
      [ngStyle]="{ 'height.px': logoHeight(), 'maxWidth.px': logoWidth() }"
      [inlineSVG]="project().logo!"
      [onSVGLoaded]="handleSVG"
      (onSVGInserted)="loadSVGLogo($event)"
      [pTooltip]="logoTooltip"
      [positionLeft]="-50"
      tooltipPosition="bottom"></div>
  }
  <img
    class="max-w-full"
    [ngStyle]="{ 'height.px': logoHeight() }"
    [height]="logoHeight()"
    loading="lazy"
    [src]="imgUrl()"
    [id]="'project-card-logo-' + project().id"
    [pTooltip]="logoTooltip"
    [positionLeft]="-50"
    tooltipPosition="bottom"
    alt="" />
</div>

<ng-template #logoTooltip>
  <div class="w-[275px]">
    <div class="font-bold">{{ project().name }}</div>
    {{ project().description }}
  </div>
</ng-template>
