<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->
<div class="lfx-select-wrap">
  @if (isSearchable()) {
  <p-autoComplete
    [(ngModel)]="selectedOption"
    [placeholder]="placeholder()"
    [dropdown]="true"
    [suggestions]="filtered()"
    [forceSelection]="true"
    [disabled]="isLoading()"
    (onDropdownClick)="onButtonClick()"
    (completeMethod)="onAutoComplete($event)"
    (onBlur)="onBlur()"
    (onSelect)="onSelectItem($event)"
    optionLabel="label"
    optionValue="value"
    emptyMessage="No items found"
    styleClass="lfx-select"
    dropdownIcon="fa fa-caret-down dropdown-icon"
    #autocomplete>
    <ng-template let-item pTemplate="item">
      @if (optionsTmpl) {
      <ng-container *ngTemplateOutlet="optionsTmpl; context: { item: item }"></ng-container>
      } @else {
      <div class="option-item" [title]="item.label">{{ item.label || '' }}</div>
      }
    </ng-template>
  </p-autoComplete>
  } @else {
  <p-dropdown
    [options]="items()"
    [(ngModel)]="selectedItem"
    [placeholder]="placeholder()"
    [disabled]="isLoading()"
    (onChange)="onSelectItem($event)"
    styleClass="lfx-select"
    optionLabel="label"
    optionValue="value"
    dropdownIcon="fa fa-caret-down dropdown-icon">
    @if (optionsTmpl) {
    <ng-template let-item pTemplate="item">
      <ng-container *ngTemplateOutlet="optionsTmpl; context: { item: item }"></ng-container>
    </ng-template>
    }
    <!-- label -->
    @if (labelTmpl) {
    <ng-template pTemplate="selectedItem" let-item>
      <ng-container *ngTemplateOutlet="labelTmpl; context: { item: item }"></ng-container>
    </ng-template>
    }
  </p-dropdown>
  }
  <div class="loading-icon" *ngIf="isLoading()">
    <i class="fas fa-circle-notch text-base"></i>
  </div>
</div>
