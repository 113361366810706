// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { InputComponent } from '../../../shared/components/input/input.component';

@Component({
    selector: 'lfx-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
    standalone: true,
    imports: [InputComponent]
})
export class PageHeaderComponent {
  public form: FormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      search: ['']
    });
  }
}
