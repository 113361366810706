// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Injectable } from '@angular/core';
import { injectInfiniteQuery, injectQuery, injectQueryClient } from '@ngneat/query';
import { ContributorsApiClass } from '@services/api/partials/ContributorsApiClass';
import { PrsApiClass } from '@services/api/partials/PrsApiClass';
import { RetentionApiClass } from '@services/api/partials/RetentionApiClass';
import { CubeService } from '../cube.service';
import { BestPracticeScoreApiClass } from './partials/BestPracticeScoreApiClass';
import { ProjectMaturityApiClass } from './partials/ProjectMaturityApiClass';
import { ActivitiesApiClass } from './partials/ActivitiesApiClass';
import { OrganizationsApiClass } from './partials/OrganizationsApiClass';
import { ConfluenceApiClass } from './partials/ConfluenceApiClass';
import { FoundationApiClass } from './partials/FoundationApiClass';
import { ProductivityContributorsApiClass } from './partials/productivityContributorsApiClass';
import { PrCodeReviewApiClass } from './partials/PrCodeReviewApiClass';
import { ICacheKey } from '@services/api/api.types';
import { ProjectHealthApiClass } from './partials/projectHealthApiClass';
import { BffService } from '../bff.service';
import { ContributorsBffApiClass } from '@services/api/partials/ContributorsBffApiClass';
import { BusFactorApiClass } from './partials/BusFactorApiClass';
import { OrganizationsBffApiClass } from './partials/organizationsBffApiClass';
import { MailingListBFFApiClass } from './partials/MailingListBFFApiClass';
import { ProductivityContributorsBFFApiClass } from './partials/productivityContributorsBFFApiClass';
import { SummaryTilesApiClass } from './partials/SummaryTilesApiClass';
import { FoundationBFFApiClass } from './partials/FoundationBFFApiClass';
import { SummaryChartsApiClass } from './partials/SummaryChartsApiClass';
import { OverviewBFFApiClass } from './partials/overviewBFFAPIClass';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public contributors: ContributorsApiClass;
  public prs: PrsApiClass;
  public bestPracticeScore: BestPracticeScoreApiClass;
  public projectMaturity: ProjectMaturityApiClass;
  public bustFactor: BusFactorApiClass;
  public activities: ActivitiesApiClass;
  public retention: RetentionApiClass;
  public organizations: OrganizationsApiClass;
  public confluence: ConfluenceApiClass;
  public foundation: FoundationApiClass;
  public foundationBFF: FoundationBFFApiClass;
  public productivityContributorsApiClass: ProductivityContributorsApiClass;
  public prCodeReviewApiClass: PrCodeReviewApiClass;
  public projectHealthApiClass: ProjectHealthApiClass;
  public organizationsBffApiClass: OrganizationsBffApiClass;
  public contributorsBffApiClass: ContributorsBffApiClass;
  public mailingListBFFApiClass: MailingListBFFApiClass;
  public productivityContributorsBFFApiClass: ProductivityContributorsBFFApiClass;
  public summaryTilesApiClass: SummaryTilesApiClass;
  public summaryChartsApiClass: SummaryChartsApiClass;
  public overviewBFFApiClass: OverviewBFFApiClass;

  protected useQuery = injectQuery();
  protected queryClient = injectQueryClient();
  protected infiniteQuery = injectInfiniteQuery();

  constructor(
    private cubejs: CubeService,
    private bffService: BffService
  ) {
    this.contributors = new ContributorsApiClass(cubejs, this.useQuery);
    this.prs = new PrsApiClass(cubejs, this.useQuery);
    this.bestPracticeScore = new BestPracticeScoreApiClass(cubejs, this.useQuery);
    this.projectMaturity = new ProjectMaturityApiClass(cubejs, this.useQuery);
    this.bustFactor = new BusFactorApiClass(bffService, this.useQuery, this.infiniteQuery);
    this.activities = new ActivitiesApiClass(cubejs, this.useQuery);
    this.retention = new RetentionApiClass(cubejs, this.useQuery);
    this.organizations = new OrganizationsApiClass(cubejs, this.useQuery);
    this.confluence = new ConfluenceApiClass(cubejs, this.useQuery);
    this.foundation = new FoundationApiClass(cubejs, this.useQuery);
    this.foundationBFF = new FoundationBFFApiClass(bffService, this.useQuery, this.infiniteQuery);
    this.productivityContributorsApiClass = new ProductivityContributorsApiClass(cubejs, this.useQuery);
    this.prCodeReviewApiClass = new PrCodeReviewApiClass(cubejs, this.useQuery);
    this.projectHealthApiClass = new ProjectHealthApiClass(bffService, this.useQuery);
    this.organizationsBffApiClass = new OrganizationsBffApiClass(bffService, this.useQuery, this.infiniteQuery);
    this.contributorsBffApiClass = new ContributorsBffApiClass(bffService, this.infiniteQuery, this.useQuery);
    this.mailingListBFFApiClass = new MailingListBFFApiClass(bffService, this.infiniteQuery, this.useQuery);
    this.overviewBFFApiClass = new OverviewBFFApiClass(bffService, this.infiniteQuery, this.useQuery);
    this.productivityContributorsBFFApiClass = new ProductivityContributorsBFFApiClass(
      bffService,
      this.infiniteQuery,
      this.useQuery
    );
    this.summaryTilesApiClass = new SummaryTilesApiClass(bffService, this.infiniteQuery, this.useQuery);
    this.summaryChartsApiClass = new SummaryChartsApiClass(bffService, this.infiniteQuery, this.useQuery);
  }
  public cancelQueries(cacheIds: ICacheKey[]) {
    return this.queryClient.cancelQueries({ queryKey: cacheIds, exact: false });
  }
}
