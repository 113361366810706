<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<div class="card-bg-color rounded-b-md text-xs text-center">
  <div class="border border-x-0 border-y">
    <div class="p-1 relative">
      Software Value: $<span class="font-semibold">{{ cocomo() }}</span>
      <i
        class="cursor-pointer text-primary ml-1 text-xs fal fa-info-circle"
        pTooltip="Contributors"
        appLfxClickOutside
        (clickOutside)="cocomoTooltip.hidden = true"
        (click)="cocomoTooltip.hidden = !cocomoTooltip.hidden"></i>

      <div
        #cocomoTooltip
        hidden
        class="tooltip absolute bottom-[120%] left-0 font-sans rounded-md bg-gray-dark text-xs text-white py-1 px-4">
        Constructive Cost Model (COCOMO) is a procedural cost estimate model for software projects.
        <a
          href="https://docs.linuxfoundation.org/lfx/insights/v3-current/getting-started/dashboard-overview/cocomo"
          target="_blank"
          class="text-primary"
          >Learn more</a
        >
        about COCOMO and how it is calculated for open source projects.
      </div>
    </div>
  </div>
</div>
