// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { UntilDestroy } from '@ngneat/until-destroy';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import {
  HorizStackedChartConfig,
  HorizStackedChartData
} from '@shared/components/horizontally-stacked-chart/horizontally-stacked-chart.types';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { isPlatformBrowser, NgIf, NgStyle } from '@angular/common';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lfx-horizontally-stacked-chart',
    templateUrl: './horizontally-stacked-chart.component.html',
    styleUrls: ['./horizontally-stacked-chart.component.scss'],
    standalone: true,
    imports: [NgIf, NgStyle]
})
export class HorizontallyStackedChartComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() public config: HorizStackedChartConfig;
  @Input() public chartId!: string;
  @Input() public chartTitle: string;
  @Input() public data!: HorizStackedChartData[];

  private root!: am5.Root;
  private noDateFoundModal: am5.Modal;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private zone: NgZone,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngAfterViewInit(): void {
    this.browserOnly(() => {
      this.initChart();
    });
  }

  public ngOnDestroy(): void {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data?.currentValue && changes?.data.isFirstChange()) {
      this.initChart();
    }
  }

  private browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  private initChart() {
    if (this.root) {
      this.root.dispose();
    }
    if (this.noDateFoundModal?.isOpen()) {
      this.noDateFoundModal.close();
    }
    const root = am5.Root.new(this.chartId);
    this.noDateFoundModal = am5.Modal.new(root, {
      content: 'No data to display'
    });
    const myTheme = am5.Theme.new(root);
    root.setThemes([myTheme]);
    // eslint-disable-next-line no-underscore-dangle
    root._logo?.children.clear();

    const data = this.data;
    if (this.data.length === 0) {
      this.noDateFoundModal.open();
    } else {
      this.noDateFoundModal.close();
    }

    // Create chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: true,
        wheelX: 'none',
        wheelY: 'none',
        arrangeTooltips: false,
        pinchZoomY: true
      })
    );

    // make x axes stack
    chart.bottomAxesContainer.set('layout', root.horizontalLayout);
    chart.zoomOutButton.set('forceHidden', true);

    // Create axes
    const yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 25
    });

    yRenderer.labels.template.setAll({
      multiLocation: 0.5,
      location: 0.5,
      paddingRight: 15,
      fontSize: 14
    });

    yRenderer.grid.template.set('location', 0.5);

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'category',
        renderer: yRenderer
      })
    );

    yAxis.data.setAll(data);

    // Add series
    function createSeries(field: any, margin: any) {
      const xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 40
      });

      xRenderer.labels.template.setAll({
        rotation: 0,
        centerY: am5.p50,
        fontSize: 12
      });

      const xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: xRenderer,
          marginLeft: margin // this makes gap between axes
        })
      );

      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          xAxis,
          yAxis,
          valueXField: field,
          categoryYField: 'category',
          sequencedInterpolation: true
        })
      );

      series.columns.template.setAll({
        height: am5.percent(55)
      });

      series.data.setAll(data);
      series.appear();

      return series;
    }
    createSeries('value', 0);

    chart.appear(1000, 100);
  }
}
