<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->

<div class="best-practice-embed" [class.compress]="!inModal">
  <div class="circle-prog-col" *ngIf="renderChart; else loading">
    <lfx-circle-progress
      [annotation]="annotation"
      [chartName]="'embedded-report-progress' + id"
      [value]="getScoreValue('globalScore')"
      [color]="getColor(scoreData.globalScore)"
      [size]="inModal ? '130px' : '100px'"
      [padding]="inModal ? -15 : -5"></lfx-circle-progress>
  </div>
  <div class="details-col">
    <div *ngFor="let scores of bestPracticesScores; let i = index" [class.bg-gray-light]="inModal && i % 2 === 1">
      <lfx-best-practices-details
        [value]="getScoreValue(scores.scoreField)"
        [size]="inModal ? 'md' : 'sm'"
        [label]="scores.label"
        [icon]="hideIcon ? '' : scores.icon"></lfx-best-practices-details>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="basis-4/12 flex justify-center items-center">
    <lfx-loading [minHeight]="130"></lfx-loading>
  </div>
</ng-template>
