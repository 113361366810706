<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->
<div class="block text-tiny text-gray-dark text-left w-full">
  <span *ngIf="!showDateDiff">
    {{ (date | date : config.DATE_FORMATTING) || 'N/A' }}
  </span>
  <span *ngIf="showDateDiff">
    {{ (date | luxonDate : undefined : 'years') || 'N/A' }}
  </span>
</div>
