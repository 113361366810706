<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<div class="project-card cards">
  <div class="rounded-t-md" [class]="{ 'card-bg-color': !isOnboarding(), 'data-incomplete-bg': isOnboarding() }">
    <lfx-card-head-buttons [project]="project()" [isOnboarding]="isOnboarding()" (downloadClicked)="downloadCard()" />

    <!-- logo -->
    <lfx-card-logo [project]="project()" (logoClicked)="onCardNavigate()" />
  </div>

  <div (click)="onCardNavigate()" class="cursor-pointer grow flex flex-col">
    <!-- stat row -->
    <lfx-project-stats-row [project]="project()" />

    <!-- chart -->
    <div class="min-h-28 pt-4 px-3">
      @if (showBPScore()) {
        <lfx-project-card-bp-score [project]="project()" [isError]="isBPError()" [suffix]="suffix()" />
      } @else {
        <div class="h-[121px]"></div>
      }
    </div>

    <!-- metrics details -->
    <lfx-project-metric-details [project]="project()" />
  </div>
  <!-- card footer -->
  <lfx-card-footer [cocomo]="project().cocomo" />
</div>
