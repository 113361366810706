// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
import * as am5 from '@amcharts/amcharts5';
import { TimeUnit } from '@amcharts/amcharts5/.internal/core/util/Time';
import * as am5xy from '@amcharts/amcharts5/xy';
import { isPlatformBrowser, NgIf, NgClass, NgFor, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import { ChartService } from '@app/shared/services/chart.service';
import {
  createChart,
  createChartRoot,
  createXAxis,
  createYAxis,
  mapAnnotations,
  tooltipObj
} from '@app/shared/utils/chart-helpers';
import { GranularityEnum, LineChartConfig, LineSeriesSettings } from 'lfx-insights';
import { APP_CONFIG } from '@app/config';
import { LineChartNewService, addLeftYAxisLabel, addRightYAxisLabel } from './line-chart-new.service';
import { LineChartData } from './line-chart-new.types';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { TooltipTriggerDirective } from '../../directives/tooltip-trigger.directive';
/**
 * This should replace all the current lfx-line-chart usage.
 * This new component will no longer pull it's own and will only accept data from the parent component
 */

@Component({
  selector: 'lfx-line-chart-new',
  templateUrl: './line-chart-new.component.html',
  styleUrls: ['./line-chart-new.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgFor, TooltipTriggerDirective, TooltipComponent, NgTemplateOutlet]
})
export class LineChartNewComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() public config!: LineChartConfig;
  @Input() public chartId!: string;
  @Input() public data!: LineChartData[];

  public annotations: any = null;
  public noData = false;

  private root!: am5.Root;
  private chartRef!: am5xy.XYChart;
  private noDataFoundModal: am5.Modal;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private zone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    private chartService: ChartService
  ) {}

  public ngAfterViewInit(): void {
    this.browserOnly(() => {
      this.initChart();
    });
  }

  public ngOnDestroy(): void {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data?.currentValue) {
      // this.changeData(); or this.initChart(); is not working for this chart without the timeout
      setTimeout(() => {
        this.initChart();
      }, 100);
    }
  }

  public getAnnotationHTML(template: string, values: any): string {
    return mapAnnotations(template, values);
  }

  public onToggleSeries(index: number): void {
    if (this.chartRef && this.chartRef.series.length > 1) {
      const series = this.chartRef.series.getIndex(index);
      if (series) {
        this.toggleSeries(series);
      }
    }
  }

  public isVisible(index: number): boolean | undefined {
    if (this.chartRef) {
      const series = this.chartRef.series.getIndex(index);
      if (series) {
        return series.get('visible');
      }

      return true;
    }

    return true;
  }

  private toggleSeries(series: am5xy.XYSeries) {
    if (series.get('visible')) {
      series.hide();
    } else {
      series.show();
    }
  }

  private browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  private initChart() {
    if (this.root) {
      this.root.dispose();
    }
    if (this.noDataFoundModal?.isOpen()) {
      this.noDataFoundModal.close();
    }
    // check if the container exists
    if (!document.getElementById(this.chartId)) {
      return;
    }
    const root = createChartRoot(this.chartId, this.config.useMicroTheme);
    this.root = root;
    this.noDataFoundModal = am5.Modal.new(root, {
      content: 'No data to display'
    });
    const myTheme = am5.Theme.new(root);
    if (this.config.yAxis?.hideGrid) {
      myTheme.rule('Grid').setAll({
        strokeOpacity: 0
      });
    }
    root.setThemes([myTheme]);
    // eslint-disable-next-line no-underscore-dangle
    root._logo?.children.clear();

    // root.locale.firstDayOfWeek = 0;
    const chart = createChart(root, true);
    const yAxis = createYAxis(
      root,
      chart,
      { max: this.config.max, label: this.config.yAxis?.hide ? undefined : this.config.yAxisName },
      this.config.yAxis,
      undefined
    );

    if (this.config.useMicroTheme) {
      chart.plotContainer.set('wheelable', false);
    }
    /*https://github.com/LF-Engineering/lfx-insights-ui/issues/1668*/
    chart.zoomOutButton.set('forceHidden', true);

    const granularity = this.getGranularity();

    const xAxis = createXAxis(root, chart, granularity, this.config.xAxis);
    if (this.config.xAxis.hideGrid) {
      (xAxis as am5xy.DateAxis<am5xy.AxisRenderer>).get('renderer').grid.template.set('forceHidden', true);
    }

    if (this.config.xAxis.axisType === 'category') {
      xAxis.data.setAll(this.data);
    }

    // Create series
    this.config.series.forEach((seriesSetting: LineSeriesSettings) => {
      if (seriesSetting.plotAsRightAxis) {
        const totalYAxis = createYAxis(
          root,
          chart,
          { max: this.config.max, label: this.config.yAxisName, color: this.config.yAxisColors?.right },
          this.config.yAxis,
          true
        );
        const lineXAxis: any = xAxis;

        if (seriesSetting.yAxisTitle) {
          addRightYAxisLabel(root, totalYAxis, seriesSetting.yAxisTitle, this.config.yAxisColors?.right);
        }
        this.createSeries(root, chart, lineXAxis, totalYAxis, seriesSetting, granularity);
      } else {
        if (seriesSetting.yAxisTitle) {
          addLeftYAxisLabel(root, yAxis, seriesSetting.yAxisTitle, this.config.yAxisColors?.left);
        }
        this.createSeries(root, chart, xAxis, yAxis, seriesSetting, granularity);
      }
    });

    // if bullets are set to false we still need to have the tooltip to see data

    // Add cursor
    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineY.set('visible', false);
    cursor.lineX.set('visible', false);
    chart.appear(1000, 100);
    this.chartRef = chart;

    this.changeData();

    //this.root = root;
  }

  private createSeries(
    root: am5.Root,
    chart: am5xy.XYChart,
    xAxis: am5xy.DateAxis<am5xy.AxisRenderer> | am5xy.CategoryAxis<am5xy.AxisRenderer>,
    yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
    seriesSetting: LineSeriesSettings,
    granularity: GranularityEnum
  ) {
    this.chartService.createLineSeries(this)(root, chart, xAxis, yAxis, seriesSetting, granularity);
    if (!seriesSetting.bullets) {
      chart.series.each((se: am5xy.XYSeries) => {
        se.set(
          'tooltip',
          tooltipObj(root, chart, granularity, this.config.customTooltipTextAdapter, this.config.customAdapter)
        );
      });
    }
  }

  private getGranularity(): GranularityEnum {
    return this.config.xAxis.granularity || (APP_CONFIG.GRANULARITY_DEFAULT as GranularityEnum);
  }

  private changeData(): void {
    if (this.chartRef) {
      /*TODO figure out why we should serialize it and update type*/
      const data = this.data[0].dateCategory
        ? LineChartNewService.dataSerializer(this.data as LineChartData[])
        : this.data;
      if (this.data.length === 0) {
        this.noDataFoundModal.open();
      } else {
        this.noDataFoundModal.close();
      }

      const granularity = this.getGranularity();
      this.chartRef.xAxes.each((axis: am5xy.Axis<am5xy.AxisRenderer>) => {
        if (this.config.xAxis?.axisType !== 'category') {
          (axis as am5xy.DateAxis<am5xy.AxisRenderer>).set('baseInterval', {
            timeUnit: granularity as TimeUnit,
            count: 1
          });
        }
        axis.data.setAll([]);
        axis.data.setAll(data);
      });

      this.chartRef.series.each((se: am5xy.XYSeries) => {
        se.data.setAll([]);
        se.data.setAll(data);
      });
    }
  }
}
