// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { DateRange } from '@cubejs-client/core';
import { BestPracticeKey, BestPracticeScores, DateRangesEnum, GaugeChartConfig } from 'lfx-insights';
import colorsPalette from '@shared/styles/color-palette.json';
import { IColorPaletteType } from '@shared/styles/color-palette.types';
import { InsightsFiltersNew } from '../services/filter/filter.types';
import { APP_CONFIG } from '@app/config';

const colors = colorsPalette as unknown as IColorPaletteType;

// TODO: Put all the documentation links here
export enum DocumentationLinkEnum {
  bestPractices = 'https://docs.linuxfoundation.org/lfx/insights/v3-current/overview-page/best-practice-score'
}

export const tablePageSize: number = 15;

export const bestPracticesScoresKeys: BestPracticeKey[] = [
  {
    icon: 'far fa-edit',
    scoreField: 'documentationScore',
    label: 'Documentation',
    key: 'documentation',
    weight: 30,
    description:
      'Documentation encompasses various files in a repository that provide essential information about the project',
    checkRefLink: APP_CONFIG.BP_CHECKS_REF_LINK + '/documentation-checks'
  },
  {
    icon: 'far fa-shield-check',
    scoreField: 'bestPracticesScore',
    label: 'Standards',
    key: 'bestPractices',
    weight: 25,
    description:
      'Standards refer to the set criteria or practices that projects should adhere to for better visibility and community engagement',
    checkRefLink: APP_CONFIG.BP_CHECKS_REF_LINK + '/standards'
  },
  {
    icon: 'far fa-lock-alt',
    scoreField: 'securityScore',
    label: 'Security',
    key: 'security',
    weight: 20,
    description: `Security encompasses practices and tools ensuring the project's protection and integrity`,
    checkRefLink: APP_CONFIG.BP_CHECKS_REF_LINK + '/security-checks'
  },
  {
    icon: 'far fa-balance-scale',
    scoreField: ['licenseScore', 'legalScore'],
    label: 'Legal',
    key: ['license', 'legal'],
    weight: 10,
    description: 'Legal standards ensure compliance with intellectual property and licensing requirements.',
    checkRefLink: APP_CONFIG.BP_CHECKS_REF_LINK + '/legal-checks'
  }
];

export const bestPracticeConfig: GaugeChartConfig = {
  ranges: [
    {
      text: '',
      color: colors.red.DEFAULT,
      value: 0,
      endValue: 25
    },
    {
      text: '',
      color: colors.orange.DEFAULT,
      value: 25,
      endValue: 50
    },
    {
      text: '',
      color: colors.yellow.DEFAULT,
      value: 50,
      endValue: 75
    },
    {
      text: '',
      color: colors.green.DEFAULT,
      value: 75,
      endValue: 100
    }
  ],
  height: 200,
  innerRadius: 90,
  value: 0,
  spriteRadius: 90
};

export function getBestPracticeColor(value: number): string {
  value = Math.round(value);
  switch (true) {
    case value >= 75:
      return colors.green.DEFAULT;
    case value < 75 && value >= 50:
      return colors.yellow.DEFAULT;
    case value < 50 && value >= 25:
      return colors.orange.DEFAULT;
    default:
      return colors.red.DEFAULT;
  }
}

export function formatBestPracticeDownload(
  bestPracticeData: BestPracticeScores,
  showGlobal: boolean = false
): { [key: string]: string | number } {
  const downloadData: { [key: string]: number | string } = {};

  if (showGlobal) {
    downloadData.globalScore = Math.round(bestPracticeData.globalScore) + '%';
  }

  bestPracticesScoresKeys.forEach((key: BestPracticeKey) => {
    downloadData[key.label] = getScoreValue(bestPracticeData, key.scoreField) + '%';
  });

  if (bestPracticeData.repository) {
    downloadData.repository = bestPracticeData.repository;
  }

  return downloadData;
}

// TODO: refactor this just only use string arrays
export function getScoreValue(data: BestPracticeScores, key: string | string[]): number {
  if (typeof key === 'string') {
    return data ? Math.round(data[key as keyof BestPracticeScores] as number) : 0;
  }
  let total = 0;
  key.forEach((k) => {
    total += data ? (data[k as keyof BestPracticeScores] as number) : 0;
  });

  return Math.round(total / (key.length > 0 ? key.length : 1));
}

export function getDateFilterBusFactor(dateFilters: DateRangesEnum | DateRange): string {
  let tmp = 'custom';

  if (typeof dateFilters === 'string') {
    switch (dateFilters) {
      case DateRangesEnum.last7:
        tmp = '7d';
        break;
      case DateRangesEnum.last30:
        tmp = '30d';
        break;
      case DateRangesEnum.lastQuarter:
        tmp = 'q';
        break;
      case DateRangesEnum.last90:
        tmp = '90d';
        break;
      case DateRangesEnum.last12Months:
        tmp = '12m';
        break;
      case DateRangesEnum.lastYear:
        tmp = 'y';
        break;
      case DateRangesEnum.last2Years:
        tmp = '2y';
        break;
      case DateRangesEnum.last3Years:
        tmp = '3y';
        break;
      case DateRangesEnum.thisYear:
        tmp = 'ty';
        break;
      case DateRangesEnum.last10Years:
        tmp = '10y';
        break;
      default:
        tmp = 'custom';
    }
  }
  return tmp;
}

export const getTimeQueryParams = (
  filter: InsightsFiltersNew
): { timeRangeName: string; dateFrom: string | undefined; dateTo: string | undefined } => {
  const timeRangeName = getDateFilterBusFactor(filter.dateFilters);
  const dateFrom = filter.dateFilters === DateRangesEnum.custom ? filter.periods?.currentPeriod[0] : undefined;
  const dateTo = filter.dateFilters === DateRangesEnum.custom ? filter.periods?.currentPeriod[1] : undefined;

  return { timeRangeName, dateFrom, dateTo };
};

// TODO: Implement this where project-platform.direcitve is implemented. This should replace all that code
export const isVisibleWithPlatform = (inputPlatform: string, projectSource?: string): boolean => {
  const platformsArr = projectSource?.split(',') || [];

  return platformsArr.includes(inputPlatform);
};

export * as PageHelpers from './page-helpers';
