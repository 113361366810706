<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<div class="flex flex-row justify-center pb-8">
  <div class="min-w-[550px]">
    <lfx-input [parent]="form" [icon]="['fal', 'search']" control="search" placeholder="Search by project or repository" [showClear]="true"></lfx-input>
  </div>
</div>
