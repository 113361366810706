import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, computed, input } from '@angular/core';
import { PlatformEnum } from '@app/shared/interface/common.enum';
import { ShortNumberPipe } from '@app/shared/pipes/short-number.pipe';
import { isPlatformEnabled } from '@app/shared/services/project-utils.service';
import { InsightProject } from 'lfx-insights';

@Component({
  selector: 'lfx-foundation-card-data',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, ShortNumberPipe],
  templateUrl: './foundation-card-data.component.html',
  styleUrl: './foundation-card-data.component.scss'
})
export class FoundationCardDataComponent {
  @Output() public readonly clicked = new EventEmitter<void>();

  public foundation = input.required<InsightProject>();
  public cardType = computed<string>(() => {
    switch (true) {
      case isPlatformEnabled(this.foundation(), PlatformEnum.gerrit) &&
        !isPlatformEnabled(this.foundation(), PlatformEnum.github):
        return 'gerrit';
      case isPlatformEnabled(this.foundation(), PlatformEnum.github) &&
        !isPlatformEnabled(this.foundation(), PlatformEnum.gerrit):
        return 'github';
      default:
        return 'gerrit-github';
    }
  });

  public onCardNavigate() {
    this.clicked.emit();
  }
}
