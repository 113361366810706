<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->
<!--  -->
<div
  class="relative w-full text-tiny text-gray-dark cursor-pointer"
  appLfxClickOutside
  (clickOutside)="onOutsideClick($event)">
  <div
    [appLfxTooltipTrigger]="'date-range-disabled-tooltip'"
    (click)="openOptions()"
    [ngClass]="{ disabled: !isLoggedIn() }"
    class="cursor-pointer section-placeholder bg-white border rounded h-[37px] min-w-[200px] flex justify-between">
    <div class="self-center">
      <span class="ml-2">{{ selectedDateDisplay() }}</span>
    </div>
    <div class="h-full bg-gray-50 border-l border-solid rounded-r-sm flex items-center px-2">
      <fa-icon [icon]="['fal', 'calendar-alt']"></fa-icon>
    </div>
  </div>
  @if (!isLoggedIn()) {
    <lfx-tooltip [id]="'date-range-disabled-tooltip'" text="Login to change the timeframe"></lfx-tooltip>
  }
  @if (isDisplayed() && isLoggedIn()) {
    <div
      appLfxPositionElement
      class="section-content-popup border rounded bg-white absolute border-box z-50"
      [class]="{ 'w-[650px]': !compactVersion() }">
      <!-- header -->
      <div class="bg-gray-50 flex justify-between px-4 py-2">
        <span class="flex items-center">
          Duration: <span class="font-bold whitespace-break-spaces"> {{ duration | number }} Days </span>
        </span>
        <fa-icon [icon]="icons.times" class="cursor-pointer" (click)="onOutsideClick()"></fa-icon>
      </div>
      <div class="flex flex-row">
        <div class="p-4" [class]="{ 'basis-1/4': !compactVersion() }">
          <lfx-range-filters
            [compactVersion]="compactVersion()"
            [selectedRange]="filterRangeSelected()"
            (selectedRangeChanged)="onSelectedRangeChange($event)"></lfx-range-filters>
        </div>

        @if (!compactVersion()) {
          <div class="basis-3/4">
            <lfx-date-range
              [(dateRange)]="selectedDateRange"
              (selectedDateChanged)="onSelectedChange()"></lfx-date-range>
          </div>
        }
      </div>

      <!-- NOTE: "compactVersion()" might be a temporary implementation and could be changed by the designers -->
      <div
        class="flex flex-row border-t border-solid border-gray items-center p-4 relative"
        [class]="{ 'justify-center': compactVersion(), 'justify-end': isFoundationOverviewPage() }">
        @if (!compactVersion() && !isFoundationOverviewPage()) {
          <div class="basis-2/5">
            <div class="font-bold mb-1">Compare</div>
            <div class="flex gap-2">
              <label class="flex space-x-2 items-center cursor-pointer">
                <input class="lfx-radio" type="radio" value="PP" [(ngModel)]="compare" />
                <span>Previous Period</span>
              </label>
            </div>
          </div>

          <div class="basis-1/5">
            <div class="font-bold">Bots</div>
            <label class="flex space-x-2 items-center cursor-pointer">
              <input type="checkbox" class="lfx-checkbox" [(ngModel)]="hideBots" /><span>Hide</span>
            </label>
          </div>
        }
        <div
          class="flex items-center gap-2 justify-end"
          [class]="{ 'basis-2/5': !compactVersion() && !isFoundationOverviewPage() }">
          @if (!compactVersion()) {
            <button class="lfx-btn white" (click)="clear()">Clear Dates</button>
          }
          <button class="lfx-btn primary w-24" (click)="apply()">Apply</button>
        </div>
      </div>
    </div>
  }
</div>
