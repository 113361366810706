// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { APP_CONFIG } from '@app/config';
import { LuxonDatePipe } from '../../../../pipes/luxon-date.pipe';
import { NgIf, DatePipe } from '@angular/common';

@Component({
    selector: 'lfx-table-date',
    templateUrl: './table-date.component.html',
    styleUrls: ['./table-date.component.scss'],
    standalone: true,
    imports: [NgIf, DatePipe, LuxonDatePipe]
})
export class TableDateComponent {
  @Input() public date: Date | string | number;
  @Input() public showDateDiff = false;
  protected readonly config = APP_CONFIG;
}
