<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->
<div [class]="'relative w-full flex ' + textClass" appLfxClickOutside (clickOutside)="showTooltip ? (showTooltip = false) : false">
  <h2 class="pr-2" *ngIf="!skipTitleTransform">{{ title | firstLetterCapitalcase }}</h2>
  <h2 class="pr-2" *ngIf="skipTitleTransform">{{ title }}</h2>

  <i
    data-role="tooltip-icon"
    class="fal fa-info-circle text-primary text-xl cursor-pointer"
    #tooltipTrigger
    *ngIf="tooltipTemplate"
    (click)="toggleTooltip()"></i>
  <div
    #calculatedTooltip
    *ngIf="showTooltip && tooltipTemplate"
    class="calculated-tooltip absolute font-sans rounded-md bg-gray-dark text-tiny text-white w-[266px] p-4 z-20 font-normal"
    [class]="getTooltipPosition() + ' ' + tooltipHorizontal">
    <ng-container [ngTemplateOutlet]="tooltipTemplate"></ng-container>
  </div>
</div>
