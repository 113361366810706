// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { ICacheKey, InjectInfiniteQuery, InjectQuery } from '@services/api/api.types';
import { BffConstants } from '@services/api/bff.constants';
import { BFFServiceBasic } from '@services/api/bff.basic';
import { getContributorsLeaderboardQuery } from '@app/shared/queries/contributor-leaderboard/contributors-leaderboard-queries';
import { BffService } from '@services/bff.service';
import { InsightsFiltersNew } from '@services/filter/filter.types';
import { InsightProject } from 'lfx-insights';
import { ActivatedRoute } from '@angular/router';
import { inject } from '@angular/core';

export interface IContributorLeaderboard {
  MEMBER_DISPLAY_NAME: string;
  LOGO_URL: string;
  ROW_NUMBER: number;
  CONTRIBUTIONS: number;
  PERCENT_TOTAL: number;
  DELTA_CONTRIBUTIONS: number;
  ALL_CONTRIBUTORS: number;
  ALL_CHANGE_FROM_PREVIOUS: number;
}

export class ContributorsBffApiClass extends BFFServiceBasic {
  protected useInfiniteQuery;
  protected useQuery: InjectQuery;

  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private cacheByPass =
    (this.activatedRoute.snapshot.queryParamMap.has('cache-bypass') &&
      this.activatedRoute.snapshot.queryParamMap.get('cache-bypass') === 'true') ||
    undefined;

  constructor(bffService: BffService, useInfiniteQuery: InjectInfiniteQuery, useQuery: InjectQuery) {
    super(bffService);
    this.useInfiniteQuery = useInfiniteQuery;
    this.useQuery = useQuery;
  }

  public getContributorsLeaderboardPagination(
    filters: InsightsFiltersNew,
    activityType: string,
    isGerrit: boolean,
    project: InsightProject | undefined,
    limit: number
  ) {
    return this.useInfiniteQuery({
      queryKey: [
        ICacheKey.GET_CONTRIBUTORS_LEADERBOARD,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.repositories,
        filters.hideBots,
        activityType
      ] as const,
      queryFn: ({ pageParam }) => {
        const query = getContributorsLeaderboardQuery(filters, activityType, isGerrit, project!, pageParam, limit);
        return this.loadData<IContributorLeaderboard[]>(BffConstants.CONTRIBUTORS_LEADERBOARD, query);
      },
      enabled: !!project,
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.length * limit;
        return lastPage.length === limit ? nextOffset : undefined;
      }
    });
  }

  public getContributorsLeaderboard(
    filters: InsightsFiltersNew,
    activityType: string,
    isGerrit: boolean,
    project: InsightProject | undefined,
    isDownloadCSV: boolean
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_CONTRIBUTORS_LEADERBOARD,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.repositories,
        filters.hideBots,
        activityType,
        isDownloadCSV
      ] as const,
      queryFn: () => {
        const query = getContributorsLeaderboardQuery(filters, activityType, isGerrit, project!, 0, 0);
        return this.loadData<IContributorLeaderboard[]>(BffConstants.CONTRIBUTORS_LEADERBOARD, query);
      },
      enabled: isDownloadCSV && !!project
    });
  }
}
