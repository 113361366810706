// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { map } from 'rxjs';
import { Query } from '@cubejs-client/core';

import { CubeServiceBasic } from '@services/api/basic';
import { ICacheKey, ICompareBase, InjectQuery } from '@services/api/api.types';
import { GranularityEnum } from 'lfx-insights';
import { InsightsFilters } from '@services/filter.service';
import { CubeService } from '../../cube.service';

export interface IActivitiesTotalCount extends IActivitiesCumulativeTimestamp, ICompareBase {
  ['SnowActivitiesCumulative.cumulative_activities']: string;
}

export interface IActivitiesSummary extends IActivitiesTimestamp, ICompareBase {
  ['SnowActivities.count']: number;
}

export interface IActivitiesDetails {
  ['SnowActivities.type']: string;
  ['SnowActivities.count']: number;
  ['SnowActivities.platform']: string;
  ['SnowActivities.timestamp']: string;
}

export interface IActivitiesTimestamp {
  ['SnowActivities.timestamp']?: string;
  ['SnowActivities.timestamp.day']?: string;
  ['SnowActivities.timestamp.week']?: string;
  ['SnowActivities.timestamp.month']?: string;
  ['SnowActivities.timestamp.quarter']?: string;
  ['SnowActivities.timestamp.year']?: string;
}

export interface IActivitiesCumulativeTimestamp {
  ['SnowActivitiesCumulative.timestamp']?: string;
  ['SnowActivitiesCumulative.timestamp.day']?: string;
  ['SnowActivitiesCumulative.timestamp.week']?: string;
  ['SnowActivitiesCumulative.timestamp.month']?: string;
  ['SnowActivitiesCumulative.timestamp.quarter']?: string;
  ['SnowActivitiesCumulative.timestamp.year']?: string;
}

export interface IActivitiesNew extends IActivitiesTimestamp {
  ['SnowActivities.count']: string;
}

export interface IActivitiesGeography {
  ['SnowMembers.contributor_country']: string;
  ['SnowActivities.count']: number;
}

export class ActivitiesApiClass extends CubeServiceBasic {
  protected useQuery: InjectQuery;
  constructor(cubejs: CubeService, useQuery: InjectQuery) {
    super(cubejs);
    this.useQuery = useQuery;
  }

  public getTotalActivitiesCumulative(
    query: Query,
    projectId: string | undefined,
    repositories: string[] | undefined,
    hideBots: boolean | undefined,
    platform: string[] | undefined
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_TOTAL_ACTIVITIES_CUMULATIVE,
        projectId,
        repositories,
        hideBots,
        query.timeDimensions,
        platform
      ],
      queryFn: () => this.load$<IActivitiesTotalCount>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getActivitySummary(query: Query, filters: InsightsFilters, platform: string[] | undefined) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_ACTIVITY_SUMMARY,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions,
        platform
      ],
      queryFn: () => this.load$<IActivitiesSummary>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getActivityDetails(
    query: Query,
    filters: InsightsFilters,
    platform: string[] | undefined,
    additionalFilters?: string
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_ACTIVITY_DETAILS,
        filters.project,
        filters.repositories,
        query.timeDimensions,
        platform,
        additionalFilters
      ],
      queryFn: () => this.load$<IActivitiesDetails>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getActivitiesByGeo(
    query: Query,
    projectId: string | undefined,
    repositories: string[] | undefined,
    hideBots: boolean | undefined,
    platform: string[] | undefined
  ) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_ACTIVITIES_BY_GEO, projectId, repositories, hideBots, query.timeDimensions, platform],
      queryFn: () => this.load$<IActivitiesGeography>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getActivityGeoDetails(
    query: Query,
    countryCode: string,
    projectId: string | undefined,
    repositories: string[] | undefined,
    platform: string[] | undefined
  ) {
    return this.useQuery({
      queryKey: ['getActivityGeoDetails', projectId, repositories, query.timeDimensions, platform, countryCode],
      queryFn: () => this.load$<IActivitiesDetails>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getActivitiesByType(query: Query, filters: InsightsFilters, platform: string[] | undefined) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_ACTIVITIES_BY_TYPE,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions,
        platform
      ],
      queryFn: () => this.load$<IActivitiesDetails>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getNewActivities(
    query: Query,
    filters: InsightsFilters,
    platform: string[] | undefined,
    granularity: GranularityEnum | undefined,
    isMocked: boolean = false
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_NEW_ACTIVITIES,
        filters.project,
        filters.repositories,
        filters.hideBots,
        platform,
        granularity,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IActivitiesNew>(query, isMocked).pipe(map((res) => this.toTable(res)))
    });
  }
}
