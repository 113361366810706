import { Component, Input } from '@angular/core';
import colorsPalette from '@shared/styles/color-palette.json';
import { AvatarModule } from 'primeng/avatar';

const defaultBackgroundColor: string[] = [
  colorsPalette.primary,
  colorsPalette.gray.dark,
  colorsPalette.orange.DEFAULT,
  colorsPalette.purple.DEFAULT
];
@Component({
    selector: 'lfx-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    standalone: true,
    imports: [AvatarModule]
})
export class AvatarComponent {
  @Input() name = ''; // TODO: this should a required input on Angular 17
  @Input() image?: string = undefined;
  @Input() size: 'normal' | 'large' | 'xlarge' = 'normal';
  @Input() shape: 'circle' | 'square' = 'circle';
  @Input() backgroundColor?: string;
  @Input() color = '#fff';
  @Input() styleClass = '';

  // TODO: convert these to computed properties on Angular 17
  get firstLetter(): string {
    return this.name ? this.name[0].toUpperCase() : '';
  }

  get hasImage(): boolean {
    return !!this.image;
  }

  get label(): string {
    return this.hasImage ? '' : this.firstLetter;
  }

  get imgSrc(): string {
    return this?.image || '';
  }

  get avatarBackgroundColor(): string {
    return this.hasImage ? '' : this.getTextBGColor();
  }

  private getTextBGColor(): string {
    const charCode = this.firstLetter.charCodeAt(0);

    return this.backgroundColor || defaultBackgroundColor[charCode % defaultBackgroundColor.length];
  }
}
