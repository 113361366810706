// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  public cache = new Map<string, any>();

  constructor() {}

  public setItem(key: any, data: any): void {
    const innerKey = JSON.stringify(key);
    this.cache.set(innerKey, data);
  }

  public getItem(key: any): any {
    const innerKey = JSON.stringify(key);

    const item = this.cache.get(innerKey);

    return item;
  }

  public clear(): void {
    this.cache.clear();
  }
}
