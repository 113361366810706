// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CoreModule } from './app/core/core.module';
import { AuthModule } from '@auth0/auth0-angular';
import { ToastModule } from 'primeng/toast';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { HttpCancelInterceptor } from '@shared/interceptors/http-cancel.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { queryClientProvider } from '@app/providers/query-client.provider';
import { APP_CONFIG } from './app/config';

if (environment.production) {
  enableProdMode();
}
bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      ReactiveFormsModule,
      ToastModule,
      AuthModule.forRoot({
        domain: environment.auth0.domain,
        clientId: environment.auth0.clientId,
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
        authorizationParams: {
          redirect_uri: environment.auth0.redirectUrl,
          audience: environment.auth0.audience,
          scope: 'openid email profile'
        },
        httpInterceptor: {
          allowedList: [
            {
              /* The endpoint you would like to prefix the access token with */
              uri: `${environment.apiURL}/*`,
              tokenOptions: {
                cacheMode: 'on',
                authorizationParams: {
                  audience: environment.auth0.audience,
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  redirect_uri: `${environment.auth0.redirectUrl}/auth`,
                  scope: 'access:api openid email profile'
                }
              }
            }
          ]
        }
      }),
      CoreModule,
      InlineSVGModule.forRoot(),
      FormsModule
    ),
    APP_CONFIG.CLIENT_CACHING.ENABLED ? queryClientProvider.getProvider() : [],
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor
    },
    MessageService,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations()
  ]
}).catch((err) => console.error(err));
