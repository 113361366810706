// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'lfx-header-tooltip',
    templateUrl: './header-tooltip.component.html',
    styleUrls: ['./header-tooltip.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class HeaderTooltipComponent {
  @Input() public tooltipText: string;
  @Input() public link: string;
  @Input() public note: string;
}
