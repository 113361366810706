// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import { isPlatformBrowser, NgStyle } from '@angular/common';
import { ISpritePointerEvent } from '@amcharts/amcharts5';
import { DonutChartConfig, DonutChartData, ISliceClickEvent } from './donut-chart.types';

@Component({
    selector: 'lfx-donut-chart',
    templateUrl: './donut-chart.component.html',
    styleUrls: ['./donut-chart.component.scss'],
    standalone: true,
    imports: [NgStyle]
})
export class DonutChartComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() public config: DonutChartConfig;
  @Input() public chartId!: string;
  @Input() public data!: DonutChartData;
  @Output() public readonly sliceClicked = new EventEmitter<ISliceClickEvent>();

  private root!: am5.Root;
  private series!: am5percent.PieSeries;
  private label!: am5.Label;
  private noDateFoundModal: am5.Modal;

  constructor(@Inject(PLATFORM_ID) private platformId: string, private zone: NgZone) {}

  public ngAfterViewInit(): void {
    this.browserOnly(() => {
      this.initChart();
    });
  }

  public ngOnDestroy(): void {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!changes?.data?.firstChange && changes?.data?.currentValue) {
      this.updateData();
    }
  }

  private browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  private initChart() {
    if (this.root) {
      this.root.dispose();
    }
    if (this.noDateFoundModal?.isOpen()) {
      this.noDateFoundModal.close();
    }

    // check if the container exists
    if (!document.getElementById(this.chartId)) {
      return;
    }

    const root = am5.Root.new(this.chartId);
    this.root = root;
    this.noDateFoundModal = am5.Modal.new(root, {
      content: 'No data to display'
    });
    const myTheme = am5.Theme.new(root);
    const { innerRadius } = this.config;

    root.setThemes([myTheme]);
    // eslint-disable-next-line no-underscore-dangle
    root._logo?.children.clear();

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5.percent(70),
        innerRadius: innerRadius ? am5.percent(innerRadius) : am5.percent(80),
        layout: root.verticalLayout
      })
    );

    this.series = this.buildSeries(chart, root);

    this.buildInnerLabel(root);
  }

  private buildSeries(chart: am5percent.PieChart, root: am5.Root): am5percent.PieSeries {
    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'label'
      })
    );

    series.slices.template.setAll({
      templateField: 'columnSettings',
      tooltipText: '',
      cursorOverStyle: 'pointer'
    });
    // disable pullout
    series.slices.template.set('toggleKey', 'none');

    // removing the shift when the user hovers
    series.slices.template.states.create('hover', {
      shiftRadius: 0,
      scale: 1
    });

    series.slices.template.events.on('click', (e: ISpritePointerEvent & { type: 'click'; target: am5.Slice }) => {
      this.zone.run(() => {
        document.body.style.cursor = 'default';
        const itemData = e.target.dataItem?.dataContext as any;
        this.sliceClicked.emit({
          data: itemData
        });
      });
    });

    // series labels styling
    const labelHtml = `<div style="background-color: #f5f5f5; color: #333; border: 1px solid #e0e0e0;
      font-size: 12px; padding: 3px 4px;">
      {value} ({valuePercentTotal.formatNumber('#.0')}%)
    </div>`;

    series.labels.template.setAll({
      fontSize: 12,
      textType: 'adjusted',
      radius: 3,
      html: labelHtml
    });

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(this.getSerializeData());

    return series;
  }

  private buildInnerLabel(root: am5.Root): am5.Label {
    // Add label
    const label = root.tooltipContainer.children.push(
      am5.Label.new(root, {
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.p50
      })
    );

    this.setLabelData(label);
    return label;
  }

  private setLabelData(label: am5.Label) {
    label.set(
      'html',
      `<div style="text-align: center; fontFamily: 'Open Sans, Source Sans Pro, sans-serif'">
        <div style="font-weight: 600; font-size: 24px;">${this.data.innerData.value}</div>
        <div style="font-size: 16px;">${this.data.innerData.label}</div>
       </div>`
    );
  }

  private updateData() {
    if (this.series) {
      this.series.data.setAll(this.getSerializeData());
    }

    if (this.label) {
      this.setLabelData(this.label);
    }
  }

  private getSerializeData(isGray?: boolean): Record<string, any>[] {
    return this.data.sliceData.map((d) => {
      const { color, ...other } = d;
      return {
        ...other,
        ...(color
          ? {
              columnSettings: {
                fill: isGray ? am5.color('#e6e6e6') : am5.color(color),
                stroke: isGray ? am5.color('#e6e6e6') : am5.color(color)
              }
            }
          : {})
      };
    });
  }
}
