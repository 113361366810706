<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->
<div class="relative min-h-[500px]" [ngStyle]="{ 'min-height': maxHeight + 53 + 'px' }" data-html2canvas-ignore>
  <ng-container
    *ngIf="
      tableConfiguration && !tableConfiguration.isLoading && !tableConfiguration.error && !isLoading;
      else loadingTemplate
    ">
    <div class="w-full flex flex-col">
      <div *ngIf="!hideHeader" class="flex items-end w-full" [ngStyle]="{ 'padding-right': scrollbarWidth + 'px' }">
        <span
          [attr.data-jid]="testsEnum.headerColumn"
          class="column-header-element"
          [ngStyle]="{
            minWidth: column.width ? column.width : '',
            maxWidth: column.width ? column.width : '',
            'padding-left': first ? '0' : '10px',
            'padding-right': last ? '0' : '10px'
          }"
          [ngClass]="column.headerCssClass"
          *ngFor="let column of tableConfiguration.columns; let first = first; let last = last; let index = index">
          {{ column.title }}
          <i
            class="fas fa-sort-up sort-icon"
            [ngClass]="{ 'fa-sort-up': !tableConfiguration.isDesc, 'fa-sort-down': tableConfiguration.isDesc }"
            (click)="changeSort(column)"
            *ngIf="column.sortable && column.title === tableConfiguration.sortedBy"></i>
        </span>
      </div>
      <div
        #tableContainer
        class="flex flex-col overflow-auto w-full"
        [style]="{ maxHeight: maxHeight + 'px', minHeight: maxHeight + 'px' }">
        <ng-container *ngIf="tableRecords?.length; else noDataTemplate">
          <div
            [attr.data-jid]="testsEnum.tableRow"
            class="flex w-full bg-white py-2"
            [ngClass]="{
              '!bg-odd': rowIndex % 2 === 0,
              'border-b': rowIndex === tableRecords.length - 1 && !isNewRefactoring()
            }"
            *ngFor="let row of tableRecords; let rowIndex = index">
            <div
              class="flex items-center"
              [ngClass]="column.columnCssClass"
              [ngStyle]="{
                minWidth: column.width ? column.width : '',
                maxWidth: column.width ? column.width : '',
                'padding-left': first ? '0' : '10px',
                'padding-right': last ? '0' : '10px'
              }"
              *ngFor="let column of tableConfiguration.columns; let first = first; let last = last; let index = index">
              <ng-container
                *ngIf="column.template"
                [ngTemplateOutlet]="column.template"
                [ngTemplateOutletContext]="{
                  $implicit: {
                    columnConfig: column,
                    rowData: row,
                    rowIndex: rowIndex
                  }
                }">
              </ng-container>
              <ng-container *ngIf="!column.template">
                <span class="text-gray-dark text-tiny font-normal font-sans">
                  {{ row[column.key] }}
                </span>
              </ng-container>
            </div>
          </div>
          <div *ngIf="pagination" data-role="pagination" class="pagination">
            <div class="py-6">
              <span
                *ngIf="currentRows < pagination.total"
                data-role="pagination-show-more"
                class="cursor-pointer off-white text-tiny text-primary font-bold flex gap-2"
                (click)="isPaginating ? false : loadMore()">
                {{ isPaginating ? 'Loading' : 'Show More' }}
                <ng-template *ngIf="isPaginating" [ngTemplateOutlet]="pageLoading"></ng-template>
              </span>
            </div>
            <div data-role="pagination-counter" class="pagination-counts">
              Showing {{ currentRows }} of {{ pagination.total }}
            </div>
          </div>
        </ng-container>
      </div>
      @if (isNewRefactoring() && extLoadMore) {
        <div data-role="pagination" class="pagination pagination-ext">
          <div class="py-6">
            @if (extPagination().currentCounter < extPagination().totalCounter) {
              <span
                data-role="pagination-show-more"
                class="cursor-pointer off-white text-tiny text-primary font-bold flex gap-2"
                (click)="isPaginating ? false : extLoadMore()">
                {{ isPaginating ? 'Loading' : 'Show More' }}
                <ng-template *ngIf="isPaginating" [ngTemplateOutlet]="pageLoading" />
              </span>
            }
          </div>
          <div data-role="pagination-counter" class="pagination-counts">
            Showing {{ extPagination().currentCounter }} of {{ extPagination().totalCounter }}
          </div>
        </div>
      }
    </div>
  </ng-container>
  <ng-template #loadingTemplate>
    <lfx-loading [minHeight]="maxHeight ? maxHeight : undefined"></lfx-loading>
  </ng-template>

  <ng-template #noDataTemplate>
    <lfx-no-data [minHeight]="maxHeight" data-role="no-data"></lfx-no-data>
  </ng-template>
</div>

<div
  class="w-full flex h-fit flex-col bg-white hidden table-component-container"
  *ngIf="tableConfiguration && !tableConfiguration.isLoading && !tableConfiguration.error && !isLoading">
  <div class="flex items-end w-full t-h">
    <span
      class="column-header-element"
      [ngStyle]="{
        minWidth: column.width ? column.width : '',
        maxWidth: column.width ? column.width : '',
        'padding-left': first ? '0' : '10px',
        'padding-right': last ? '0' : '10px'
      }"
      [ngClass]="column.headerCssClass"
      *ngFor="let column of tableConfiguration.columns; let first = first; let last = last; let index = index">
      {{ column.title }}
      <i
        class="fas fa-sort-up sort-icon"
        [ngClass]="{ 'fa-sort-up': !tableConfiguration.isDesc, 'fa-sort-down': tableConfiguration.isDesc }"
        (click)="changeSort(column)"
        *ngIf="column.sortable && column.title === tableConfiguration.sortedBy"></i>
    </span>
  </div>
  <div class="flex flex-col w-full t-c">
    <ng-container *ngIf="tableRecords?.length; else noDataTemplate">
      <div
        class="flex w-full bg-white py-2 border-t border-gray hover:!bg-blue/10"
        [ngClass]="{ '!bg-odd': rowIndex % 2 === 0, 'border-b': rowIndex === tableRecords.length - 1 }"
        *ngFor="let row of tableRecords; let rowIndex = index">
        <div
          [ngClass]="column.columnCssClass"
          [ngStyle]="{
            minWidth: column.width ? column.width : '',
            maxWidth: column.width ? column.width : '',
            'padding-left': first ? '0' : '10px',
            'padding-right': last ? '0' : '10px'
          }"
          *ngFor="let column of tableConfiguration.columns; let first = first; let last = last; let index = index">
          <ng-container
            *ngIf="column.template"
            [ngTemplateOutlet]="column.template"
            [ngTemplateOutletContext]="{
              $implicit: {
                columnConfig: column,
                rowData: row,
                rowIndex: rowIndex
              }
            }">
          </ng-container>
          <ng-container *ngIf="!column.template">
            <span class="text-gray-dark text-tiny font-normal font-sans">
              {{ row[column.key] }}
            </span>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #pageLoading>
  <div class="flex justify-center animate-spin">
    <i class="fas fa-circle-notch text-xl"></i>
  </div>
</ng-template>
