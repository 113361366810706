// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
import * as am5 from '@amcharts/amcharts5';
import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FilterService } from '@app/shared/services/filter.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MapChartConfig } from 'lfx-insights';
import * as am5map from '@amcharts/amcharts5/map';
import {
  createBubbleSeries,
  createChartRoot,
  createMapChart,
  createPolygonSeries
} from '@app/shared/utils/chart-helpers';
import { ISerializeGeoDistributionData } from '@foundation/overview/components/geographical-distribution/geographical-distribution.service';

/**
 *
 * INFO:
 * To configure a new chart, you need to review te next files
 * packages/lfx-insights/interfaces/charts.d.ts
 * apps/frontend/src/app/shared/services/chart.service.ts
 */
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lfx-map-chart',
    templateUrl: './map-chart.component.html',
    styleUrls: ['./map-chart.component.scss'],
    standalone: true
})
export class MapChartComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() public config!: MapChartConfig;
  @Input() public data!: ISerializeGeoDistributionData[];
  @ViewChild('chartElement') chartElement: ElementRef<HTMLElement>;
  private root!: am5.Root;
  private bubbleSeries!: am5map.MapPointSeries;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private zone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public filterService: FilterService
  ) {}

  // Run the function only in the browser
  public browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes?.data) {
      this.changeData();
    }
  }

  public ngAfterViewInit() {
    // Chart code goes in here
    this.browserOnly(() => {
      am5.ready(() => {
        if (!this.chartElement?.nativeElement) return;
        const root = createChartRoot(this.chartElement.nativeElement);
        const chart = createMapChart(root);
        const polygonSeries = createPolygonSeries(root, chart);
        this.bubbleSeries = createBubbleSeries(root, chart, polygonSeries, this.config);
        this.bubbleSeries.data.setAll(this.data);
        this.root = root;
      });
    });
  }

  public ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  public checkEmptyData(): boolean {
    // TODO: implement this
    return false;
  }

  private changeData(): void {
    if (this.bubbleSeries) {
      this.bubbleSeries.data.setAll(this.data);
    }
  }
}
