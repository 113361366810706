<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->
<span
  class="text-white rounded-sm h-[16px] w-[16px] flex justify-center items-center inline-block absolute mx-[2px]"
  [class]="classes"
  [appDownloadStyle]="{ marginTop: '8px' }">
  <fa-icon [icon]="icon" [size]="'sm'"></fa-icon>
</span>
