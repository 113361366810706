import { map } from 'rxjs';
import { DateRange, Query } from '@cubejs-client/core';

import { CubeServiceBasic } from '@services/api/basic';
import { ICacheKey, ICompareBase, InjectQuery } from '@services/api/api.types';
import { CubeService } from '../../cube.service';

export interface IFoundationAllTimeStats {
  ['SnowSegmentCard.activeContributorsSum']: number;
  ['SnowSegmentCard.csetCountsSum']: number;
  ['SnowSegmentCard.commitsCountsSum']: number;
  ['SnowSegmentCard.forksCountsSum']: number;
  ['SnowSegmentCard.issuesCountsSum']: number;
  ['SnowSegmentCard.prCountsSum']: number;
  ['SnowSegmentCard.starsCountsSum']: number;
  ['SnowSegmentCard.linesCodeSum']: number;
  ['SnowSegmentCard.activeOrganizationsSum']: number;
  ['SnowSegmentCard.lastActivityAll']: string;
  ['SnowSegmentCard.slug']: string;
  ['SnowSegmentCard.topGithubOrg']: string;
}

export interface IFoundationProjectKeyMetric extends ICompareBase {
  ['SnowSubSegments.project_slug']: string;
  ['SnowProjectCardKeyMetrics.total_participating_organizations']: number;
  ['SnowProjectCardKeyMetrics.pr_time_to_first_review_avg']: number;
  ['SnowProjectCardKeyMetrics.issues_time_to_comment_avg']: number;
}

export interface IFoundationMetrics {
  ['SnowSegmentCard.activeContributorsSum']: number;
  ['SnowSegmentCard.activeOrganizationsSum']: number;
  ['SnowSegmentCard.prCountsSum']: number;
  ['SnowSegmentCard.commitsCountsSum']: number;
  ['SnowSegmentCard.descendantsCountsSum']: number;
  ['SnowSegmentCard.linesCodeSum']: number;
  ['SnowSegmentCard.csetCountsSum']: number;
  ['SnowSegmentCard.topGithubOrg']: string;
}

export interface IActiveContributorsKeyMetric extends ICompareBase {
  ['SnowContributions.count_contributors']?: number;
  ['SnowContributions.count_organization']?: number;
  ['SnowContributions.project']: string;
}

export interface ICOCOMOScore {
  ['SnowRepositoryReport.cocomoInDollars']: string;
  ['SnowRepositoryReport.projectSlug']?: string;
  ['SnowSegmentRepos.segment_id']?: string;
}

export interface IProjectVelocity {
  ['vw_project_maturity_contributions.admin_category']: string;
  ['vw_project_maturity_contributions.name']: string;
  ['vw_project_maturity_contributions.count_contributors']: number;
  ['vw_project_maturity_contributions.count_commits']: number;
  ['vw_project_maturity_contributions.count_pullrequests']: number;
  ['vw_project_maturity_contributions.count_issues']: number;
  ['vw_project_maturity_contributions.count_total_changesets']: number;
}

export interface IProjectMaturityLevel {
  ['vw_project_maturity_contributions.admin_category']: string;
  ['vw_project_maturity_contributions.name']: string;
  ['vw_project_maturity_contributions.projectId']: string;
  ['vw_project_maturity_contributions.slug']: string;
  ['vw_project_maturity_contributions.foundation']: string;
  ['vw_project_maturity_contributions.segment_id']: string;
}

export interface IProjectSources {
  ['SnowSegmentSources.id']: string;
  ['SnowSegmentSources.sources']: string;
}

export class FoundationApiClass extends CubeServiceBasic {
  protected useQuery: InjectQuery;
  constructor(cubejs: CubeService, useQuery: InjectQuery) {
    super(cubejs);
    this.useQuery = useQuery;
  }

  public getFoundationAllTimeStats(query: Query, slugs: string[]) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_FOUNDATION_ALL_TIME_STATS, slugs.join('-')],
      queryFn: () => this.load$<IFoundationAllTimeStats>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getFoundationProjectKeyMetrics(query: Query, projectSlugs: string[], periods: DateRange[]) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_FOUNDATION_PROJECT_KEY_METRICS, projectSlugs, periods],
      queryFn: () => this.load$<IFoundationProjectKeyMetric>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getActiveContributorsKeyMetric(query: Query, projectSlugs: string[], periods: DateRange[]) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_ACTIVE_CONTRIBUTORS_KEY_METRICS, projectSlugs, periods],
      queryFn: () => this.load$<IActiveContributorsKeyMetric>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getProjectCOCOMOBySlug(query: Query, projectSlugs: string[]) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_FOUNDATION_COCOMO_BY_SLUG, projectSlugs],
      queryFn: () => this.load$<ICOCOMOScore>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getFoundationCOCOMOBySegmentID(query: Query, foundationIDs: string[]) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_FOUNDATION_COCOMO_BY_SEGMENT_ID, foundationIDs],
      queryFn: () => this.load$<ICOCOMOScore>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getFoundationProjectsVelocity(query: Query, slug: string) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_FOUNDATION_PROJECT_VELOCITY, slug],
      queryFn: () => this.load$<IProjectVelocity>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getFoundationProjectMaturityLevel(query: Query, slug: string) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_FOUNDATION_PROJECT_MATURITY_LEVEL, slug],
      queryFn: () => this.load$<IProjectMaturityLevel>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getFoundationMetrics(query: Query, slug: string) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_FOUNDATION_METRICS, slug] as const,
      queryFn: () => this.load$<IFoundationMetrics>(query).pipe(map((res) => this.toTable(res)))
    });
  }
}
