<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<div class="cursor-pointer flex flex-col gap-2 pb-2" [appDownloadStyle]="{ paddingTop: '20px' }" (click)="onClick()">
  <lfx-project-logo class="px-6" [project]="project()" [logoHeight]="50" [logoWidth]="200" />
  <h2 class="!text-gray-dark !font-normal text-center px-4 mt-1 h-[60px] relative capitalize">
    {{ project().name }}
  </h2>
</div>

