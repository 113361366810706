import { NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core';
import { DownloadStyleDirective } from '@app/shared/directives/download-style.directive';
import { InsightProject } from 'lfx-insights';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  standalone: true,
  selector: 'lfx-project-logo',
  templateUrl: './project-logo.component.html',
  styleUrls: ['./project-logo.component.scss'],
  imports: [DownloadStyleDirective, NgIf, NgStyle, InlineSVGModule, TooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ProjectLogoComponent {
  public project = input.required<InsightProject>();
  public logoHeight = input<number>(50);
  public logoWidth = input<number>(200);

  public hideSVGTag = signal(false);
  public projectLogo = computed<string | undefined>(() => this.project().logo);
  public isSVGLogo = computed(() => this.project()?.logo?.includes('.svg') || false);
  public imgUrl = computed(() =>
    this.project().logo === undefined || this.project().logo === null
      ? 'assets/icons/' + this.project().slug + '.svg'
      : this.project().logo!
  );

  /**
   * TODO: need to find a better way to handle SVG images
   * This causes CORS error if the logo is hosted on a server that doesn't have CORS enabled.
   *
   * This functions is used to convert an SVG into data:image so that it can be downloaded by the html2canvas library
   */

  public loadSVGLogo(svg: SVGElement) {
    const svgData = encodeURIComponent(svg.outerHTML);
    const dataUrl = `data:image/svg+xml;charset=utf-8,${svgData}`;
    const img = document.getElementById('project-card-logo-' + this.project().id) as HTMLImageElement;

    if (img) {
      // Set the data URL as the src attribute of the Image element
      img.src = dataUrl;
      this.hideSVGTag.set(true);
    }
  }

  public handleSVG = (svg: SVGElement): SVGElement =>  {
    // check if this svg has the viewBox attribute
    if (!svg.getAttribute('height')) {
      svg.setAttribute('height', `${this.logoHeight()}`);
    }
    if (!svg.getAttribute('viewBox')) {
      const height = svg.getAttribute('height');
      const width = svg.getAttribute('width');

      // add one if it doesn't
      if (width && height) {
        svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
      }
    }
    return svg;
  }
}
