// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { LineChartData } from './line-chart-new.types';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

export const dataSerializer = (data: LineChartData[]): Record<string, any>[] =>
  data.map((d) => {
    let item = {};
    d.values.forEach((s, index) => {
      item = {
        ...item,
        [`value_${index + 1}`]: s
      };
    });
    return {
      dateCategory: d.dateCategory,
      ...item
    };
  });


export function addLeftYAxisLabel(
  root: am5.Root,
  yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
  yAxisTitle: string,
  color?: string
) {
  yAxis.children.unshift(
    am5.Label.new(root, {
      text: yAxisTitle,
      fontSize: 12,
      textAlign: 'center',
      y: am5.p50,
      fill: am5.color(color || '#333333'),
      rotation: -90,
      fontWeight: '400'
    })
  );
}

export function addRightYAxisLabel(
  root: am5.Root,
  yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
  yAxisTitle: string,
  color?: string
) {
  yAxis.children.push(
    am5.Label.new(root, {
      text: yAxisTitle,
      fontSize: 12,
      textAlign: 'center',
      y: am5.p50,
      fill: am5.color(color || '#333333'),
      rotation: -90,
      fontWeight: '400'
    })
  );
}

export * as LineChartNewService from './line-chart-new.service';
