<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->
<div class="flex justify-between">
  <span>{{ title }}</span>
  <div class="flex flex-row justify-end gap-2 basis-5/12">
    <span *ngIf="type === 'number'">{{ (keyMetric?.value | lfxShortNumber) || '-' }}</span>
    <span *ngIf="type === 'number'" class="basis-1/4 text-end" [class]="keyMetric?.direction || ''">{{ (keyMetric?.change | lfxShortNumber | integerSign) || '-' }}</span>

    <span *ngIf="type === 'date'">{{ (keyMetric?.value | hoursDays : true) || '-' }}</span>
    <span *ngIf="type === 'date'" class="basis-1/4 text-end" [class]="keyMetric?.direction || ''">{{
      (keyMetric?.change | hoursDays : true : true : true) || '-'
    }}</span>
  </div>
</div>
