<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<div class="bp-score-wrap">
  Best Practices Score
  <span class="relative">
    <i class="fal fa-info-circle info-icon" [appLfxTooltipTrigger]="'project_score' + project.id"> </i>
    <lfx-tooltip
      [id]="'project_score' + project.id"
      [text]="scoreUpdatedDate(project.bestPracticeScores?.lastCloUpdate)"
      [config]="{ show: 'bottom', positionValue: '120%', textWrap: 'white-space:nowrap' }"></lfx-tooltip>
  </span>
</div>
<div class="bp-score-container" *ngIf="project.bestPracticeScores">
  <lfx-best-practice-embed
    [id]="project.id + suffix"
    [inModal]="false"
    [scoreData]="project.bestPracticeScores"
    [hideIcon]="true"></lfx-best-practice-embed>
</div>
