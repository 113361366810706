<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->
<div class="h-full w-full relative">
  <div class="absolute top-8 left-0 w-full h-full flex justify-center items-center" *ngIf="isLoading">
    <lfx-loading></lfx-loading>
  </div>
  <div *ngIf="!isLoading" class="flex flex-col h-full w-full">
    <div [id]="chartName" [ngClass]="chartName" class="flex-grow w-full"></div>
  </div>
</div>
