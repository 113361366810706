// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const enum FeaturesEnum {
  gitOnly = 'gitOnly',
  foundationAnalytics = 'foundationAnalytics',
  projectVelocity = 'projectVelocity',
  projectMaturity = 'projectMaturity',
  mailingList = 'mailingList',
  confluence = 'confluence',
  gerrit = 'gerrit',
  newSidNav = 'newSidNav'
}

export enum PlatformEnum {
  git = 'git',
  github = 'github',
  gerrit = 'gerrit',
  groupsio = 'groupsio',
  confluence = 'confluence'
}

export enum PlatformsFilterLeaderboard {
  gitGithub = 'git_github',
  gitGerrit = 'git_gerrit'
}

export const enum ProjectRatingEnum {
  a = 'A',
  b = 'B',
  c = 'C',
  d = 'D'
}

export const enum NoDataPageRoutesEnum {
  generic = 'Generic',
  mailingList = 'MailingList',
  confluence = 'Confluence',
  gerrit = 'Gerrit',
  github = 'Github'
  // TODO: add more here as needed
}

export enum NoDataPageMsgEnum {
  generic = 'The data for this data source is not configured for this project',
  mailingList = 'Mailing lists are not configured for this project',
  confluence = 'Confluence is not configured for this project',
  gerrit = 'Gerrit is not configured for this project',
  github = 'Github is not configured for this project'
}
