// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  constructor() {}

  public isFeatureEnabled(featureName: string): boolean {
    if (environment.features && Object.prototype.hasOwnProperty.call(environment.features, featureName)) {
      return (environment.features as { [key: string]: boolean })[featureName];
    }
    return false;
  }
}
