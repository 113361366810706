<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<ng-container *ngIf="!useMicroSize; else microSizeTemplate">
  <div
    class="flex flex-col items-center justify-center w-full min-h-[400px] bg-repeat-round"
    [ngStyle]="{ 'background-image': 'url(' + bgImagePath + ')', minHeight: minHeight + 'px' }">
    <span class="font-roboto text-gray-dark text-3-5xl mb-4"> COMING SOON </span>
    <div class="flex items-center">
      <fa-icon class="text-xl text-gray-dark cursor-pointer mr-2.5 hover:text-yellow-pale" [icon]="['fal', 'thumbs-up']" (click)="increaseThumbs()"></fa-icon>
      <span class="text-base text-gray-dark font-sans">{{ thumbs }}</span>
    </div>
  </div>
</ng-container>
<ng-template #microSizeTemplate>
  <div class="flex items-center">
    <div class="bg-gray-400 rounded-l-3xl px-2 h-7 mr-0.5">
      <span class="text-white text-tiny font-sans whitespace-nowrap"> Coming Soon </span>
    </div>
    <span class="flex items-center bg-gray-400 rounded-r-3xl px-1 h-7">
      <fa-icon class="text-tiny text-white cursor-pointer mr-1 hover:text-yellow-pale" [icon]="['fal', 'thumbs-up']" (click)="increaseThumbs()"></fa-icon>
      <span class="text-tiny text-white font-sans">{{ thumbs }}</span>
    </span>
  </div>
</ng-template>
