// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import * as am5 from '@amcharts/amcharts5';
import * as am5radar from '@amcharts/amcharts5/radar';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { isPlatformBrowser, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import { FilterService } from '@app/shared/services/filter.service';
import { GaugeChartConfig } from 'lfx-insights';

@Component({
    selector: 'lfx-gauge-chart',
    templateUrl: './gauge-chart.component.html',
    styleUrls: ['./gauge-chart.component.scss'],
    standalone: true,
    imports: [NgStyle]
})
export class GaugeChartComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() public config!: GaugeChartConfig;
  @Input() public chartName!: string;

  private root!: am5.Root;
  private chartRef!: am5xy.XYChart;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private zone: NgZone,
    public filterService: FilterService
  ) {}

  // Run the function only in the browser
  public browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  public ngAfterViewInit() {
    // this.loadData();
    // Chart code goes in here
    this.browserOnly(() => {
      // TODO: find a way to remove this hack. This was needed to add a bit of pause before rendering this chart
      // if this chart was being rendered using data from cache, it doesn't render at all
      // adding this timeout somehow fixes it
      setTimeout(() => {
        this.initChart();
      }, 50);
    });
  }

  public ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  // eslint-disable-next-line no-unused-vars
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.config && !changes.config.firstChange) {
      setTimeout(() => {
        this.initChart();
      }, 50);
    }
  }

  public checkEmptyData(): boolean {
    // TODO: implement this
    return false;
  }

  private initChart(): void {
    if (this.root) {
      this.root.dispose();
    }

    // check if the container exists
    if (!document.getElementById(this.chartName)) {
      return;
    }

    // Create root element
    const root = am5.Root.new(this.chartName);

    // Set themes
    root.setThemes([am5themesAnimated.new(root)]);
    // remove footer logo
    // eslint-disable-next-line no-underscore-dangle
    root._logo?.children.clear();

    // Create chart
    const chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360,
        height: this.config.height
      })
    );

    // Create axis and its renderer
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Axes
    const axisRenderer = am5radar.AxisRendererCircular.new(root, {
      innerRadius: am5.percent(this.config.innerRadius), // -50
      radius: am5.p100,
      strokeOpacity: 0.1
    });

    axisRenderer.labels.template.setAll({
      textType: 'adjusted',
      centerY: 100,
      fill: am5.color('#807f83'),
      fontSize: 12,
      fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
      paddingBottom: 10
    });
    axisRenderer.labels.template.adapters.add('text', (text: string | undefined) =>
      text === '0' || text === '100' ? text : ''
    );
    axisRenderer.grid.template.set('forceHidden', true);

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: 100,
        strictMinMax: true,
        renderer: axisRenderer
      })
    );

    // add yes and no labels
    for (const iterator of this.config.ranges) {
      const element = xAxis.makeDataItem({
        value: iterator.value,
        endValue: iterator.endValue
      });
      xAxis.createAxisRange(element);
      // const label = element.get('label') as am5xy.AxisLabelRadial;

      // label.setAll({
      //   position: 'relative',
      //   forceHidden: false,
      //   text: `[bold]${iterator.text}[/]\n(${iterator.value}-${iterator.endValue}%)`,
      //   fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
      //   fontSize: 13,
      //   textAlign: 'center',
      //   textType: 'adjusted'
      // });
      element.get('axisFill')?.setAll({ visible: true, fillOpacity: 1, fill: am5.color(iterator.color) });
    }
    // Add clock hand
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Clock_hands
    const axisDataItem = xAxis.makeDataItem({
      value: this.config.value
    });

    const hand = axisDataItem.set(
      'bullet',
      am5xy.AxisBullet.new(root, {
        sprite: am5radar.ClockHand.new(root, {
          radius: am5.percent(this.config.spriteRadius),
          pinRadius: 5
        })
      })
    );

    (hand.get('sprite') as any).hand.setAll({
      fill: am5.color('#6884ad')
    });

    (hand.get('sprite') as any).pin.setAll({
      fill: am5.color('#6884ad')
    });

    xAxis.createAxisRange(axisDataItem);

    axisDataItem.get('grid')?.set('visible', false);

    this.chartRef = chart;

    this.root = root;
  }
}
