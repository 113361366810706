<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<div class="font-bold mb-6">Date Range</div>
<div
  *ngFor="let item of filterDatesOptions"
  (click)="onSelectDateFilter(item)"
  class="mb-2 cursor-pointer hover:text-primary/90"
  [ngClass]="{ 'text-primary/90': item === selectedRange }">
  {{ item }}
</div>
