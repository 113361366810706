// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'integerSign',
    standalone: true
})
export class IntegerSignPipe implements PipeTransform {
  public transform(value: number | string | undefined | null): any {
    if (value === undefined || value === null) {
      return value;
    }

    if (typeof value === 'string') {
      return +value > 0 ? `+${value}` : value;
    }

    return value > 0 ? `+${value}` : value;
  }
}
