<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->
<div class="table-delta-container">
  <span
    [ngClass]="{
      'text-green': delta && delta > 0,
      'text-red-dark': delta && delta <= 0
    }">
    {{ (delta | validVarType) !== 'N/A' ? (delta | lfxShortNumber | integerSign) : 'ــ' }}
  </span>
</div>
