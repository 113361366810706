// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { ChangeDetectionStrategy, Component, ElementRef, computed, inject, input } from '@angular/core';
import { FilterService } from '@app/shared/services/filter.service';
import { DownloadService } from '@app/shared/services/download.service';
import { InsightProject } from 'lfx-insights';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformEnum } from '@app/shared/interface/common.enum';
import { CardErrorkeys } from './project-card.service';
import { getProjectOverviewRoutedFrom, getProjectOverviewUrl } from '@app/shared/services/project-utils.service';
import { ProjectNewService } from '@app/shared/services/project-new.service';
import { CardFooterComponent } from '../card-footer/card-footer.component';
import { ProjectMetricDetailsComponent } from '../project-metric-details/project-metric-details.component';
import { NgIf } from '@angular/common';
import { ProjectStatsRowComponent } from '../project-stats-row/project-stats-row.component';
import { CardLogoComponent } from '../card-logo/card-logo.component';
import { CardHeadButtonsComponent } from '../card-head-buttons/card-head-buttons.component';
import { isPlatformEnabled } from '@app/shared/services/project-utils.service';
import { ProjectCardBpScoreComponent } from '../project-card-bp-score/project-card-bp-score.component';

@Component({
  selector: 'lfx-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
  standalone: true,
  imports: [
    CardHeadButtonsComponent,
    CardLogoComponent,
    ProjectStatsRowComponent,
    NgIf,
    ProjectMetricDetailsComponent,
    CardFooterComponent,
    ProjectCardBpScoreComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectCardComponent {
  protected readonly githubPlatform = PlatformEnum.github;

  public project = input.required<InsightProject>();
  public isOnboarding = input(false);
  public suffix = input('');
  public errorKeys = input<CardErrorkeys[]>([]); // a way to pass the error details to the card

  public isBPError = computed(() => this.errorKeys()?.includes(CardErrorkeys.bestPracticeScore) || false);
  public showBPScore = computed(() => isPlatformEnabled(this.project(), PlatformEnum.github));

  private route: ActivatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);
  private elementRef: ElementRef = inject(ElementRef);
  private projectNewService: ProjectNewService = inject(ProjectNewService);
  private downloadService: DownloadService = inject(DownloadService);
  private filterService: FilterService = inject(FilterService);

  public onCardNavigate(bestPractice: boolean = false): void {
    const foundationSlug = this.route.snapshot.paramMap.get('foundationSlug') || this.project().slug;
    const projectSlug = this.project().slug;

    this.filterService.applyFilterPartially({
      ...this.filterService.defaultFilters,
      project: projectSlug,
      foundationSlug
    });

    this.projectNewService.setSelectedProjectSlug(projectSlug);

    this.router.navigate([`${getProjectOverviewUrl(this.project(), foundationSlug)}`], {
      queryParams: {
        project: projectSlug,
        routedFrom: getProjectOverviewRoutedFrom(this.project()),
        bestPractice
      },
      queryParamsHandling: 'merge'
    });
  }

  public downloadCard(): void {
    this.downloadService.download(this.elementRef.nativeElement, this.project.name + '-card', 2, false);
  }
}
