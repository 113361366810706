import { PivotConfig, Query } from '@cubejs-client/core';
import { CubeServiceBasic } from '@services/api/basic';
import { CubeService } from '@services/cube.service';
import { ICacheKey, InjectQuery } from '@app/shared/services/api/api.types';
import { map } from 'rxjs';
import { InsightsFilters } from '@app/shared/services/filter.service';

export interface IPullRequestsParticipants {
  ['SnowPullRequestsOverview.activity_count']: number;
  ['SnowPullRequestsOverview.member_id']: string;
  ['SnowPullRequestsOverview.username']?: string;
  ['SnowMembers.displayName']?: string;
  ['SnowMembers.logo_url']?: string;
}

interface ChartData {
  xValues: string[];
  x: string;
}

export interface IPullRequestsCodeReviews extends ChartData {
  ['SnowPullRequestsOverview.non_empty_reviews_count']: number;
}

export interface IPullRequestsReviewComments extends ChartData {
  ['SnowPullRequestsOverview.non_empty_review_comments_count']: number;
}

export interface IPullRequestsReviewed extends ChartData {
  ['SnowPullRequestsOverview.reviewed_count']: number;
}

export class PrCodeReviewApiClass extends CubeServiceBasic {
  protected useQuery: InjectQuery;
  constructor(cubejs: CubeService, useQuery: InjectQuery) {
    super(cubejs);
    this.useQuery = useQuery;
  }

  public getPullRequestsParticipantsSummary(query: Query, filters: InsightsFilters) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_PULL_REQUESTS_PARTICIPANTS_SUMMARY,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IPullRequestsParticipants>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getTotalPullRequestsCodeReviews(query: Query, filters: InsightsFilters) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_TOTAL_PULL_REQUESTS_CODE_REVIEWS,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IPullRequestsCodeReviews>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getTotalPullRequestsReviewComments(query: Query, filters: InsightsFilters) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_TOTAL_PULL_REQUESTS_REVIEW_COMMENTS,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IPullRequestsReviewComments>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getTotalPullRequestsReviewed(query: Query, filters: InsightsFilters) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_TOTAL_PULL_REQUESTS_REVIEWED,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IPullRequestsReviewed>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getPullRequestsCodeReviews(
    query: Query,
    filters: InsightsFilters,
    enabled: boolean,
    pivotConfig?: PivotConfig
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_PULL_REQUESTS_CODE_REVIEWS,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () =>
        this.load$<IPullRequestsCodeReviews>(query).pipe(map((res) => this.toChartSeries(res, pivotConfig))),
      enabled
    });
  }

  public getPullRequestsReviewComments(
    query: Query,
    filters: InsightsFilters,
    enabled: boolean,
    pivotConfig?: PivotConfig
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_PULL_REQUESTS_REVIEW_COMMENTS,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () =>
        this.load$<IPullRequestsReviewComments>(query).pipe(map((res) => this.toChartSeries(res, pivotConfig))),
      enabled
    });
  }

  public getPullRequestsReviewed(query: Query, filters: InsightsFilters, enabled: boolean, pivotConfig?: PivotConfig) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_PULL_REQUESTS_REVIEWED,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IPullRequestsReviewed>(query).pipe(map((res) => this.toChartSeries(res, pivotConfig))),
      enabled
    });
  }
}
