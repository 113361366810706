<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<div
  [id]="'tooltip_' + id"
  class="tooltip absolute font-sans rounded-md bg-gray-dark text-xs text-white py-1 px-4 invisible z-50"
  [style]="styles"
  [ngClass]="{ 'ellipses-tooltip': showLimitedLines }"
  [ngStyle]="{ '-webkit-line-clamp': showLimitedLines ? showLimitedLines : 'unset' }"
  [innerHTML]="text"
  *ngIf="text.length > 0 && !tooltipTemplate"></div>

<div
  [id]="'tooltip_' + id"
  class="tooltip absolute font-sans rounded-md bg-gray-dark text-xs text-white py-1 px-4 invisible z-50"
  [style]="styles"
  [ngClass]="{ 'ellipses-tooltip': showLimitedLines }"
  [ngStyle]="{ '-webkit-line-clamp': showLimitedLines ? showLimitedLines : 'unset' }"
  *ngIf="tooltipTemplate">
  <ng-container [ngTemplateOutlet]="tooltipTemplate"></ng-container>
</div>
