<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<span class="foundation-name">
  @if (allowFoundationNavigation()) {
    <a (click)="goToFoundationView()" class="hover:underline cursor-pointer">
      <h2>{{ foundation().name }}</h2>
    </a>
  } @else {
    <a>
      <h2>{{ foundation().name }}</h2>
    </a>
  }
</span>
<span class="filter-divide">/</span>
<div class="project-select-wrap">
  <lfx-select
    [items]="projects()"
    [selectedItem]="selectedProjectSlug()"
    [isLoading]="
      projects().length === 0 || (isRepoSelectVisible() && projectNewService.selectedProject() === undefined)
    "
    [isSearchable]="true"
    [isClearable]="false"
    [isSelectedFirst]="false"
    placeholder="Select a project..."
    (selectedChanged)="onSelectProjectChange($event)" />
</div>

@if (isMailGroupVisible() || isRepoSelectVisible()) {
  <span class="filter-divide">/</span>
}

<div class="repo-select-wrap" *ngIf="isRepoSelectVisible()">
  <lfx-select
    [items]="repositories()"
    [selectedItem]="selectedRepository()"
    [isSearchable]="true"
    [isLoading]="projectNewService.repositories() === undefined"
    (selectedChanged)="onSelectRepository($event)" />
</div>
