<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<!-- TODO: need to revisit this appProjectPlatform directive implementation -->
<div class="stat-row" [appDownloadStyle]="{ paddingTop: 0 }">
  <span pTooltip="Contributors" tooltipPosition="top">
    <i class="cursor-pointer text-gray text-xs fal fa-user pr-1"></i>
    {{ project().projectStat?.activeContributors || 0 | lfxShortNumber: 1 }}
    {{ isStatsLoading() ? '...' : '' }}
  </span>
  @if (hasGithubSource()) {
    <span pTooltip="Pull Requests" tooltipPosition="top">
      <img
        class="max-h-[40px] pr-1"
        src="assets/icons/pullrequest.svg"
        alt=""
        [appDownloadStyle]="{ marginTop: '10px', float: 'left' }" />
      {{ project().projectStat?.prCounts || 0 | lfxShortNumber: 1 }}
    </span>
  }
  @if (hasGerritSource()) {
    <span pTooltip="ChangeSets" tooltipPosition="top">
      <img
        class="max-h-[40px] pr-1"
        src="assets/icons/pullrequest.svg"
        alt=""
        [appDownloadStyle]="{ marginTop: '10px', float: 'left' }" />
      {{ project().projectStat?.changesetCounts || 0 | lfxShortNumber: 1 }}
    </span>
  }

  <span pTooltip="Commits" tooltipPosition="top">
    <i class="cursor-pointer text-gray text-xs fal fa-code-commit pr-1"></i>
    {{ project().projectStat?.commitsCounts || 0 | lfxShortNumber: 1 }}
  </span>

  @if (hasGithubSource()) {
    <span pTooltip="Issues" tooltipPosition="top">
      <i class="cursor-pointer text-gray text-xs fal fa-dot-circle pr-1"></i>
      {{ project().projectStat?.issuesCounts || 0 | lfxShortNumber: 1 }}
    </span>
    <span pTooltip="Stars" tooltipPosition="top">
      <i class="cursor-pointer text-gray text-xs fal fa-star pr-1"></i>
      {{ project().projectStat?.starsCounts || 0 | lfxShortNumber: 1 }}
    </span>
    <span pTooltip="Forks" tooltipPosition="top">
      <i class="cursor-pointer text-gray text-xs fal fa-code-branch pr-1"></i>
      {{ project().projectStat?.forksCounts || 0 | lfxShortNumber: 1 }}
    </span>
  }
</div>
