// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Injectable, Signal, computed, inject } from '@angular/core';
import { DateRangesEnum, GranularityEnum } from 'lfx-insights';
import { APP_CONFIG } from '@app/config';
import { getCurrentPreviousPeriodDateRanges } from '../../utils/cubejs-helpers';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AppService } from '../app.service';
import { FilterFromParams, InsightsFiltersNew, defaultFilters } from './filter.types';
import { calculateGranularity, getAuthenticatedUserFilters, getRetentionPageFilters } from './filter.utils';
import { filterRangeAll } from '@app/shared/utils/dropdown-helpers';

@Injectable({
  providedIn: 'root'
})
export class FilterNewService {
  public queryParams: Signal<FilterFromParams>;
  public filters: Signal<InsightsFiltersNew> = computed(() =>
    this.mapParamsToFilters(this.queryParams(), this.appService.isAuthenticated(), this.appService.currentUrl())
  );

  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private appService: AppService = inject(AppService);

  constructor(private router: Router) {
    this.queryParams = toSignal(
      this.activatedRoute.queryParams.pipe(
        switchMap((params): Observable<FilterFromParams> => {
          const { project, repository, dateFilters, dateRange, compare, granularity, hideBots } = params;
          let dateFilterValue = APP_CONFIG.DATE_RANGE_DEFAULT as DateRangesEnum;

          if (dateFilters) {
            dateFilterValue =
              filterRangeAll.indexOf(dateFilters) === -1 ? DateRangesEnum.custom : (dateFilters as DateRangesEnum);
          }

          return of({
            project,
            repository,
            dateFilters: dateFilterValue,
            dateRange,
            compare,
            granularity: granularity ? (granularity as GranularityEnum) : defaultFilters.granularity,
            hideBots: hideBots === undefined ? true : hideBots === 'true'
          });
        })
      ),
      { initialValue: {} as FilterFromParams }
    );
  }

  // TODO: remove the skip param after we've remove the old filter service
  public applyFilterPartially(filters: InsightsFiltersNew, skip = true) {
    const currentState = {
      ...this.filters(),
      ...filters
    };

    if (filters.dateFilters || filters.compare) {
      const periods = getCurrentPreviousPeriodDateRanges(
        currentState.dateFilters || APP_CONFIG.DATE_RANGE_DEFAULT,
        currentState.compare || 'PP'
      );
      currentState.periods = periods;

      currentState.granularity = calculateGranularity(currentState);
    }

    if (!skip) {
      this.updateFilterParams(currentState);
    }
  }

  private updateFilterParams(currentFilter: InsightsFiltersNew) {
    const queryParams = {
      project: currentFilter.project,
      repository: currentFilter.repositories ? currentFilter.repositories[0] : undefined,
      dateFilters: currentFilter.dateFilters,
      dateRange:
        currentFilter.periods && Array.isArray(currentFilter.periods?.currentPeriod)
          ? currentFilter.periods.currentPeriod.join(' to ')
          : undefined,
      compare: currentFilter.compare,
      granularity: currentFilter.granularity,
      hideBots: currentFilter.hideBots
    };

    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge' // remove to replace all query params by provided
    });
  }

  private mapParamsToFilters(params: FilterFromParams, isAuthenticated: boolean, url: string): InsightsFiltersNew {
    const { dateFilters, compare, granularity, hideBots, project, repository } = params;
    let tmpFilters: InsightsFiltersNew = {
      ...defaultFilters,
      project,
      repositories: repository ? [repository] : undefined,
      compare,
      granularity,
      hideBots
    };

    if (isAuthenticated) {
      if (dateFilters) {
        tmpFilters.dateFilters = dateFilters;
      }

      if (url.indexOf('/retention') > -1) {
        tmpFilters = getRetentionPageFilters(tmpFilters);
      } else {
        tmpFilters = getAuthenticatedUserFilters(tmpFilters, params);
      }
    } else {
      // set the user with default date filters if not authenticated
      tmpFilters.dateFilters = defaultFilters.dateFilters;
      tmpFilters.periods = defaultFilters.periods;
      tmpFilters.granularity = defaultFilters.granularity;
    }

    return tmpFilters;
  }
}
