// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { isPlatformBrowser, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { HeatChartConfig } from 'lfx-insights';

// @UntilDestroy({ checkProperties: true })
@Component({
  selector: 'lfx-heat-chart',
  templateUrl: './heat-chart.component.html',
  styleUrls: ['./heat-chart.component.scss'],
  standalone: true,
  imports: [NgStyle]
})
export class HeatChartComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() public config!: HeatChartConfig;
  @Input() public data: any[] = [];
  @ViewChild('chartElement') chartElement: ElementRef<HTMLElement>;

  private root!: am5.Root;
  private chartRef!: am5xy.XYChart;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private zone: NgZone,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  // Run the function only in the browser
  public browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.data) {
      this.changeData();
    }
  }

  public ngAfterViewInit() {
    this.browserOnly(() => {
      am5.ready(() => {
        this.initChart();
      });
    });
  }

  public ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  public checkEmptyData(): boolean {
    // TODO: implement this
    return false;
  }

  private initChart(): void {
    // Create root element
    if (!this.chartElement.nativeElement) return;
    const root = am5.Root.new(this.chartElement.nativeElement);
    const { isCompact, pillWidth } = this.config;

    // Set themes
    const myTheme = am5themesAnimated.new(root);
    if (isCompact) {
      myTheme.rule('Grid', ['base']).setAll({
        strokeOpacity: 1,
        stroke: am5.color('#ff0000'),
        strokeWidth: 5,
        disabled: false,
        visible: true
      });
    }
    root.setThemes([myTheme]);

    // remove footer logo
    // eslint-disable-next-line no-underscore-dangle
    root._logo?.children.clear();

    // Create chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
        height: this.config.height,
        layout: root.verticalLayout
      })
    );

    // Create axes and their renderers
    const yRenderer = am5xy.AxisRendererY.new(root, {
      visible: isCompact,
      minGridDistance: 20,
      inversed: this.config.yAxis?.inversed !== undefined ? this.config.yAxis?.inversed : true,
      strokeOpacity: 1,
      strokeWidth: 2,
      stroke: am5.color('#333333'),
      cellStartLocation: isCompact ? 0.01 : undefined
    });

    yRenderer.grid.template.set('visible', false);

    yRenderer.labels.template.setAll({
      fontSize: '11px',
      fill: am5.color('#807f83'),
      textAlign: 'start',
      width: 40
    });

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        renderer: yRenderer,
        categoryField: this.config.categoryYField // 'hour'
      })
    );

    if (isCompact) {
      yAxis.getNumberFormatter().setAll({
        numberFormat: '#######'
      });
    }

    const xRenderer = am5xy.AxisRendererX.new(root, {
      visible: isCompact,
      minGridDistance: 30,
      opposite: this.config.xAxis?.oposite !== undefined ? this.config.xAxis?.oposite : true,
      strokeOpacity: 1,
      strokeWidth: 2,
      stroke: am5.color('#333333'),
      cellStartLocation: isCompact ? 0.01 : undefined
    });

    xRenderer.grid.template.set('visible', false);
    xRenderer.labels.template.setAll({
      fontSize: '12px',
      fill: am5.color('#807f83')
    });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: this.config.categoryXField // 'weekday',
      })
    );

    // Create series
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        calculateAggregates: true,
        stroke: am5.color(0xffffff),
        clustered: false,
        xAxis,
        yAxis,
        categoryXField: this.config.categoryXField,
        categoryYField: this.config.categoryYField,
        valueField: this.config.valueField
      })
    );

    const tooltip = am5.Tooltip.new(root, {
      autoTextColor: false,
      getFillFromSprite: false,
      getStrokeFromSprite: true,
      maxWidth: 350
    });

    tooltip.get('background')?.setAll({
      fill: am5.color('#000000')
    });

    tooltip.label.setAll({
      fill: am5.color('#ffffff'),
      fontSize: 12,
      fontFamily: 'Open Sans, Source Sans Pro, sans-serif'
    });

    tooltip.label.adapters.add(
      'text',
      this.config.tooltipAdapter
        ? this.config.tooltipAdapter
        : (text, target) => {
            if (target.dataItem?.dataContext) {
              const data = target.dataItem?.dataContext as any;
              const hours = data[this.config.categoryYField].split(':');
              const startHour = hours[0] + ':00';
              const endHour = (+hours[0] + 1 + '').padStart(2, '0') + ':59';
              const tooltipText =
                `[bold]${data[this.config.valueField]} ${this.config.tooltipValueTitle ? this.config.tooltipValueTitle : 'Commits'}[/] : ` +
                `[bold]${data[this.config.categoryXField]}[/] between [bold]${startHour}-${endHour}\n`;
              return tooltipText;
            }
            return text;
          }
    );

    series.columns.template.setAll(
      isCompact
        ? {
            tooltipText: '{value}',
            width: am5.percent(100),
            height: am5.percent(100)
          }
        : {
            tooltipText: '{value}',
            cornerRadiusBL: 10,
            cornerRadiusTL: 10,
            cornerRadiusBR: 10,
            cornerRadiusTR: 10,
            width: pillWidth || 66, //am5.percent(100),
            height: 13
          }
    );
    series.set('tooltip', tooltip);
    // Create modal for a "no data" note
    const modal = am5.Modal.new(root, {
      content: 'No data to display'
    });

    series.events.on('datavalidated', (ev) => {
      if (ev.target.data.length < 1) {
        modal.open();
      } else {
        modal.close();
      }
    });

    // Set up heat rules
    series.set('heatRules', [
      {
        target: series.columns.template,
        min: am5.color(this.config.startColor),
        max: am5.color(this.config.endColor),
        dataField: this.config.valueField,
        key: 'fill'
      }
    ]);
    if (this.config.showLegend) {
      this.setHeatMap(series, chart, root);
    }
    // Set data

    const axisData = this.prepareData();
    yAxis.get('renderer').labels.template.adapters.add('text', (value, target) => {
      const dataItem = target.dataItem?.dataContext as any;
      if (target && value && dataItem && dataItem.hour) {
        if (+dataItem[this.config.categoryYField].split(':')[0] < 8) {
          return dataItem[this.config.categoryYField] + '[fontSize: 9px;verticalAlign: super;]+1';
        }
        return dataItem[this.config.categoryYField];
      }
      return value;
    });

    series.data.setAll(this.data);
    xAxis.data.setAll(axisData.xAxisData);

    yAxis.data.setAll(axisData.yAxisData);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
    this.root = root;
    this.chartRef = chart;
  }

  private prepareData() {
    const xAxisSet = new Set<string>();
    const yAxisSet = new Set<string>();
    let xAxisData: any[] = [];
    const yAxisData: any[] = [];
    this.data.forEach((element) => {
      if (!xAxisSet.has(element[this.config.categoryXField])) {
        xAxisSet.add(element[this.config.categoryXField]);
        xAxisData.push({
          [this.config.categoryXField]: element[this.config.categoryXField]
        });
      }
      if (!yAxisSet.has(element[this.config.categoryYField])) {
        yAxisSet.add(element[this.config.categoryYField]);
        yAxisData.push({
          [this.config.categoryYField]: element[this.config.categoryYField]
        });
      }
    });

    if (this.config.xAxis?.axisData) {
      xAxisData = this.config.xAxis.axisData.map((d) => ({ [this.config.categoryXField]: d }));
    }

    return {
      xAxisData,
      yAxisData
    };
  }

  private changeData(): void {
    if (this.chartRef) {
      const data = this.prepareData();
      this.chartRef.xAxes.each((axis: am5xy.Axis<am5xy.AxisRenderer>) => {
        axis.data.setAll(data.xAxisData);
      });
      this.chartRef.yAxes.each((axis: am5xy.Axis<am5xy.AxisRenderer>) => {
        axis.data.setAll(data.yAxisData);
      });
      this.chartRef.series.each((se: am5xy.XYSeries) => {
        se.data.setAll(this.data);
      });
    }
  }

  private setHeatMap(series: am5xy.ColumnSeries, chart: am5xy.XYChart, root: am5.Root): void {
    if (this.config.isCompact) {
      series.events.on('datavalidated', () => {
        heatLegend.set('startValue', series.getPrivate('valueLow'));
        heatLegend.set('endValue', series.getPrivate('valueHigh'));
      });
      // Add heat legend
      const { scale, stepCount } = this.config.legend || {};

      const heatLegend = chart.bottomAxesContainer.children.push(
        am5.HeatLegend.new(root, {
          orientation: 'horizontal',
          startColor: am5.color(this.config.startColor),
          endColor: am5.color(this.config.endColor),
          startText: '',
          endText: '',
          paddingBottom: 40,
          marginTop: 10,
          centerX: am5.percent(-25),
          stepCount,
          scale
        })
      );

      series.columns.template.events.on('pointerover', (event) => {
        const di = event.target.dataItem?.dataContext as any;
        if (di) {
          heatLegend.showValue(di.value);
        }
      });

      series.columns.template.events.on('pointerout', () => {
        heatLegend.hideTooltip();
      });

      // heatLegend
      //   .getTooltip()
      //   ?.get('background')
      //   ?.setAll({
      //     fill: am5.color('#000000')
      //   });
      heatLegend.getTooltip()?.label.setAll({
        // fill: am5.color('#ffffff'),
        fontSize: 12,
        fontFamily: 'Open Sans, Source Sans Pro, sans-serif'
      });
      // heatLegend.getTooltip()?.setAll({
      //   autoTextColor: false,
      //   //   background: new am5.Rectangle(root, { backgroundColor: am5.color('#000000') }, true)
      // });

      heatLegend.labelContainer.children.push(
        am5.Label.new(root, {
          text: this.config.startText,
          fill: am5.color('#333333'),
          fontSize: '18px',
          fontFamily: 'Open Sans',
          position: 'absolute',
          y: 20
        })
      );
      heatLegend.labelContainer.children.push(
        am5.Label.new(root, {
          text: this.config.endText,
          fill: am5.color('#333333'),
          fontSize: '18px',
          fontFamily: 'Open Sans',
          position: 'absolute',
          centerX: am5.percent(100),
          y: 20,
          x: am5.percent(100)
        })
      );
    } else {
      chart.bottomAxesContainer.set('layout', root.horizontalLayout);
      chart.bottomAxesContainer.set('centerX', am5.percent(-35));

      chart.bottomAxesContainer.children.push(
        am5.Label.new(root, {
          text: this.config.startText,
          fill: am5.color('#333333'),
          fontSize: '13px',
          fontFamily: 'Open Sans'
        })
      );
      chart.bottomAxesContainer.children.push(
        am5.HeatLegend.new(root, {
          orientation: 'horizontal',
          startColor: am5.color(this.config.startColor),
          endColor: am5.color(this.config.endColor),
          width: 80
        })
      );
      chart.bottomAxesContainer.children.push(
        am5.Label.new(root, {
          text: this.config.endText,
          fill: am5.color('#333333'),
          fontSize: '13px',
          fontFamily: 'Open Sans'
        })
      );
    }
  }
}
