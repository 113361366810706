<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<div class="h-full w-full relative" [style]="{ height: config.minHeight }">
  @if (annotations && config.annotationSettings && !noData) {
    <div
      [ngClass]="[config.annotationSettings.horizontalAlign, config.annotationSettings.verticalAlign]"
      class="annotation-wrap"
      [innerHTML]="getAnnotationHTML(config.annotationSettings.template, annotations)"></div>
  }

  <div class="flex flex-col h-full w-full">
    <div [id]="chartId" [ngClass]="chartId" class="flex-grow w-full"></div>
    @if (config.showLegend && !noData) {
      <ng-container *ngTemplateOutlet="chartLegend" />
    }
  </div>
</div>

<ng-template #chartLegend>
  <div class="legend-wrap">
    @for (se of config.series; track $index) {
      <span
        [ngClass]="{ 'cursor-default': config.series.length === 1, 'cursor-pointer': config.series.length > 1 }"
        class="legend-item"
        (click)="onToggleSeries($index)"
        [class.turned-off]="!isVisible($index)"
        [appLfxTooltipTrigger]="'line-chart-legend' + chartId + '' + $index">
        <span class="legend-icon" [ngClass]="{ '!w-4 !h-2': config.useNewLegend }">
          <span
            class="circle"
            [ngClass]="{ '!w-2 !h-2': config.useNewLegend }"
            [style]="{ borderColor: se.color, backgroundColor: se.color }"></span>
          @if (!config.useNewLegend) {
            <span
              class="line"
              [ngClass]="{ '!border-dashed': se.stroke, '!w-2': config.useNewLegend }"
              [style]="{ borderColor: se.color }"></span>
          }
        </span>
        {{ se.name }}
        @if (se.legendTooltipText) {
          <lfx-tooltip
            [id]="'line-chart-legend' + chartId + '' + $index"
            [text]="se.legendTooltipText"
            [config]="{
              show: 'top',
              positionValue: se.positionValue ? se.positionValue : '-160%',
              horizontalAlign: '-45%',
              width: se.legendTooltipWidth ? se.legendTooltipWidth : 'fit-content',
              textWrap: se.textWrap ? se.textWrap : 'white-space:nowrap'
            }" />
        }
      </span>
    }
  </div>
</ng-template>
