<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->
<div class="relative">
  <fa-icon
    *ngIf="!isLoading"
    [icon]="'arrow-to-bottom'"
    (click)="downloadMenu.toggle($event)"
    data-html2canvas-ignore
    class="text-gray text-xl text-end cursor-pointer hover:text-primary"
    (mouseover)="showTooltip = true"
    (mouseout)="showTooltip = false"></fa-icon>

  <div
    *ngIf="showTooltip"
    class="absolute bottom-[110%] -left-[37px] font-sans rounded-md bg-gray-dark text-tiny text-white p-4 z-20 font-normal">
    Download
  </div>
</div>

<p-menu #downloadMenu [model]="items()" [popup]="true" appendTo="body" [style]="{ width: '80px' }"></p-menu>

<lfx-loading [minHeight]="20" *ngIf="isLoading"></lfx-loading>
