// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input, TemplateRef } from '@angular/core';
import { NgIf, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'lfx-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, NgStyle, NgTemplateOutlet]
})
export class TooltipComponent {
  @Input() public id: string = '';
  @Input() public config: {
    width?: string;
    show?: 'top' | 'left' | 'right' | 'bottom';
    positionValue?: string;
    horizontalAlign?: string;
    verticalAlign?: string;
    textWrap?: string;
  } = {
    show: 'bottom',
    positionValue: '120%',
    horizontalAlign: '0',
    textWrap: 'white-space:nowrap'
  };
  @Input() public text = '';
  @Input() public tooltipTemplate?: TemplateRef<any>;
  @Input() public showLimitedLines: number | undefined;

  public get styles(): any {
    const obj: any = {};
    const { show, width, positionValue, horizontalAlign, verticalAlign, textWrap } = this.config;
    if (show && positionValue) {
      obj[show] = positionValue;
    }

    if (width) {
      obj.width = width;
    }

    if (horizontalAlign) {
      // TODO: refactor this
      if (horizontalAlign.indexOf('right:') >= 0) {
        obj.right = horizontalAlign.replace('right:', '');
      } else {
        obj.left = horizontalAlign;
      }
    }

    if (verticalAlign) {
      if (verticalAlign.indexOf('bottom:') >= 0) {
        obj.bottom = verticalAlign.replace('bottom:', '');
      } else {
        obj.top = verticalAlign;
      }
    }

    if (textWrap && textWrap !== '') {
      obj[textWrap.split(':')[0]] = textWrap.split(':')[1];
    }

    return obj;
  }
}
