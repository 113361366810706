// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { ValidVarTypePipe } from '../../../../pipes/valid-var-type.pipe';
import { NgClass } from '@angular/common';

@Component({
    selector: 'lfx-table-name',
    templateUrl: './table-name.component.html',
    styleUrls: ['./table-name.component.scss'],
    standalone: true,
    imports: [NgClass, ValidVarTypePipe]
})
export class TableNameComponent {
  @Input() public name!: string | null | undefined;
  @Input() public classNames?: string;
}
