// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Directive, Input, OnChanges, OnInit, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { InsightProject } from 'lfx-insights';
import { FeatureService } from '../services/feature.service';
import { FeaturesEnum } from '../interface/common.enum';
import { ProjectNewService } from '../services/project-new.service';

// TODO: revisit this implentation, remove this if possible
@Directive({
    selector: '[appProjectPlatform]',
    standalone: true
})
export class ProjectPlatformDirective implements OnInit, OnChanges {
  @Input() public appProjectPlatform: {
    outerCondition: boolean;
    input: {
      platform: string | string[];
      actionType: 'hide' | 'show';
      platformExistingType: 'only' | 'part';
      selectedProject?: InsightProject;
    };
  };

  private projectNewService: ProjectNewService = inject(ProjectNewService);
  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<any>,
    private featureService: FeatureService
  ) {}

  public ngOnInit() {
    if (this.featureService.isFeatureEnabled(FeaturesEnum.gitOnly)) {
      if (this.appProjectPlatform.input.selectedProject) {
        this.handleInternalProject();
      } else {
        const project = this.projectNewService.selectedProject();
        if (project && project.sources?.length && this.appProjectPlatform.outerCondition) {
          this.handleProjectPlatform(project);
        } else {
          this.handleOuterCondition();
        }
      }
    } else {
      this.handleOuterCondition();
    }
  }

  public ngOnChanges() {
    const project = this.projectNewService.selectedProject();
    if (
      this.featureService.isFeatureEnabled(FeaturesEnum.gitOnly) &&
      project &&
      project.sources?.length &&
      this.appProjectPlatform.outerCondition
    ) {
      this.handleProjectPlatform(project);
    } else {
      this.handleOuterCondition();
    }
  }

  private handleInternalProject() {
    if (
      this.appProjectPlatform.input.selectedProject &&
      this.appProjectPlatform.input.selectedProject.sources?.length &&
      this.appProjectPlatform.outerCondition
    ) {
      this.handleProjectPlatform(this.appProjectPlatform.input.selectedProject);
    } else {
      this.handleOuterCondition();
    }
  }

  private handleProjectPlatform(project: InsightProject) {
    if (this.checkPlatformExisting(project)) {
      this.applyAction();
    } else if (this.appProjectPlatform.outerCondition) {
      this.oppositeActionApplying();
    } else {
      // case of the condition that came as input is false in that case we need to hide the component
      this.handleOuterCondition();
    }
  }

  private checkPlatformExisting(project: InsightProject) {
    if (Array.isArray(this.appProjectPlatform.input.platform)) {
      return this.appProjectPlatform.input.platform.some((platform) => this.checkSinglePlatform(platform, project));
    }
    return this.checkSinglePlatform(this.appProjectPlatform.input.platform, project);
  }
  private checkSinglePlatform(platform: string, project: InsightProject) {
    if (this.appProjectPlatform.input.platformExistingType === 'only') {
      return project?.sources === platform;
    }
    return project?.sources?.includes(platform);
  }

  private applyAction() {
    if (this.appProjectPlatform.input.actionType === 'hide') {
      this.clear();
    } else {
      this.present();
    }
  }

  private handleOuterCondition() {
    if (this.appProjectPlatform.outerCondition === false) {
      this.clear();
    } else {
      this.present();
    }
  }

  private oppositeActionApplying() {
    if (this.appProjectPlatform.input.actionType === 'hide') {
      this.present();
    } else {
      this.clear();
    }
  }

  private clear() {
    this.view.clear();
  }

  private present() {
    this.view.clear();
    this.view.createEmbeddedView(this.template);
  }
}
