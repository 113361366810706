import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, computed, inject, input } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { WarningComponent } from '../warning/warning.component';
import { PlatformEnum } from '@app/shared/interface/common.enum';
import { InsightProject } from 'lfx-insights';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'lfx-project-sources-icons',
  standalone: true,
  imports: [NgIf, FaIconComponent, InlineSVGModule, WarningComponent, TooltipModule],
  templateUrl: './project-sources-icons.component.html',
  styleUrl: './project-sources-icons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSourcesIconsComponent implements OnInit {
  protected readonly platforms = PlatformEnum;

  project = input.required<InsightProject>();
  isOnboarding = input(false);
  hasGerritPlatform = computed(() => this.project().sources?.includes(PlatformEnum.gerrit) || false);
  isGerritOnly = computed(
    () =>
      (this.project().sources?.includes(PlatformEnum.gerrit) &&
        !this.project().sources?.includes(PlatformEnum.github)) ||
      false
  );
  isGitOnly = computed(() => this.project().sources === PlatformEnum.git);
  repoUrl = computed(() => {
    const url = this.project().topRepoURL;
    return !url?.startsWith('http') ? '//' + url : url;
  });
  websiteUrl = computed(() => {
    const url = this.project().website;
    return !url?.startsWith('http') ? '//' + url : url;
  });

  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  public ngOnInit() {
    this.cdr.detectChanges();
  }

  public hasPlatform(platform: PlatformEnum): boolean {
    return this.project().sources?.includes(platform) || false;
  }
}
