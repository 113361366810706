// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { ICacheKey, InjectInfiniteQuery, InjectQuery } from '@services/api/api.types';
import { BffConstants } from '@services/api/bff.constants';
import { BFFServiceBasic } from '@services/api/bff.basic';
import { BffService } from '@services/bff.service';
import { APP_CONFIG } from '@app/config';
import { InsightsFiltersNew } from '@services/filter/filter.types';
import {
  getActiveContributorsQuery,
  getActiveOrganizationQuery,
  getAllMailingListsQuery,
  getMailingListCountryQuery,
  getMailingSummaryChartsQuery,
  getMailingTopTilesQuery,
  getNewContributorsQuery,
  getNewOrganizationQuery,
  getPopularThreadsQuery,
  getRecentMessagesQuery,
  getTopMailingListQuery
} from '@app/shared/queries/mailing-list/queries';
import { InsightProject } from 'lfx-insights';

export interface IMailingGeoDistribution {
  CURRENT_CONTRIBUTOR_COUNT: number;
  PREVIOUS_CONTRIBUTOR_COUNT: number;
  COUNTRIES: {
    MEMBER_COUNTRY: string;
    CONTRIBUTOR_COUNT: number;
  }[];
}

export interface ITopMailingListRow {
  GROUP_NAME: string;
  THREADS_COUNT: number;
  THREADS_COUNT_PREV: number;
  MESSAGES_COUNT: number;
  MESSAGES_COUNT_PREV: number;
  SUBSCRIBERS_COUNT: number;
  SUBSCRIBERS_COUNT_PREV: number;
  AUTHORS_COUNT: number;
  AUTHORS_COUNT_PREV: number;
  ORGANIZATIONS_COUNT: number;
  ORGANIZATIONS_COUNT_PREV: number;
}

export interface IMailingListTopTiles {
  MESSAGES_COUNT: number;
  MESSAGES_COUNT_PREV: number;
  MAILING_LISTS_COUNT: number;
  MAILING_LISTS_COUNT_PREV: number;
  CONTRIBUTORS_COUNT: number;
  CONTRIBUTORS_COUNT_PREV: number;
  ORGANIZATIONS_COUNT: number;
  ORGANIZATIONS_COUNT_PREV: number;
}

export interface IMailingListSummaryCharts {
  MAILING_LISTS: IMailingListChartDataItem[];
  MESSAGES: IMailingListChartDataItem[];
  CONTRIBUTORS: IMailingListChartDataItem[];
  ORGANIZATIONS: IMailingListChartDataItem[];
}

export interface IMailingListChartDataItem {
  START_DATE: string;
  END_DATE: string;
  ACTIVE_VALUE: number;
  CUMULATIVE_VALUE: number;
}

export interface ITopMailingList {
  CURRENT_MESSAGES_COUNT: number;
  PREVIOUS_MESSAGES_COUNT: number;
  MAILING_LISTS_COUNT: number;
  MAILING_LISTS: ITopMailingListRow[];
}

export interface IContributorsResponse {
  CURRENT_CONTRIBUTOR_COUNT: number;
  PREVIOUS_CONTRIBUTOR_COUNT: number;
  CONTRIBUTORS: INewContributor[];
}

export interface INewContributor {
  MEMBER_ID: string;
  MEMBER_DISPLAY_NAME: string;
  MEMBER_LOGO: string;
  MESSAGE_COUNT: number;
  MESSAGE_COUNT_PREV: number;
  LAST_MESSAGE_DATE: string;
}

export interface IOrganizationsResponse {
  CURRENT_ORGANIZATION_COUNT: number;
  PREVIOUS_ORGANIZATION_COUNT: number;
  ORGANIZATIONS: INewOrganization[];
}

export interface INewOrganization {
  ORGANIZATION_ID: string;
  ORGANIZATION_NAME: string;
  ORGANIZATION_LOGO: string;
  MESSAGE_COUNT: number;
  MESSAGE_COUNT_PREV: number;
  LAST_MESSAGE_DATE: string;
}

export interface ISubjectsResponse {
  SUBJECTS_COUNT: number;
  SUBJECTS: IMailingSubject[];
}

export interface IMailingSubject {
  SUBJECT: string;
  MESSAGES_COUNT: number;
  MESSAGES_COUNT_PREV: number;
  LAST_MESSAGE_DATE: string;
}

export interface IMailingListGroups {
  MAILING_LISTS: [
    {
      GROUP_NAME: string;
      GROUP_SLUG: string;
      GROUP_ID: string;
    }
  ];
}

export class MailingListBFFApiClass extends BFFServiceBasic {
  protected useInfiniteQuery;
  protected useQuery: InjectQuery;

  constructor(bffService: BffService, useInfiniteQuery: InjectInfiniteQuery, useQuery: InjectQuery) {
    super(bffService);
    this.useInfiniteQuery = useInfiniteQuery;
    this.useQuery = useQuery;
  }

  public getMailingTopTiles(
    filter: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    options: { signal?: AbortSignal; [key: string]: any } = {}
  ) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_MAILING_LIST_TOP_TILE, filter, groupName],
      queryFn: () => {
        const query = getMailingTopTilesQuery(filter, project!, groupName);
        return this.loadData<IMailingListTopTiles>(BffConstants.GROUPS_IO_TOP_TILES, query, options);
      },
      enabled: !!project
    });
  }

  public getMailingSummaryCharts(
    filter: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    options: { signal?: AbortSignal; [key: string]: any } = {}
  ) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_MAILING_LIST_SUMMARY_CHARTS, filter, groupName],
      queryFn: () => {
        const query = getMailingSummaryChartsQuery(filter, project!, groupName);
        return this.loadData<IMailingListSummaryCharts>(BffConstants.GROUPS_IO_SUMMARY_CHARTS, query, options);
      },
      enabled: !!project
    });
  }

  public getMailingListCountry(
    filter: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    options: { signal?: AbortSignal; [key: string]: any } = {}
  ) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_MAILING_LIST_COUNTRY, filter, groupName],
      queryFn: () => {
        const query = getMailingListCountryQuery(filter, project!, groupName);
        return this.loadData<IMailingGeoDistribution>(BffConstants.GROUPS_IO_CONTRIBUTOR_COUNTRY, query, options);
      },
      enabled: !!project
    });
  }

  public getTopMailingListPagination(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    limit: number = APP_CONFIG.PAGINATION_SIZE
  ) {
    return this.useInfiniteQuery({
      queryKey: [
        ICacheKey.GET_TOP_MAILING_LIST,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        groupName
      ] as const,
      queryFn: ({ pageParam }) => {
        const query = getTopMailingListQuery(filters, project!, groupName, pageParam, limit);
        return this.loadData<ITopMailingList>(BffConstants.GROUPS_IO_TOP_MAILING_LISTS, query);
      },
      enabled: !!project,
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.length * limit;
        return lastPage.MAILING_LISTS.length === limit ? nextOffset : undefined;
      }
    });
  }

  public getTopMailingList(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    isDownloadCSV: boolean
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_TOP_MAILING_LIST,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        groupName,
        isDownloadCSV
      ] as const,
      queryFn: () => {
        const query = getTopMailingListQuery(filters, project!, groupName, 0, 0);
        return this.loadData<ITopMailingList>(BffConstants.GROUPS_IO_TOP_MAILING_LISTS, query);
      },
      enabled: !!project && isDownloadCSV
    });
  }

  public getNewContributorsPagination(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    limit: number = APP_CONFIG.PAGINATION_SIZE
  ) {
    return this.useInfiniteQuery({
      queryKey: [
        ICacheKey.GET_MAILING_NEW_CONTRIBUTORS,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        groupName
      ] as const,
      queryFn: ({ pageParam }) => {
        const query = getNewContributorsQuery(filters, project!, groupName, pageParam, limit);
        return this.loadData<IContributorsResponse>(BffConstants.GROUPS_IO_NEW_CONTRIBUTORS, query);
      },
      enabled: !!project,
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.length * limit;
        return lastPage.CONTRIBUTORS.length === limit ? nextOffset : undefined;
      }
    });
  }

  public getNewContributors(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    isDownloadCSV: boolean
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_MAILING_NEW_CONTRIBUTORS,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        groupName,
        isDownloadCSV
      ] as const,
      queryFn: () => {
        const query = getNewContributorsQuery(filters, project!, groupName, 0, 0);
        return this.loadData<IContributorsResponse>(BffConstants.GROUPS_IO_NEW_CONTRIBUTORS, query);
      },
      enabled: !!project && isDownloadCSV
    });
  }

  public getActiveContributorsPagination(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    limit: number = APP_CONFIG.PAGINATION_SIZE
  ) {
    return this.useInfiniteQuery({
      queryKey: [
        ICacheKey.GET_MAILING_ACTIVE_CONTRIBUTORS,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        groupName
      ] as const,
      queryFn: ({ pageParam }) => {
        const query = getActiveContributorsQuery(filters, project!, groupName, pageParam, limit);
        return this.loadData<IContributorsResponse>(BffConstants.GROUPS_IO_ACTIVE_CONTRIBUTORS, query);
      },
      enabled: !!project,
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.length * limit;
        return lastPage.CONTRIBUTORS.length === limit ? nextOffset : undefined;
      }
    });
  }

  public getActiveContributors(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    isDownloadCSV: boolean
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_MAILING_ACTIVE_CONTRIBUTORS,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        groupName,
        isDownloadCSV
      ] as const,
      queryFn: () => {
        const query = getActiveContributorsQuery(filters, project!, groupName, 0, 0);
        return this.loadData<IContributorsResponse>(BffConstants.GROUPS_IO_ACTIVE_CONTRIBUTORS, query);
      },
      enabled: !!project && isDownloadCSV
    });
  }

  public getNewOrganizationPagination(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    limit: number = APP_CONFIG.PAGINATION_SIZE
  ) {
    return this.useInfiniteQuery({
      queryKey: [
        ICacheKey.GET_MAILING_NEW_ORGS,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        groupName
      ] as const,
      queryFn: ({ pageParam }) => {
        const query = getNewOrganizationQuery(filters, project!, groupName, pageParam, limit);
        return this.loadData<IOrganizationsResponse>(BffConstants.GROUPS_IO_NEW_ORGANIZATION, query);
      },
      enabled: !!project,
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.length * limit;
        return lastPage.ORGANIZATIONS.length === limit ? nextOffset : undefined;
      }
    });
  }

  public getNewOrganization(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    isDownloadCSV: boolean
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_MAILING_NEW_ORGS,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        groupName,
        isDownloadCSV
      ] as const,
      queryFn: () => {
        const query = getNewOrganizationQuery(filters, project!, groupName, 0, 0);
        return this.loadData<IOrganizationsResponse>(BffConstants.GROUPS_IO_NEW_ORGANIZATION, query);
      },
      enabled: !!project && isDownloadCSV
    });
  }

  public getActiveOrganizationPagination(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    limit: number = APP_CONFIG.PAGINATION_SIZE
  ) {
    return this.useInfiniteQuery({
      queryKey: [
        ICacheKey.GET_MAILING_ACTIVE_ORGS,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        groupName
      ] as const,
      queryFn: ({ pageParam }) => {
        const query = getActiveOrganizationQuery(filters, project!, groupName, pageParam, limit);
        return this.loadData<IOrganizationsResponse>(BffConstants.GROUPS_IO_ACTIVE_ORGANIZATION, query);
      },
      enabled: !!project,
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.length * limit;
        return lastPage.ORGANIZATIONS.length === limit ? nextOffset : undefined;
      }
    });
  }

  public getActiveOrganization(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    isDownloadCSV: boolean
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_MAILING_ACTIVE_ORGS,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        groupName,
        isDownloadCSV
      ] as const,
      queryFn: () => {
        const query = getActiveOrganizationQuery(filters, project!, groupName, 0, 0);
        return this.loadData<IOrganizationsResponse>(BffConstants.GROUPS_IO_ACTIVE_ORGANIZATION, query);
      },
      enabled: !!project && isDownloadCSV
    });
  }

  public getPopularThreadsPagination(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    limit: number = APP_CONFIG.PAGINATION_SIZE
  ) {
    return this.useInfiniteQuery({
      queryKey: [
        ICacheKey.GET_POPULAR_THREADS,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        groupName
      ] as const,
      queryFn: ({ pageParam }) => {
        const query = getPopularThreadsQuery(filters, project!, groupName, pageParam, limit);
        return this.loadData<ISubjectsResponse>(BffConstants.GROUPS_IO_POPULAR_THREADS, query);
      },
      enabled: !!project,
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.length * limit;
        return lastPage.SUBJECTS.length === limit ? nextOffset : undefined;
      }
    });
  }

  public getPopularThreads(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    total: number
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_POPULAR_THREADS,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        groupName,
        total
      ] as const,
      queryFn: () => {
        const query = getPopularThreadsQuery(filters, project!, groupName, 0, total);
        return this.loadData<ISubjectsResponse>(BffConstants.GROUPS_IO_POPULAR_THREADS, query);
      },
      enabled: !!project && total > 0
    });
  }

  public getRecentMessagesPagination(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    limit: number = APP_CONFIG.PAGINATION_SIZE
  ) {
    return this.useInfiniteQuery({
      queryKey: [
        ICacheKey.GET_RECENT_MESSAGES,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        groupName
      ] as const,
      queryFn: ({ pageParam }) => {
        const query = getRecentMessagesQuery(filters, project!, groupName, pageParam, limit);
        return this.loadData<ISubjectsResponse>(BffConstants.GROUPS_IO_RECENT_MESSAGES, query);
      },
      enabled: !!project,
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.length * limit;
        return lastPage.SUBJECTS.length === limit ? nextOffset : undefined;
      }
    });
  }

  public getRecentMessages(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    groupName: string,
    total: number
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_RECENT_MESSAGES,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        groupName,
        total
      ] as const,
      queryFn: () => {
        const query = getRecentMessagesQuery(filters, project!, groupName, 0, total);
        return this.loadData<ISubjectsResponse>(BffConstants.GROUPS_IO_RECENT_MESSAGES, query);
      },
      enabled: !!project && total > 0
    });
  }

  public getAllMailingLists(project: InsightProject | undefined) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_MAILING_LIST_GROUPS, project] as const,
      queryFn: () => {
        const query = getAllMailingListsQuery(project!);
        return this.loadData<IMailingListGroups>(BffConstants.GROUPS_IO_MAILING_LISTS, query);
      },
      enabled: !!project
    });
  }
}
