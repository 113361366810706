// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '@app/shared/module/icons.module';
import { SidebarModule } from 'primeng/sidebar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ErrorComponent } from '@shared/components/error/error.component';
import { TruncateTextPipe } from '@shared/pipes/truncate-text.pipe';
import { ReplaceTextPipe } from '@shared/pipes/replace-text.pipe';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { CheckboxModule } from 'primeng/checkbox';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { RouterModule } from '@angular/router';
import { TableDateComponent } from '@shared/components/table/cells/table-date/table-date.component';
import { HorizontallyStackedChartComponent } from '@shared/components/horizontally-stacked-chart/horizontally-stacked-chart.component';
import { ClusteredColumnChartComponent } from '@shared/components/clustered-column-chart/clustered-column-chart.component';
import { WarningComponent } from '@shared/components/warning/warning.component';
import { NoDataComponent } from '@shared/components/no-data/no-data.component';
import { FirstLetterCapitalcasePipe } from '@shared/pipes/first-letter-capitalcase.pipe';
import { TableRankComponent } from '@shared/components/table/cells/table-rank/table-rank.component';
import { TableNumberDeltaComponent } from '@shared/components/table/cells/table-number-delta/table-number-delta.component';
import { TableNameAvatarComponent } from '@shared/components/table/cells/table-name-avatar/table-name-avatar.component';
import { TableNameComponent } from '@shared/components/table/cells/table-name/table-name.component';
import { AvatarModule } from 'primeng/avatar';
import { ToastModule } from 'primeng/toast';
import { SkeletonModule } from 'primeng/skeleton';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { InputComponent } from './components/input/input.component';
import { ContentHeaderComponent } from './components/content-header/content-header.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { TableComponent } from './components/table/table.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { DateRangeFilterComponent } from './components/date-range-filter/date-range-filter.component';
import { GaugeChartComponent } from './components/gauge-chart/gauge-chart.component';
import { HeatChartComponent } from './components/heat-chart/heat-chart.component';
import { DialogTemplateComponent } from './components/dialog-template/dialog-template.component';
import { TextCopyBlockComponent } from './components/text-copy-block/text-copy-block.component';
import { CircleProgressComponent } from './components/circle-progress/circle-progress.component';
import { CalculatedCardHeaderComponent } from './components/calculated-card-header/calculated-card-header.component';
import { TopFiltersComponent } from './components/top-filters/top-filters.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { BoldPhrasePipe } from './pipes/bold-phrase.pipe';
import { IconIndicatorPipe } from './pipes/icon-indicator.pipe';
import { LoadingComponent } from './components/loading/loading.component';
import { DownloadComponent } from './components/download/download.component';
import { PositionElementDirective } from './directives/position-element.directive';
import { MapChartComponent } from './components/map-chart/map-chart.component';
import { FirstLettersPipe } from './pipes/first-letters.pipe';
import { CalculatePercentagePipe } from './pipes/calculate-percentage.pipe';
import { AsyncNumberPipe } from './pipes/asyncNumber.pipe';
import { NumberColorPipe } from './pipes/number-color.pipe';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { IconStatusPipe } from './pipes/icon-status.pipe';
import { ValueChangePipe } from './pipes/value-change.pipe';
import { WipComponent } from './components/wip/wip.component';
import { AbsoluteValuePipe } from './pipes/absoluteValue.pipe';
import { VariationCardHeaderComponent } from './components/variation-card-header/variation-card-header.component';
import { RadarChartComponent } from './components/radar-chart/radar-chart.component';
import { BestPracticeEmbedComponent } from './components/best-practice-embed/best-practice-embed.component';
import { BestPracticesDetailsComponent } from './components/best-practices-details/best-practices-details.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TooltipTriggerDirective } from './directives/tooltip-trigger.directive';
import { IntegerSignPipe } from './pipes/integer-sign.pipe';
import { DeferredLoaderDirective } from './directives/defer.directive';
import { CommentaryIconComponent } from './components/commentary-icon/commentary-icon.component';
import { BiDirectionalProgressComponent } from './components/bi-directional-progress/bi-directional-progress.component';
import { ProjectCardComponent } from './components/project-card/project-card.component';
import { XyBubbleChartComponent } from './components/xy-bubble-chart/xy-bubble-chart.component';
import { FoundationCardComponent } from './components/foundation-card/foundation-card.component';
import { WithLoadingPipe } from './pipes/with-loading.pipe';
import { DownloadStyleDirective } from './directives/download-style.directive';
import { ProjectPlatformDirective } from './directives/project-platform.directive';
import { BestPracticeNoDataComponent } from './components/best-practice-no-data/best-practice-no-data.component';
import { ProjectSearchComponent } from './components/project-search/project-search.component';
import { LineChartNewComponent } from './components/line-chart-new/line-chart-new.component';
import { LuxonDatePipe } from './pipes/luxon-date.pipe';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { HoursDaysPipe } from './pipes/hours-days.pipe';
import { ProjectRepoFilterComponent } from './components/project-repo-filter/project-repo-filter.component';
import { ProjectDescriptionComponent } from './components/project-description/project-description.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { RangeFiltersComponent } from './components/range-filters/range-filters.component';
import { ValidVarTypePipe } from './pipes/valid-var-type.pipe';
import { TableNumberComponent } from './components/table/cells/table-number/table-number.component';
import { TableDeltaComponent } from './components/table/cells/table-delta/table-delta.component';
import { TableTotalComponent } from './components/table/cells/table-total/table-total.component';
import { HeaderTooltipComponent } from './components/header-tooltip/header-tooltip.component';
import { GeoDistributionLegendsComponent } from './components/geo-distribution-legends/geo-distribution-legends.component';
import { SummaryChartsTabComponent } from './components/summary-charts-tab/summary-charts-tab.component';
import { CardHeadButtonsComponent } from './components/card-head-buttons/card-head-buttons.component';
import { CardLogoComponent } from './components/card-logo/card-logo.component';
import { ProjectStatsRowComponent } from './components/project-stats-row/project-stats-row.component';
import { CardFooterComponent } from './components/card-footer/card-footer.component';
import { ProjectMetricDetailsComponent } from './components/project-metric-details/project-metric-details.component';
import { KeyMetricRowComponent } from './components/key-metric-row/key-metric-row.component';
import { CardBpScoreComponent } from './components/card-bp-score/card-bp-score.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { MetricSelectComponent } from './components/metric-select/metric-select.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { SelectComponent } from './components/select/select.component';
import { TablePlatformComponent } from './components/table/cells/table-platform/table-platform.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SidebarModule,
    IconsModule,
    FormsModule,
    OverlayPanelModule,
    InputSwitchModule,
    InlineSVGModule,
    DialogModule,
    RouterModule, //elease to prod v0.0.41 in y
    TooltipModule,
    MenuModule,
    CheckboxModule,
    AvatarModule,
    ToastModule,
    SkeletonModule,
    CalendarModule,
    AutoCompleteModule,
    DropdownModule,
    InputComponent,
    ContentHeaderComponent,
    BarChartComponent,
    HeatChartComponent,
    TableComponent,
    ClickOutsideDirective,
    ShortNumberPipe,
    DateRangeFilterComponent,
    GaugeChartComponent,
    DialogTemplateComponent,
    TextCopyBlockComponent,
    CircleProgressComponent,
    CalculatedCardHeaderComponent,
    TopFiltersComponent,
    ProgressBarComponent,
    BoldPhrasePipe,
    IconIndicatorPipe,
    LoadingComponent,
    DownloadComponent,
    PositionElementDirective,
    MapChartComponent,
    FirstLettersPipe,
    CalculatePercentagePipe,
    AsyncNumberPipe,
    NumberColorPipe,
    ComingSoonComponent,
    IconStatusPipe,
    ValueChangePipe,
    AbsoluteValuePipe,
    ValidVarTypePipe,
    WipComponent,
    VariationCardHeaderComponent,
    RadarChartComponent,
    BestPracticeEmbedComponent,
    BestPracticesDetailsComponent,
    TooltipComponent,
    TooltipTriggerDirective,
    IntegerSignPipe,
    DeferredLoaderDirective,
    CommentaryIconComponent,
    BiDirectionalProgressComponent,
    ProjectCardComponent,
    XyBubbleChartComponent,
    FoundationCardComponent,
    TableDateComponent,
    WithLoadingPipe,
    DownloadStyleDirective,
    ProjectPlatformDirective,
    TruncateTextPipe,
    ReplaceTextPipe,
    ErrorComponent,
    BestPracticeNoDataComponent,
    HorizontallyStackedChartComponent,
    ClusteredColumnChartComponent,
    WarningComponent,
    NoDataComponent,
    NoDataComponent,
    ProjectSearchComponent,
    LineChartNewComponent,
    LuxonDatePipe,
    DonutChartComponent,
    HoursDaysPipe,
    FirstLetterCapitalcasePipe,
    ProjectRepoFilterComponent,
    ProjectDescriptionComponent,
    DateRangeComponent,
    RangeFiltersComponent,
    TableRankComponent,
    TableNumberComponent,
    TableDeltaComponent,
    TableNumberDeltaComponent,
    TableNameAvatarComponent,
    TableNameComponent,
    TableTotalComponent,
    TablePlatformComponent,
    HeaderTooltipComponent,
    SummaryChartsTabComponent,
    GeoDistributionLegendsComponent,
    CardHeadButtonsComponent,
    CardLogoComponent,
    ProjectStatsRowComponent,
    CardFooterComponent,
    ProjectMetricDetailsComponent,
    KeyMetricRowComponent,
    CardBpScoreComponent,
    TabsComponent,
    MetricSelectComponent,
    AvatarComponent,
    SelectComponent
  ],
  providers: [ShortNumberPipe, LuxonDatePipe],
  exports: [
    SidebarModule,
    ReactiveFormsModule,
    InputComponent,
    IconsModule,
    ContentHeaderComponent,
    BarChartComponent,
    HeatChartComponent,
    TableComponent,
    ClickOutsideDirective,
    ShortNumberPipe,
    IconIndicatorPipe,
    FirstLettersPipe,
    BoldPhrasePipe,
    GaugeChartComponent,
    DateRangeFilterComponent,
    DialogTemplateComponent,
    TextCopyBlockComponent,
    CircleProgressComponent,
    CalculatedCardHeaderComponent,
    TopFiltersComponent,
    ProgressBarComponent,
    LoadingComponent,
    DownloadComponent,
    PositionElementDirective,
    MapChartComponent,
    CalculatePercentagePipe,
    AsyncNumberPipe,
    NumberColorPipe,
    OverlayPanelModule,
    ComingSoonComponent,
    IconStatusPipe,
    ValueChangePipe,
    AbsoluteValuePipe,
    ValidVarTypePipe,
    WipComponent,
    VariationCardHeaderComponent,
    RadarChartComponent,
    BestPracticeEmbedComponent,
    BestPracticesDetailsComponent,
    TooltipComponent,
    TooltipTriggerDirective,
    IntegerSignPipe,
    DeferredLoaderDirective,
    CommentaryIconComponent,
    BiDirectionalProgressComponent,
    InputSwitchModule,
    ProjectCardComponent,
    XyBubbleChartComponent,
    FoundationCardComponent,
    TableDateComponent,
    DialogModule,
    TooltipModule,
    MenuModule,
    CheckboxModule,
    WithLoadingPipe,
    DownloadStyleDirective,
    ProjectPlatformDirective,
    ErrorComponent,
    BestPracticeNoDataComponent,
    ReplaceTextPipe,
    HorizontallyStackedChartComponent,
    ClusteredColumnChartComponent,
    WarningComponent,
    NoDataComponent,
    ProjectSearchComponent,
    LineChartNewComponent,
    LuxonDatePipe,
    DonutChartComponent,
    HoursDaysPipe,
    FirstLetterCapitalcasePipe,
    TableRankComponent,
    TableNumberComponent,
    TableDeltaComponent,
    TableNumberDeltaComponent,
    TableNameAvatarComponent,
    TablePlatformComponent,
    TableNameComponent,
    TableTotalComponent,
    HeaderTooltipComponent,
    GeoDistributionLegendsComponent,
    SummaryChartsTabComponent,
    TabsComponent,
    MetricSelectComponent,
    AvatarComponent,
    ToastModule,
    SkeletonModule,
    CalendarModule,
    DateRangeComponent,
    AutoCompleteModule,
    DropdownModule,
    SelectComponent
  ]
})
export class SharedModule {}
