<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<div class="stacked-cards">
  <ng-template [ngTemplateOutlet]="stackedLowerCard"></ng-template>
  <ng-template [ngTemplateOutlet]="stackedLowerCard"></ng-template>
  <ng-template [ngTemplateOutlet]="card"></ng-template>
</div>

<ng-template #card>
  <div class="foundation-card cards">
    <div class="rounded-t-md" [class]="{ 'card-bg-color': !isOnboarding(), 'data-incomplete-bg': isOnboarding() }">
      <lfx-card-head-buttons
        [project]="foundation()"
        [isOnboarding]="isOnboarding()"
        (downloadClicked)="downloadCard()" />

      <!-- logo -->
      <lfx-card-logo [project]="foundation()" (logoClicked)="onCardNavigate()" />
    </div>

    <lfx-foundation-card-data [foundation]="foundation()" />

    <!-- card footer -->
    <lfx-card-footer [cocomo]="foundation().cocomo" />
  </div>
</ng-template>

<!-- Card to be stacked on -->
<ng-template #stackedLowerCard>
  <div class="stacked-lower-card">
    <!-- Top head line -->
    <div
      class="stacked-header-card"
      [class]="{ 'card-bg-color': !isOnboarding(), 'data-incomplete-bg': isOnboarding() }"></div>
    <!-- Card head -->
    <!-- Card body -->
    <div class="card-bg-color stacked-footer-card"></div>
  </div>
</ng-template>
