// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { ClusteredColumnChartData } from '@shared/components/clustered-column-chart/clustered-column-chart.types';

export const dataSerializer = (data: ClusteredColumnChartData[]): Record<string, any>[] =>
  data.map((d) => {
    let item = {};
    d.data.forEach((s, index) => {
      item = {
        ...item,
        [`value_${index + 1}`]: s.value,
        ...(s.label ? { [`label_${index + 1}`]: s.label } : {}),
        ...(s.color ? { [`color_${index + 1}`]: s.color } : {})
      };
    });
    return {
      category: d.category,
      ...item
    };
  });

export * as ClusteredColumnChartService from './clustered-column-chart.service';
