// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { NgIf, NgFor } from '@angular/common';

export interface ITopRegionsRow {
  name: string;
  value: number;
}

@Component({
    selector: 'lfx-geo-distribution-legends',
    templateUrl: './geo-distribution-legends.component.html',
    styleUrls: ['./geo-distribution-legends.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class GeoDistributionLegendsComponent {
  @Input() public topRegions: ITopRegionsRow[] = [];

  public getWidth(value: number): string {
    // setting the highest value as the ceiling (100%) and computing the other values based on that
    const ceiling = this.topRegions.length > 0 ? this.topRegions[0].value : 1;

    return `${(value / ceiling) * 100}%`;
  }
}
