// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
import {
  AfterContentChecked, ChangeDetectorRef, Component, computed, ElementRef, EventEmitter, input, Input, Output, ViewChild
} from '@angular/core';
import { AdditionalValues, TableColumnConfig, TableConfig, TablePagination } from '@app/core/models/table.model';
import { orderBy } from 'lodash';
import { tablePageSize } from '@app/shared/utils/page-helpers';
import { TestsEnum } from '@shared/interface/tests.enum';
import { NoDataComponent } from '../no-data/no-data.component';
import { LoadingComponent } from '../loading/loading.component';
import { NgStyle, NgIf, NgFor, NgClass, NgTemplateOutlet } from '@angular/common';
import { ITableExtPagination } from '@shared/components/table/table.types';
@Component({
  selector: 'lfx-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: true,
  imports: [NgStyle, NgIf, NgFor, NgClass, NgTemplateOutlet, LoadingComponent, NoDataComponent]
})
export class TableComponent implements AfterContentChecked {
  // TODO: fix this table config passing of template as it is causing an error on the console
  @Input() public tableConfiguration!: TableConfig;
  @Input() public tableRecords: Array<any> = [];
  @Input() public maxHeight: number;
  @Input() public hideHeader: boolean = false;
  @Input() public showNotIntegratedIcon: boolean = true;
  @Input() public tableName: string = '';
  @Input() public additionalValues: AdditionalValues;
  @Input() public isLoading: boolean = false;
  @Input() public isPaginating: boolean = false;
  @Input() public pagination?: TablePagination;
  @Input() public extLoadMore?: () => void;

  @Output() public readonly loadMoreClicked: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('tableContainer') public tableContainer: ElementRef;

  // TODO: refactor component to stateless standalone component based on signals
  public extPagination = input<ITableExtPagination>({currentCounter: 0, totalCounter: 0});
  public isNewRefactoring = computed(() => {
    return !!this.extPagination().currentCounter;
  });

  public scrollbarWidth = 0;

  public noDataFound = false;
  protected testsEnum = TestsEnum;

  constructor(public changeDetectorRef: ChangeDetectorRef) {}

  public ngAfterContentChecked(): void {
    this.checkIsTableHasScrollbar();
  }

  public get currentRows(): number {
    if (this.pagination) {
      const newPage = this.pagination.currentPage + tablePageSize;
      return newPage > this.pagination.total ? this.pagination.total : newPage;
    }

    return 0;
  }

  public checkIsTableHasScrollbar() {
    if (this.tableContainer?.nativeElement.scrollHeight > this.tableContainer?.nativeElement.clientHeight) {
      this.scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    } else {
      this.scrollbarWidth = 0;
    }
  }

  // public onTryAgain() {
  //   this.noDataFound = false;
  //   this.tableConfiguration.error = false;
  //   this.tableService.tableMetricChange$.next(this.tableName);

  //   this.changeDetectorRef.detectChanges();
  // }

  public changeSort(column: TableColumnConfig) {
    if (this.tableConfiguration.sortedBy === column.title) {
      this.tableConfiguration.isDesc = !this.tableConfiguration.isDesc;
    } else {
      this.tableConfiguration.sortedBy = column.title;
      this.tableConfiguration.isDesc = true;
    }
    this.applySort();
    // this.onTryAgain();
  }

  public loadMore(): void {
    this.loadMoreClicked.emit((this.pagination?.currentPage || 0) + tablePageSize);
  }

  protected showLoader(): void {
    this.tableConfiguration.isLoading = true;
    this.changeDetectorRef.detectChanges();
  }

  protected hideLoader(): void {
    this.tableConfiguration.isLoading = false;
    this.changeDetectorRef.detectChanges();
  }

  private applySort() {
    this.tableRecords = orderBy(this.tableRecords, 'rank', this.tableConfiguration.isDesc ? 'desc' : 'asc');
  }
}
