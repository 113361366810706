// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Injectable } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  public currentScroll: number = 0;
  constructor(private vc: ViewportScroller) {}

  public getOffsetY(el: Window | HTMLElement, debounce: number = 0): Observable<[number, number]> {
    return fromEvent(el, 'scroll').pipe(
      debounceTime(debounce),
      distinctUntilChanged(),
      map(() => this.vc.getScrollPosition()),
      untilDestroyed(this)
    );
  }
}
