// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { map } from 'rxjs';
import { Query } from '@cubejs-client/core';

import { CubeServiceBasic } from '@services/api/basic';
import { CubeService } from '../../cube.service';
import { ICacheKey, InjectQuery } from '../api.types';

export interface IBestPracticeScore {
  ['SnowRepositoryReport.bestPracticesScore']: number;
  ['SnowRepositoryReport.documentationScore']: number;
  ['SnowRepositoryReport.globalScore']: number;
  ['SnowRepositoryReport.legalScore']: number;
  ['SnowRepositoryReport.licenseScore']: number;
  ['SnowRepositoryReport.securityScore']: number;
  ['SnowRepositoryReport.last_clo_update']: string;
  ['SnowRepositoryReport.lastupdate']: string;
  ['SnowRepositoryReport.repository']?: string;
  ['SnowRepositoryReport.projectSlug']?: string;
}

export interface IBestPracticeReport {
  bestPracticesReport: string;
  documentationReport: string;
  legalReport: string;
  licenseReport: string;
  repository: string;
  securityReport: string;
}

export class BestPracticeScoreApiClass extends CubeServiceBasic {
  protected useQuery: InjectQuery;
  constructor(cubejs: CubeService, useQuery: InjectQuery) {
    super(cubejs);
    this.useQuery = useQuery;
  }

  public getBestPracticeScores(query: Query, repos: string[]) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_BP_SCORES, repos],
      queryFn: () => this.load$<IBestPracticeScore>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getBestPracticeScoresAll(query: Query, slugs: string[]) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_BP_SCORES_ALL, slugs],
      queryFn: () => this.load$<IBestPracticeScore>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getBestPracticeScoreRepoDetail(query: Query, projectSlug: string, repos: string[], exactMatch: boolean) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_BP_SCORE_DETAILS, projectSlug, repos, exactMatch],
      queryFn: () => this.load$<IBestPracticeScore>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getBestPracticeReport(query: Query, repository: string) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_BP_REPORT, repository],
      queryFn: () => this.load$<IBestPracticeReport>(query).pipe(map((res) => this.toTable(res)))
    });
  }
}
