<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->

@switch (platform) {
  @case (platformEnum.git) {
    <span class="text-gray" [appDownloadStyle]="{ marginTop: '8px' }">
      <fa-icon [icon]="['fab', 'git-alt']"/>
    </span>
  }
  @case (platformEnum.github) {
    <span class="text-gray" [appDownloadStyle]="{ marginTop: '8px' }">
      <fa-icon [icon]="['fab', 'github']"/>
    </span>
  }
  @case (platformEnum.gerrit) {
    <div [appDownloadStyle]="{ marginTop: '8px' }" class="w-4" [inlineSVG]="'/assets/icons/gerrit.svg'"></div>
  }
  @default {
    <span class="N-A">N/A</span>
  }
}
