/* eslint-disable no-case-declarations */
// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, EventEmitter, Output, ViewChild, computed, effect, model, signal } from '@angular/core';
import { APP_CONFIG } from '@app/config';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { IDateRange } from '../date-range-filter/date-range-filter.types';
import { NgTemplateOutlet } from '@angular/common';
import { SharedModule } from 'primeng/api';

@Component({
    selector: 'lfx-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss'],
    standalone: true,
    imports: [CalendarModule, SharedModule, NgTemplateOutlet]
})
export class DateRangeComponent {
  @ViewChild('fromCal') private fromCalendarElem!: Calendar;
  @ViewChild('toCal') private toCalendarElem!: Calendar;

  @Output() public readonly selectedDateChanged = new EventEmitter<IDateRange>();

  dateRange = model.required<IDateRange>();
  fromViewDate = signal<Date>(new Date());
  toViewDate = signal<Date>(new Date());
  fromDate = computed<number>(() => {
    const dt = this.dateRange()[0];
    if (dt) {
      return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()).getTime();
    }
    return 0;
  });
  toDate = computed<number>(() => {
    const dt = this.dateRange()[1];
    if (dt) {
      return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()).getTime();
    }
    return 0;
  });
  minDate = signal<Date>(new Date(APP_CONFIG.DEFAULT_MIN_DATE));

  private selectedRange: IDateRange = [];

  constructor() {
    effect(
      () => {
        // note: never write to the dateRange signal here, it will cause an infinite loop
        this.selectedRange = this.dateRange();
        this.updateCalendarModels();
        this.updateCalenderViews(this.dateRange());
      },
      { allowSignalWrites: true } // be careful with this option, it can lead to infinite loops
    );
  }

  public onDateSelect(date: Date) {
    if (this.selectedRange.length >= 2) {
      this.selectedRange = [];
    }
    this.selectedRange.push(date);

    this.dateRange.set([...this.selectedRange]);

    this.selectedDateChanged.emit(this.dateRange());
    this.updateMinDates();
    this.updateCalendarModels();
  }

  public getTime(day: number, month: number, year: number): number {
    return new Date(year, month, day).getTime();
  }

  private updateCalenderViews(dateRange: IDateRange) {
    if (dateRange.length > 1) {
      this.fromCalendarElem.defaultDate = dateRange[0];
      this.toCalendarElem.defaultDate = dateRange[1];
      this.fromViewDate.set(dateRange[0]);
      this.toViewDate.set(dateRange[1]);
    }
  }

  private updateCalendarModels() {
    this.fromCalendarElem.updateModel(this.dateRange());
    this.toCalendarElem.updateModel(this.dateRange());
  }

  private updateMinDates() {
    if (this.selectedRange.length < 2) {
      this.minDate.set(this.selectedRange[0]);
    } else {
      this.minDate.set(new Date(APP_CONFIG.DEFAULT_MIN_DATE));
    }
  }
}
