<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->
<div *ngIf="itemDescription">
  <span>{{ showMoreText ? itemDescription : (itemDescription | truncateText : 125) }}</span>
  <span *ngIf="itemDescription.length > 125" class="text-blue-600 cursor-pointer" (click)="showMoreText = !showMoreText">{{
    showMoreText ? ' Show Less' : ' Show More'
  }}</span>
</div>
