/* eslint-disable no-case-declarations */
// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DateRangesEnum } from 'lfx-insights';
import { filterRangeAll, filterRangeCompact } from '@app/shared/utils/dropdown-helpers';
import { IDateRangeFilter } from '../date-range-filter/date-range-filter.types';
import { getDatesFromRangeEnum } from './range-filters.service';
import { NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'lfx-range-filters',
    templateUrl: './range-filters.component.html',
    styleUrls: ['./range-filters.component.scss'],
    standalone: true,
    imports: [NgFor, NgClass]
})
export class RangeFiltersComponent implements OnChanges {
  @Input() public compactVersion: boolean = false;
  @Input() public selectedRange = DateRangesEnum.thisYear;

  @Output() public readonly selectedRangeChanged = new EventEmitter<IDateRangeFilter>();

  public filterDatesOptions = filterRangeAll;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.compactVersion) {
      if (changes.compactVersion.currentValue) {
        this.filterDatesOptions = filterRangeCompact;
      } else {
        this.filterDatesOptions = filterRangeAll;
      }
    }
  }

  public onSelectDateFilter(rangeEnum: DateRangesEnum) {
    this.selectedRange = rangeEnum;

    this.selectedRangeChanged.emit({
      rangeEnum,
      dateRange: getDatesFromRangeEnum(rangeEnum)
    });
  }
}
