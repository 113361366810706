// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { map } from 'rxjs';
import { PivotConfig, Query } from '@cubejs-client/core';

import { CubeServiceBasic } from '@services/api/basic';
import { ICacheKey, InjectQuery } from '@services/api/api.types';
import { InsightsFilters } from '@services/filter.service';
import { CubeService } from '../../cube.service';

export interface IPRVelocityAvgHours {
  ['SnowPullRequestsVelocity.hoursAvg']: number;
}

export interface IPRVelocityTotalTimeMerge {
  ['SnowPullRequestsVelocity.merged_count']: number;
}

export interface IPRVelocityTimeMerge {
  ['SnowPullRequestsVelocity.hoursAvg']: number;
  ['SnowPullRequestsVelocity.merged_count']: number;
  ['SnowPullRequestsVelocity.mergedAt']?: string;
  ['SnowPullRequestsVelocity.mergedAt.day']?: string;
  ['SnowPullRequestsVelocity.mergedAt.week']?: string;
  ['SnowPullRequestsVelocity.mergedAt.month']?: string;
  ['SnowPullRequestsVelocity.mergedAt.quarter']?: string;
  ['SnowPullRequestsVelocity.mergedAt.year']?: string;
}

export interface IPRLeadTime {
  ['SnowPullRequestsTimeline.time_to_first_review_avg']: number;
  ['SnowPullRequestsTimeline.review_time_avg']: number;
  ['SnowPullRequestsTimeline.approval_time_avg']: number;
  ['SnowPullRequestsTimeline.time_from_review_to_merge_avg']: number;
  ['SnowPullRequestsTimeline.time_from_open_to_merge_avg']: number;
}

export interface IPRLeadTimeSize {
  ['SnowPullRequestsTimeline.time_from_open_to_merge_avg']: number;
  ['SnowPullRequestsTimeline.prs_count']: number;
  ['SnowPullRequestsTimeline.bucket']: string;
  ['SnowPullRequestsTimeline.bucketId']: number;
}

export interface IPRReviewTimeSize {
  ['SnowPullRequestsTimeline.time_from_review_to_merge_avg']: number;
  ['SnowPullRequestsTimeline.prs_count']: number;
  ['SnowPullRequestsTimeline.bucket']: string;
  ['SnowPullRequestsTimeline.bucketId']: number;
}

export interface IEffortByPRBatchSize {
  ['SnowPullRequestsTimeline.time_from_review_to_merge_avg']: number;
  ['SnowPullRequestsTimeline.time_from_open_to_merge_avg']: number;
  ['SnowPullRequestsTimeline.reviews_count']: number;
  ['SnowPullRequestsTimeline.comments_count']: number;
  ['SnowPullRequestsTimeline.contributors_count']: number;
  ['SnowPullRequestsTimeline.prs_count']: number;
  ['SnowPullRequestsTimeline.bucket']: string;
  ['SnowPullRequestsTimeline.bucketId']: number;
}

export interface IAverageWaitTime {
  x: string; // date
  ['SnowPullRequestsTimeline.time_to_first_review_avg']: string;
  ['SnowPullRequestsTimeline.first_reviewed_at']: string;
}

export class PrsApiClass extends CubeServiceBasic {
  protected useQuery: InjectQuery;
  constructor(cubejs: CubeService, useQuery: InjectQuery) {
    super(cubejs);
    this.useQuery = useQuery;
  }

  public getPRVelocityAvgHours(query: Query, projectId: string | undefined, repositories: string[] | undefined) {
    return this.useQuery({
      queryKey: ['getPRVelocityAvgHours', projectId, repositories, query.timeDimensions],
      queryFn: () => this.load$<IPRVelocityAvgHours>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getPRVelocityTotalMergeCount(query: Query, projectId: string | undefined, repositories: string[] | undefined) {
    return this.useQuery({
      queryKey: ['getPRVelocityTotalMergeCount', projectId, repositories, query.timeDimensions],
      queryFn: () => this.load$<IPRVelocityTotalTimeMerge>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getPRVelocityTimeToMerge(
    query: Query,
    projectId: string | undefined,
    repositories: string[] | undefined,
    pivotConfig?: PivotConfig
  ) {
    return this.useQuery({
      queryKey: ['getPRVelocityTimeToMerge', projectId, repositories, query.timeDimensions],
      queryFn: () => this.load$<IPRVelocityTimeMerge>(query).pipe(map((res) => this.toTable(res, pivotConfig)))
    });
  }

  public getPRLeadTime(query: Query, filters: InsightsFilters) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_PR_LEAD_TIME,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IPRLeadTime>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getLeadTimeByPRSize(query: Query, filters: InsightsFilters) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_LEAD_TIME_BY_PR_SIZE,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IPRLeadTimeSize>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getReviewTimeByPRSize(query: Query, filters: InsightsFilters) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_REVIEW_TIME_BY_PR_SIZE,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IPRReviewTimeSize>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getWaitTimeFor1stReview(query: Query, projectId: string | undefined, repositories: string[] | undefined) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_WAIT_TIME_FOR_1ST_REVIEW, projectId, repositories, query.timeDimensions, query.filters],
      queryFn: () => this.load$<IAverageWaitTime>(query).pipe(map((res) => this.toChartSeries(res)))
    });
  }

  public getWaitTimeFor1stReviewTotal(query: Query, projectId: string | undefined, repositories: string[] | undefined) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_WAIT_TIME_FOR_1ST_REVIEW_TOTAL, projectId, repositories, query.timeDimensions],
      queryFn: () => this.load$<IAverageWaitTime>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getEffortByPullRequestBatchSizeTimeline(query: Query, filters: InsightsFilters) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_EFFORT_BY_P_R_BATCH_SIZE_TIMELINE,
        filters.project,
        filters.repositories,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IEffortByPRBatchSize>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }
}
