<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->
<div class="flex items-center bp-details-wrap" [class]="size">
  <div class="details-col" *ngIf="label">
    <span *ngIf="icon" class="min-w-[25px] text-center icon-wrap" [ngClass]="{ '!mr-0': size === 'sm' }">
      <i [class.text-lg]="size === 'lg'" [class.text-base]="size === 'md'" [class]="icon"></i>
    </span>
    <span
      class="description-text flex items-center"
      [ngClass]="{
        'font-semibold': size === 'lg',
        '!ml-0': icon
      }">
      {{ label }}</span
    >
  </div>
  <div class="progress-val-col" *ngIf="size !== 'sm'">
    <span class="font-semibold font-sans text-base" [style]="{ color: getColor() }" *ngIf="size === 'lg'">{{ value }}%</span>
    <span class="font-bold font-sans text-tiny" *ngIf="size === 'md'">{{ value }}</span>
  </div>

  <div class="w-full progress-bar-col" [class.mx-4]="size === 'sm'">
    <lfx-progress-bar [value]="displayedValue()" [config]="getConfig()"></lfx-progress-bar>
  </div>

  <div class="min-w-[25px] flex justify-end h-[20px] items-center" *ngIf="size === 'sm'">
    <span class="text-gray-dark font-sans text-xs">{{ value }}</span
    >{{ progressBarStyle === 'thick' ? '%' : '' }}
  </div>
</div>
