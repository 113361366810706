// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import am5themesMicro from '@amcharts/amcharts5/themes/Micro';
import * as am5map from '@amcharts/amcharts5/map';
import {
  AxisSettings,
  CreateLinearSeriesOptions,
  CreateYAxisOptions,
  GranularityEnum,
  LegendOptions,
  MapChartConfig,
  YAxisSettings
} from 'lfx-insights';

import { TimeUnit } from '@amcharts/amcharts5/.internal/core/util/Time';
import { IColorPaletteType } from '@shared/styles/color-palette.types';
import colorsPalette from '@shared/styles/color-palette.json';
import { dateDiff, dateToLuxon, endOf, formatDate, startOf, today } from '../services/date.service';
import { am5geodataWorldLow } from './geo-data';

const colors = colorsPalette as unknown as IColorPaletteType;

export function createLinearSeries(
  root: am5.Root,
  chart: am5xy.XYChart,
  xAxis: am5xy.DateAxis<am5xy.AxisRenderer>,
  yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
  { data = [], field = 'value', name = 'Series', color = '#ff3185', bullets = false }: CreateLinearSeriesOptions = {}
) {
  const series = chart.series.push(
    am5xy.SmoothedXLineSeries.new(root, {
      name,
      xAxis,
      yAxis,
      valueYField: field,
      valueXField: 'date',
      stroke: am5.color(color)
    })
  );

  series.strokes.template.setAll({
    strokeWidth: 2
  });

  if (bullets) {
    series.bullets.push(() =>
      am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 6,
          strokeWidth: 2,
          fill: am5.color('#fff'),
          stroke: am5.color(color)
        })
      })
    );
  }

  series.data.setAll(data);
  series.appear(1000);
  return series;
}

export const createColumnSeries = (
  root: am5.Root,
  chart: am5xy.XYChart,
  xAxis: am5xy.DateAxis<am5xy.AxisRenderer> | am5xy.CategoryAxis<am5xy.AxisRenderer>,
  yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
  data: any[],
  name = '',
  field = '',
  color = ''
) => {
  const series = chart.series.push(
    am5xy.ColumnSeries.new(root, {
      name,
      xAxis,
      yAxis,
      valueYField: field,
      valueXField: 'date'
    })
  );
  series.columns.template.setAll({
    width: am5.percent(20),
    stroke: am5.color(color),
    fill: am5.color(color)
  });
  series.data.setAll(data);
};

export function createLegend(root: am5.Root, chart: am5xy.XYChart, { iconSize = 30 }: LegendOptions = {}) {
  const legend = chart.children.push(
    am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    })
  );
  // Note: all chart legend label have same style
  legend.labels.template.setAll({
    fontSize: 12,
    fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
    fill: am5.color('#333333')
  });
  // all
  legend.markers.template.setAll({
    width: iconSize,
    height: iconSize
  });
  // column series
  legend.markerRectangles.template.setAll({
    cornerRadiusTL: 100,
    cornerRadiusTR: 100,
    cornerRadiusBL: 100,
    cornerRadiusBR: 100
  });
  legend.data.setAll(chart.series.values);
}

// THIS WILL ONLY CREATE THE OBJECT AND RETURN IT. IT WILL NOT BE ASSIGNED TO A CHART
export function tooltipObj(
  root: am5.Root,
  chart: am5xy.XYChart,
  granularity: GranularityEnum,
  customTooltipTextAdapter?: (text: string | undefined, target: am5.Label) => any,
  customAdapter: 'text' | 'html' = 'text'
): am5.Tooltip {
  // Add tooltip

  const tooltip = am5.Tooltip.new(root, {
    autoTextColor: false,
    getFillFromSprite: false,
    getStrokeFromSprite: true,
    pointerOrientation: 'horizontal',
    labelText: '[bold]{valueX}[/]'
  });

  tooltip.get('background')?.setAll({
    fill: am5.color('#000000')
  });

  tooltip.label.setAll({
    fill: am5.color('#ffffff'),
    fontSize: 12,
    fontFamily: 'Open Sans, Source Sans Pro, sans-serif'
  });

  if (customTooltipTextAdapter) {
    tooltip.label.adapters.add(customAdapter, customTooltipTextAdapter);
  } else {
    tooltip.label.adapters.add('text', (text, target) => {
      if (
        target.dataItem?.dataContext &&
        ((target.dataItem?.dataContext as any).date || (target.dataItem?.dataContext as any).dateCategory)
      ) {
        return buildTooltipText(chart, granularity, target);
      }
      return text;
    });
  }

  return tooltip;
}

function buildTooltipText(chart: am5xy.XYChart, granularity: GranularityEnum, target: am5.Label) {
  const date = (target.dataItem?.dataContext as any).date || (target.dataItem?.dataContext as any).dateCategory;
  const dataItems = chart.series.values[0].dataItems;
  const lastBucket = dataItems[dataItems.length - 1].dataContext as any;
  let newText = mapDate(date, granularity, lastBucket.dateCategory === date);
  chart.series.each((subSeries) => {
    const stroke = subSeries.get('stroke')?.toString();
    const fill = subSeries.get('fill')?.toString();
    const color = stroke ? stroke : fill;
    newText +=
      '\n[' +
      color +
      ']●[/] [width:100px]' +
      subSeries.get('name') +
      '[/] [bold]{' +
      subSeries.get('valueYField') +
      '}';
  });
  return newText;
}

export function mapDate(date: any, granularity: GranularityEnum, lastBucket?: boolean) {
  switch (granularity) {
    case GranularityEnum.day:
      return `[bold]${formatDate(date, 'MMM dd')}[/]`;
    case GranularityEnum.week:
      return `[bold]${startOf(date, 'week', 'MMM dd') + ' - ' + endOf(date, 'week', 'MMM dd')}[/]`;
    case GranularityEnum.month:
      return `[bold]${startOf(date, 'month', 'MMM dd') + ' - ' + endOf(date, 'month', 'MMM dd')}[/]`;
    default:
      return `[bold]${startOf(date, 'year', 'yyyy (MMM dd')} - ${lastBucket ? today('MMM dd)') : endOf(date, 'year', 'MMM dd)')}[/]`;
  }
}

export function createChartRoot(parentDiv: string | HTMLElement = '', isMicro = false) {
  const root: am5.Root = am5.Root.new(parentDiv);

  const myTheme = am5.Theme.new(root);
  myTheme.rule('Grid').setAll({
    strokeOpacity: 0
  });

  root.setThemes([am5themesAnimated.new(root), myTheme]);
  if (isMicro) {
    root.setThemes([am5themesMicro.new(root)]);
  }

  // remove footer logo
  // eslint-disable-next-line no-underscore-dangle
  root._logo?.children.clear();

  // setting the start of the week to Monday
  root.locale.firstDayOfWeek = 1;

  return root;
}

export function createChart(root: am5.Root, applyPadding: boolean) {
  return root.container.children.push(
    am5xy.XYChart.new(root, {
      panY: false,
      layout: root.verticalLayout,
      paddingBottom: 0,
      paddingRight: applyPadding ? 25 : 0,
      paddingLeft: applyPadding ? 10 : 0,
      paddingTop: applyPadding ? 25 : 0,
      maxTooltipDistance: -1
    })
  );
}

export function createYAxis(
  root: am5.Root,
  chart: am5xy.XYChart,
  { max = undefined, label = undefined, numberFormat = undefined, color = undefined }: CreateYAxisOptions = {},
  config?: YAxisSettings,
  oppositeAxis = false
) {
  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      max,
      numberFormat,
      min: 0,
      extraMax: 0.1,
      maxPrecision: 0,
      // strictMinMax: true,
      renderer: am5xy.AxisRendererY.new(
        root,
        config?.hide
          ? {}
          : {
              opposite: oppositeAxis ? true : false,
              fill: am5.color('#807f83'),
              stroke: am5.color('#807f83'),
              minGridDistance: 25,
              strokeOpacity: config?.hideAxis ? 0 : 1,
              strokeWidth: 1
            }
      )
    })
  );

  if (config?.hideAxis) {
    yAxis
      .get('renderer')
      .labels.template.adapters.add('text', (text: string | undefined) => (oppositeAxis ? `- ${text}` : `${text} -`));
  }

  // Note: all chart y-axis label have same style
  if (label) {
    const yAxisLabel = am5.Label.new(root, {
      rotation: -90,
      text: label,
      fontSize: 12,
      fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
      fill: am5.color(color || '#333333'),
      y: am5.p50,
      centerX: am5.p50
    });

    yAxis.children.unshift(yAxisLabel);
  }

  const yRenderer = yAxis.get('renderer');
  yRenderer.labels.template.setAll(
    config?.hide
      ? { visible: false }
      : {
          fill: am5.color(color || '#807f83'),
          fontSize: 12
        }
  );

  if (!config?.hide && config?.hideFirstLabel) {
    yRenderer.labels.template.adapters.add('text', (value: string | undefined) => {
      if (value === '0') {
        return '';
      }

      return value;
    });
  }

  chart.appear(1000, 100);

  return yAxis;
}

export function createXAxis(
  root: am5.Root,
  chart: am5xy.XYChart,
  granularity: GranularityEnum,
  xAxisSettings?: AxisSettings
): am5xy.DateAxis<am5xy.AxisRenderer> | am5xy.CategoryAxis<am5xy.AxisRenderer> {
  const renderer = am5xy.AxisRendererX.new(root, {
    stroke: xAxisSettings?.hideAxis ? am5.color('#00FFFFFF') : am5.color('#807f83'),
    minGridDistance: 28,
    strokeOpacity: 1,
    strokeWidth: 1,
    cellStartLocation: xAxisSettings?.cellStartLocation,
    crisp: true
    // cellEndLocation: xAxisSettings?.cellEndLocation
  });

  const xAxis =
    xAxisSettings?.axisType === 'category'
      ? chart.xAxes.push(
          am5xy.CategoryAxis.new(root, {
            categoryField: xAxisSettings?.field,
            endLocation: xAxisSettings?.endLocation,
            startLocation: xAxisSettings?.startLocation,
            renderer
          })
        )
      : chart.xAxes.push(
          am5xy.DateAxis.new(root, {
            markUnitChange: false,
            groupData: true,
            extraMax: xAxisSettings?.endLocation === 0 ? 0.008 : undefined,
            endLocation: xAxisSettings?.endLocation,
            startLocation: xAxisSettings?.startLocation,
            dateFormats:
              xAxisSettings && xAxisSettings.dateFormats
                ? xAxisSettings.dateFormats
                : {
                    day: 'MMM\ndd',
                    week: 'MMM\ndd',
                    month: 'MMM\nyy',
                    year: 'yyyy'
                  },
            baseInterval: {
              timeUnit: granularity as TimeUnit,
              count: 1
            },
            renderer
          })
        );

  (xAxis as am5xy.DateAxis<am5xy.AxisRenderer>).get('renderer').labels.template.setAll({
    fill: am5.color('#807f83'),
    fontSize: 12,
    textAlign: 'center',
    location: xAxisSettings?.labelLocation,
    rotation: xAxisSettings?.labelRotation
  });

  if (xAxisSettings && xAxisSettings.addLabelSkip) {
    (xAxis as am5xy.DateAxis<am5xy.AxisRenderer>)
      .get('renderer')
      .labels.template.adapters.add('text', (value: string | undefined, target: am5xy.AxisLabel) => {
        // eslint-disable-next-line no-underscore-dangle
        const { index = 0 } = target.dataItem ? (target.dataItem._settings as any) : {}; // overriding the type here so we can get the index field
        const { addLabelSkip = 0 } = xAxisSettings;
        if (index % addLabelSkip > 0) {
          return '';
        }

        return value;
      });
  }

  if (xAxisSettings?.customTextAdapter) {
    (xAxis as am5xy.DateAxis<am5xy.AxisRenderer>)
      .get('renderer')
      .labels.template.adapters.add('text', xAxisSettings.customTextAdapter);
  }

  return xAxis;
}

export function createMapChart(root: am5.Root): am5map.MapChart {
  const chart = root.container.children.push(
    am5map.MapChart.new(root, {
      projection: am5map.geoNaturalEarth1(),
      panX: 'none',
      panY: 'none',
      wheelY: 'none',
      maxZoomLevel: 1
    })
  );

  return chart;
}

export function createPolygonSeries(root: am5.Root, chart: am5map.MapChart) {
  const polygonSeries = chart.series.push(
    am5map.MapPolygonSeries.new(root, {
      geoJSON: am5geodataWorldLow as GeoJSON.GeoJSON,
      exclude: ['AQ'],
      fill: am5.color('#d6d6d6')
    })
  );

  polygonSeries.mapPolygons.template.setAll({
    interactive: true
  });

  polygonSeries.mapPolygons.template.states.create('hover', {
    fill: am5.color('#797979')
  });
  polygonSeries.mapPolygons.template.states.create('active', {
    fill: am5.color('#797979')
  });

  return polygonSeries;
}

export function createSeriesTarget(
  yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
  series: am5xy.SmoothedXLineSeries | am5xy.ColumnSeries,
  value: number
) {
  // add series range
  const seriesRangeDataItem = yAxis.makeDataItem({ value, endValue: value });
  series.createAxisRange(seriesRangeDataItem);
  seriesRangeDataItem.get('grid')?.setAll({
    strokeOpacity: 1,
    visible: true,
    stroke: am5.color('#807f83'),
    strokeDasharray: 7
  });
}

function setPolygonState(stateName = 'default', polygonSeries: am5map.MapPolygonSeries, circle: am5.Circle) {
  const dataItem = circle.dataItem;
  if (!dataItem) {
    return;
  }
  const dataContext = dataItem.dataContext as { id: string };
  const polygonItem = polygonSeries.getDataItemById(dataContext.id);
  if (!polygonItem) {
    return;
  }
  const polygon = polygonItem.get('mapPolygon');
  polygon.states.applyAnimate(stateName);
}

export function createBubbleSeries(
  root: am5.Root,
  chart: am5map.MapChart,
  polygonSeries: am5map.MapPolygonSeries,
  config: MapChartConfig
) {
  const pointSeries = chart.series.push(
    am5map.MapPointSeries.new(root, {
      valueField: config.series.valueField,
      calculateAggregates: config.series.calculateAggregates || true,
      polygonIdField: config.series.idField
    })
  );

  const circleTemplate = am5.Template.new({}) as am5.Template<am5.Circle>;
  circleTemplate.events.on('pointerover', (ev) => {
    const circle = ev.target;
    setPolygonState('hover', polygonSeries, circle);
  });
  circleTemplate.events.on('pointerout', (ev) => {
    const circle = ev.target;
    setPolygonState('default', polygonSeries, circle);
  });

  pointSeries.bullets.push(bubbleMapPointTemplate(circleTemplate, config.tooltipHTML || ''));
  pointSeries.set('heatRules', [
    {
      target: circleTemplate,
      dataField: config.series.valueField,
      min: config.min || 10,
      max: config.max || 30,
      key: 'radius'
    }
  ]);

  return pointSeries;
}

export function mapAnnotations(template: string, values: any): string {
  let output = template;

  Object.keys(values).forEach((key) => {
    if (key === 'changeDirection') {
      output = output.replace('{changeDirection}', values.changeDirection === 'negative' ? 'text-red' : 'text-green');
    } else {
      output = output.replace(`{${key}}`, values[key]);
    }
  });

  return output;
}

let isFirstBubble = true;
const bubbleMapPointTemplate =
  (circleTemplate: am5.Template<am5.Circle>, tooltipHtml = '') =>
  (root: am5.Root) => {
    const container = am5.Container.new(root, {});
    const tooltip = createBulletMapTooltip(root);
    if (!tooltip) {
      return;
    }
    createBubble(root, container, tooltip, circleTemplate, false, tooltipHtml);
    if (isFirstBubble) {
      createBubble(root, container, tooltip, circleTemplate, false, tooltipHtml);
      isFirstBubble = false;
    }

    return am5.Bullet.new(root, {
      sprite: container,
      dynamic: true
    });
  };
function createBulletMapTooltip(root: am5.Root) {
  const tooltip = am5.Tooltip.new(root, {
    pointerOrientation: 'left',
    keepTargetHover: true,
    getFillFromSprite: false,
    labelText: '[bold]{name}[/]\n{valueX.formatDate()}: {valueY}',
    animationDuration: 0
  });

  const template = tooltip.get('background');
  if (!template) {
    return;
  }
  template.setAll({
    fill: am5.color('#000')
  });

  return tooltip;
}

function createBubble(
  root: am5.Root,
  container: am5.Container,
  tooltip: am5.Tooltip,
  circleTemplate: am5.Template<am5.Circle>,
  isAnimated = false,
  tooltipHtml = ''
) {
  const circle = container.children.push(
    am5.Circle.new(
      root,
      {
        radius: 20,
        fillOpacity: 0.7,
        fill: am5.color(colors.blue.DEFAULT),
        cursorOverStyle: 'pointer',
        tooltip,
        tooltipX: am5.percent(100),
        tooltipHTML: tooltipHtml
      },
      circleTemplate
    )
  );

  if (isAnimated) {
    //   circle.animate({
    //     key: 'scale',
    //     from: 1,
    //     to: 1.4,
    //     duration: 1000,
    //     easing: am5.ease.out(am5.ease.cubic),
    //     loops: Infinity
    //   });
    //   circle.animate({
    //     key: 'opacity',
    //     from: 1,
    //     to: 0,
    //     duration: 1000,
    //     easing: am5.ease.out(am5.ease.cubic),
    //     loops: Infinity
    //   });
  }

  return circle;
}

export function formatWeekLabel(dateString = '2022-06-01') {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);

  const previousMonday = new Date(date.getTime() - (date.getDay() - 1) * 86400000);
  const previousMondayMonth = previousMonday.toLocaleDateString('en-US', { month: 'short' });
  const previousMondayDay = previousMonday.toLocaleDateString('en-US', { day: 'numeric' });

  const nextSunday = new Date(date.getTime() + (7 - date.getDay()) * 86400000);
  const nextSundayMonth = nextSunday.toLocaleDateString('en-US', { month: 'short' });
  const nextSundayDay = nextSunday.toLocaleDateString('en-US', { day: 'numeric' });

  let template = ``;

  if (previousMondayMonth === nextSundayMonth) {
    template = `${previousMondayMonth}\n${previousMondayDay}-${nextSundayDay}`;
  } else {
    template = `${previousMondayMonth}${previousMondayDay}-\n${nextSundayMonth} ${nextSundayDay}`;
  }

  return template;
}

export function fillMissingDates<T, K extends keyof T>(
  dateFrom: string | number,
  dateTo: string | number,
  granularity: GranularityEnum,
  data: T[],
  dateKey: K,
  cumulativeKey?: K,
  defaultValue?: any
) {
  if (!data.length) {
    return [];
  }
  const diff = Math.ceil(dateDiff(dateTo, dateFrom, granularity));
  const objTemplate = Object.fromEntries(Object.keys(data[0] || {}).map((key) => [key, defaultValue || 0]));
  const points = Array.from({ length: diff + 1 }, (_, i) => {
    const date = dateToLuxon(dateFrom).plus({ [granularity]: i });
    return (
      data.find((p) => date.hasSame(dateToLuxon(p[dateKey] as number), granularity)) || {
        ...objTemplate,
        [dateKey]: date.toMillis()
      }
    );
  }) as T[];

  if (cumulativeKey) {
    return handleCumulative<T, K>(points, cumulativeKey);
  }

  return points;
}

const handleCumulative = <T, K extends keyof T>(data: T[], cumulativeKey: K) => {
  for (let i = 1; i < data.length; i++) {
    if (data[i][cumulativeKey] === 0 && data[i - 1][cumulativeKey] !== 0) {
      data[i][cumulativeKey] = data[i - 1][cumulativeKey];
    }
  }
  return data;
};
