// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { NgModule } from '@angular/core';
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faAnalytics,
  faTachometerAltFastest,
  faShieldCheck,
  faUserCheck,
  faGlobe,
  faTimesCircle,
  faCaretDown,
  faCalendar,
  faChartLine,
  faChartLineDown,
  faArrowUp,
  faArrowDown,
  faTired,
  faTurtle,
  faRabbitFast,
  faEllipsisHAlt,
  faArrowToBottom,
  IconDefinition,
  faChartPie as faChartSolid,
  faFileEdit as faFileEditSolid,
  faBorderTop as faBorderTopSolid,
  faEllipsisV as faEllipsisVSolid,
  faBookmark,
  faArrowCircleUp,
  faFile,
  faFileChartLine,
  faCheck,
  faCheckSquare,
  faArrowSquareDown,
  faArrowCircleDown,
  faComments,
  faDatabase as faDatabaseSolid
} from '@fortawesome/pro-solid-svg-icons';
import {
  faSearch,
  faQuestionCircle,
  faUserCircle,
  faCalendarAlt,
  faFileCsv,
  faDownload,
  faCalendar as faCalendarLight,
  faUser,
  faCodeCommit,
  faCodeMerge,
  faCodeBranch,
  faStar,
  faThumbsUp,
  faFileEdit,
  faLayerGroup,
  faDotCircle,
  faInfoCircle,
  faTimes,
  faVectorSquare,
  faBuilding,
  faGlobe as faGlobeLight,
  faSitemap,
  faHandsHelping,
  faSortSizeDown,
  faCheck as faCheckLight
} from '@fortawesome/pro-light-svg-icons';
import {
  faEllipsisV,
  faAnalytics as faAnalyticsReg,
  faTachometerAltFastest as faTachometerAltFastestReg,
  faShieldCheck as faShieldCheckReg,
  faUserCheck as faUserCheckReg,
  faGlobe as faGlobeReg,
  faCode,
  faStars,
  faUserFriends,
  faCodeBranch as farCodeBranch,
  faFileDownload,
  faRetweet,
  faHashtag,
  faGrin,
  faChartPie,
  faBorderTop,
  faChartLine as faChartLineSolid,
  faAngleUp,
  faAngleDown,
  faChevronDoubleLeft,
  faHomeAlt,
  faSearch as faSearchSolid,
  faDatabase,
  faObjectsColumn,
  faFileChartColumn,
  faEnvelope
} from '@fortawesome/pro-regular-svg-icons';

import { faTwitter, faGithub, faGitAlt, faConfluence } from '@fortawesome/free-brands-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule]
})
export class IconsModule {
  constructor(library: FaIconLibrary, config: FaConfig) {
    config.fixedWidth = true;

    library.addIcons(
      faAnalytics,
      faSearch,
      faSearchSolid,
      faTimesCircle,
      faEllipsisV,
      faCaretDown,
      faQuestionCircle,
      faUserCircle,
      faTachometerAltFastest,
      faShieldCheck,
      faUserCheck,
      faGlobe,
      faAnalyticsReg,
      faTachometerAltFastestReg,
      faShieldCheckReg,
      faUserCheckReg,
      faGlobeReg,
      faGlobeLight,
      faCalendar,
      faCalendarAlt,
      faCalendarLight,
      faFileCsv,
      faDownload,
      faUser,
      faChartLine,
      faChartLineDown,
      faCodeCommit,
      faCodeMerge,
      faCodeBranch,
      faStar,
      faCode,
      faArrowUp,
      faArrowDown,
      faTired,
      faTurtle,
      faEllipsisHAlt,
      faThumbsUp,
      faArrowToBottom,
      faUserFriends,
      faStars,
      farCodeBranch,
      faFileDownload,
      faRetweet,
      faHashtag,
      faGrin,
      faTwitter as IconDefinition,
      faFileEdit,
      faChartPie,
      faBorderTop,
      faChartSolid,
      faFileEditSolid,
      faBorderTopSolid,
      faRabbitFast,
      faEllipsisVSolid,
      faBookmark,
      faLayerGroup,
      faDotCircle,
      faInfoCircle,
      faArrowCircleUp,
      faArrowCircleDown,
      faFile,
      faFileChartLine,
      faGithub as IconDefinition,
      faGitAlt as IconDefinition,
      faConfluence,
      faChartLineSolid,
      faAngleUp,
      faAngleDown,
      faTimes,
      faVectorSquare,
      faCheck,
      faCheckSquare,
      faBuilding,
      faArrowSquareDown,
      faChevronDoubleLeft,
      faSitemap,
      faHandsHelping,
      faHomeAlt,
      faComments,
      faDatabaseSolid,
      faDatabase,
      faSortSizeDown,
      faObjectsColumn,
      faFileChartColumn,
      faCheckLight,
      faEnvelope
    );
  }
}
