import { DateRangesEnum, GranularityEnum } from 'lfx-insights';
import { FilterFromParams, InsightsFiltersNew, defaultFilters } from './filter.types';
import {
  getCurrentPreviousPeriodDateRanges,
  getFirstAndLastDayOfDateRangeType
} from '@app/shared/utils/cubejs-helpers';
import { formatDate } from '../date.service';
import { APP_CONFIG } from '@app/config';
import { getPreviousPeriod } from '@app/shared/cubejs/helpers/utils';
import { filterRangeCompact } from '@app/shared/utils/dropdown-helpers';

export const getDateRangeFromStringParam = (dateRangeStr?: string): [string, string] => {
  const defaultRange = getFirstAndLastDayOfDateRangeType(APP_CONFIG.DATE_RANGE_DEFAULT);
  const dateRangeArr = dateRangeStr && dateRangeStr.indexOf(' to ') ? dateRangeStr.split(' to ') : undefined;

  return dateRangeArr && dateRangeArr.length > 1
    ? [dateRangeArr[0], dateRangeArr[1]]
    : [formatDate(defaultRange.startDate), formatDate(defaultRange.endDate)];
};

export const getAuthenticatedUserFilters = (
  filters: InsightsFiltersNew,
  params: FilterFromParams
): InsightsFiltersNew => {
  const tmpFilters = { ...filters };
  const { dateFilters, dateRange } = params;

  if (dateFilters === DateRangesEnum.custom && dateRange) {
    const currentPeriod = getDateRangeFromStringParam(dateRange);
    const previousPeriod = getPreviousPeriod(currentPeriod[0], currentPeriod[1]);
    tmpFilters.periods = {
      currentPeriod,
      previousPeriod
    };
  } else {
    tmpFilters.periods = getCurrentPreviousPeriodDateRanges(tmpFilters.dateFilters, tmpFilters.compare);
  }

  return tmpFilters;
};

export const getRetentionPageFilters = (filters: InsightsFiltersNew): InsightsFiltersNew => {
  const tmpFilters = { ...filters };

  tmpFilters.compare = undefined;
  tmpFilters.granularity = GranularityEnum.month;

  if (filterRangeCompact.indexOf(filters.dateFilters) > -1) {
    tmpFilters.periods = getCurrentPreviousPeriodDateRanges(tmpFilters.dateFilters);
  } else {
    // override the dateFilters selection so it doesn't show up in the date filter selection
    tmpFilters.dateFilters = defaultFilters.dateFilters;
    tmpFilters.periods = defaultFilters.periods;
    tmpFilters.granularity = defaultFilters.granularity;
  }

  return tmpFilters;
};

export const calculateGranularity = (filters: InsightsFiltersNew): GranularityEnum => {
  let granularity = GranularityEnum.week;

  switch (filters.dateFilters) {
    case DateRangesEnum.yesterday:
      granularity = GranularityEnum.day;
      break;
    case DateRangesEnum.last7:
      granularity = GranularityEnum.day;
      break;
    case DateRangesEnum.last15:
      granularity = GranularityEnum.day;
      break;
    case DateRangesEnum.last30:
      granularity = GranularityEnum.day;
      break;
    case DateRangesEnum.last60:
    case DateRangesEnum.last90:
    case DateRangesEnum.last180:
    case DateRangesEnum.lastQuarter:
    case DateRangesEnum.last2Quarters:
      granularity = GranularityEnum.week;
      break;
    case DateRangesEnum.thisYear:
    case DateRangesEnum.lastYear:
    case DateRangesEnum.last12Months:
      granularity = GranularityEnum.month;
      break;
    case DateRangesEnum.last2Years:
      granularity = GranularityEnum.month;
      break;
    case DateRangesEnum.last5Years:
      granularity = GranularityEnum.year;
      break;
    case DateRangesEnum.last10Years:
      granularity = GranularityEnum.year;
      break;
    default:
      granularity = GranularityEnum.month;
  }

  return granularity;
};
