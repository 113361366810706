<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->
<div [class]="{ 'text-[20px]': !isCompact, 'text-tiny': isCompact }">
  <p [class]="{ 'mb-4': !isCompact, 'mb-2': isCompact }">
    {{ isCompact ? 'This ' : 'Your ' }}{{ selectedRepository ? 'repository' : 'project' }} isn't set up for best practices yet.
    <a [href]="docsLink" target="_blank" class="text-primary">Learn how</a>
  </p>
  <!-- <p *ngIf="selectedRepository">
    Learn more about
    <a [href]="docsLink" target="_blank" class="text-primary">adopting best practices</a>
    or <a [href]="selectedRepository" target="_blank" class="text-primary">submit a pull request</a> to add this repository to the list to be monitored.
  </p> -->
</div>
