<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->
<ng-container>
  <div #topFilterContainer class="top-20 z-[18]" [class.sticky]="componentFixed()">
    @if (componentFixed()) {
      <div class="overlay-top-fixed-bar"></div>
    }
    <div #topFilterComponent class="top-filter-component" [class.data-incomplete-bg]="!isOnboarded">
      <div [class.absolute]="componentFixed()" class="section-top">
        <div class="page-title">
          <h1>
            {{ pageTitle() | firstLetterCapitalcase }}
          </h1>
          <div class="relative ml-4" *ngIf="tooltipTemplate">
            <i
              class="fal fa-info-circle text-primary text-xl cursor-pointer"
              [appLfxTooltipTrigger]="'top_filter_tooltip'">
            </i>
            <lfx-tooltip
              [id]="'top_filter_tooltip'"
              [tooltipTemplate]="tooltipTemplate"
              [config]="{
                show: 'left',
                positionValue: '150%',
                verticalAlign: '0',
                textWrap: 'white-space:wrap'
              }"></lfx-tooltip>
          </div>
        </div>
        <div class="additional-filters">
          <ng-content select="[additionalFilter]"></ng-content>
        </div>
        <div *ngIf="!isOnboarded" class="on-boarding-wrap">
          <lfx-warning [minHeight]="24" [appLfxTooltipTrigger]="'isOnboarding-top-filter'"></lfx-warning>
          <lfx-tooltip
            [id]="'isOnboarding-top-filter'"
            [text]="getOnboardingTooltip()"
            [config]="{
              show: 'top',
              positionValue: '-175%',
              horizontalAlign: '-178px',
              width: '200px',
              textWrap: ''
            }"></lfx-tooltip>
        </div>
      </div>

      <div *ngIf="!isError; else error">
        <div *ngIf="projectNewService.selectedFoundation() as selectedFoundation; else loading">
          <div class="flex gap-4 flex-wrap flex-row">
            <div class="section-content-left" [class.foundation-page]="isFoundationPage()">
              <div [ngClass]="{ 'mb-3': !componentFixed() && filterContainer() !== 'projectHealth' }">
                <lfx-project-repo-filter
                  [isOverviewPage]="isOverviewPage()"
                  [filterContainer]="filterContainer()"
                  [foundation]="selectedFoundation"></lfx-project-repo-filter>

                <ng-content select="[pageFilter]"></ng-content>
              </div>
            </div>

            <!-- DATE FILTER -->
            <div class="section-content-right ml-auto basis-1/5" *ngIf="!isFoundationPage() && !hideDateFilter()">
              <lfx-date-range-filter
                [dateRangeFilter]="dateRangeFilter()"
                [compactVersion]="useCompactDateFilter()"
                (selectedFilterChanged)="onDateFilterChange($event)"></lfx-date-range-filter>
            </div>

            <ng-content select="[foundationContent]"></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <lfx-loading [minHeight]="50"></lfx-loading>
</ng-template>

<ng-template #error>
  <lfx-error></lfx-error>
</ng-template>
