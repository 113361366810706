// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit, Signal, inject, signal } from '@angular/core';

import { environment } from '@environments/environment';
import { environment as datadogEnv } from '@environments/environment.datadog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { datadogRum } from '@datadog/browser-rum';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import colorsPalette from '@shared/styles/color-palette.json';
import { NgClass } from '@angular/common';
import { SideNavComponent } from './core/components/side-nav/side-nav.component';
import { ToastModule } from 'primeng/toast';
import { AppService, IHeaderElem } from './shared/services/app.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'lfx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [ToastModule, SideNavComponent, NgClass, RouterOutlet],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppComponent implements OnInit {
  isCollapsed = signal(false);

  isLandingPage: Signal<boolean>;
  isFoundationPage: Signal<boolean>;

  private router: Router = inject(Router);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private authService: AuthService = inject(AuthService);
  private appService: AppService = inject(AppService);

  // router has to be put in the constructor otherwise we won't be able to use it in the currentUrl definition
  constructor() {
    const currenthref = window.location.href;
    if (currenthref.includes('code=') && currenthref.includes('state=')) {
      this.authService.handleRedirectCallback(currenthref);
      this.handleLoginRedirect();
    }

    this.isLandingPage = this.appService.isLandingPage;
    this.isFoundationPage = this.appService.isFoundationPage;
  }

  public ngOnInit(): void {
    this.loadLFxHeaderScriptAndLinks();

    this.handleLogoutRedirect();
  }

  public toggleCollapsed() {
    this.isCollapsed.set(!this.isCollapsed());
  }

  public collapsed() {
    this.isCollapsed.set(false);
  }

  private handleLoginRedirect() {
    this.authService.appState$.pipe(untilDestroyed(this)).subscribe((state) => {
      // LFX Header is redirecting to the wrong URL, which causes the app route to redirect to the landing page
      setTimeout(() => {
        // pausing a bit to override the initial redirect caused by the invalid route
        const urlCallback = new URL(decodeURIComponent(state?.returnTo || ''));
        this.router.navigateByUrl(`${urlCallback.pathname}${urlCallback.search}`);
      }, 50);
    });
  }

  private handleLogoutRedirect() {
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      if (params.returnTo) {
        const urlCallback = new URL(decodeURIComponent(params.returnTo));
        this.router.navigateByUrl(`${urlCallback.pathname}${urlCallback.search}`);
      }
    });
  }

  private loadLFxHeaderScriptAndLinks() {
    this.appService.loadLFxHeaderScriptAndLinks((header: IHeaderElem) => {
      this.loadDataDogScript();
      this.addBetaLogo();
      this.changeHeaderBackgroundColor();
      this.changeLogoLink();

      this.authService.user$.pipe(untilDestroyed(this)).subscribe((data) => {
        if (data) {
          header.authuser = data;

          const ddrData = {
            id: data['https://sso.linuxfoundation.org/claims/username'],
            email: data.email,
            name: data.name
          };
          datadogRum.setUser(ddrData);
        }

        datadogRum.startSessionReplayRecording();
      });
    });
  }

  private loadDataDogScript() {
    if (!datadogRum.getInitConfiguration()) {
      datadogRum.init({
        applicationId: datadogEnv.datadogAppID,
        clientToken: datadogEnv.datadogToken,
        site: 'datadoghq.com',
        service: 'lfx-insights-v3',
        env: environment.datadogEnv,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        trackInteractions: true,
        trackFrustrations: true,
        allowedTracingOrigins: environment.traceOrigins,
        defaultPrivacyLevel: 'mask-user-input'
      });
    }
  }

  // this is a hack since the lfx header is from a different project
  private addBetaLogo() {
    const head = document.querySelector('lfx-header-v2');
    const projectTitle = head?.shadowRoot?.querySelector('.project-title');
    const style = document.createElement('style');
    style.innerHTML = `.project-title { position: relative; }
    .beta-tag {
      position: absolute;
      font-size: 12px;
      color: #0e4c67;
      bottom: -12px;
      right: 0;
    }`;

    if (head && head.shadowRoot && projectTitle) {
      head.shadowRoot.appendChild(style);
      const tmp = document.createElement('div');
      tmp.className = 'beta-tag';
      tmp.innerHTML = 'Beta';

      projectTitle.append(tmp);
    }
  }

  private changeHeaderBackgroundColor() {
    const head = document.querySelector('lfx-header-v2');
    const header = head?.shadowRoot?.querySelector('.lfx-main-header-v2');

    if (head && head.shadowRoot && header) {
      header.setAttribute('style', `background-color: ${colorsPalette.white.DEFAULT};`);
    }
  }

  // this is a hack since the lfx header is from a different project
  private changeLogoLink() {
    const head = document.querySelector('lfx-header-v2');
    const link = head?.shadowRoot?.querySelector('.lfx-main-header-left>a');

    if (head && head.shadowRoot && link) {
      link.setAttribute('href', '/');
    }
  }
}
