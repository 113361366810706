// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { DocumentationLinkEnum } from '@app/shared/utils/page-helpers';

@Component({
    selector: 'lfx-best-practice-no-data',
    templateUrl: './best-practice-no-data.component.html',
    styleUrls: ['./best-practice-no-data.component.scss'],
    standalone: true
})
export class BestPracticeNoDataComponent {
  @Input() public selectedRepository: string = '';
  @Input() public isCompact = false;

  public docsLink = DocumentationLinkEnum.bestPractices;
}
