// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { map } from 'rxjs';
import { PivotConfig, Query } from '@cubejs-client/core';

import { CubeServiceBasic } from '@services/api/basic';
import { CubeService } from '../../cube.service';
import { InjectQuery } from '@services/api/api.types';

export interface IProjectAcceptedOvertime {
  ['SnowLfxProjects.count_projects_cumulative']: number;
  ['SnowLfxProjects.start_date.year']: string;
}

export interface IProjectAcceptedTrends {
  ['SnowLfxProjects.count_projects']: number;
  ['SnowLfxProjects.start_date.month']: string;
}

export interface IProjectMaturityGroups {
  ['SnowLfxProjects.count_projects']: number;
  ['SnowLfxProjects.admin_category']: string;
}

export interface IFoundationFirstAcceptanceDate {
  ['SnowLfxProjects.start_date']: string;
}

export class ProjectMaturityApiClass extends CubeServiceBasic {
  protected useQuery: InjectQuery;
  constructor(cubejs: CubeService, useQuery: InjectQuery) {
    super(cubejs);
    this.useQuery = useQuery;
  }

  public getProjectAcceptedOvertime(query: Query, foundationSlug: string) {
    const pivotConfig: PivotConfig = {
      fillMissingDates: true
    };
    return this.useQuery({
      queryKey: ['getProjectAcceptedOvertime', query.timeDimensions, foundationSlug],
      queryFn: () => this.load$<IProjectAcceptedOvertime>(query).pipe(map((res) => this.toTable(res, pivotConfig)))
    });
  }

  public getProjectAcceptedTrends(query: Query, foundationSlug: string) {
    const pivotConfig: PivotConfig = {
      fillMissingDates: true
    };
    return this.useQuery({
      queryKey: ['getProjectAcceptedTrends', query.timeDimensions, foundationSlug],
      queryFn: () => this.load$<IProjectAcceptedTrends>(query).pipe(map((res) => this.toTable(res, pivotConfig)))
    });
  }

  public getProjectMaturityGroups(query: Query, foundationSlug: string) {
    return this.useQuery({
      queryKey: ['getProjectMaturityGroups', query.limit, query.offset, foundationSlug],
      queryFn: () => this.load$<IProjectMaturityGroups>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getFoundationFirstAcceptanceDate(query: Query, foundationSlug: string) {
    return this.useQuery({
      queryKey: ['getFoundationFirstAcceptanceDate', foundationSlug],
      queryFn: () => this.load$<IFoundationFirstAcceptanceDate>(query).pipe(map((res) => this.toTable(res)))
    });
  }
}

