<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->
<div class="tabs">
  @for (tab of tabs; track $index) {
    <div
      class="tab"
      [ngClass]="{ 'disabled': tab.disabled }"
      (click)="tab.disabled ? false : onTabClick($index)"
      [class.active]="$index === selectedTabIdx">
      {{ tab.label }}
    </div>
  }
</div>
