// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Injectable } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { GranularityEnum, LineChartConfig, LineSeriesSettings } from 'lfx-insights';

import { tooltipObj, createSeriesTarget } from '../utils/chart-helpers';

// TODO: Revisit this service, the getChartData should no longer be used for getting data
@Injectable({
  providedIn: 'root'
})
export class ChartService {
  constructor() {}

  public getSingleLineChartConfig(opts = {}): LineChartConfig {
    const {
      granularity = undefined,
      xAxisField = 'date',
      xAxisType = 'date',
      showLegend = true,
      seriesField = 'value',
      seriesName = 'line series',
      seriesColor = '#ff3185',
      bullets = true,
      extra = {}
    } = (opts as any) || {};
    return {
      showLegend,
      series: [
        {
          field: seriesField,
          name: seriesName,
          color: seriesColor,
          bullets,
          locationX: 0
        }
      ],
      xAxis: {
        field: xAxisField,
        axisType: xAxisType,
        granularity,
        endLocation: 0,
        labelLocation: 0
      },
      type: 'smooth',
      ...extra
    };
  }

  public createLineSeries =
    (component: any) =>
    (
      root: am5.Root,
      chart: am5xy.XYChart,
      xAxis: am5xy.DateAxis<am5xy.AxisRenderer> | am5xy.CategoryAxis<am5xy.AxisRenderer>,
      yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
      settings: LineSeriesSettings,
      granularity: GranularityEnum
    ) => {
      const series = chart.series.push(
        am5xy.SmoothedXLineSeries.new(
          root,
          component.config.xAxis.axisType === 'category'
            ? {
                name: settings.name,
                xAxis,
                yAxis,
                valueYField: settings.field,
                categoryXField: component.config.xAxis.field,
                stroke: am5.color(settings.color || '#ff3185'),
                locationX: settings.locationX,
                userData: settings.legendTooltipText
              }
            : {
                name: settings.name,
                xAxis,
                yAxis,
                valueYField: settings.field,
                valueXField: component.config.xAxis.field,
                stroke: am5.color(settings.color || '#ff3185'),
                locationX: settings.locationX,
                userData: settings.legendTooltipText
              }
        )
      );

      series.strokes.template.setAll({});
      series.strokes.template.setAll({
        strokeWidth: 2
      });

      if (settings.bullets) {
        series.bullets.setAll([]);
        series.bullets.push((...parmas) => {
          const [, se, dataItem] = parmas;
          const dataContext = dataItem.dataContext as any;
          const valueField = (se as any).get('valueYField');
          const showFirst = settings.showFirstBullet;
          const hideFirst = !showFirst && dataItem.uid === se.dataItems[0].uid && se.dataItems.length > 1;

          // creating a dettached tooltip
          const tooltip = tooltipObj(
            component.chartRef.root,
            component.chartRef,
            granularity,
            component.config.customTooltipTextAdapter
          );

          const circle = am5.Circle.new(root, {
            radius: 5,
            strokeWidth: 2,
            opacity: !dataContext[valueField] || hideFirst ? 0 : 1,
            fill: am5.color(settings.color || '#fff'),
            stroke: am5.color(settings.color || '#ff3185'),
            tooltipText: component.config.xAxis.axisType === 'category' ? '{categoryX}: {valueY}' : '{valueY}',
            showTooltipOn: 'hover',
            tooltip // assigning the dettached tooltip here instead of the series
          });
          // attaching it here vs the series will make sure the tooltip only appears when you hover the circle

          const bullet = am5.Bullet.new(root, {
            sprite: circle,
            locationX: settings.locationX
          });

          return bullet;
        });
      }

      if (settings.stroke) {
        series.strokes.template.setAll({
          strokeWidth: settings.strokeWidth || 3,
          strokeDasharray: settings.strokeDasharray || [10, 5]
        });
      }

      if (settings.fills) {
        if (settings.fillGradientColors) {
          const grad = am5.LinearGradient.new(root, {
            stops: settings.fillGradientColors.map((c) => ({
              color: am5.color(c)
            })),
            rotation: 90
          });
          series.fills.template.setAll({
            fillOpacity: 0.6,
            visible: true,
            fillGradient: grad
          });
        } else {
          series.fills.template.setAll({
            fillOpacity: 0.2,
            visible: true
          });
        }
      }

      if (component.config.targetValue) {
        // adding the dashed line
        createSeriesTarget(yAxis, series, component.config.targetValue);
      }

      series.data.setAll([]);
      series.data.setAll(component.data);
    };
}
