// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  effect,
  inject,
  input,
  model,
  signal
} from '@angular/core';
import { DropdownOptions } from '@app/shared/interface/common';
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
  AutoCompleteModule
} from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { NgTemplateOutlet, NgIf } from '@angular/common';
import { SharedModule } from 'primeng/api';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@Component({
  selector: 'lfx-select',
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  standalone: true,
  imports: [AutoCompleteModule, ReactiveFormsModule, FormsModule, SharedModule, NgTemplateOutlet, DropdownModule, NgIf]
})
export class SelectComponent implements OnInit {
  @Input() public optionsTmpl?: TemplateRef<any>;
  @Input() public labelTmpl?: TemplateRef<any>;

  @ViewChild('autocomplete', { static: false }) autoComplete!: AutoComplete;
  @Output() public readonly selectedChanged = new EventEmitter<string>();

  selectedItem = model<string | undefined>(undefined);
  selectedOption = model<DropdownOptions | undefined>(undefined);
  items = input.required<DropdownOptions[]>();
  placeholder = input<string | undefined>(undefined);
  isSelectedFirst = input(true);
  isSearchable = input(true); // will use autocomplete
  isClearable = input(false); // will only be used if isSearchable is true
  isLoading = input(false);

  filtered = signal<DropdownOptions[]>([]);

  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor() {
    effect(
      () => {
        const selectedText = this.selectedItem();
        if (selectedText) {
          this.findItemAndSet(selectedText);
        } else {
          if (this.isSelectedFirst() && this.items().length > 0) {
            this.updateModels(this.items()[0]);
          }
        }
      },
      { allowSignalWrites: true }
    );
  }

  public ngOnInit() {
    this.cdr.detectChanges();
  }

  public onSelectItem(event: AutoCompleteSelectEvent) {
    if (this.isSearchable()) {
      const item = event.value as DropdownOptions;
      this.updateModels(item);
      this.selectedChanged.emit(item.value);
    } else {
      this.selectedChanged.emit(event.value);
    }
  }

  public onAutoComplete(event: AutoCompleteCompleteEvent) {
    const filtered = this.items().filter((item) => item.label.toLowerCase().indexOf(event.query.toLowerCase()) >= 0);

    this.filtered.set(filtered || []);
  }

  public onButtonClick() {
    const inputCtrl = this.autoComplete.inputEL?.nativeElement as HTMLInputElement;

    if (inputCtrl) {
      inputCtrl.select();
    }
  }

  public onBlur() {
    if (!this.isClearable()) {
      const inputCtrl = this.autoComplete.inputEL?.nativeElement as HTMLInputElement;
      if (inputCtrl.value === '' && this.selectedItem()) {
        this.findItemAndSet(this.selectedItem() || '');
      }
    }
  }

  private findItemAndSet(value: string) {
    const item = this.items().find((i) => i.value === value);

    if (item) {
      this.updateModels(item);
    }
  }

  private updateModels(item: DropdownOptions) {
    this.selectedOption.set(item);
    if (this.isSearchable()) {
      this.selectedItem.set(item.value);
    }
  }
}
