enum FoundationCardColumnFilters {
  slugPath = 'slug_path',
  slug = 'slug',
  subprojectSlug = 'subproject_slug',
  projectSlug = 'project_slug',
  projectGroupSlug = 'project_group_slug',
  githubOrg = 'github_org'
}

enum FoundationCardOrderBy {
  slugPath = 'slug_path',
  slug = 'slug',
  subprojectSlug = 'subproject_slug',
  projectSlug = 'project_slug',
  projectGroupSlug = 'project_group_slug',
  githubOrg = 'github_org',
  contributorsCount = 'contributors_count',
  identitiesCount = 'identities_count',
  lastActivity = 'last_activity',
  prsCount = 'prs_count',
  csetsCount = 'csets_count',
  commitsCount = 'commits_count',
  issuesCount = 'issues_count',
  starsCount = 'stars_count',
  forksCount = 'forks_count',
  descendantsCount = 'descendants_count',
  organizationsCount = 'organizations_count',
  linesCode = 'lines_code'
}

interface AllFoundationCardsRequest {
  cacheByPass?: boolean;
  fields?: string;
  keys?: string[];
  column: FoundationCardColumnFilters;
  column2?: string;
  keys2?: string[];
  orderBy: FoundationCardOrderBy;
  asc: boolean;
  limit?: number;
  offset?: number;
}

export interface FilterParam {
  column: string;
  values: string[];
}

const fields = [
  'SLUG',
  'SUBPROJECT_SLUG',
  'PROJECT_SLUG',
  'PROJECT_GROUP_SLUG',
  'GITHUB_ORG',
  'CONTRIBUTORS_COUNT',
  'PRS_COUNT',
  'CSETS_COUNT',
  'COMMITS_COUNT',
  'ISSUES_COUNT',
  'STARS_COUNT',
  'FORKS_COUNT',
  'DESCENDANTS_COUNT',
  'ORGANIZATIONS_COUNT',
  'LINES_CODE',
  'NAME',
  'GRANDPARENT_NAME',
  'SEGMENT_ID',
  'DESCRIPTION',
  'URL',
  'PROJECT_TYPE',
  'ONBOARDING_STATUS',
  'LOGO',
  'REPOSITORY_URL',
  'START_DATE_AS_TIMESTAMP',
  'LAST_ACTIVITY',
  'PLATFORMS',
  'MATURITY_LEVEL'
];

export const buildAllFoundationsQuery = (
  limit: number = 0,
  offset: number = 0,
  cacheByPass?: boolean,
  typeFilter?: FilterParam,
  additionalFilter?: FilterParam
) => {
  const req: AllFoundationCardsRequest = {
    cacheByPass,
    fields: fields.join(','),
    column: typeFilter ? (typeFilter.column as FoundationCardColumnFilters) : FoundationCardColumnFilters.slug,
    orderBy: FoundationCardOrderBy.contributorsCount,
    asc: false,
    limit,
    offset
  };

  if (typeFilter) {
    req.keys = typeFilter.values;
  }

  if (additionalFilter) {
    req.column2 = additionalFilter.column;
    req.keys2 = additionalFilter.values;
  }

  return req;
};

const projectFields = [
  'ADMIN_CATEGORY',
  'DESCRIPTION',
  'LOGO',
  'MATURITY_LEVEL',
  'NAME',
  'ONBOARDING_STATUS',
  'PLATFORMS',
  'PROJECT_ID',
  'PROJECT_TYPE',
  'REPOSITORY_URL',
  'SEGMENT_ID',
  'SLUG',
  'START_DATE_AS_TIMESTAMP',
  'WEBSITE',
  'PROJECT_SLUG',
  'PROJECT_GROUP_SLUG'
];

export const buildProjectsQuery = (cacheByPass?: boolean, typeFilter?: FilterParam, additionalFilter?: FilterParam) => {
  const req: AllFoundationCardsRequest = {
    cacheByPass,
    fields: projectFields.join(','),
    column: typeFilter ? (typeFilter.column as FoundationCardColumnFilters) : FoundationCardColumnFilters.slug,
    orderBy: FoundationCardOrderBy.slug,
    asc: true
    // limit: 1000000,
    // offset: 0
  };

  if (typeFilter) {
    req.keys = typeFilter.values;
  }

  if (additionalFilter) {
    req.column2 = additionalFilter.column;
    req.keys2 = additionalFilter.values;
  }

  return req;
};
