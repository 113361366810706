// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hoursDays',
    standalone: true
})
export class HoursDaysPipe implements PipeTransform {
  public transform(
    hours: number | null | undefined,
    skipHours?: boolean,
    shortSuffix?: boolean,
    showIntSign?: boolean
  ): string {
    if (!hours) {
      return '';
    }

    const hrsSuffix = shortSuffix ? 'H' : ' Hrs';
    const daySuffix = shortSuffix ? 'D' : ' Day';
    const hrsAbs = Math.abs(hours);

    if (hrsAbs > 24) {
      const days = Math.floor(hrsAbs / 24);
      const remainingHours = Math.round(hrsAbs - days * 24);
      const sign = showIntSign ? (hours > 0 ? '+' : '-') : '';

      return (
        sign +
        (skipHours
          ? `${days}${daySuffix}${days > 1 && !shortSuffix ? 's' : ''}`
          : `${days}${daySuffix}${days > 1 && !shortSuffix ? 's' : ''} ${
              remainingHours > 0 ? remainingHours + hrsSuffix : ''
            }`)
      );
    }

    return `${Math.round(hours)}${hrsSuffix}`;
  }
}
