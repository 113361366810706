// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { getVisualizationPlayground } from '@app/shared/cubejs/helpers/utils';
import { DownloadService } from '@app/shared/services/download.service';
import { environment } from '@environments/environment';
import { MenuItem } from 'primeng/api';
import { LoadingComponent } from '../loading/loading.component';
import { MenuModule } from 'primeng/menu';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';

@Component({
    selector: 'lfx-download',
    templateUrl: './download.component.html',
    styleUrls: ['./download.component.scss'],
    standalone: true,
    imports: [NgIf, FaIconComponent, MenuModule, LoadingComponent]
})
export class DownloadComponent implements OnInit {
  @Input() public visualization: string | HTMLElement;
  @Input() public title: string;
  @Input() public cardName: string;
  @Input() public isLoading = false;
  @Input() public height: number | null = null;
  @Output() public readonly exportCSV = new EventEmitter<any>();

  items = signal<MenuItem[] | undefined>(undefined);

  public isDevEnv = environment.environment === 'dev';
  public showTooltip: boolean = false;
  constructor(private downloadService: DownloadService) {}

  public ngOnInit() {
    const items = [
      { label: 'PNG', command: () => this.downloadPNG() },
      { label: 'CSV', command: () => this.downloadCSV() }
    ];

    if (this.showCubeMenuItem()) {
      items.push({ label: 'Cube', command: () => this.redirectToCube() });
    }

    this.items.set(items);
  }

  public downloadPNG() {
    this.downloadService.download(
      this.visualization,
      this.title + '-' + new Date().getTime(),
      2,
      true,
      null,
      this.height
    );
  }

  public redirectToCube() {
    if (this.isDevEnv) {
      this.downloadService.openVisualizationInPlayground(this.cardName);
    }
  }

  public downloadCSV() {
    this.exportCSV.emit();
    const card = this.downloadService.getReadToDownloadCard(this.cardName);
    if (card) {
      this.downloadService.downloadCSV(this.title, card.data);
    }
  }

  private showCubeMenuItem() {
    return this.isDevEnv && !!this.cardName && !!getVisualizationPlayground(this.cardName);
  }
}
