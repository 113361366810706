// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { ProgressBarConfig } from '@app/shared/interface/common';
import { NgClass } from '@angular/common';

@Component({
    selector: 'lfx-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    standalone: true,
    imports: [NgClass]
})
export class ProgressBarComponent {
  @Input() public config: ProgressBarConfig = {
    color: '',
    rounded: true,
    background: 'gray',
    class: ''
  };

  @Input() public value: number = 0;

  public getClass(): any {
    return {
      ['border border-gray']: this.config.background === 'bordered',
      ['bg-gray-100']: this.config.background === 'gray',
      ['rounded-sm']: this.config.rounded,
      [this.config.class]: true,
      ['h-2.5']: this.config.height === undefined,
      [this.config.height || '']: this.config.height
    };
  }
}
