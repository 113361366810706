// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component } from '@angular/core';

@Component({
    selector: 'lfx-content-header',
    templateUrl: './content-header.component.html',
    styleUrls: ['./content-header.component.scss'],
    standalone: true
})
export class ContentHeaderComponent {}
