// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { TruncateTextPipe } from '@shared/pipes/truncate-text.pipe';
import { NgIf } from '@angular/common';

@Component({
    selector: 'lfx-project-description',
    templateUrl: './project-description.component.html',
    styleUrls: ['./project-description.component.scss'],
    standalone: true,
    imports: [NgIf, TruncateTextPipe]
})
export class ProjectDescriptionComponent {
  @Input() public itemDescription = '';

  public showMoreText = false;
}
