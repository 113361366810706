// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { MetricDropdownOptions } from '@app/shared/interface/common';
import { PlatformEnum, PlatformsFilterLeaderboard } from '@app/shared/interface/common.enum';
import { getDateFilterBusFactor } from '@app/shared/utils/page-helpers';
import { InsightsFilters } from '@app/shared/services/filter.service';
import { getRepositoryFilterValue } from '@app/shared/cubejs/helpers/utils';
import { InsightProject } from 'lfx-insights';

// TODO: clean this up once gerrit has it's own page
export const contributorsMetricsForDropdown: MetricDropdownOptions[] = [
  {
    label: 'Activities',
    value: 'contributions',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'contributions'
  },
  {
    label: 'Commit Activities',
    value: 'commits',
    displayFor: [PlatformEnum.git, PlatformEnum.github],
    busFactorValue: 'commits'
  },
  {
    label: 'Issues Opened',
    value: 'issues-opened',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'issues-opened'
  },
  {
    label: 'Issues Closed',
    value: 'issues-closed',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'issues-closed'
  },
  {
    label: 'Pull Request Comments',
    value: 'pr_comments',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pr_comments'
  },
  {
    label: 'Pull Request Reviews',
    value: 'pull_request-reviewed',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pull_request-reviewed'
  },
  {
    label: 'Pull Requests Closed',
    value: 'pull_request-closed',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pull_request-closed'
  },
  {
    label: 'Pull Requests Merged',
    value: 'pull_request-merged',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pull_request-merged'
  },
  {
    label: 'Pull Requests Opened',
    value: 'pull_request-opened',
    displayFor: [PlatformEnum.github],
    busFactorValue: 'pull_request-opened'
  },
  {
    label: 'Activities',
    value: 'contributions',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'contributions'
  },
  {
    label: 'Patchsets Activities',
    value: 'commits',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'commits'
  },
  {
    label: 'Comments',
    value: 'gerrit_comments',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'gerrit_comments'
  },
  {
    label: 'Changeset Reviews',
    value: 'changeset-reviews',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'changeset-reviews'
  },
  {
    label: 'Changesets Abandoned',
    value: 'changeset-abandoned',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'changeset-abandoned'
  },
  {
    label: 'Changesets Merged',
    value: 'changeset-merged',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'changeset-merged'
  },
  {
    label: 'Changesets Opened',
    value: 'changeset-created',
    displayFor: [PlatformEnum.gerrit],
    busFactorValue: 'changeset-created'
  }
];

interface ContributorLeaderboardRequest {
  fields: string;
  project: string;
  repository: string;
  timeRangeName: string;
  ctr?: string;
  platforms?: string;
  dateFrom?: string;
  dateTo?: string;
  segmentId: string;
  activityType: string;
  orderBy: string;
  filterBots: number;
  asc: boolean;
  limit: number;
  offset: number;
}

export const getContributorsLeaderboardQuery = (
  filters: InsightsFilters,
  activityType: string,
  isGerrit: boolean,
  project: InsightProject,
  offset: number,
  limit: number
): ContributorLeaderboardRequest => {
  const repositoryUrl = getRepositoryFilterValue(filters.repositories);
  const timeRangeParam = filters.dateFilters ? getDateFilterBusFactor(filters.dateFilters) : '';
  const req: ContributorLeaderboardRequest = {
    fields:
      'MEMBER_DISPLAY_NAME,ROW_NUMBER,LOGO_URL,CONTRIBUTIONS,PERCENT_TOTAL,DELTA_CONTRIBUTIONS,ALL_CONTRIBUTORS,ALL_CHANGE_FROM_PREVIOUS',
    project: filters.project!,
    repository: [repositoryUrl === '' ? 'all-repos-combined' : repositoryUrl].toString(),
    timeRangeName: timeRangeParam,
    platforms: isGerrit ? PlatformsFilterLeaderboard.gitGerrit : PlatformsFilterLeaderboard.gitGithub,
    segmentId: project.id,
    activityType,
    orderBy: 'row_number',
    filterBots: filters.hideBots ? 1 : 0,
    asc: true,
    limit,
    offset
  };

  if (timeRangeParam === 'custom') {
    req.timeRangeName = '';
    req.dateFrom = filters.periods?.currentPeriod[0];
    req.dateTo = filters.periods?.currentPeriod[1];
    req.ctr = 'hll';
  }

  return req;
};
