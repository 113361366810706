// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input, OnInit } from '@angular/core';
import { NgStyle, NgClass } from '@angular/common';

@Component({
    selector: 'lfx-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    standalone: true,
    imports: [NgStyle, NgClass]
})
export class LoadingComponent implements OnInit {
  @Input() public minHeight: number | undefined;

  public ngOnInit(): void {
    if (!this.minHeight) {
      this.minHeight = 300;
    }
  }
}
