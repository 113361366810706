// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Pipe, PipeTransform } from '@angular/core';
import { truncate } from 'lodash';

@Pipe({
    name: 'truncateText',
    standalone: true
})
export class TruncateTextPipe implements PipeTransform {
  public transform(txt: string, length: number): string {
    return truncate(txt, { length });
  }
}
