// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { DownloadStyleDirective } from '../../../../directives/download-style.directive';
import { AvatarComponent } from '../../../avatar/avatar.component';

@Component({
    selector: 'lfx-table-name-avatar',
    templateUrl: './table-name-avatar.component.html',
    styleUrls: ['./table-name-avatar.component.scss'],
    standalone: true,
    imports: [AvatarComponent, DownloadStyleDirective]
})
export class TableNameAvatarComponent {
  @Input() public name!: string | null | undefined;
  @Input() public avatar: string | null | undefined;
  @Input() public roundedAvatar: boolean = true;
}
