// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { ValidVarTypePipe } from '../../../../pipes/valid-var-type.pipe';

@Component({
    selector: 'lfx-table-total',
    templateUrl: './table-total.component.html',
    styleUrls: ['./table-total.component.scss'],
    standalone: true,
    imports: [ValidVarTypePipe]
})
export class TableTotalComponent {
  @Input() public total: number | undefined | null;
}
