// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ScrollService } from '@app/shared/services/scroll.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, fromEvent } from 'rxjs';
import { FirstLetterCapitalcasePipe } from '@shared/pipes/first-letter-capitalcase.pipe';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lfx-calculated-card-header',
    templateUrl: './calculated-card-header.component.html',
    styleUrls: ['./calculated-card-header.component.scss'],
    standalone: true,
    imports: [ClickOutsideDirective, NgIf, NgTemplateOutlet, FirstLetterCapitalcasePipe]
})
export class CalculatedCardHeaderComponent implements OnInit {
  @ViewChild('calculatedTooltip') public calculatedTooltip: ElementRef;
  @Input() public tooltipTemplate: TemplateRef<any>;
  @Input() public title: string;
  @Input() public tooltipHorizontal = 'left-[25%]';
  @Input() public tooltipClearance = 0;
  @Input() public textClass = 'text-lg';
  @Input() public skipTitleTransform = false;
  public showTooltip = false;
  public topPosition = 0;

  constructor(public elementRef: ElementRef, public scrollService: ScrollService) {}

  public ngOnInit() {
    setTimeout(() => {
      this.calculateTopPos();
    }, 200);

    fromEvent(window, 'resize')
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe(this.calculateTopPos.bind(this));
  }

  public get scrollTop(): number {
    return this.scrollService.currentScroll;
  }

  public toggleTooltip(): void {
    this.showTooltip = !this.showTooltip;
  }

  public getTooltipPosition(): string {
    const clientRect =
      this.elementRef?.nativeElement?.getBoundingClientRect()?.top -
      this.calculatedTooltip?.nativeElement?.clientHeight;
    if (clientRect < 150) {
      return 'top-[120%]';
    }
    return 'bottom-[120%]';
  }

  private calculateTopPos(): void {
    const box = this.elementRef.nativeElement.getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = this.scrollTop || docEl.scrollTop || body.scrollTop;

    const clientTop = docEl.clientTop || body.clientTop || 0;

    this.topPosition = Math.round(box.top + scrollTop - clientTop);
  }
}
