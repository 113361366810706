<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->

<div class="flex mt-6">
  <div class="w-11/12 whitespace-nowrap overflow-x-auto text-tiny" #copyBox>
    {{ text }}
  </div>
  <div class="text-primary relative text-xl ml-2.5 mt-1.5 cursor-pointer">
    <fa-icon (click)="copyLink(copyBox)" [icon]="faCopy"></fa-icon>
    <span class="text-xs absolute -top-[25px] -left-[9px] text-gray" *ngIf="showMsg">Copied!</span>
  </div>
</div>
