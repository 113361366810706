// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appLfxTooltipTrigger]',
    standalone: true
})
export class TooltipTriggerDirective {
  @Input() public appLfxTooltipTrigger: string = '';

  constructor() {}

  @HostListener('mouseover')
  public onMouseOver() {
    const element = document.querySelector(`#tooltip_${this.appLfxTooltipTrigger}`);
    if (element && !element.classList.contains('visible')) {
      element.classList.remove('invisible');
      element.classList.add('visible');
    }
  }

  @HostListener('mouseout')
  public onMouseOut() {
    const element = document.querySelector(`#tooltip_${this.appLfxTooltipTrigger}`);
    if (element && !element.classList.contains('invisible')) {
      element.classList.add('invisible');
      element.classList.remove('visible');
    }
  }
}
