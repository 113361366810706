<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->
<!-- TODO: Fix this when the embed report summary is in used, this should be a dialog, but since designs may change
removing the mat-dialog usage for now -->
<div class="flex justify-between border border-gray">
  <div class="flex items-center py-5 px-6 text-gray-dark text-lg font-roboto font-bold">{{ title }}</div>
  <div class="text-gray-dark text-3xl outline-none p-1 cursor-pointer"><fa-icon [icon]="faTimes"></fa-icon></div>
</div>
<div>
  <ng-content></ng-content>
</div>
<div align="end">
  <ng-content select=".actions"></ng-content>
</div>
