// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const getContributorDiversificationQuery = (projectSlug: string, segmentId: string) => {
  return {
    fields: 'YEAR,CURR_BUS_FACTOR,CURR_ELEPHANT_FACTOR,CURR_TOP_COMMITERS_COMMITS_PERCENT,CURR_TOP_COMMITING_ORGS_COMMITS_PERCENT,COMMITS_COUNT',
    orderBy: 'year',
    asc: true,
    column: 'slug',
    value: projectSlug,
    column2: 'segment_id',
    value2: segmentId,
    limit: 0,
    offset: 0
  };
};

export const getProjectHealthForksQuery = (projectSlug: string, segmentId: string) => {
  return {
    fields: 'YEAR,FORKS_COUNT',
    segment_id: segmentId,
    subproject_slug: projectSlug,
    orderBy: 'year',
    asc: true
  };
};

export const getProjectHealthStarsQuery = (projectSlug: string, segmentId: string) => {
  return {
    fields: 'YEAR,STARS_COUNT',
    segment_id: segmentId,
    subproject_slug: projectSlug,
    orderBy: 'year',
    asc: true
  };
};

export const getProjectHealthScores = (projectSlug: string) => {
  return {
    SLUG: projectSlug,
  };
};
