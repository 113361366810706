<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->
<div class="search-input" [class.compact]="isCompact()">
  <div class="searchable-select z-[4]">
    <p-autoComplete
      placeholder="Search a project..."
      emptyMessage="No items found"
      styleClass="searchable_select"
      [suggestions]="filtered()"
      (completeMethod)="onAutoComplete($event)"
      (onSelect)="selectFoundationOrProject($event)"
      [disabled]="isLoading()"
      [showClear]="true"
      clearicon="fas fa-circle-xmark"
      #autocomplete
      (onFocus)="!autocomplete.value && autocomplete.handleDropdownClick($event)"
      optionLabel="label"
      optionValue="value">
      <ng-template let-project pTemplate="item">
        <div class="option-item" [title]="project.label" [ngClass]="project.level">
          {{ project.label || '' }}
        </div>
      </ng-template>
      <ng-template pTemplate="clearicon">
        <i class="fas fa-circle-xmark font-bold text-xl text-gray"></i>
      </ng-template>
    </p-autoComplete>

    <div class="search-icon">
      <fa-icon [icon]="[isCompact() ? 'far' : 'fal', 'search']" [size]="isCompact() ? 'sm' : 'lg'"></fa-icon>
    </div>

    <div class="loading-icon" *ngIf="isLoading()">
      <i class="fas fa-circle-notch text-xl"></i>
    </div>
  </div>
</div>
