// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Injectable, inject } from '@angular/core';
import { DateRange } from '@cubejs-client/core';
import { DateRangesEnum, GranularityEnum } from 'lfx-insights';
import { BehaviorSubject, distinctUntilChanged, filter, map, take, tap, combineLatest } from 'rxjs';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { APP_CONFIG } from '@app/config';
import { AuthService } from '@auth0/auth0-angular';
import { getCurrentPreviousPeriodDateRanges } from '../utils/cubejs-helpers';
import { ProjectRatingEnum } from '../interface/common.enum';
import { dateDiff } from './date.service';
import { ProjectNewService } from './project-new.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { filterRangeCompact } from '@app/shared/utils/dropdown-helpers';

/**
 *
 * INFO: This needs to match with all filters in
 * 1) top-filters.component.ts
 * 2) date-range-filter.component.ts
 *
 * Types of dateFilters coming from CubeJS API
 * 1) Last Year, last Quarter, This week
 * 2) ['2020-01-01', '2020-12-31']
 *
 */
export interface InsightsFilters {
  dateFilters?: DateRangesEnum | DateRange;
  // @deprecated
  // we need to support multiple projects array
  project?: string;
  //
  repositoryTags?: string[];
  compare?: string;
  platform?: string[];
  granularity?: GranularityEnum;
  // Info: Use it to calculate diff values between periods
  periods?: {
    currentPeriod: [string, string] | DateRange;
    previousPeriod: [string, string] | DateRange;
  };
  // Info: Use it to calculate Visualization Data
  foundationSlug?: string;
  projects?: string[];
  subprojects?: string[];
  repositories?: string[];
  hideBots?: boolean;
}

export interface FoundationProjectsFilters {
  dateFilters?: DateRangesEnum | DateRange;
  maturityLevel?: string;
  rating?: 'All' | ProjectRatingEnum;
}

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  public defaultFilters: InsightsFilters = {
    dateFilters: APP_CONFIG.DATE_RANGE_DEFAULT,
    project: '',
    hideBots: true,
    repositories: ['all'],
    repositoryTags: [],
    compare: 'PP',
    periods: getCurrentPreviousPeriodDateRanges(APP_CONFIG.DATE_RANGE_DEFAULT),
    granularity: APP_CONFIG.GRANULARITY_DEFAULT as GranularityEnum,
    foundationSlug: ''
  };

  public currentFilter: InsightsFilters = {
    ...this.defaultFilters
  };

  public filter$ = new BehaviorSubject<InsightsFilters>(this.currentFilter);
  public prevUrl: string;

  private projectNewService: ProjectNewService = inject(ProjectNewService);

  // TODO: revisit this, temporary solution until we refactor this whole service
  private foundation$ = toObservable(this.projectNewService.selectedFoundation);

  // TODO: This needs refactoring as well. Will tackle after project service
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    // just get the query params for the initial load
    this.handleUrlQueryParams();
    this.foundationUrlEventListener();
  }

  // TODO: This needs to be revisited once we start refactoring this service
  public handleUrlQueryParams() {
    combineLatest([this.activatedRoute.queryParams.pipe(take(1)), this.authService.isAuthenticated$]).subscribe(
      ([params, isAuthenticated]) => {
        const { project, repository, dateFilters, dateRange, compare, granularity, hideBots } = params;

        const urlArr = this.router.url.split('/');
        // update the date filters if they are present
        if (project) {
          this.currentFilter.project = project;
          this.projectNewService.setSelectedProjectSlug(project);
        } else if (
          /^\/foundation\/[a-zA-Z-_]*\/[a-zA-Z-_]*/g.test(this.router.url) &&
          urlArr[3].startsWith('projects')
        ) {
          // TODO: refactor this
          this.foundation$.subscribe((foundation) => {
            if (foundation && foundation.projects && foundation.projects.length === 1) {
              this.currentFilter.project = foundation.projects[0].slug;
              this.projectNewService.setSelectedProjectSlug(foundation.projects[0].slug);
              this.router.navigate([], { queryParams: { project: foundation.projects[0].slug } });
            } else {
              if (!urlArr[3].startsWith('projects')) {
                this.router.navigate(['/foundation/', urlArr[2]]);
              }
            }
          });
        }
        if (repository && repository !== 'all') {
          this.projectNewService.setRepositoryUrl(repository);
          this.currentFilter.repositories = [repository];
        }

        // TODO: Revisit this once we have confirmation how the date filters behave for the retention page
        if (this.router.url.indexOf('/retention') > -1) {
          // normal date filters don't work on this page so we're overriding them if they are in the URL
          this.currentFilter.dateFilters = dateFilters ? dateFilters : APP_CONFIG.DATE_RANGE_DEFAULT;
          this.currentFilter.compare = undefined;
          this.currentFilter.granularity = GranularityEnum.month;

          if (filterRangeCompact.indexOf(dateFilters) > -1) {
            if (dateRange && this.currentFilter.periods) {
              this.currentFilter.periods.currentPeriod =
                dateRange && dateRange.indexOf(' to ') ? dateRange.split(' to ') : APP_CONFIG.DATE_RANGE_DEFAULT;
            }
          } else {
            // override the dateFilters selection so it doesn't show up in the date filter selection
            this.currentFilter.dateFilters = APP_CONFIG.DATE_RANGE_DEFAULT;
          }
        } else {
          if (dateFilters) {
            this.currentFilter.dateFilters = dateFilters.indexOf(' to ') >= 0 ? dateFilters.split(' to ') : dateFilters;
          }
          if (dateRange && this.currentFilter.periods) {
            this.currentFilter.periods.currentPeriod =
              dateRange && dateRange.indexOf(' to ') ? dateRange.split(' to ') : APP_CONFIG.DATE_RANGE_DEFAULT;
          }
          if (compare) {
            this.currentFilter.compare = compare;
          }
          if (granularity) {
            this.currentFilter.granularity = granularity;
          }
          if (this.currentFilter.dateFilters) {
            this.currentFilter.periods = getCurrentPreviousPeriodDateRanges(
              this.currentFilter.dateFilters,
              this.currentFilter.compare
            );
          }
        }

        if (hideBots !== undefined) {
          this.currentFilter.hideBots = hideBots === 'true' ? true : false;
        }

        if (isAuthenticated) {
          this.filter$.next(this.currentFilter);
        } else if (this.currentFilter.dateFilters && this.currentFilter.dateFilters !== APP_CONFIG.DATE_RANGE_DEFAULT) {
          // TODO: temporary remove
          // this.authService.loginWithRedirect();
        }
      }
    );
  }

  public foundationUrlEventListener() {
    this.router.events
      .pipe(
        filter((e) => e instanceof ActivationEnd),
        distinctUntilChanged(() => this.router.url === this.prevUrl),
        tap(() => (this.prevUrl = this.router.url)),
        map((e) => (e instanceof ActivationEnd ? e.snapshot.params : {}))
      )
      .subscribe((params) => {
        const { foundationSlug } = params;
        if (foundationSlug) {
          // NEW
          this.projectNewService.setSelectedFoundationBySlug(foundationSlug);
          return;
        }

        // TODO: revisit this
        this.projectNewService.clearSelectedFoundation();
      });
  }

  public applyFilterPartially(filters: InsightsFilters) {
    const currentState = {
      ...this.currentFilter,
      ...filters
    };

    if (filters.dateFilters || filters.compare) {
      const periods = getCurrentPreviousPeriodDateRanges(
        currentState.dateFilters || APP_CONFIG.DATE_RANGE_DEFAULT,
        currentState.compare || 'PP'
      );
      currentState.periods = periods;

      currentState.granularity = this.calculateGranularity(currentState);
    }
    this.filter$.next(currentState);
    this.currentFilter = currentState;

    this.updateFilterParams();
  }

  public setFiltersToDefault(): void {
    this.currentFilter = {
      dateFilters: APP_CONFIG.DATE_RANGE_DEFAULT,
      foundationSlug: '',
      project: '',
      hideBots: true,
      repositories: ['all'],
      repositoryTags: [],
      compare: 'PP',
      periods: getCurrentPreviousPeriodDateRanges(APP_CONFIG.DATE_RANGE_DEFAULT),
      granularity: GranularityEnum.month
    };
  }

  private calculateGranularity(filters: InsightsFilters): GranularityEnum {
    let granularity = GranularityEnum.week;
    if (Array.isArray(filters.dateFilters)) {
      const diffInDays = dateDiff(filters.dateFilters[1], filters.dateFilters[0], 'days');
      const diffInMonth = dateDiff(filters.dateFilters[1], filters.dateFilters[0], 'months');
      if (diffInDays <= 30) {
        granularity = GranularityEnum.day;
      } else if (diffInMonth <= 6) {
        granularity = GranularityEnum.week;
      } else if (diffInMonth <= 60) {
        granularity = GranularityEnum.month;
      } else {
        granularity = GranularityEnum.year;
      }
    } else {
      switch (filters.dateFilters) {
        // case DateRangesEnum.today:
        //   granularity = GranularityEnum.day;
        //   break;
        case DateRangesEnum.yesterday:
          granularity = GranularityEnum.day;
          break;
        case DateRangesEnum.last7:
          granularity = GranularityEnum.day;
          break;
        case DateRangesEnum.last15:
          granularity = GranularityEnum.day;
          break;
        case DateRangesEnum.last30:
          granularity = GranularityEnum.day;
          break;
        case DateRangesEnum.last60:
        case DateRangesEnum.last90:
        case DateRangesEnum.last180:
        case DateRangesEnum.lastQuarter:
        case DateRangesEnum.last2Quarters:
          granularity = GranularityEnum.week;
          break;
        case DateRangesEnum.thisYear:
        case DateRangesEnum.lastYear:
        case DateRangesEnum.last12Months:
        case DateRangesEnum.last2Years:
        case DateRangesEnum.last3Years:
          granularity = GranularityEnum.month;
          break;
        case DateRangesEnum.last5Years:
          granularity = GranularityEnum.year;
          break;
        case DateRangesEnum.last10Years:
          granularity = GranularityEnum.year;
          break;
        default:
          granularity = GranularityEnum.month;
      }
    }
    return granularity;
  }

  private updateFilterParams() {
    const queryParams = {
      project: this.currentFilter.project,
      repository: this.currentFilter.repositories ? this.currentFilter.repositories[0] : undefined,
      dateFilters: Array.isArray(this.currentFilter.dateFilters)
        ? this.currentFilter.dateFilters.join(' to ')
        : this.currentFilter.dateFilters,
      dateRange:
        this.currentFilter.periods && Array.isArray(this.currentFilter.periods?.currentPeriod)
          ? this.currentFilter.periods.currentPeriod.join(' to ')
          : undefined,
      compare: this.currentFilter.compare,
      granularity: this.currentFilter.granularity,
      hideBots: this.currentFilter.hideBots
    };

    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge' // remove to replace all query params by provided
    });
  }
}
