// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import {
  addToJS,
  dateFromFormatToJS,
  endOfJSDate,
  startOfJSDate,
  subtractToJS
} from '@app/shared/services/date.service';
import { DateRangesEnum } from 'lfx-insights';
import { IDateRange } from '../date-range-filter/date-range-filter.types';
import { InsightsFiltersNew } from '@app/shared/services/filter/filter.types';

export const getDatesFromRangeEnum = (filterDateOption: DateRangesEnum): IDateRange => {
  let currentDate = new Date();
  let startDate = new Date();
  const lastYear = subtractToJS(1, 'year', currentDate);

  switch (filterDateOption) {
    case DateRangesEnum.yesterday:
      currentDate.setDate(currentDate.getDate() - 1);
      break;
    case DateRangesEnum.last7:
      startDate.setDate(currentDate.getDate() - 6);
      break;
    case DateRangesEnum.last15:
      startDate.setDate(currentDate.getDate() - 14);
      break;
    case DateRangesEnum.last30:
      startDate.setDate(currentDate.getDate() - 29);
      break;
    case DateRangesEnum.last60:
      startDate.setDate(currentDate.getDate() - 59);
      break;
    case DateRangesEnum.last90:
      startDate.setDate(currentDate.getDate() - 89);
      break;
    case DateRangesEnum.last180:
      startDate.setDate(currentDate.getDate() - 179);
      break;
    case DateRangesEnum.lastQuarter: {
      const lastQuarter = subtractToJS(1, 'quarter', currentDate);
      startDate = startOfJSDate(lastQuarter, 'quarter');
      currentDate = endOfJSDate(lastQuarter, 'quarter');
      break;
    }
    case DateRangesEnum.last2Quarters:
      startDate = startOfJSDate(subtractToJS(2, 'quarters', currentDate), 'quarter');
      currentDate = endOfJSDate(subtractToJS(1, 'quarter', currentDate), 'quarter');
      break;
    case DateRangesEnum.last12Months:
      startDate = addToJS(1, 'day', subtractToJS(1, 'year', currentDate));
      break;
    case DateRangesEnum.thisYear:
      startDate = startOfJSDate(currentDate, 'year');
      break;
    case DateRangesEnum.lastYear: {
      startDate = startOfJSDate(lastYear, 'year');
      currentDate = endOfJSDate(lastYear, 'year');
      break;
    }
    case DateRangesEnum.last2Years: {
      const last2Year = subtractToJS(2, 'year', currentDate);
      startDate = startOfJSDate(last2Year, 'year');
      currentDate = endOfJSDate(lastYear, 'year');
      break;
    }
    case DateRangesEnum.last3Years: {
      const last3Year = subtractToJS(3, 'year', currentDate);
      startDate = startOfJSDate(last3Year, 'year');
      currentDate = endOfJSDate(lastYear, 'year');
      break;
    }
    case DateRangesEnum.last5Years: {
      const last5Year = subtractToJS(5, 'year', currentDate);
      startDate = startOfJSDate(last5Year, 'year');
      currentDate = endOfJSDate(lastYear, 'year');
      break;
    }
    case DateRangesEnum.last10Years: {
      startDate = subtractToJS(10, 'year', currentDate);
      break;
    }
  }

  return [startDate, currentDate];
};

export const getDateRangeFromFilter = (filter: InsightsFiltersNew): IDateRange => {
  let startDate = new Date();
  let endDate = new Date();

  if (filter.periods?.currentPeriod && filter.periods.currentPeriod.length > 1) {
    startDate = dateFromFormatToJS(filter.periods.currentPeriod[0]);
    endDate = dateFromFormatToJS(filter.periods.currentPeriod[1]);
  }

  return [startDate, endDate];
};

export * as RangeFilterService from './range-filters.service';
