<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<div
  class="fixed w-[225px] h-full side-menu bg-white top-[60px] z-50"
  [@widthIncreaser]="isPopupMenuOpened() ? 'open' : 'closed'"
  (mouseleave)="onMouseLeave()"
  (mouseenter)="onMouseEnter()">
  @if (projectNewService.selectedFoundation()) {
    @if (isFoundation() && isPopupMenuOpened()) {
      <div class="mb-10 px-4 mt-1">
        <lfx-project-search [isCompact]="true"></lfx-project-search>
      </div>
    }

    @for (menu of menus(); track $index) {
      @if (!menu.isHidden && !menu.disabled) {
        @if (menu.subMenu) {
          <ng-container
            [ngTemplateOutlet]="menuItemWithSubTmpl"
            [ngTemplateOutletContext]="{ menu: menu }"></ng-container>
        } @else {
          <ng-container [ngTemplateOutlet]="menuItemTmpl" [ngTemplateOutletContext]="{ menu: menu }"></ng-container>
        }
      }

      @if (menu.disabled) {
        <div [title]="menu.name" class="menu-item disabled">
          <ng-container
            [ngTemplateOutlet]="iconAndNameTmpl"
            [ngTemplateOutletContext]="{ icon: menu.icon, name: menu.name }"></ng-container>
        </div>
      }

      @if (menu.subMenu && isPopupMenuOpened()) {
        <ng-container [ngTemplateOutlet]="subMenuItemTmpl" [ngTemplateOutletContext]="{ menu: menu }"></ng-container>
      }
    }
    <hr />

    <div class="menu-item" [class.iconsOnly]="!isPopupMenuOpened()" (click)="backToGlobal()">
      <ng-container
        [ngTemplateOutlet]="iconAndNameTmpl"
        [ngTemplateOutletContext]="{ icon: ['far', 'globe'], name: 'Global Insights' }"></ng-container>
    </div>
  } @else {
    <lfx-loading></lfx-loading>
  }
</div>

@if (isPopupMenuOpened() && isCollapsed()) {
  <div class="fixed w-full h-full bg-black bg-opacity-20 z-40" @fadeIn></div>
}

<ng-template #menuItemTmpl let-menu="menu">
  <div
    [routerLinkActiveOptions]="{ exact: menu.exact || false }"
    [queryParamsHandling]="'merge'"
    [routerLink]="menu.url"
    [routerLinkActive]="'active'"
    [queryParams]="menu.queryParam"
    [title]="menu.name"
    class="menu-item"
    (isActiveChange)="menuActiveChange($event)"
    [class.iconsOnly]="!isPopupMenuOpened()"
    [class.is-opened]="menu.subMenu && isPopupMenuOpened()">
    <ng-container
      [ngTemplateOutlet]="iconAndNameTmpl"
      [ngTemplateOutletContext]="{ icon: menu.icon, name: menu.name }"></ng-container>
  </div>
</ng-template>

<ng-template #menuItemWithSubTmpl let-menu="menu">
  <div
    (click)="toggleSubMenu(menu.name)"
    [title]="menu.name"
    class="menu-item"
    [class.iconsOnly]="!isPopupMenuOpened()"
    [ngClass]="{ active: menu.isActive }"
    [class.is-opened]="menu.subMenu && !isPopupMenuOpened()">
    <ng-container
      [ngTemplateOutlet]="iconAndNameTmpl"
      [ngTemplateOutletContext]="{ icon: menu.icon, name: menu.name }"></ng-container>
    <fa-icon
      *ngIf="isPopupMenuOpened()"
      [icon]="['far', menu.isSubMenuOpen ? 'angle-up' : 'angle-down']"
      size="sm"></fa-icon>
  </div>
</ng-template>

<ng-template #iconAndNameTmpl let-icon="icon" let-name="name">
  <fa-icon [icon]="icon" size="sm"></fa-icon>
  <span *ngIf="isPopupMenuOpened()">
    {{ name }}
  </span>
</ng-template>

<ng-template #subMenuItemTmpl let-menu="menu">
  <div class="sub-menu" [ngClass]="{ hidden: !menu.isSubMenuOpen }">
    @for (smenu of menu.subMenu; track $index) {
      @if (!smenu.isHidden) {
        <div
          [routerLinkActiveOptions]="{ exact: false }"
          [queryParamsHandling]="'merge'"
          [routerLink]="smenu.url"
          [routerLinkActive]="'active'"
          [queryParams]="smenu.queryParam"
          [title]="smenu.name"
          class="menu-item sub-menu-item">
          <span>
            {{ smenu.name }}
          </span>
        </div>
      }
    }
  </div>
</ng-template>
