// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const APP_CONFIG = {
  DATE_FORMATTING: 'MMM d, y',
  DATE_FORMATTING_SHORT: 'yyyy-MM-dd',
  DATE_PIPE_FORMAT: 'MMM dd, yyyy',
  DEFAULT_MIN_DATE: '1/1/1979',
  ALL_TIME_YEARS: 10,
  CLIENT_CACHING: {
    ENABLED: true,
    PERSISTOR: true,
    PERSISTOR_STORAGE: window.localStorage,
    PERSISTOR_COMPRESS: true,
    PERSISTOR_EXPIRATION: 10800000, // 3h
    QUERY_DEFAULT_CONFIG: {
      staleTime: 10800000 // 3h
    }
  },

  PAGINATION_SIZE: 15,

  VELOCITY_AVERAGE_WAIT_TIME:
    'https://docs.linuxfoundation.org/lfx/insights/v3-current/velocity/average-wait-time-for-1st-review',
  // Reports Documentations URLs
  REPORTS_ACTIVITIES_URL: 'https://docs.linuxfoundation.org/lfx/insights/v3-current/reports/activities',
  REPORTS_RETENTION_URL: 'https://docs.linuxfoundation.org/lfx/insights/v3-current/reports/retention',
  REPORTS_ORG_REPORT_URL: 'https://docs.linuxfoundation.org/lfx/insights/v3-current/reports/organizations-reports',
  REPORTS_CONTRIBUTORS_REPORT_URL:
    'https://docs.linuxfoundation.org/lfx/insights/v3-current/reports/contributors-reports',
  MAILING_LISTS_MAILING_LISTS:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/mailing-lists/mailing-lists',
  MAILING_LISTS_NEW_CONTRIBUTORS:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/mailing-lists/new-contributors',
  MAILING_LISTS_MOST_ACTIVE_CONTRIBUTORS:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/mailing-lists/most-active-contributors',
  MAILING_LISTS_NEW_ORGANIZATIONS:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/mailing-lists/new-organizations',
  MAILING_LISTS_MOST_ACTIVE_ORGANIZATIONS:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/mailing-lists/most-active-organizations',
  MAILING_LISTS_GEOGRAPHICAL_DISTRIBUTIONS:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/mailing-lists/geographical-distributions',
  MAILING_LISTS_TOP_MAILING_LISTS:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/mailing-lists/top-mailing-lists',
  MAILING_LISTS_POPULAR_THREADS:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/mailing-lists/popular-threads',
  MAILING_LISTS_RECENT_MESSAGES:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/mailing-lists/recent-messages',
  CONFLUENCE_CONT_LEADERBOARD:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/data-integrations/confluence/contributor-leaderboard',
  CONFLUENCE_ORG_LEADERBOARD:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/data-integrations/confluence/organization-leaderboard',
  CONFLUENCE_MOST_POPULAR_PAGE:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/data-integrations/confluence/most-popular-pages',
  CONFLUENCE_ACTIVITIES_BY_DAY:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/data-integrations/confluence/activities-by-day-of-the-week',
  CONFLUENCE_ACTIVITIES_BREAKDOWN:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/data-integrations/confluence/activity-breakdown',
  // TODO: need to update these as we've broken them down into 2 separate components
  CONFLUENCE_NEW_CONTRIBUTORS:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/data-integrations/confluence/new-contributors',
  CONFLUENCE_DRIFT_CONTRIBUTORS:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/data-integrations/confluence/drifting-away-contributors',
  CONFLUENCE_NEW_ORGANIZATIONS:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/data-integrations/confluence/new-organizations',
  CONFLUENCE_DRIFT_ORGANIZATIONS:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/data-integrations/confluence/drifting-away-organizations',
  CONFLUENCE_GEOGRAPHICAL_DISTRIBUTIONS:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/data-integrations/confluence/geographical-distribution',
  OVERVIEW_WORK_TIME_DISTRIBUTION:
    'https://docs.linuxfoundation.org/lfx/insights/v3-current/overview-page/work-time-distribution',
  PRODUCTIVITY_COMMITS_PER_ACTIVE_DAY:
    'https://docs.linuxfoundation.org/lfx/insights/v3-current/productivity/commits-per-active-day',
  PROJECTS_PAGE_SIZE: 4,
  BP_CHECKS_REF_LINK:
    'https://docs.linuxfoundation.org/lfx/insights/v3-beta-version-current/project-overview-page/github/best-practice-score/checks',
  BP_CHECKS_REF_STR: 'Checks reference documentation',
  MAILING_GROUPS_ALL_STR: 'All Lists',
  DATE_RANGE_DEFAULT: 'Last 12 Months',
  GRANULARITY_DEFAULT: 'month',
  ALLOWED_SLUGS: ['sonicfund', 'tlf', 'cdf', 'ena'],
  SUPPORT_TICKET_LINK: 'https://jira.linuxfoundation.org/plugins/servlet/desk/portal/4?requestGroup=54'
};
