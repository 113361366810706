// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  effect,
  inject,
  input,
  signal,
  untracked
} from '@angular/core';
import { FilterService } from '@app/shared/services/filter.service';
import { ScrollService } from '@app/shared/services/scroll.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateRangesEnum } from 'lfx-insights';
import { DateRange as DateRangeCube } from '@cubejs-client/core';
import { formatDate } from '@app/shared/services/date.service';
import { IDateFilterValues } from '../date-range-filter/date-range-filter.types';
import { getDateRangeFromFilter, getDatesFromRangeEnum } from '../range-filters/range-filters.service';
import { ProjectNewService } from '@app/shared/services/project-new.service';
import { FirstLetterCapitalcasePipe } from '@shared/pipes/first-letter-capitalcase.pipe';
import { ErrorComponent } from '../error/error.component';
import { LoadingComponent } from '../loading/loading.component';
import { DateRangeFilterComponent } from '../date-range-filter/date-range-filter.component';
import { ProjectRepoFilterComponent } from '../project-repo-filter/project-repo-filter.component';
import { WarningComponent } from '../warning/warning.component';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { TooltipTriggerDirective } from '../../directives/tooltip-trigger.directive';
import { NgIf, NgClass } from '@angular/common';
import { FilterNewService } from '@app/shared/services/filter/filter-new.service';
import { InsightsFiltersNew } from '@app/shared/services/filter/filter.types';

export enum FilterContainer {
  projectPages = 'projectPages', // overview, velocity, productivity, bestPracticeScoreLanding
  reportsPages = 'reportsPages',
  foundationOverview = 'foundationOverview',
  foundationProjects = 'foundationProjects',
  mailingList = 'mailingList',
  confluence = 'confluence',
  projectHealth = 'projectHealth'
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'lfx-top-filters',
  templateUrl: './top-filters.component.html',
  styleUrls: ['./top-filters.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TooltipTriggerDirective,
    TooltipComponent,
    WarningComponent,
    NgClass,
    ProjectRepoFilterComponent,
    DateRangeFilterComponent,
    LoadingComponent,
    ErrorComponent,
    FirstLetterCapitalcasePipe
  ]
})
export class TopFiltersComponent implements OnInit {
  @ViewChild('topFilterComponent', { static: false }) public topFilterComponent!: ElementRef<HTMLElement>;
  @ViewChild('topFilterContainer', { static: false }) public topFilterContainer!: ElementRef<HTMLElement>;

  @Input() public tooltipTemplate?: TemplateRef<HTMLElement>;

  @Output() public readonly filterDateSelectedChange = new EventEmitter<string>();
  @Output() public readonly filterHideBotsChange = new EventEmitter<boolean>();

  isOverviewPage = input(false);
  filterContainer = input<FilterContainer>(FilterContainer.foundationOverview);
  pageTitle = input.required<string>();
  isFoundationPage = input(false);
  hideDateFilter = input(false);
  useCompactDateFilter = input(false);

  dateRangeFilter = signal<IDateFilterValues>({
    rangeEnum: DateRangesEnum.thisYear,
    dateRange: getDatesFromRangeEnum(DateRangesEnum.thisYear),
    hideBots: true,
    compare: 'PP'
  });
  componentFixed = signal(false);

  public isFoundationProjectsPage = false;

  public prevUrl: string;
  public isError = false;

  public isOnboarded = true;

  private filterNewService: FilterNewService = inject(FilterNewService);

  constructor(
    public projectNewService: ProjectNewService,
    private scrollService: ScrollService,
    private filterService: FilterService
  ) {
    effect(
      () => {
        const currentFilter = this.filterNewService.filters();
        untracked(() => {
          this.updateDateRangeFilter(currentFilter);
        });
      },
      { allowSignalWrites: true }
    );
  }

  public ngOnInit() {
    this.isFoundationProjectsPage = this.isFoundationPage() && this.pageTitle() === 'Projects';
    this.isError = false;

    // this.subscribeToFilterChanges();
    this.subscribeToScroll();
  }

  public onDateFilterChange(val: IDateFilterValues) {
    this.dateRangeFilter.set(val);

    let dateFilters: DateRangesEnum | DateRangeCube = val.rangeEnum;

    if (val.rangeEnum === DateRangesEnum.custom && val.dateRange.length > 0) {
      const [start, end] = val.dateRange;
      dateFilters = [formatDate(start), formatDate(end || start)];
    }

    // TODO: remove OLD
    this.filterService.applyFilterPartially({
      dateFilters,
      hideBots: val.hideBots,
      compare: val.compare
    });

    // NEW
    this.filterNewService.applyFilterPartially({
      dateFilters: val.rangeEnum,
      hideBots: val.hideBots,
      compare: val.compare
    });
  }

  public getOnboardingTooltip() {
    return this.filterContainer() === FilterContainer.foundationOverview ||
      this.filterContainer() === FilterContainer.foundationProjects
      ? 'Data for this foundation is being onboarded…'
      : 'Data for this project is being onboarded…';
  }

  private subscribeToScroll() {
    this.scrollService
      .getOffsetY(window, 30)
      .pipe(untilDestroyed(this))
      .subscribe((pos) => {
        const topFiltersHeight = this.topFilterContainer.nativeElement.offsetHeight || 0;
        if (pos?.[1] > 5 && document.body.scrollHeight - document.body.clientHeight > topFiltersHeight) {
          this.topFilterComponent.nativeElement.classList.add('top-filters--fixed');
          this.componentFixed.set(true);
        } else {
          this.componentFixed.set(false);
          this.topFilterComponent.nativeElement.classList.remove('top-filters--fixed');
        }
      });
  }

  private updateDateRangeFilter(selectedFilter: InsightsFiltersNew) {
    const valuesFromService: IDateFilterValues = { ...this.dateRangeFilter() };

    valuesFromService.rangeEnum = selectedFilter.dateFilters;
    valuesFromService.dateRange =
      valuesFromService.rangeEnum === DateRangesEnum.custom
        ? getDateRangeFromFilter(selectedFilter)
        : getDatesFromRangeEnum(valuesFromService.rangeEnum);

    valuesFromService.hideBots = selectedFilter.hideBots ? true : false;
    valuesFromService.compare = selectedFilter.compare || valuesFromService.compare;

    this.dateRangeFilter.set(valuesFromService);
  }
}
