<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->
<div class="lfx-calendar-range-wrap">
  <p-calendar
    class="max-w-full"
    [ariaLabel]="dateRange().join(' to ')"
    [inline]="true"
    selectionMode="range"
    [showOtherMonths]="false"
    [defaultDate]="fromViewDate()"
    [readonlyInput]="true"
    [minDate]="minDate()"
    styleClass="lfx-calendar"
    (onSelect)="onDateSelect($event)"
    #fromCal>
    <ng-template pTemplate="date" let-date>
      <ng-container *ngTemplateOutlet="dateTmpl; context: { date: date }"></ng-container>
    </ng-template>
  </p-calendar>
  <p-calendar
    class="max-w-full"
    [ariaLabel]="dateRange().join(' to ')"
    [inline]="true"
    selectionMode="range"
    [showOtherMonths]="false"
    [defaultDate]="toViewDate()"
    [readonlyInput]="true"
    [minDate]="minDate()"
    styleClass="lfx-calendar"
    (onSelect)="onDateSelect($event)"
    #toCal>
    <ng-template pTemplate="date" let-date>
      <ng-container *ngTemplateOutlet="dateTmpl; context: { date: date }"></ng-container>
    </ng-template>
  </p-calendar>
</div>

<ng-template let-date="date" #dateTmpl>
  <span
    class="display-date"
    [class.start-date]="getTime(date.day, date.month, date.year) === fromDate()"
    [class.end-date]="getTime(date.day, date.month, date.year) === toDate()"
    >{{ date.day }}</span
  >
</ng-template>
