// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { PlatformsFilterLeaderboard } from '@app/shared/interface/common.enum';
import { InsightsFiltersNew } from '@app/shared/services/filter/filter.types';
import { getTimeQueryParams } from '@app/shared/utils/page-helpers';
import { InsightProject } from 'lfx-insights';

export interface IBusFactorQueryParam {
  fields?: string;
  segmentId: string;
  project: string;
  repository: string | null;
  timeRangeName: string;
  activityType: string;
  asc: boolean;
  limit: number;
  offset: number;
  ctr?: string;
  dateFrom?: string;
  dateTo?: string;
  orderBy: string;
  platforms: PlatformsFilterLeaderboard;
}

export const contributorDependencyFields = [
  'ROW_NUMBER',
  'CNT',
  'PREV_CNT',
  'DELTA',
  'PERCENT',
  'CUMULATIVE_PERCENT',
  'CHANGE_FROM_PREVIOUS',
  'BUS_FACTOR',
  'MIN_PERCENT',
  'OTHERS_COUNT',
  'OTHERS_PERCENT',
  'SEGMENT_ID',
  'MEMBER_ID',
  'MEMBER_DISPLAY_NAME',
  'JOINED_AT',
  'LOGO_URL',
  'COUNTRY'
];

export const orgDependencyFields = [
  'ROW_NUMBER',
  'CNT',
  'PREV_CNT',
  'DELTA',
  'PERCENT',
  'CUMULATIVE_PERCENT',
  'CHANGE_FROM_PREVIOUS',
  'BUS_FACTOR',
  'MIN_PERCENT',
  'OTHERS_COUNT',
  'OTHERS_PERCENT',
  'ORG_ID',
  'ORG_NAME',
  'ORG_LOGO_URL',
  'ORG_CREATED_AT'
];

export const getDependencyQuery = (
  fields: string[],
  filter: InsightsFiltersNew,
  activityType: string,
  limit: number,
  offset: number,
  isGerritProject: boolean,
  project: InsightProject
): IBusFactorQueryParam => {
  const { timeRangeName, dateFrom, dateTo } = getTimeQueryParams(filter);

  const tmp: IBusFactorQueryParam = {
    fields: fields.join(','),
    segmentId: project.id,
    project: filter?.project || '',
    repository: filter.repositories?.[0] === 'all' ? null : filter.repositories?.[0] || '',
    timeRangeName: timeRangeName === 'custom' ? '' : timeRangeName,
    platforms: isGerritProject ? PlatformsFilterLeaderboard.gitGerrit : PlatformsFilterLeaderboard.gitGithub,
    activityType,
    asc: true,
    limit,
    offset,
    orderBy: 'row_number'
  };

  if (timeRangeName === 'custom') {
    tmp.ctr = 'hll';
    tmp.dateFrom = dateFrom;
    tmp.dateTo = dateTo;
  }

  return tmp;
};
