// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { ChangeDetectionStrategy, Component, EventEmitter, Output, input } from '@angular/core';
import { InsightProject } from 'lfx-insights';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';
import { ProjectSourcesIconsComponent } from '../project-sources-icons/project-sources-icons.component';

@Component({
  selector: 'lfx-card-head-buttons',
  templateUrl: './card-head-buttons.component.html',
  styleUrls: ['./card-head-buttons.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, FaIconComponent, ProjectSourcesIconsComponent]
})
export class CardHeadButtonsComponent {
  @Output() public readonly downloadClicked = new EventEmitter<void>();

  project = input.required<InsightProject>();
  isOnboarding = input(false);

  public downloadCard() {
    this.downloadClicked.emit();
  }
}
