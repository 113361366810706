<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->
<div class="h-full w-full relative">
  <div class="loading-wrap" *ngIf="isLoading">
    <lfx-loading></lfx-loading>
  </div>
  <div
    *ngIf="annotations && config.annotationSettings && !noDataFound"
    [ngClass]="[config.annotationSettings.horizontalAlign, config.annotationSettings.verticalAlign]"
    class="absolute flex flex-col w-[190px]"
    [innerHTML]="getAnnotationHTML(config.annotationSettings.template, annotations)"></div>
  <div *ngIf="!isLoading" class="flex flex-col h-full w-full">
    <div [id]="chartName" [ngClass]="chartName" class="flex-grow w-full"></div>
    <div class="legend-wrap" *ngIf="config?.showLegend && !noDataFound">
      <span
        [ngClass]="{ 'cursor-default': config.series.length === 1 , 'cursor-pointer': config.series.length > 1}"
        class="legend-item"
        (click)="onToggleSeries(index)"
        *ngFor="let se of config.series; let index = index"
        [appLfxTooltipTrigger]="'bar-chart-legend' + chartName + '' + index"
        [class.turned-off]="!isVisible(index)">
        <span class="legend-icon" [class.line-legend]="se.plotAsLineChart">
          <span class="circle" [style]="{ borderColor: se.color, backgroundColor: se.color }"></span>
          <span class="line" [style]="{ borderColor: se.color }" *ngIf="se.plotAsLineChart"></span>
        </span>
        {{ se.name }}
        <lfx-tooltip
          *ngIf="se.legendTooltipText"
          [id]="'bar-chart-legend' + chartName + '' + index"
          [text]="se.legendTooltipText"
          [config]="{
            show: 'top',
            positionValue: '-160%',
            horizontalAlign: '-45%',
            width: 'fit-content',
            textWrap: 'white-space:nowrap'
          }"></lfx-tooltip>
      </span>
    </div>
  </div>
</div>
