import { transition, trigger, animate, style, state } from '@angular/animations';

/* reusable functions can be used in trigger */

export const fadeIn = trigger('fadeIn', [
  transition(':enter', [style({ opacity: 0 }), animate('500ms ease-out', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('500ms ease-out', style({ opacity: 0 }))])
]);

export const fadeInRight = trigger('fadeInRight', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(-40px)' }),
    animate('500ms ease-in', style({ opacity: 1, transform: 'translateX(0px)' }))
  ])
]);

export const widthIncreaser = trigger('widthIncreaser', [
  state('open', style({ minWidth: '*', width: '*' })),
  state('closed', style({ minWidth: '70px', width: '70px' })),
  transition('open => closed', animate('.25s')),
  transition('closed => open', animate('.25s'))
]);
