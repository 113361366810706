// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProgressBarConfig } from '@app/shared/interface/common';
import { getBestPracticeColor } from '@app/shared/utils/page-helpers';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'lfx-best-practices-details',
    templateUrl: './best-practices-details.component.html',
    styleUrls: ['./best-practices-details.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, ProgressBarComponent]
})
export class BestPracticesDetailsComponent implements OnInit {
  @Input() public value: number = 0;
  @Input() public label: string = '';
  @Input() public progressBarStyle: string = 'thin';
  @Input() public size: 'lg' | 'md' | 'sm' = 'lg';
  @Input() public showDetails: boolean = false;
  @Input() public hideToggle: boolean = false;
  @Input() public icon: string = '';
  @Output() public readonly toggleMetricEmitter = new EventEmitter();

  constructor() {}

  public ngOnInit(): void {}

  public toggleMetric() {
    this.toggleMetricEmitter.emit();
  }

  public getColor() {
    return getBestPracticeColor(this.value);
  }

  public getConfig(): ProgressBarConfig {
    return {
      color: this.getColor(),
      rounded: true,
      background: 'gray',
      class: 'progress-bar-col',
      height: this.size === 'lg' ? 'h-[5px]' : this.size === 'md' ? 'h-[9px]' : undefined
    };
  }

  public displayedValue() {
    if (this.value > 100) {
      return 100;
    } else if (this.value < 0) {
      return 0;
    }

    return this.value;
  }
}
