// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { BffConstants } from '@services/api/bff.constants';

@Injectable({
  providedIn: 'root'
})
export class BffService {
  private httpClient = inject(HttpClient);
  private activatedRoute = inject(ActivatedRoute);

  public loadData<T>(
    apiURL: BffConstants,
    query: Record<string, any>,
    options: { signal?: AbortSignal; [key: string]: any } = {},
    isMocked: boolean = false
  ) {
    const url = isMocked ? 'http://localhost:3004/api' : `${environment.bffURL + apiURL}`;
    return this.callDataByGet<T>(url, query, options);
  }

  private async getWithSignal<T>(url: string, options: { signal?: AbortSignal; [key: string]: any } = {}): Promise<T> {
    const { signal, ...httpOptions } = options;
    const promise: Promise<T> = firstValueFrom(this.httpClient.get<T>(url, httpOptions));

    if (signal) {
      return new Promise((resolve, reject) => {
        signal.addEventListener('abort', () => reject(new DOMException('Aborted', 'AbortError')));
        promise.then(resolve).catch(reject);
      });
    }

    return promise;
  }

  private buildSearchParams(query: Record<string, any>): URLSearchParams {
    const searchParams = new URLSearchParams();
    Object.entries(query).forEach(([key, value]) => {
      if (value && Array.isArray(value)) {
        (value as string[]).forEach((vals) => {
          searchParams.append(`${key}[]`, vals);
        });
      } else {
        searchParams.append(key, value?.toString() || '');
      }
    });

    const queryParameters = this.activatedRoute.snapshot.queryParamMap;
    if (queryParameters.has('cache-bypass') && queryParameters.get('cache-bypass') === 'true') {
      searchParams.append('cache-bypass', 'true');
    }
    return searchParams;
  }

  private callDataByGet<T>(
    url: string,
    query: Record<string, any>,
    options: { signal?: AbortSignal; [key: string]: any } = {}
  ): Promise<T> {
    const searchParams = this.buildSearchParams(query);
    const fullUrl = `${url}?${searchParams.toString()}`;
    return this.getWithSignal(fullUrl, options);
  }
}
