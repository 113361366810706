// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { ICacheKey, InjectInfiniteQuery, InjectQuery } from '@services/api/api.types';
import { BFFServiceBasic } from '../bff.basic';
import { BffService } from '../../bff.service';
import { BffConstants } from '../bff.constants';
import {
  buildAllFoundationsQuery,
  buildProjectsQuery,
  FilterParam
} from '@app/shared/cubejs/metrics/foundations/queries';
import { ActivatedRoute } from '@angular/router';
import { inject } from '@angular/core';
import { buildRepositoryBySlugQuery } from '@app/shared/queries/projects/projects';

export interface IFoundationCard {
  SLUG: string;
  SUBPROJECT_SLUG: string;
  PROJECT_SLUG: string;
  PROJECT_GROUP_SLUG: string;
  GITHUB_ORG: string;
  CONTRIBUTORS_COUNT: number;
  PRS_COUNT: number;
  CSETS_COUNT: number;
  COMMITS_COUNT: number;
  ISSUES_COUNT: number;
  STARS_COUNT: number;
  FORKS_COUNT: number;
  DESCENDANTS_COUNT: number;
  ORGANIZATIONS_COUNT: number;
  LINES_CODE: number;
  NAME: string;
  GRANDPARENT_NAME: string;
  SEGMENT_ID: string;
  DESCRIPTION: string;
  URL: string;
  PROJECT_TYPE: string;
  ONBOARDING_STATUS: string;
  LOGO: string;
  REPOSITORY_URL: string;
  START_DATE_AS_TIMESTAMP: string;
  LAST_ACTIVITY: string;
  PLATFORMS: string[];
  MATURITY_LEVEL: string | null;
}

export interface IProjects {
  PROJECT_ID: string;
  SEGMENT_ID: string;
  NAME: string;
  DESCRIPTION: string;
  LOGO: string;
  SLUG: string;
  REPOSITORY_URL: string;
  PROJECT_TYPE: string;
  ADMIN_CATEGORY: string | null;
  ONBOARDING_STATUS: string;
  WEBSITE: string | null;
  MATURITY_LEVEL: string | null;
  START_DATE_AS_TIMESTAMP: string;
  PLATFORMS: string[];
  PROJECT_SLUG: string;
  PROJECT_GROUP_SLUG: string;
}

export interface IRepositories {
  REPOSITORY_URL: string;
  PLATFORM: string;
}

export class FoundationBFFApiClass extends BFFServiceBasic {
  protected useQuery: InjectQuery;
  protected useInfiniteQuery;

  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private cacheByPass =
    (this.activatedRoute.snapshot.queryParamMap.has('cache-bypass') &&
      this.activatedRoute.snapshot.queryParamMap.get('cache-bypass') === 'true') ||
    undefined;

  constructor(bffService: BffService, useQuery: InjectQuery, useInfiniteQuery: InjectInfiniteQuery) {
    super(bffService);
    this.useQuery = useQuery;
    this.useInfiniteQuery = useInfiniteQuery;
  }

  public getAllFoundationCardsPaginate(limit: number, startingOffset: number) {
    return this.useInfiniteQuery({
      queryKey: [ICacheKey.GET_ALL_FOUNDATION_CARDS, startingOffset, limit, this.cacheByPass] as const,
      queryFn: ({ pageParam }) => {
        const query = buildAllFoundationsQuery(limit, pageParam + startingOffset, this.cacheByPass);
        return this.loadData<IFoundationCard[]>(BffConstants.GLOBAL_CARDS, query);
      },
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.length * limit;
        return lastPage.length === limit ? nextOffset : undefined;
      }
    });
  }

  public getAllFoundationCards(
    limit: number,
    startingOffset: number,
    typeFilter?: FilterParam,
    additionalFilter?: FilterParam
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_ALL_FOUNDATION_CARDS,
        startingOffset,
        limit,
        this.cacheByPass,
        typeFilter,
        additionalFilter
      ] as const,
      queryFn: () => {
        const query = buildAllFoundationsQuery(limit, startingOffset, this.cacheByPass, typeFilter, additionalFilter);
        return this.loadData<IFoundationCard[]>(BffConstants.GLOBAL_CARDS, query);
      }
    });
  }

  // Note: we don't need pagination here as this is required to fetch all project for the drop downs and the autocomplete search
  public getAllProjects(typeFilter?: FilterParam, additionalFilter?: FilterParam) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_ALL_PROJECTS, this.cacheByPass, typeFilter, additionalFilter] as const,
      queryFn: () => {
        const query = buildProjectsQuery(this.cacheByPass, typeFilter, additionalFilter);
        return this.loadData<IProjects[]>(BffConstants.GLOBAL_PROJECTS, query);
      }
    });
  }

  public getFoundationAndProjectsBySlug(slug: string, additionalFilter?: FilterParam) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_FOUNDATION_AND_PROJECTS, this.cacheByPass, slug, additionalFilter] as const,
      queryFn: () => {
        const query = buildProjectsQuery(
          this.cacheByPass,
          { column: 'project_group_slug', values: [slug] },
          additionalFilter
        );
        return this.loadData<IProjects[]>(BffConstants.GLOBAL_PROJECTS, query);
      }
    });
  }

  public getRepositoriesBySlug(slug: string | undefined) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_REPOSITORIES_BY_SLUG, this.cacheByPass, slug] as const,
      queryFn: () => {
        const query = buildRepositoryBySlugQuery(slug || '', this.cacheByPass);
        return this.loadData<IRepositories[]>(BffConstants.GLOBAL_REPOSITORIES, query);
      },
      enabled: !!slug
    });
  }
}
