// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { map } from 'rxjs';
import { PivotConfig, Query } from '@cubejs-client/core';

import { CubeServiceBasic } from '@services/api/basic';
import { InsightsFilters } from '@services/filter.service';
import { ICacheKey, InjectQuery } from '@services/api/api.types';
import { CubeService } from '../../cube.service';
import { IActivitiesCumulativeTimestamp } from './ActivitiesApiClass';

export interface ITotalOrganizations extends IActivitiesCumulativeTimestamp, INewOrgTimestamp {
  ['SnowActivitiesCumulative.cumulative_organizations']?: number;
  ['SnowNewOrganizations.count_organizations_cumulative']?: number;
}

interface INewOrgTimestamp {
  ['SnowNewOrganizations.first_activity']?: string;
  ['SnowNewOrganizations.first_activity.day']?: string;
  ['SnowNewOrganizations.first_activity.week']?: string;
  ['SnowNewOrganizations.first_activity.month']?: string;
  ['SnowNewOrganizations.first_activity.quarter']?: string;
  ['SnowNewOrganizations.first_activity.year']?: string;
}

export interface IActivitiesOrgCountSummary {
  ['SnowActivities.count_organizations']?: number;
  ['SnowNewOrganizations.count_organizations']?: number;
}

export interface IActivitiesOrgReportDetails {
  ['SnowOrganizations.logo']?: string;
  ['SnowOrganizations.name']?: string;
  ['SnowNewOrganizations.organization_name']?: string;
  ['SnowNewOrganizations.organization_logo']?: string;
}

export interface IOrgLeaderboardMostActive {
  ['SnowOrganizations.id']?: string;
  ['SnowOrganizations.name']?: string;
  ['SnowOrganizations.logo']?: string;
  ['SnowActivities.count_active_days']?: string;
  ['SnowActivities.metric_contributions']?: string;

  ['SnowNewOrganizations.organization_id']?: string;
  ['SnowNewOrganizations.organization_name']?: string;
  ['SnowNewOrganizations.organization_logo']?: string;
  ['SnowNewOrganizations.total_active_days']?: string;
  ['SnowNewOrganizations.total_contributions']?: string;
}

export interface IOrgLeaderboardMostActiveTotal {
  ['SnowActivities.metric_contributions']?: string;
  ['SnowActivities.count_organizations']?: string;
  ['SnowNewOrganizations.count_organizations']?: string;
  ['SnowNewOrganizations.total_contributions']?: string;
}
export interface INewOrgReport extends INewOrgTimestamp {
  ['SnowNewOrganizations.count_organizations']: string;
}

export interface IActiveOrgReport {
  ['SnowContributions.count_organization']: string;
  ['SnowContributions.timestamp']?: string;
  ['SnowContributions.timestamp']?: string;
  ['SnowContributions.timestamp.day']?: string;
  ['SnowContributions.timestamp.week']?: string;
  ['SnowContributions.timestamp.month']?: string;
}

export interface IReturningOrgReport {
  ['SnowReturningOrganizations.count_organizations']: string;
  ['SnowReturningOrganizations.timestamp']?: string;
  ['SnowReturningOrganizations.timestamp.day']?: string;
  ['SnowReturningOrganizations.timestamp.week']?: string;
  ['SnowReturningOrganizations.timestamp.month']?: string;
}

export class OrganizationsApiClass extends CubeServiceBasic {
  protected useQuery: InjectQuery;
  constructor(cubejs: CubeService, useQuery: InjectQuery) {
    super(cubejs);
    this.useQuery = useQuery;
  }

  public getTotalActiveOrganizations(
    query: Query | Query[],
    filters: InsightsFilters,
    platform: string[] | undefined,
    newOrgsOnly: boolean
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_TOTAL_ACTIVE_ORGANIZATIONS,
        filters.project,
        filters.repositories,
        filters.hideBots,
        Array.isArray(query) ? query[0].timeDimensions : query.timeDimensions,
        platform,
        newOrgsOnly
      ],
      queryFn: () => this.load$<ITotalOrganizations>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getActivitiesOrgCountReport(
    query: Query,
    projectId: string | undefined,
    repositories: string[] | undefined,
    platform: string[],
    newOrgsOnly: boolean
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_ACTIVITIES_ORG_COUNT_REPORT,
        projectId,
        repositories,
        query.timeDimensions,
        platform,
        newOrgsOnly
      ],
      queryFn: () => this.load$<IActivitiesOrgCountSummary>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getActivitiesOrgReportDetails(
    query: Query,
    projectId: string | undefined,
    repositories: string[] | undefined,
    platform: string[],
    newOrgsOnly: boolean,
    offset?: number
  ) {
    return this.useQuery({
      queryKey: [
        'getActivitiesOrgReportDetails',
        projectId,
        repositories,
        query.timeDimensions,
        platform,
        newOrgsOnly,
        offset
      ],
      queryFn: () => this.load$<IActivitiesOrgReportDetails>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getOrgLeaderboardMostActive(
    query: Query,
    filters: InsightsFilters,
    platform: string[],
    newOrgsOnly: boolean,
    offset?: number
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_ORG_LEADERBOARD_MOST_ACTIVE,
        filters.project,
        filters.repositories,
        filters.hideBots,
        platform.join(','),
        newOrgsOnly,
        offset === undefined ? 'all' : offset,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IOrgLeaderboardMostActive>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getNewOrgsReport(
    query: Query,
    projectId: string | undefined,
    repositories: string[] | undefined,
    platform: string[],
    newOrgsOnly: boolean
  ) {
    const pivotConfig: PivotConfig = {
      fillMissingDates: true
    };

    return this.useQuery({
      queryKey: ['getNewOrgsReport', projectId, repositories, query.timeDimensions, platform, newOrgsOnly],
      queryFn: () => this.load$<INewOrgReport>(query).pipe(map((res) => this.toTable(res, pivotConfig)))
    });
  }

  public getOrgLeaderboardMostActiveTotal(
    query: Query,
    filters: InsightsFilters,
    platform: string[],
    newOrgsOnly: boolean
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_ORG_LEADERBOARD_MOST_ACTIVE_TOTAL,
        filters.project,
        filters.repositories,
        filters.hideBots,
        platform.join(','),
        newOrgsOnly,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IOrgLeaderboardMostActiveTotal>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getActiveOrgsReport(
    query: Query,
    projectId: string | undefined,
    repositories: string[] | undefined,
    platform: string[],
    newOrgsOnly: boolean
  ) {
    const pivotConfig: PivotConfig = {
      fillMissingDates: true
    };

    return this.useQuery({
      queryKey: ['getActiveOrgsReport', projectId, repositories, query.timeDimensions, platform, newOrgsOnly],
      queryFn: () => this.load$<IActiveOrgReport>(query).pipe(map((res) => this.toTable(res, pivotConfig)))
    });
  }

  public getReturningOrgsReport(
    query: Query,
    projectId: string | undefined,
    repositories: string[] | undefined,
    platform: string[],
    newOrgsOnly: boolean
  ) {
    const pivotConfig: PivotConfig = {
      fillMissingDates: true
    };

    return this.useQuery({
      queryKey: ['getReturningOrgsReport', projectId, repositories, query.timeDimensions, platform, newOrgsOnly],
      queryFn: () => this.load$<IReturningOrgReport>(query).pipe(map((res) => this.toTable(res, pivotConfig)))
    });
  }

}
