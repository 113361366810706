<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<div class="flex flex-row gap-4 items-center">
  <div class="flex flex-row cursor-pointer gap-1 items-center">
    <div class="icon-wrap">
      <fa-icon [icon]="['far', 'ellipsis-v']" size="lg"></fa-icon>
      <fa-icon [icon]="['far', 'ellipsis-v']" size="lg"></fa-icon>
      <fa-icon [icon]="['far', 'ellipsis-v']" size="lg"></fa-icon>
    </div>
    <div class="text-tiny">
      Tools
      <fa-icon [icon]="['fas', 'caret-down']" size="sm"></fa-icon>
    </div>
  </div>
  <div>
    <fa-icon class="single-icons" [icon]="['fal', 'question-circle']" size="2x"></fa-icon>
  </div>
  <div>
    <fa-icon class="single-icons" [icon]="['fal', 'user-circle']" size="2x"></fa-icon>
  </div>
</div>
