// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { InsightsFiltersNew } from '@app/shared/services/filter/filter.types';
import { getRepositoryFilterValue } from '@app/shared/cubejs/helpers/utils';

export const getDriftingAwayContributorsQuery = (
  segmentId: string,
  filter: InsightsFiltersNew,
  limit: number,
  offset: number
) => {
  return {
    fields: 'ALL_MEMBERS,MEMBER_ID,MEMBER_DISPLAY_NAME,MEMBER_LOGO_URL,CONTRIBUTIONS,LAST_ACTIVITY_AT',
    segmentId,
    repository: getRepositoryFilterValue(filter.repositories),
    orderBy: 'contributions',
    asc: false,
    filterBots: filter.hideBots ? 1 : 0,
    limit,
    offset
  };
};

export const getNewContributorsQuery = (
  segmentId: string,
  filter: InsightsFiltersNew,
  limit: number,
  offset: number
) => {
  return {
    fields: 'ALL_MEMBERS,MEMBER_ID,MEMBER_DISPLAY_NAME,MEMBER_LOGO_URL,CONTRIBUTIONS,PREV_MEMBERS',
    segmentId,
    repository: getRepositoryFilterValue(filter.repositories),
    dateFrom: filter.periods?.currentPeriod[0],
    dateTo: filter.periods?.currentPeriod[1],
    orderBy: 'contributions',
    filterBots: filter.hideBots ? 1 : 0,
    asc: false,
    limit,
    offset
  };
};

export const getEngagementGapQuery = (segmentId: string, filter: InsightsFiltersNew) => {
  return {
    segmentId,
    repository: getRepositoryFilterValue(filter.repositories),
    dateFrom: filter.periods?.currentPeriod[0],
    dateTo: filter.periods?.currentPeriod[1],
    granularity: filter.granularity,
    orderBy: 'timestamp',
    asc: false,
    limit: 0,
    offset: 0
  };
};
