// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input, OnInit } from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';

@Component({
    selector: 'lfx-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    standalone: true,
    imports: [NgClass, NgStyle]
})
export class ErrorComponent implements OnInit {
  @Input() public minHeight: number | undefined;
  @Input() public isCompact = false;

  public ngOnInit(): void {
    if (!this.minHeight) {
      this.minHeight = 50;
    }
  }
}
