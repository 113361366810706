/* eslint-disable @typescript-eslint/prefer-for-of */
// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import { ShortNumberPipe } from '../pipes/short-number.pipe';
import { openVisualizationInPlayground } from '../cubejs/helpers/utils';

type IReplacements = { replClass: string; elem: HTMLElement };
const handleStatusIcons = (element: HTMLElement) => {
  const icons = element.getElementsByClassName('status-icon');
  for (let i = 0; i < icons.length; i++) {
    const icon = icons[i] as HTMLElement;
    if (icon.children && icon.children.length) {
      (icon.children[0] as HTMLElement).style.marginTop = '13px';
    }
  }
};

const handleLegendIcons = (element: HTMLElement) => {
  const legendIcons = element.getElementsByClassName('legend-icon');
  for (let i = 0; i < legendIcons.length; i++) {
    const legendIcon = legendIcons[i] as HTMLElement;
    legendIcon.style.marginTop = '15px';
  }
};

const handleTables = (element: HTMLElement) => {
  const tables = element.getElementsByClassName('table-component-container');
  for (let i = 0; i < tables.length; i++) {
    const table = tables[i] as HTMLElement;
    table.style.display = 'flex';
  }
};

const handleCustomStyles = (element: HTMLElement) => {
  const elementStyles = element.querySelectorAll('[data-style]');
  // doing it this way gives more flexibility
  for (let i = 0; i < elementStyles.length; i++) {
    const elementStyle = elementStyles[i] as HTMLElement;
    const styleAsString = elementStyle.attributes.getNamedItem('data-style');

    if (styleAsString) {
      const styleObj = JSON.parse(styleAsString.value);

      Object.keys(styleObj).forEach((key) => {
        (elementStyle.style as any)[key] = styleObj[key];
      });
    }
  }
};

const handleRadarAnnotation = (element: HTMLElement) => {
  const radarAnnotate = element.querySelectorAll('.radar-annotation');
  if (radarAnnotate) {
    radarAnnotate.forEach((ra) => {
      (ra as HTMLElement).style.marginTop = '-10px';
    });
  }
};

const handleAvatars = (element: HTMLElement) => {
  const avatarContainer = element.querySelectorAll('.p-avatar.p-component');

  // override for ngx-avatar text
  for (let i = 0; i < avatarContainer.length; i++) {
    const avatarElem = avatarContainer[i] as HTMLElement;
    if (avatarElem) {
      avatarElem.style.display = 'flex';
      avatarElem.style.justifyContent = 'center';
      avatarElem.style.alignItems = 'flex-start';
      avatarElem.style.lineHeight = '16px';
      avatarElem.style.height = '32px';
    }
  }
};

const handleReplacements = (element: HTMLElement, replacements?: IReplacements | null) => {
  if (replacements) {
    // element replacements
    const rpl = element.querySelector(replacements.replClass);

    if (rpl) {
      rpl.replaceWith(replacements.elem);
    }
  }
};

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  private readToDownloadCSVMap = new Map<string, { cardName: string; data: any }>();
  constructor(private shortNumber: ShortNumberPipe) {}

  public download(
    elementRef: string | HTMLElement,
    title: string,
    scale: number = 2,
    parentDownload = true,
    replacements?: IReplacements | null,
    height?: number | null
  ) {
    let visualization;
    if (typeof elementRef === 'string') {
      visualization = document.getElementById(elementRef);
    } else {
      visualization = parentDownload ? elementRef.parentElement : elementRef;
    }

    if (!visualization) {
      return;
    }

    html2canvas(visualization as HTMLElement, {
      ...(height ? { height } : {}),
      scrollY: window.pageYOffset * -1 + 80,
      scrollX: window.pageXOffset * -1,
      useCORS: true,
      allowTaint: true,
      scale,
      imageTimeout: 0,
      onclone(_, element) {
        handleStatusIcons(element);
        handleLegendIcons(element);
        handleTables(element);
        handleCustomStyles(element);
        handleRadarAnnotation(element);
        handleAvatars(element);
        handleReplacements(element, replacements);
      }
    }).then((_canvas) => {
      this.saveAs(_canvas.toDataURL(), title + '.png');
    });
  }

  public svgToPng(elem: HTMLElement, height: number, width: number, scale: number = 1): Promise<HTMLCanvasElement> {
    return html2canvas(elem, {
      useCORS: true,
      scale,
      imageTimeout: 0,
      windowHeight: height,
      windowWidth: width
    });
  }

  public downloadCSV(title: string, data: any, skipShortNum?: boolean) {
    this.saveAs(
      'data:text/csv;charset=utf-8,' + encodeURIComponent(this.generateCSVFile(data, skipShortNum)),
      title + '.csv'
    );
  }

  public openVisualizationInPlayground(cardName: string) {
    openVisualizationInPlayground(cardName);
  }

  public updateReadToDownload(cardName: string, data: any) {
    this.readToDownloadCSVMap.set(cardName, { cardName, data });
  }

  public getReadToDownloadCard(cardName: string) {
    return this.readToDownloadCSVMap.get(cardName);
  }

  private generateCSVFile(data: any[], skipShortNum?: boolean) {
    let csvContent = '';
    csvContent += this.getCSVHeaders(data[0]);
    data.forEach((e) => {
      csvContent += this.mapObjectValueForCSV(e, undefined, skipShortNum);
    });
    return csvContent;
  }

  private getCSVHeaders(obj: any, withoutEndLine?: boolean, headerName?: string) {
    let csvHeader = '';
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'object') {
          csvHeader += this.getCSVHeaders(obj[key], true, key);
        } else {
          csvHeader += this.useCamelCase(headerName ? headerName + '_' + key : key) + ',';
        }
      }
    }
    return csvHeader + (withoutEndLine ? '' : '\n');
  }

  private useCamelCase(text: string) {
    return text
      .split('_')
      .map((i) => i.charAt(0).toUpperCase() + i.slice(1))
      .join(' ');
  }

  private mapObjectValueForCSV(obj: any, withoutEndLine?: boolean, skipShortNum?: boolean): string {
    return (
      Object.values(obj)
        .map((i: any) => {
          if (typeof i === 'string') {
            return `"${i.replace(/"/g, "'")}"`;
          }
          if (typeof i === 'number' && !skipShortNum) {
            return `${this.shortNumber.transform(i)}`;
          }
          if (i !== null && typeof i === 'object') {
            return this.mapObjectValueForCSV(i, true);
          }
          return `"${i}"`;
        })
        .join(',') + (withoutEndLine ? '' : '\n')
    );
  }

  private saveAs(uri: string, filename: string) {
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = uri;
      link.download = filename;
      // Firefox requires the link to be in the body
      document.body.appendChild(link);
      // simulate click
      link.click();
      // remove the link when done
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  }
}
