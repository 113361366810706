// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, OnInit, computed, input } from '@angular/core';
import { InsightProject } from 'lfx-insights';
import { PlatformEnum } from '@app/shared/interface/common.enum';
import { LoadingComponent } from '../loading/loading.component';
import { KeyMetricRowComponent } from '../key-metric-row/key-metric-row.component';
import { NgIf } from '@angular/common';
import { isPlatformEnabled } from '@app/shared/services/project-utils.service';

@Component({
  selector: 'lfx-project-metric-details',
  templateUrl: './project-metric-details.component.html',
  styleUrls: ['./project-metric-details.component.scss'],
  standalone: true,
  imports: [NgIf, KeyMetricRowComponent, LoadingComponent]
})
export class ProjectMetricDetailsComponent implements OnInit {
  public project = input.required<InsightProject>();

  public hasGithubSource = computed(() => isPlatformEnabled(this.project(), PlatformEnum.github));

  constructor() {}

  public ngOnInit(): void {}
}
