<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->
<div class="legends-wrap relative">
  <div *ngIf="topRegions && topRegions.length" class="font-roboto text-lg uppercase pb-[1.2rem] laptop:text-base">Top 5 Regions</div>
  <div class="top-region-item mb-[0.9rem]" *ngFor="let item of topRegions">
    <div class="bar-wrap">
      <div class="legend-pecent data-pt-1">{{ item.value }}%</div>
      <div class="bar" [style]="{ width: getWidth(item.value) }"></div>
    </div>
    <div class="text-gray text-tiny">{{ item.name }}</div>
  </div>
</div>
