// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { ChangeDetectionStrategy, Component, ElementRef, input } from '@angular/core';
import { Router } from '@angular/router';
import { DownloadService } from '@app/shared/services/download.service';
import { InsightProject } from 'lfx-insights';
import { FilterService } from '@app/shared/services/filter.service';
import { getProjectOverviewRoutedFrom, getProjectOverviewUrl } from '@app/shared/services/project-utils.service';
import { ProjectNewService } from '@app/shared/services/project-new.service';
import { CardFooterComponent } from '../card-footer/card-footer.component';
import { CardLogoComponent } from '../card-logo/card-logo.component';
import { CardHeadButtonsComponent } from '../card-head-buttons/card-head-buttons.component';
import { NgTemplateOutlet, NgIf } from '@angular/common';
import { FoundationCardDataComponent } from '../foundation-card-data/foundation-card-data.component';

@Component({
  selector: 'lfx-foundation-card',
  templateUrl: './foundation-card.component.html',
  styleUrls: ['./foundation-card.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgTemplateOutlet,
    CardHeadButtonsComponent,
    CardLogoComponent,
    CardFooterComponent,
    NgIf,
    FoundationCardDataComponent
  ]
})
export class FoundationCardComponent {
  public foundation = input.required<InsightProject>();
  public isOnboarding = input(false);

  constructor(
    private downloadService: DownloadService,
    private elementRef: ElementRef,
    private router: Router,
    private projectNewService: ProjectNewService,
    private filterService: FilterService
  ) {}

  public downloadCard(): void {
    this.downloadService.download(this.elementRef.nativeElement, this.foundation().name + '-card', 2, false);
  }

  public onCardNavigate(): void {
    if (this.foundation().numberOfProjects === 1) {
      if (this.foundation().projects) {
        const foundationSlug = this.foundation().projects![0].foundationSlug;
        const projectSlug = this.foundation().projects![0].slug;

        this.filterService.applyFilterPartially({
          ...this.filterService.defaultFilters,
          project: projectSlug,
          foundationSlug
        });

        this.projectNewService.setSelectedProjectSlug(projectSlug);

        this.router.navigate([`${getProjectOverviewUrl(this.foundation().projects![0], this.foundation().slug)}`], {
          queryParams: {
            project: projectSlug,
            routedFrom: getProjectOverviewRoutedFrom(this.foundation().projects![0])
          },
          queryParamsHandling: 'merge'
        });
      }
    } else {
      this.router.navigate(['/foundation/', this.foundation().slug], { queryParamsHandling: 'merge' });
    }
  }
}
