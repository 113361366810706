// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { map } from 'rxjs';
import { Query } from '@cubejs-client/core';

import { CubeServiceBasic } from '@services/api/basic';
import { ICacheKey, ICompareBase, InjectQuery } from '@services/api/api.types';
import { GranularityEnum } from 'lfx-insights';
import { InsightsFilters } from '@services/filter.service';
import { CubeFilters } from '@app/shared/cubejs/helpers/utils';
import { CubeService } from '../../cube.service';

export interface IActivitiesContributorCount extends ICompareBase {
  ['SnowActivities.metric_contributors']: string;
}

export interface IActivitiesContributorCountSummary {
  ['SnowActivities.metric_contributors']?: number;
  ['SnowContributorFirstContributions.count_contributors']?: number;
  ['SnowContributorFirstContributions.count_contributors']?: number;
}

export interface IActivitiesContributorReportDetails {
  ['SnowMembers.logo_url']?: string;
  ['SnowActivities.username']?: string;
  ['SnowContributorFirstContributions.username']?: string;
  ['SnowContributorFirstContributions.logo_url']?: string;
  ['SnowContributorFirstContributions.username']?: string;
  ['SnowContributorFirstContributions.logo_url']?: string;
}

export interface IContLeaderboardMostActiveTotal extends ICompareBase {
  ['SnowActivities.metric_contributions']?: string;
  ['SnowActivities.metric_identities']?: string;
  ['SnowContributorFirstContributions.count_contributors']?: string;
  ['SnowContributorFirstContributions.count_contributions']?: string;

  ['SnowContributorFirstContributions.count_contributors']?: string;
  ['SnowContributorFirstContributions.count_contributions']?: string;
}

export interface IContLeaderboardMostActive extends ICompareBase {
  ['SnowActivities.username']?: string;
  ['SnowMembers.logo_url']?: string;
  ['SnowActivities.metric_contributions']?: string;
  ['SnowActivities.metric_identities']?: string;
  ['SnowActivities.count_active_days']?: string;

  ['SnowContributorFirstContributions.member_id']?: string;
  ['SnowContributorFirstContributions.username']?: string;
  ['SnowContributorFirstContributions.logo_url']?: string;
  ['SnowContributorFirstContributions.total_active_days']?: string;
  ['SnowContributorFirstContributions.total_contributions']?: string;

  ['SnowContributorFirstContributions.member_id']?: string;
  ['SnowContributorFirstContributions.username']?: string;
  ['SnowContributorFirstContributions.logo_url']?: string;
  ['SnowContributorFirstContributions.total_active_days']?: string;
  ['SnowContributorFirstContributions.total_contributions']?: string;
}

export interface IContributorCountry extends ICompareBase {
  ['SnowActivities.metric_contributors']?: string;
  ['SnowMembers.contributor_country']: string;
  ['SnowGerritActivities.metric_contributors']?: string;
}

export interface IContributorsTotal extends ICompareBase {
  ['SnowActivitiesCumulative.cumulative_members']: string;
  ['SnowActivitiesCumulative.timestamp']: string;
  ['SnowActivitiesCumulative.timestamp.day']?: string;
  ['SnowActivitiesCumulative.timestamp.week']?: string;
  ['SnowActivitiesCumulative.timestamp.month']?: string;
  ['SnowActivitiesCumulative.timestamp.quarter']?: string;
  ['SnowActivitiesCumulative.timestamp.year']?: string;
}

export interface IFirstContributions extends ICompareBase {
  ['SnowContributorFirstContributions.count_contributors_cumulative']: string;
  ['SnowContributorFirstContributions.first_activity']: string;
  ['SnowContributorFirstContributions.first_activity.day']?: string;
  ['SnowContributorFirstContributions.first_activity.week']?: string;
  ['SnowContributorFirstContributions.first_activity.month']?: string;
  ['SnowContributorFirstContributions.first_activity.quarter']?: string;
  ['SnowContributorFirstContributions.first_activity.year']?: string;
}

export interface IActiveSnowContributors {
  ['SnowContributions.timestamp']?: string;
  ['SnowContributions.timestamp.week']?: string;
  ['SnowContributions.timestamp.month']?: string;
  ['SnowContributions.count_contributors']: string;
}

export interface IReturningSnowContributors {
  ['SnowContributorsFirstActivity.activity_date']?: string;
  ['SnowContributorsFirstActivity.activity_date.week']?: string;
  ['SnowContributorsFirstActivity.activity_date.month']?: string;
  ['SnowContributorsFirstActivity.count_contributors']: string;
}

export interface INewSnowContributors {
  ['SnowContributorFirstContributions.count_contributors']: string;
  ['SnowContributorFirstContributions.first_activity']?: string;
  ['SnowContributorFirstContributions.first_activity.week']?: string;
  ['SnowContributorFirstContributions.first_activity.month']?: string;
}

export class ContributorsApiClass extends CubeServiceBasic {
  protected useQuery: InjectQuery;
  constructor(cubejs: CubeService, useQuery: InjectQuery) {
    super(cubejs);
    this.useQuery = useQuery;
  }

  public getContributorsCountry(query: Query, filters: InsightsFilters, isMocked = false) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_CONTRIBUTORS_COUNTRY,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ] as const,
      queryFn: () => this.load$<IContributorCountry>(query, isMocked).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getContributorsTotal(query: Query | Query[], filters: CubeFilters, platform: string[]) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_CONTRIBUTORS_TOTAL,
        filters.projectId,
        filters.repositoryUrl,
        filters.hideBots,
        platform,
        Array.isArray(query) ? query[0].timeDimensions : query.timeDimensions
      ] as const,
      queryFn: () => this.load$<IContributorsTotal>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  // cannot use toTableCompare here because of the way the new contributors are queried
  public getNewContributorsTotal(queries: Query[], filters: CubeFilters, platform: string[]) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_NEW_CONTRIBUTORS_TOTAL,
        filters.projectId,
        filters.repositoryUrl,
        filters.hideBots,
        platform,
        queries[0].timeDimensions
      ] as const,
      queryFn: () =>
        this.load$<[IFirstContributions[], IFirstContributions[]]>(queries).pipe(map((res) => this.toTableMulti(res)))
    });
  }

  public getActiveContributors(
    queries: Query[],
    filters: InsightsFilters,
    platform: string[],
    granularity: GranularityEnum | undefined,
    useNewContributors: boolean
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_ACTIVE_CONTRIBUTORS,
        filters.project,
        filters.repositories,
        filters.hideBots,
        platform,
        granularity,
        useNewContributors,
        queries[0].timeDimensions
      ] as const,
      queryFn: () =>
        this.load$<[IActiveSnowContributors[] | INewSnowContributors[], IReturningSnowContributors[]]>(queries).pipe(
          map((res) => this.toTableMulti(res))
        )
    });
  }

  public getActivitiesContributorCountReport(
    query: Query,
    filters: InsightsFilters,
    platform: string[],
    newContribsOnly: boolean
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_ACTIVITIES_CONTRIBUTOR_COUNT_REPORT,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions,
        platform,
        newContribsOnly
      ] as const,
      queryFn: () => this.load$<IActivitiesContributorCountSummary>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getActivitiesContributorReportDetails(
    query: Query,
    projectId: string | undefined,
    repositories: string[] | undefined,
    platform: string[],
    newContribsOnly: boolean
  ) {
    return this.useQuery({
      queryKey: [
        'getActivitiesContributorReportDetails',
        projectId,
        repositories,
        query.timeDimensions,
        platform,
        newContribsOnly
      ] as const,
      queryFn: () => this.load$<IActivitiesContributorReportDetails>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getContLeaderboardMostActiveTotal(
    query: Query,
    filters: InsightsFilters,
    platform: string[],
    newContribsOnly: boolean
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_CONT_LEADERBOARD_MOST_ACTIVE_TOTAL,
        filters.project,
        filters.repositories,
        filters.hideBots,
        platform.join(','),
        newContribsOnly,
        query.timeDimensions
      ] as const,
      queryFn: () => this.load$<IContLeaderboardMostActiveTotal>(query).pipe(map((res) => this.toTable(res)))
    });
  }

  public getContLeaderboardMostActive(
    query: Query,
    filters: InsightsFilters,
    platform: string[],
    newContribsOnly: boolean,
    offset?: number
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_CONT_LEADERBOARD_MOST_ACTIVE,
        filters.project,
        filters.repositories,
        filters.hideBots,
        platform.join(','),
        newContribsOnly,
        offset === undefined ? 'all' : offset,
        query.timeDimensions
      ] as const,
      queryFn: () => this.load$<IContLeaderboardMostActive>(query).pipe(map((res) => this.toTable(res)))
    });
  }
}
