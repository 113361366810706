/* eslint-disable @typescript-eslint/naming-convention */
// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Injectable } from '@angular/core';
import { BestPracticeDetail, BestPracticeScores, MetricInfo } from 'lfx-insights';
import {
  bestPracticeScore,
  bestPracticeScoreAllQuery,
  bestPracticeScoreRepoDetail
} from '../cubejs/metrics/best-practices/BestPracticesScores';
import { QueryObserverResult } from '@tanstack/query-core';
import { ApiService } from './api/Api.service';
import { IBestPracticeReport, IBestPracticeScore } from './api/partials/BestPracticeScoreApiClass';
import { ToTable } from './api/basic';
import { InsightsFiltersNew } from './filter/filter.types';
import { metricDetails } from '../utils/metric-details';
import { map } from 'lodash';

// All BP score related call should be done here instead.
export type IBPTableRes = ToTable<IBestPracticeScore, keyof IBestPracticeScore>;
export type IBPScoreDataResp = QueryObserverResult<IBPTableRes, unknown>;

export type IBPReportData = ToTable<IBestPracticeReport, keyof IBestPracticeReport>;
export type IBPReportResp = QueryObserverResult<IBPReportData, unknown>;

export interface IMetricReportFlags {
  exempt: boolean;
  failed: boolean;
  passed: boolean;
  url?: string;
  exemption_reason?: string;
}
export interface IMetricReport {
  [key: string]: IMetricReportFlags;
}

@Injectable({
  providedIn: 'root'
})
export class BestPracticeScoreService {
  constructor(private apiService: ApiService) {}

  public getBPScoreData(projectSlugs: string[]) {
    return this.apiService.bestPracticeScore.getBestPracticeScoresAll(
      bestPracticeScoreAllQuery(projectSlugs),
      projectSlugs
    ).result$;
  }

  public getBPScoreDataByRepo(repositories: string[]) {
    return this.apiService.bestPracticeScore.getBestPracticeScores(bestPracticeScore(repositories), repositories)
      .result$;
  }

  public getBestPracticeScoreRepoDetail(filter: InsightsFiltersNew) {
    const projectSlug = filter.project || '';
    const repositories =
      filter.repositories?.length === 1 && filter.repositories[0] === 'all' ? [] : filter.repositories;
    return this.apiService.bestPracticeScore.getBestPracticeScoreRepoDetail(
      bestPracticeScoreRepoDetail(projectSlug, repositories, true),
      projectSlug,
      repositories || [],
      true
    ).result$;
  }

  public checkNoBPData(data?: BestPracticeScores) {
    return (
      !data ||
      (data.bestPracticesScore === 0 &&
        data.documentationScore === 0 &&
        data.legalScore === 0 &&
        data.licenseScore === 0 &&
        data.securityScore === 0)
    );
  }

  public bestPracticeScoreMapAll(rd: IBestPracticeScore[], projectSlugs?: string[]): BestPracticeScores[] {
    return projectSlugs
      ? this.fillMissingData(rd, projectSlugs).map((r) => this.bestPracticeScoreMap(r))
      : rd.map(this.bestPracticeScoreMap);
  }

  public bestPracticeScoreMap(rd: IBestPracticeScore): BestPracticeScores {
    return {
      globalScore: +rd['SnowRepositoryReport.globalScore'],
      documentationScore: +rd['SnowRepositoryReport.documentationScore'],
      licenseScore: +rd['SnowRepositoryReport.licenseScore'],
      bestPracticesScore: +rd['SnowRepositoryReport.bestPracticesScore'],
      securityScore: +rd['SnowRepositoryReport.securityScore'],
      legalScore: +rd['SnowRepositoryReport.legalScore'],
      lastUpdate: new Date(rd['SnowRepositoryReport.lastupdate']).getTime(),
      lastCloUpdate: new Date(rd['SnowRepositoryReport.last_clo_update']).getTime(),
      repository: rd['SnowRepositoryReport.repository'],
      projectSlug: rd['SnowRepositoryReport.projectSlug']
    };
  }

  public bestPracticeReportMap(reports: IBestPracticeReport[]): BestPracticeDetail[] {
    return metricDetails.map((md) => {
      const mdTmp = { ...md };
      // pull all the reports for this key metric (ex: documentation)
      const reportsObj = this.getReportsObjArr(reports, md.key);

      mdTmp.metricInfo = this.mapMetricInfo(mdTmp, reportsObj);

      return mdTmp;
    });
  }

  private getReportsObjArr(reports: IBestPracticeReport[], key: string): IMetricReport[] {
    const reportGroupObj = map(reports, `SnowRepositoryReport.${key}Report`);

    return reportGroupObj.map((r) => (typeof r === 'string' ? JSON.parse(r) : r));
  }

  /**
   * This function maps the metricInfo to the reportsObj
   * Pass, fail or exempt status is determined by the following logic:
   * - If the exempt flag is set to true, the status is exempt
   * - If the exempt flag is set to false, then check if the failed flag is set to true
   * - If the failed flag is set to true, the status is failed
   * - else the status is passed
   */
  private mapMetricInfo(md: BestPracticeDetail, reportsObj: IMetricReport[]): MetricInfo[] {
    // next we loop throught all the metricInfo keys and find their status in the reportsObj
    return md.metricInfo.map((mi) => {
      const miTmp = { ...mi };
      const reportByKey = map(reportsObj, mi.key);

      if (miTmp.type === 'normal') {
        const url = reportByKey.filter((flags) => flags?.url);
        if (url && url.length > 0) {
          miTmp.docUrl = url[0].url || '';
        }

        const isNotExempt = reportByKey.some((flags) => !flags?.exempt);
        if (isNotExempt && !this.isAllFalse(reportByKey, reportsObj.length)) {
          // not exempted
          // checking if there is a failed status
          miTmp.status = reportByKey.some((flags) => flags?.failed) ? 'failed' : 'passed';
        } else {
          // setting the status to 'exempt'
          miTmp.status = 'exempt';
        }
      }

      return miTmp;
    });
  }

  private isAllFalse(reportByKey: (IMetricReportFlags | undefined)[], reportCount: number): boolean {
    return reportByKey.filter((flags) => !flags?.exempt && !flags?.failed && !flags?.passed).length === reportCount;
  }

  // TODO: consider converting this to public
  private fillMissingData(rawBPs: IBestPracticeScore[], projectSlugs: string[]): IBestPracticeScore[] {
    return projectSlugs.map((slug) => {
      const bestScore = rawBPs.find((rawBP) => rawBP['SnowRepositoryReport.projectSlug'] === slug);
      if (bestScore) {
        return bestScore;
      }
      return {
        'SnowRepositoryReport.bestPracticesScore': 0,
        'SnowRepositoryReport.documentationScore': 0,
        'SnowRepositoryReport.globalScore': 0,
        'SnowRepositoryReport.legalScore': 0,
        'SnowRepositoryReport.licenseScore': 0,
        'SnowRepositoryReport.securityScore': 0,
        'SnowRepositoryReport.projectSlug': slug
      } as IBestPracticeScore;
    });
  }
}
