/* eslint-disable no-case-declarations */
// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { DownloadStyleDirective } from '../../directives/download-style.directive';

@Component({
    selector: 'lfx-commentary-icon',
    templateUrl: './commentary-icon.component.html',
    styleUrls: ['./commentary-icon.component.scss'],
    standalone: true,
    imports: [DownloadStyleDirective, FaIconComponent]
})
export class CommentaryIconComponent {
  @Input() public icon: IconDefinition | [IconPrefix, IconName];
  @Input() public classes: string;
}
