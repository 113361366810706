import { Injectable, Signal, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, filter, map } from 'rxjs';
import { environment } from '@environments/environment';
import { AuthService, User } from '@auth0/auth0-angular';

export interface IHeaderElem extends HTMLElement {
  product: string;
  docslink: string;
  supportlink: string;
  links: { title: string; url: string; target: string }[];
  authuser: User | null | undefined;
  callbackurl: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public currentUrl: Signal<string> = toSignal(this.getUrlFromRouter(), { initialValue: '' });
  public isLandingPage = computed(() => !!(this.currentUrl() === '/'));
  public isFoundationPage = computed(() =>
    /^(\/foundation\/[a-zA-Z-_]*|\/foundation\/[a-zA-Z-_]*\/projects)$/g.test(this.currentUrl())
  );
  public isAuthenticated = toSignal(this.authService.isAuthenticated$, { initialValue: false });

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  public loadLFxHeaderScriptAndLinks(cb: (header: IHeaderElem) => void) {
    this.setScript().then(() => {
      const header: IHeaderElem | null = document.getElementById('lfx-header') as IHeaderElem;

      if (header) {
        header.product = 'LF DA';
        header.docslink = 'https://docs.linuxfoundation.org/lfx/insights/v3-current';
        header.supportlink = 'https://jira.linuxfoundation.org/plugins/servlet/desk/portal/4?requestGroup=54';
        header.links = [
          {
            title: 'Enroll Project',
            url: 'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4/create/341',
            target: '_blank'
          }
        ];
        header.callbackurl = window.location.origin;

        cb(header);
      }
    });
  }

  private getUrlFromRouter(): Observable<string> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: any) => {
        const url = event.url;
        return url.includes('?') ? url.slice(0, url.indexOf('?')) : url;
      })
    );
  }

  private async setScript() {
    return await this.injectScript(environment.lfxHeader + '/lfx-header-v2.js');
  }

  private injectScript(src: string): Promise<any> {
    if (this.isScriptLoaded(src)) {
      return Promise.resolve(true);
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');

      script.src = src;
      script.addEventListener('load', resolve);
      script.addEventListener('error', (e) => reject(e.error));
      document.head.appendChild(script);
    });
  }

  private isScriptLoaded(url: string): boolean {
    const scripts = document.getElementsByTagName('script');

    for (let i = scripts.length; i--; ) {
      if (scripts[i].src === url) {
        return true;
      }
    }

    return false;
  }
}
