// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Pipe, PipeTransform } from '@angular/core';
import { APP_CONFIG } from '@app/config';
import { convertToDateAgo, formatDate } from '../services/date.service';

export type IDateDiffs = 'years' | 'months' | 'days' | 'hours';
@Pipe({
    name: 'luxonDate',
    standalone: true
})
export class LuxonDatePipe implements PipeTransform {
  public transform(value: Date | string | number | undefined | null, format?: string, dateDiff?: IDateDiffs): string {
    if (value === undefined || value === null || value === 0) {
      return '';
    }
    return dateDiff
      ? convertToDateAgo(value, dateDiff, format || APP_CONFIG.DATE_PIPE_FORMAT)
      : formatDate(value, format || APP_CONFIG.DATE_PIPE_FORMAT);
  }
}
