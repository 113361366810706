// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { KeyMetricValue } from 'lfx-insights';
import { HoursDaysPipe } from '../../pipes/hours-days.pipe';
import { IntegerSignPipe } from '../../pipes/integer-sign.pipe';
import { ShortNumberPipe } from '../../pipes/short-number.pipe';
import { NgIf } from '@angular/common';

@Component({
    selector: 'lfx-key-metric-row',
    templateUrl: './key-metric-row.component.html',
    styleUrls: ['./key-metric-row.component.scss'],
    standalone: true,
    imports: [NgIf, ShortNumberPipe, IntegerSignPipe, HoursDaysPipe]
})
export class KeyMetricRowComponent {
  @Input() public keyMetric?: KeyMetricValue;
  @Input() public title = '';
  @Input() public type: 'number' | 'date' = 'number';
}
