import { DropdownOptions } from '@app/shared/interface/common';
import { PlatformEnum } from '@app/shared/interface/common.enum';
import { InsightProject, Repositories } from 'lfx-insights';

export const mapProjectsToDropdownOptions = (projects: InsightProject[]): DropdownOptions[] =>
  projects.map((project) => ({ label: project.name, value: project.slug }));

export const getProjectBySlug = (projects: InsightProject[], slug: string): InsightProject | undefined =>
  projects.find((project) => project.slug === slug);

export const mapReposToDropdownOptions = (repos: Repositories[]): DropdownOptions[] =>
  repos.map((repo) => ({ label: repo.name, value: repo.url, extraVal: repo.platform }));

export const addAllItemToRepos = (repos: Repositories[]): Repositories[] => {
  const allItem = { name: 'All', url: 'all', id: '' };
  if (repos.length === 1) {
    return repos;
  }
  return [allItem, ...repos];
};

export const filterReposByPlatform = (
  repos: Repositories[],
  isGerritProject: boolean | undefined,
  isGitOnlyProject: boolean | undefined
): Repositories[] => {
  return (repos = repos.filter((repo) => {
    switch (true) {
      case repo.name.toLowerCase() === 'all':
        return true;
      case isGerritProject:
        return repo.platform === PlatformEnum.gerrit;
      case isGitOnlyProject:
        return repo.platform === PlatformEnum.git;
      default:
        return repo.platform === PlatformEnum.github;
    }
  }));
};

export * from './project-repo-filter.service';
