// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { isPlatformBrowser, NgIf, NgClass } from '@angular/common';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { GranularityEnum, XYBubbleChartConfig } from 'lfx-insights';
import { tooltipObj } from '@app/shared/utils/chart-helpers';
import { minBy, sortBy, uniqBy } from 'lodash';
import { LoadingComponent } from '../loading/loading.component';
@Component({
  selector: 'lfx-xy-bubble-chart',
  templateUrl: './xy-bubble-chart.component.html',
  styleUrls: ['./xy-bubble-chart.component.scss'],
  standalone: true,
  imports: [NgIf, LoadingComponent, NgClass]
})
export class XyBubbleChartComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() public config!: XYBubbleChartConfig;
  @Input() public chartName!: string;
  @Input() public data: any[] = [];

  public isLoading = false;

  private root!: am5.Root;
  private chartRef!: am5xy.XYChart;
  private noDateFoundModal: am5.Modal;
  private customSeriesMap = new Map<string, am5xy.LineSeries>();
  private ellipsesMap = new Map<string, am5.Graphics>();
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private zone: NgZone,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes.data || changes.config)) {
      setTimeout(() => {
        this.initChart();
      }, 200);
    }
  }

  // Run the function only in the browser
  public browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  public ngAfterViewInit() {
    // Chart code goes in here
    this.browserOnly(() => {
      this.initChart();
    });
  }

  public ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  public checkEmptyData(): boolean {
    const check = this.chartRef.series.values.every((se) => {
      const valueYField = (se as any).get('valueYField');
      const valueXField = (se as any).get('valueXField');
      return se.data.values.every((e: any) => !e[valueYField] && !e[valueXField]);
    });
    return check;
  }

  public initChart() {
    if (this.root) {
      this.root.dispose();
    }
    if (this.noDateFoundModal && this.noDateFoundModal.isOpen()) {
      this.noDateFoundModal.close();
    }
    const root = am5.Root.new(this.chartName);

    root.setThemes([am5themesAnimated.new(root)]);

    // remove footer logo
    // eslint-disable-next-line no-underscore-dangle
    root._logo?.children.clear();

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        paddingTop: 5,
        paddingBottom: 0,
        paddingRight: this.config.useMicroTheme ? 0 : 15
      })
    );

    chart.zoomOutButton.set('forceHidden', true);

    const xAxis = this.createXAxis(root, chart);

    const yAxis = this.createYAxis(root, chart);

    const series = this.createSeries(root, chart, xAxis, yAxis);

    // Add bullet
    const circleTemplate: am5.Template<am5.Circle> = am5.Template.new({});
    this.handleBubbleClickEvent(circleTemplate, chart);
    this.addSeriesBullet(root, chart, series, circleTemplate);

    const labelTemplate: am5.Template<am5.Label> = am5.Template.new({});
    if (!this.config.useMicroTheme) {
      this.addBulletLabels(root, series, labelTemplate);
    }
    this.addChartRules(series, circleTemplate, labelTemplate);
    series.strokes.template.set('strokeOpacity', 0);
    this.fixData();
    series.data.setAll(this.data);
    if (this.config.drawEllipse?.groupBy) {
      const groupBy = this.config.drawEllipse.groupBy;
      const uniqValues = uniqBy(this.data, groupBy);
      uniqValues.forEach((element) => {
        if (element[groupBy] && element[groupBy].length) {
          // TEMPORARILY REMOVING THIS
          // const mySeries = this.config.series.find((se) => se.name === element[groupBy]);
          // const color = '#' + mySeries?.color[0];
          // this.handleEllipseDrawing(root, chart, xAxis, yAxis, element[groupBy], color);
        }
      });
      // TODO: check this later, hiding it for now for the demo
      // this.handleLegend(root, chart, uniqValues, groupBy);
    }

    this.chartRef = chart;

    this.root = root;
    // this.checkNoDataFound(this.chartRef);
  }

  public loadData(): void {}

  private createXAxis(root: am5.Root, chart: am5xy.XYChart) {
    const minValue = minBy(this.data, this.config.xAxis.field)[this.config.xAxis.field];
    const minValueLength = minValue.toString().length;
    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        logarithmic: this.config.xAxis.useLogarithmic,
        treatZeroAs: 1,
        extraMin: 0.5,
        extraMax: 0.7,
        maxPrecision: 0,
        min: this.data.length === 1 ? undefined : Math.pow(10, minValueLength - 1),
        renderer: am5xy.AxisRendererX.new(root, {
          fill: am5.color('#807f83'),
          stroke: am5.color('#807f83'),
          minGridDistance: 60,
          forceHidden: this.config.useMicroTheme,
          strokeOpacity: this.config.useMicroTheme ? 0 : 1,
          strokeWidth: this.config.useMicroTheme ? 0 : 1
        })
      })
    );

    xAxis.get('renderer').labels.template.setAll({
      fill: am5.color('#807f83'),
      fontSize: 12,
      textAlign: 'center',
      forceHidden: this.config.useMicroTheme
    });
    if (this.config.xAxis.label) {
      this.addAxisLabel(this.config.xAxis.label, xAxis, root, true);
    }
    return xAxis;
  }

  private createYAxis(root: am5.Root, chart: am5xy.XYChart) {
    const minValue = minBy(this.data, this.config.yAxis.field)[this.config.yAxis.field];
    const minValueLength = minValue.toString().length;
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        logarithmic: this.config.yAxis.useLogarithmic,
        treatZeroAs: 1,
        extraMin: 0.7,
        extraMax: 0.7,
        maxPrecision: 0,
        min: this.data.length === 1 ? undefined : Math.pow(10, minValueLength - 1),
        renderer: am5xy.AxisRendererY.new(root, {
          fill: am5.color('#807f83'),
          stroke: am5.color('#807f83'),
          minGridDistance: 40,
          forceHidden: this.config.useMicroTheme,
          strokeOpacity: this.config.useMicroTheme ? 0 : 1,
          strokeWidth: this.config.useMicroTheme ? 0 : 1
        })
      })
    );

    yAxis.get('renderer').labels.template.setAll({
      fill: am5.color('#807f83'),
      fontSize: 12,
      textAlign: 'center',
      forceHidden: this.config.useMicroTheme
    });

    yAxis.get('renderer').labels.template.adapters.add('text', (value) => {
      if (value && value[0] === '-') {
        return '';
      }
      return value;
    });

    if (this.config.yAxis.label) {
      this.addAxisLabel(this.config.yAxis.label, yAxis, root, false);
    }

    return yAxis;
  }

  private createSeries(
    root: am5.Root,
    chart: am5xy.XYChart,
    xAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
    yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>
  ) {
    return chart.series.push(
      am5xy.LineSeries.new(root, {
        calculateAggregates: true,
        xAxis,
        yAxis,
        valueYField: this.config.yAxis.field,
        valueXField: this.config.xAxis.field,
        valueField: this.config.valueField
      })
    );
  }

  private addSeriesBullet(
    root: am5.Root,
    chart: am5xy.XYChart,
    series: am5xy.LineSeries,
    circleTemplate: am5.Template<am5.Circle>,
    opt?: { color?: string; borderColor?: string; radius?: number }
  ) {
    series.bullets.push((...[, , dataItem]) => {
      let hasPerviousValue = false;
      if (
        (dataItem.dataContext as any).previousValue &&
        Object.getOwnPropertyNames((dataItem.dataContext as any).previousValue).length
      ) {
        hasPerviousValue = true;
      }
      if (opt?.borderColor && hasPerviousValue) {
        return;
      }
      const color = opt?.color ? am5.color(opt.color) : am5.color((dataItem.dataContext as any).color);
      const borderColor = opt?.borderColor ? am5.color(opt.borderColor) : undefined;
      const tooltip = tooltipObj(root, chart, GranularityEnum.week, this.config.customTooltipTextAdapter);
      const graphics = am5.Circle.new(
        root,
        {
          fill: color,
          stroke: borderColor,
          strokeDasharray: borderColor ? 2 : undefined,
          tooltipText: this.config.useMicroTheme ? undefined : '{valueY}',
          cursorOverStyle: hasPerviousValue ? 'pointer' : undefined,
          showTooltipOn: 'hover',
          tooltip,
          radius: opt?.radius
        },
        circleTemplate
      );
      return am5.Bullet.new(root, {
        sprite: graphics
      });
    });
  }

  private addBulletLabels(
    root: am5.Root,
    series: am5xy.LineSeries,
    labelTemplate: am5.Template<am5.Label>,
    opt?: { labelColor?: string; labelFontSize?: number }
  ) {
    series.bullets.push((...[, , dataItem]) => {
      if (opt?.labelColor && (dataItem.dataContext as any).previousValue) {
        return;
      }
      const label = am5.Label.new(
        root,
        {
          text: '{dataContext.displayedName}',
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          populateText: true,
          fontFamily: 'Roboto Slab sans-serif',
          fill: opt?.labelColor ? am5.color(opt.labelColor) : am5.color('#fff'),
          fontSize: opt?.labelFontSize ? opt.labelFontSize : 12
        },
        labelTemplate
      );
      return am5.Bullet.new(root, {
        sprite: label
      });
    });
  }

  private handleLongNames(name: string): string {
    const regex = /^.*[(](.+)[)].*$/gm;
    const res = regex.exec(name);
    if (res) {
      return res[1];
    }
    return name;
  }

  private addChartRules(
    series: am5xy.LineSeries,
    circleTemplate: am5.Template<am5.Circle>,
    labelTemplate: am5.Template<am5.Label>
  ) {
    series.set('heatRules', [
      {
        target: circleTemplate,
        min: this.data.length === 1 ? this.config.maxBubbleSize : this.config.minBubbleSize,
        max: this.config.maxBubbleSize,
        dataField: 'value',
        key: 'radius'
      },
      {
        target: labelTemplate,
        min: 7,
        max: 12,
        dataField: 'value',
        key: 'fontSize'
      }
    ]);
  }

  private handleEllipseDrawing(
    root: am5.Root,
    chart: am5xy.XYChart,
    xAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
    yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
    groupByValue: string,
    borderColor: string
  ) {
    if (!this.config.drawEllipse) {
      return;
    }
    const ellipseSeries = this.createSeries(root, chart, xAxis, yAxis);
    ellipseSeries.strokes.template.set('strokeOpacity', 0);
    const groupByKey = this.config.drawEllipse.groupBy;
    let filteredData = this.data;
    if (groupByKey && groupByValue) {
      filteredData = this.data.filter((item: any) => item[groupByKey] === groupByValue);
    }
    // no need to draw ellipse if we have only one element
    if (filteredData.length === 1) {
      return;
    }
    const ellipseSeriesPoints = this.getEllipseCoordinate(
      this.config.drawEllipse.highPercentage,
      this.config.drawEllipse.lowPercentage,
      filteredData
    );
    ellipseSeries.data.setAll(ellipseSeriesPoints);
    setTimeout(() => {
      this.addEllipse(chart, root, ellipseSeries, groupByValue, borderColor);
      const seriesIndex = chart.series.indexOf(ellipseSeries);
      if (seriesIndex >= 0) {
        chart.series.removeIndex(seriesIndex);
      }
    }, 350);
  }

  private addEllipse(
    chart: am5xy.XYChart,
    root: am5.Root,
    series: am5xy.LineSeries,
    ellipseTitle: string,
    borderColor: string
  ) {
    // Calculate the center and radius of the ellipse based on the series values
    const centerPoint = this.getCenterPointPosition(series.dataItems);
    let radiusX = Math.abs((series.dataItems.at(3)?.get('point')?.x || 0) - centerPoint.x);
    let radiusY = Math.abs((series.dataItems.at(3)?.get('point')?.y || 0) - centerPoint.y);

    if (radiusX <= 0) {
      radiusX = 20;
    }
    if (radiusY <= 0) {
      radiusY = 20;
    }

    // this.addEllipseTitle(root, chart, centerPoint, radiusY, ellipseTitle, borderColor);
    this.drawEllipse(root, chart, { centerPoint, radiusX, radiusY, borderColor, ellipseTitle });
  }

  private addEllipseTitle(
    root: am5.Root,
    chart: am5xy.XYChart,
    centerPoint: { x: number; y: number },
    radiusY: number,
    ellipseTitle: string,
    borderColor: string
  ) {
    // Add title
    chart.children.push(
      am5.Label.new(root, {
        text: ellipseTitle,
        fontSize: 16,
        fill: am5.color('#333333'),
        background: am5.Rectangle.new(root, {
          fill: am5.color('#f9f9f9'),
          fillOpacity: 1,
          stroke: am5.color(borderColor)
        }),
        textAlign: 'center',
        x: centerPoint.x + 95,
        y: centerPoint.y - (radiusY + 40 + ellipseTitle.length) // 40 ==> padding top to add some spaces between ellipse and label
      })
    );
  }

  private drawEllipse(
    root: am5.Root,
    chart: am5xy.XYChart,
    opt: {
      centerPoint: { x: number; y: number };
      radiusX: number;
      radiusY: number;
      borderColor: string;
      ellipseTitle: string;
    }
  ) {
    const ellipse = chart.children.unshift(
      am5.Graphics.new(root, {
        stroke: am5.color(opt.borderColor),
        strokeWidth: 1,
        fill: am5.color(opt.borderColor),
        fillOpacity: 0.5,
        x: opt.centerPoint.x + 95,
        y: opt.centerPoint.y + 5,
        draw: (display) => {
          display.drawEllipse(0, 0, opt.radiusX, opt.radiusY);
        }
      })
    );
    this.ellipsesMap.set(opt.ellipseTitle, ellipse);
  }

  private getCenterPointPosition(seriesDataItems: am5.DataItem<am5xy.ILineSeriesDataItem>[]) {
    const x =
      ((seriesDataItems.at(2)?.get('point')?.x || 0) - (seriesDataItems.at(0)?.get('point')?.x || 0)) / 2 +
      (seriesDataItems.at(0)?.get('point')?.x || 0);
    const y =
      ((seriesDataItems.at(1)?.get('point')?.y || 0) - (seriesDataItems.at(0)?.get('point')?.y || 0)) / 2 +
      (seriesDataItems.at(0)?.get('point')?.y || 0);

    return { x, y };
  }

  private getEllipseCoordinate(highPercentage: number, lowPercentage: number, data: any[]) {
    const xAxisData = data.map((dataItem) => dataItem[this.config.xAxis.field]);
    const yAxisData = data.map((dataItem) => dataItem[this.config.yAxis.field]);
    const x1 = this.percentile(lowPercentage, xAxisData);
    const x2 = this.percentile(highPercentage, xAxisData);
    const y1 = this.percentile(lowPercentage, yAxisData);
    const y2 = this.percentile(highPercentage, yAxisData);

    return [
      {
        [this.config.xAxis.field]: x1,
        [this.config.yAxis.field]: y1,
        [this.config.valueField]: 0
      },
      {
        [this.config.xAxis.field]: x1,
        [this.config.yAxis.field]: y2,
        [this.config.valueField]: 0
      },
      {
        [this.config.xAxis.field]: x2,
        [this.config.yAxis.field]: y1,
        [this.config.valueField]: 0
      },
      {
        [this.config.xAxis.field]: x2,
        [this.config.yAxis.field]: y2,
        [this.config.valueField]: 0
      }
    ];
  }

  private percentile(p: number, list: number[]) {
    const sortedList = sortBy(list);
    const rank = (p / 100) * (list.length - 1);
    const ri = Math.floor(rank);
    const rf = rank - Math.floor(rank);
    if (ri === list.length - 1) {
      return sortedList[ri];
    }
    return sortedList[ri] + rf * (sortedList[ri + 1] - sortedList[ri]);
  }

  private handleBubbleClickEvent(circleTemplate: am5.Template<am5.Circle>, chart: am5xy.XYChart) {
    if (!this.config.bubbleClickHandle) {
      return;
    }

    circleTemplate.events.on('click', (ev) => {
      const series: am5xy.LineSeries = ev.target?.dataItem?.component as any;
      if (ev.target?.dataItem?.component) {
        const currentValue = ev.target?.dataItem?.dataContext as any;
        let hasPerviousValue = false;
        if (
          (ev.target?.dataItem?.dataContext as any).previousValue &&
          Object.getOwnPropertyNames((ev.target?.dataItem?.dataContext as any).previousValue).length
        ) {
          hasPerviousValue = true;
        }
        if (!hasPerviousValue) {
          return;
        }
        const customSeries = this.customSeriesMap.get(currentValue.project);
        if (customSeries) {
          this.customSeriesMap.delete(currentValue.project);
          chart.series.removeIndex(chart.series.indexOf(customSeries));
          chart.zoomOut();
        } else {
          this.addCustomSeries(chart, series, ev);
        }
      }
    });
  }

  private addCustomSeries(
    chart: am5xy.XYChart,
    series: am5xy.LineSeries,
    ev: am5.ISpritePointerEvent & {
      type: 'click';
      target: am5.Circle;
    }
  ) {
    if (ev.target?.dataItem?.component) {
      const currentValue = ev.target?.dataItem?.dataContext as any;
      const previousValue = currentValue.previousValue;
      const newSeries = this.createSeries(
        this.root,
        chart,
        series.get('xAxis') as am5xy.ValueAxis<am5xy.AxisRenderer>,
        series.get('yAxis') as am5xy.ValueAxis<am5xy.AxisRenderer>
      );
      newSeries.set('stroke', am5.color(currentValue.color));
      newSeries.strokes.template.setAll({
        strokeWidth: 3,
        strokeDasharray: 2
      });
      const customCircleTemplate: am5.Template<am5.Circle> = am5.Template.new({});
      this.addSeriesBullet(this.root, chart, newSeries, customCircleTemplate, {
        color: '#ffffff',
        borderColor: currentValue.color,
        radius: ev.target.get('radius')
      });
      customCircleTemplate.events.on('click', () => {
        this.customSeriesMap.delete(currentValue.project);
        chart.series.removeIndex(chart.series.indexOf(newSeries));
      });
      const labelTemplate: am5.Template<am5.Label> = am5.Template.new({});
      const labelFontSize = +((ev.target.dataItem.bullets?.at(1)?.get('sprite') as am5.Label).get('fontSize') || 12);
      this.addBulletLabels(this.root, newSeries, labelTemplate, {
        labelColor: currentValue.color,
        labelFontSize
      });
      newSeries.data.setAll([previousValue, currentValue]);
      this.customSeriesMap.set(currentValue.project, newSeries);
      setTimeout(() => {
        chart.zoomOut();
      }, 5);
    }
  }

  private handleLegend(root: am5.Root, chart: am5xy.XYChart, uniqValues: any[], groupBy: string) {
    const legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: 'name',
        fillField: 'color',
        strokeField: 'color',
        x: am5.percent(5),
        y: am5.percent(100),
        centerY: am5.percent(100)
      })
    );
    legend.itemContainers.template.events.on('pointerover', (ev) => {
      if (!ev.target.dataItem?.dataContext) {
        return;
      }
      const name = (ev.target.dataItem?.dataContext as any).name;
      this.ellipsesMap.forEach((ellipse, ellipseName) => {
        if (ellipseName === name) {
          ellipse.set('fillOpacity', 0.9);
          ellipse.set('strokeOpacity', 0.9);
        } else {
          ellipse.set('fillOpacity', 0.1);
          ellipse.set('strokeOpacity', 0.1);
        }
      });
    });
    legend.itemContainers.template.events.on('pointerout', () => {
      this.ellipsesMap.forEach((ellipse) => {
        ellipse.set('fillOpacity', 0.5);
        ellipse.set('strokeOpacity', 1);
      });
    });

    legend.data.setAll(
      uniqValues
        .map((item) => {
          let name = item[groupBy];
          if (item[groupBy] === null) {
            name = 'Others';
          }
          const mySeries = this.config.series.find((se) => se.name === name);
          return {
            name,
            color: '#' + mySeries?.color[0]
          };
        })
        .filter((e) => e.name !== 'Others')
    );
  }

  private addAxisLabel(label: string, axis: am5xy.ValueAxis<am5xy.AxisRenderer>, root: am5.Root, isXAxis: boolean) {
    if (label) {
      const axisLabel = am5.Label.new(root, {
        rotation: isXAxis ? 0 : -90,
        text: label,
        fontSize: 12,
        fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
        fill: am5.color('#333333'),
        x: isXAxis ? am5.p50 : undefined,
        y: isXAxis ? undefined : am5.p50,
        centerX: am5.p50
      });
      if (isXAxis) {
        axis.children.push(axisLabel);
      } else {
        axis.children.unshift(axisLabel);
      }
    }
  }
  private fixData(): void {
    this.data.forEach((i) => {
      i.displayedName = this.handleLongNames(i.project);
      if (i[this.config.xAxis.field] === 0) {
        i[this.config.xAxis.field] = 1;
      }
      if (i[this.config.yAxis.field] === 0) {
        i[this.config.yAxis.field] = 1;
      }
      if (i.previousValue) {
        if (i.previousValue[this.config.xAxis.field] === 0) {
          i.previousValue[this.config.xAxis.field] = 1;
        }
        if (i.previousValue[this.config.yAxis.field] === 0) {
          i.previousValue[this.config.yAxis.field] = 1;
        }
      }
    });
  }
}
