// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'validVarType',
    standalone: true
})
export class ValidVarTypePipe implements PipeTransform {
  public transform(value: number | string | null | undefined): number | string | 'N/A' {
    if (value === undefined || value === null) {
      return 'N/A';
    }
    return value;
  }
}
