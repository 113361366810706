// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import {
  Directive,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  TemplateRef,
  EmbeddedViewRef,
  ViewContainerRef,
  Renderer2,
  EventEmitter,
  Output,
  ContentChild,
  ChangeDetectorRef,
  Inject,
  PLATFORM_ID
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Directive({
    selector: '[appDefer]',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'p-element'
    },
    standalone: true
})
export class DeferredLoaderDirective implements AfterViewInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public readonly onLoad: EventEmitter<any> = new EventEmitter();

  @ContentChild(TemplateRef) public template: TemplateRef<any>;

  // eslint-disable-next-line @typescript-eslint/ban-types
  public documentScrollListener: Function | null;

  public view: EmbeddedViewRef<any> | null;

  public window: Window;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    public el: ElementRef,
    public renderer: Renderer2,
    public viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef
  ) {
    this.window = this.document.defaultView as Window;
  }

  public ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.shouldLoad()) {
        this.load();
      }

      if (!this.isLoaded()) {
        this.documentScrollListener = this.renderer.listen(this.window, 'scroll', () => {
          if (this.shouldLoad()) {
            this.load();
            if (this.documentScrollListener) {
              this.documentScrollListener();
              this.documentScrollListener = null;
            }
          }
        });
      }
    }
  }

  public shouldLoad(): boolean {
    if (this.isLoaded()) {
      return false;
    }
    const rect = this.el.nativeElement.getBoundingClientRect();
    const docElement = this.document.documentElement;
    const winHeight = docElement.clientHeight;

    return winHeight >= rect.top;
  }

  public load(): void {
    this.view = this.viewContainer.createEmbeddedView(this.template);
    this.onLoad.emit();
    this.cd.detectChanges();
  }

  public isLoaded() {
    return this.view != null && isPlatformBrowser(this.platformId);
  }

  public ngOnDestroy() {
    this.view = null;

    if (this.documentScrollListener) {
      this.documentScrollListener();
    }
  }
}
