import { map } from 'rxjs';
import { Query } from '@cubejs-client/core';

import { CubeServiceBasic } from '@services/api/basic';
import { ICacheKey, InjectQuery } from '@services/api/api.types';
import { InsightsFilters } from '@services/filter.service';
import { CubeService } from '../../cube.service';

export interface IWorkTimeDistribution {
  ['SnowActivities.businessHours']: string;
  ['SnowActivities.weekendHours']: string;
  ['SnowActivities.nonBusinessHours']: string;
}

export class ProductivityContributorsApiClass extends CubeServiceBasic {
  protected useQuery: InjectQuery;
  constructor(cubejs: CubeService, useQuery: InjectQuery) {
    super(cubejs);
    this.useQuery = useQuery;
  }

  public getWorkTimeDistributionHours(query: Query, filters: InsightsFilters) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_WORK_TIME_DISTRIBUTION_HOURS,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IWorkTimeDistribution>(query).pipe(map((res) => this.toTableCompare(res)))
    });
  }
}
