// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { IntegerSignPipe } from '../../../../pipes/integer-sign.pipe';
import { ValidVarTypePipe } from '../../../../pipes/valid-var-type.pipe';
import { ShortNumberPipe } from '../../../../pipes/short-number.pipe';
import { NgClass } from '@angular/common';

@Component({
    selector: 'lfx-table-delta',
    templateUrl: './table-delta.component.html',
    styleUrls: ['./table-delta.component.scss'],
    standalone: true,
    imports: [NgClass, ShortNumberPipe, ValidVarTypePipe, IntegerSignPipe]
})
export class TableDeltaComponent {
  @Input() public delta: number | null | undefined;
}
