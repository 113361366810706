// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { PivotConfig, Query, ResultSet, TableColumn } from '@cubejs-client/core';
import { Observable } from 'rxjs';
import { flattenColumns, getDisplayedColumns } from '@shared/cubejs/helpers/utils';
import { CubeService } from '../cube.service';

export type ToTable<T, K extends keyof T> = {
  tableData: T[];
  displayedColumns: K[];
  columnTitles: string[];
};

export type ToTableCompare<T, K extends keyof T> = {
  previousTableData: T[];
  currentTableData: T[];
  displayedColumns: K[];
  columnTitles: string[];
};

export type ToTableMulti<T, K extends keyof T> = {
  tableData: T;
  displayedColumns: Array<K[]>;
  columnTitles: Array<string[]>;
};

export type ToChartSeries<T> = {
  data: T[];
};

export class CubeServiceBasic {
  private cubejs: CubeService;

  constructor(cubejs: CubeService) {
    this.cubejs = cubejs;
  }

  protected load$<T>(
    query: Query | Query[],
    isMocked: boolean = false,
    filterExclusions?: string[]
  ): Observable<ResultSet<T>> {
    return this.cubejs.cubeQuery$<T>(query, isMocked, filterExclusions);
  }

  protected toTable<T extends object, K extends keyof T>(
    resultSet: ResultSet<T>,
    pivotConfig?: PivotConfig
  ): ToTable<T, K> {
    if (!resultSet) {
      return {
        tableData: [],
        displayedColumns: [],
        columnTitles: []
      };
    }

    const tableData = resultSet.tablePivot(pivotConfig) as T[];
    const displayedColumns = getDisplayedColumns(resultSet.tableColumns(pivotConfig)) as K[];
    const columnTitles = flattenColumns(resultSet.tableColumns(pivotConfig)) as string[];

    return {
      tableData,
      displayedColumns,
      columnTitles
    };
  }

  protected toTableCompare<T extends object, K extends keyof T>(
    resultSet: ResultSet<T>,
    pivotConfig?: PivotConfig
  ): ToTableCompare<T, K> {
    if (!resultSet) {
      return {
        previousTableData: [],
        currentTableData: [],
        displayedColumns: [],
        columnTitles: []
      };
    }

    const resultSets: ResultSet<T>[] = resultSet.decompose();
    // temp disabled due to problem with several charts across project
    // INFO: we need to use first Resultset coz , it will have the universe of data , and 2nd resultset can be empty
    // columns can have children so we need to replace the Parent with all his children columns
    // We can't use  resultSets[1], becuase if for any reason the 2nd resultset is empty,
    // we will have we won't be able to calculate the columns for 1st resultset (Issue for 10y timeseries)
    let tableColumns = resultSets[0].tableColumns(pivotConfig);
    tableColumns = tableColumns
      .map((tc) => {
        if (tc.children) {
          return tc.children.map((c) => ({
            ...c,
            // tables Columns must match the DIM and Measures names
            // for timeseries with custom time rang, Cube returns the time joined with the measure name by `,`
            dataIndex: c.dataIndex.includes(',') ? c.dataIndex.split(',')[1] : c.dataIndex
          }));
        }
        return tc;
      })
      .flat();

    const currentTableData =
      resultSets.length > 0
        ? (resultSets[0].tablePivot(pivotConfig).map((v) => this.mapCompareColumns(v, tableColumns)) as T[])
        : [];
    const previousTableData =
      resultSets.length > 1
        ? (resultSets[1].tablePivot(pivotConfig).map((v) => this.mapCompareColumns(v, tableColumns)) as T[])
        : [];
    const displayedColumns = getDisplayedColumns(tableColumns) as K[];
    const columnTitles = flattenColumns(resultSets[0].tableColumns(pivotConfig)) as string[];
    return {
      currentTableData,
      previousTableData,
      displayedColumns,
      columnTitles
    };
  }

  // Handles multiple query with different structures
  protected toTableMulti<T extends object, K extends keyof T>(
    resultSet: ResultSet<T>,
    pivotConfig?: PivotConfig
  ): ToTableMulti<T, K> {
    if (!resultSet) {
      return {
        tableData: [] as T,
        displayedColumns: [],
        columnTitles: []
      };
    }

    const resultSets: ResultSet<T>[] = resultSet.decompose();
    const tableData: T[] = [];
    const displayedColumns: Array<K[]> = [];
    const columnTitles: Array<string[]> = [];
    resultSets.forEach((r) => {
      tableData.push(r.tablePivot(pivotConfig) as T);
      displayedColumns.push(getDisplayedColumns(r.tableColumns(pivotConfig)) as K[]);
      columnTitles.push(flattenColumns(r.tableColumns(pivotConfig)) as string[]);
    });

    return {
      tableData: tableData as T,
      displayedColumns,
      columnTitles
    };
  }

  protected toChartSeries<T extends object>(resultSet: ResultSet<T>, pivotConfig?: PivotConfig): ToChartSeries<T> {
    if (!resultSet) {
      return {
        data: []
      };
    }

    const resultSeries = resultSet.chartPivot(pivotConfig);

    if (!resultSeries || !resultSeries.length) {
      return {
        data: []
      };
    }

    return {
      data: resultSeries.map((r) => r as T)
    };
  }

  // we have to use any here because cube will return the column names as {daterange},{column-name}
  private mapCompareColumns(v: any, keys: TableColumn[]) {
    const tmp: { [key: string]: number | string | boolean | undefined } = {};

    keys.forEach((tc) => {
      const dataKey = Object.keys(v).find((dk) => dk.indexOf(tc.key) >= 0);
      if (dataKey) {
        tmp[tc.key] = v[dataKey];
      }
    });
    return tmp;
  }
}
