// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { IDependencyTab } from '@app/shared/interface/common';

@Component({
    selector: 'lfx-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
    standalone: true,
    imports: [NgClass]
})
export class TabsComponent {
  @Input() public tabs: IDependencyTab[];
  @Input() public selectedTabIdx: number;
  @Output() public readonly selectedTab = new EventEmitter<number>();

  public onTabClick(idx: number) {
    this.selectedTab.emit(idx);
  }
}
