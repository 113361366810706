// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { ValidVarTypePipe } from '../../../../pipes/valid-var-type.pipe';
import { ShortNumberPipe } from '../../../../pipes/short-number.pipe';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'lfx-table-number-delta',
    templateUrl: './table-number-delta.component.html',
    styleUrls: ['./table-number-delta.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, ShortNumberPipe, ValidVarTypePipe]
})
export class TableNumberDeltaComponent {
  @Input() public value: number | null | undefined;
  @Input() public delta: number | null | undefined;
}
