import {
  getDriftingAwayContributorsQuery,
  getEngagementGapQuery,
  getNewContributorsQuery
} from '@app/shared/queries/productivity/productivity-queries';
import { BFFServiceBasic } from '@services/api/bff.basic';
import { BffService } from '@services/bff.service';
import { ICacheKey, InjectInfiniteQuery, InjectQuery } from '@services/api/api.types';
import { APP_CONFIG } from '@app/config';
import { InsightProject } from 'lfx-insights';
import { InsightsFiltersNew } from '@app/shared/services/filter/filter.types';
import { BffConstants } from '../bff.constants';

export interface IPDriftingAwayContributor {
  MEMBER_ID: string;
  MEMBER_DISPLAY_NAME: string;
  MEMBER_LOGO_URL: string;
  CONTRIBUTIONS: number;
  LAST_ACTIVITY_AT: string;
  ALL_MEMBERS: number;
}

export interface IPNewContributors {
  MEMBER_ID: string;
  MEMBER_DISPLAY_NAME: string;
  MEMBER_LOGO_URL: string;
  CONTRIBUTIONS: number;
  ALL_MEMBERS: number;
  PREV_MEMBERS: number;
}

export interface IPEngagementGap {
  TIMESTAMP: string;
  ENGAGEMENT_GAP: number;
  AVG_ENGAGEMENT_GAP: number;
  PREV_AVG_ENGAGEMENT_GAP: number;
  AVG_ENGAGEMENT_GAP_CHANGE: number;
}

export class ProductivityContributorsBFFApiClass extends BFFServiceBasic {
  protected useInfiniteQuery;
  protected useQuery: InjectQuery;

  constructor(bffService: BffService, useInfiniteQuery: InjectInfiniteQuery, useQuery: InjectQuery) {
    super(bffService);
    this.useInfiniteQuery = useInfiniteQuery;
    this.useQuery = useQuery;
  }

  public getDriftingAwayContributorsPagination(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    limit: number = APP_CONFIG.PAGINATION_SIZE
  ) {
    return this.useInfiniteQuery({
      queryKey: [
        ICacheKey.GET_DRIFTING_AWAY_CONTRIBUTORS,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        filters.repositories
      ] as const,
      queryFn: ({ pageParam }) => {
        const query = getDriftingAwayContributorsQuery(project!.id, filters, limit, pageParam);
        return this.loadData<IPDriftingAwayContributor[]>(BffConstants.PRODUCTIVITY_DRIFTING_AWAY_CONTRIBUTORS, query);
      },
      enabled: !!project,
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.length * limit;
        return lastPage.length === limit ? nextOffset : undefined;
      }
    });
  }

  public getDriftingAwayContributors(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    isDownloadCSV: boolean,
    limit: number
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_DRIFTING_AWAY_CONTRIBUTORS,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        isDownloadCSV,
        filters.repositories
      ] as const,
      queryFn: () => {
        const query = getDriftingAwayContributorsQuery(project!.id, filters, limit, 0);
        return this.loadData<IPDriftingAwayContributor[]>(BffConstants.PRODUCTIVITY_DRIFTING_AWAY_CONTRIBUTORS, query);
      },
      enabled: !!project && isDownloadCSV
    });
  }

  public getNewContributorsPagination(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    limit: number = APP_CONFIG.PAGINATION_SIZE
  ) {
    return this.useInfiniteQuery({
      queryKey: [
        ICacheKey.GET_NEW_CONTRIBUTORS,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        filters.repositories
      ] as const,
      queryFn: ({ pageParam }) => {
        const query = getNewContributorsQuery(project!.id, filters, limit, pageParam);
        return this.loadData<IPNewContributors[]>(BffConstants.PRODUCTIVITY_NEW_CONTRIBUTORS, query);
      },
      enabled: !!project,
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.length * limit;
        return lastPage.length === limit ? nextOffset : undefined;
      }
    });
  }

  public getNewContributors(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    isDownloadCSV: boolean,
    limit: number
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_NEW_CONTRIBUTORS,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        isDownloadCSV,
        filters.repositories
      ] as const,
      queryFn: () => {
        const query = getNewContributorsQuery(project!.id, filters, limit, 0);
        return this.loadData<IPNewContributors[]>(BffConstants.PRODUCTIVITY_NEW_CONTRIBUTORS, query);
      },
      enabled: !!project && isDownloadCSV
    });
  }

  public getEngagementGap(
    filters: InsightsFiltersNew,
    project: InsightProject | undefined,
    options: { signal?: AbortSignal; [key: string]: any } = {}
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_ENGAGEMENT_GAP,
        filters.project,
        filters.dateFilters,
        filters.periods?.currentPeriod[0],
        filters.periods?.currentPeriod[1],
        filters.hideBots,
        filters.repositories
      ] as const,
      queryFn: () => {
        const query = getEngagementGapQuery(project!.id, filters);
        return this.loadData<IPEngagementGap[]>(BffConstants.PRODUCTIVITY_ENGAGEMENT_GAP, query, options);
      },
      enabled: !!project?.slug
    });
  }
}
