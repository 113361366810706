import { MenuItems } from '@app/shared/interface/common';
import { NoDataPageRoutesEnum } from '@app/shared/interface/common.enum';
import { environment } from '@environments/environment';
import { InsightProject } from 'lfx-insights';

export const foundationMenuItems = (slug: string): MenuItems[] => [
  {
    name: 'Overview',
    icon: ['far', 'home-alt'],
    url: `/foundation/${slug}`,
    exact: true
  },
  {
    name: 'Projects',
    icon: ['far', 'chart-line'],
    url: `/foundation/${slug}/projects`,
    exact: true
  }
];

export const projectMenuItems = (
  project: InsightProject,
  isGitOnly: boolean,
  isProdAndGerrit: boolean
): MenuItems[] => {
  return [
    {
      name: 'Overview',
      icon: ['far', 'objects-column'],
      url: `foundation/${project.foundationSlug}/overview/github`,
      queryParam: { routedFrom: NoDataPageRoutesEnum.github },
      isSubMenuOpen: true, // need to ask Sameh why this flag matters to the sub menus ==> need to be by true to open sub menu by default
      subMenu: [
        {
          name: 'GitHub',
          url: `foundation/${project.foundationSlug}/overview/github`,
          queryParam: { routedFrom: NoDataPageRoutesEnum.github }
        },
        {
          name: 'Gerrit',
          url: `foundation/${project.foundationSlug}/overview/gerrit`,
          queryParam: { routedFrom: NoDataPageRoutesEnum.gerrit }
        },
        {
          name: 'Confluence',
          url: `foundation/${project.foundationSlug}/confluence`,
          queryParam: { routedFrom: NoDataPageRoutesEnum.confluence }
        },
        {
          name: 'Mailing Lists',
          url: `foundation/${project.foundationSlug}/mailing-list`,
          queryParam: { routedFrom: NoDataPageRoutesEnum.mailingList }
        }
      ]
    },
    {
      name: 'Velocity',
      icon: ['far', 'chart-line'],
      url: `foundation/${project.foundationSlug}/velocity`,
      isHidden: isGitOnly || isProdAndGerrit
    },
    {
      name: 'Productivity',
      icon: ['fal', 'check'],
      url: `foundation/${project.foundationSlug}/productivity`,
      isHidden: isProdAndGerrit
    },
    {
      name: 'Reports',
      icon: ['far', 'file-chart-column'],
      url: `foundation/${project.foundationSlug}/reports`,
      isHidden: isProdAndGerrit,
      subMenu: [
        {
          name: 'Contributors',
          url: `foundation/${project.foundationSlug}/reports/contributors`
        },
        {
          name: 'Organizations',
          url: `foundation/${project.foundationSlug}/reports/organizations`
        },
        {
          name: 'Activities',
          url: `foundation/${project.foundationSlug}/reports/activities`
        },
        {
          name: 'Retention',
          url: `foundation/${project.foundationSlug}/reports/retention`
        },
        {
          name: 'Project Health',
          url: `foundation/${project.foundationSlug}/reports/project-health`,
          isHidden: !environment.features.projectHealth
        }
      ]
    }
  ];
};

export * as SideNavService from './side-nav.service';
