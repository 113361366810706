// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { isPlatformBrowser, NgIf } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, NgZone, OnChanges, PLATFORM_ID, SimpleChanges } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5radar from '@amcharts/amcharts5/radar';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { RadialConfig, RadialSeriesSettings } from 'lfx-insights';
import { IXYAxis } from '@amcharts/amcharts5/.internal/charts/xy/series/XYSeries';
import { LoadingComponent } from '../loading/loading.component';

@Component({
    selector: 'lfx-radar-chart',
    templateUrl: './radar-chart.component.html',
    styleUrls: ['./radar-chart.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingComponent]
})
export class RadarChartComponent implements AfterViewInit, OnChanges {
  @Input() public config!: RadialConfig;
  @Input() public chartName: string = 'radartesting';
  @Input() public data: any[] = [
    {
      category: 'value',
      value: 60,
      full: 100
    }
  ];
  @Input() public annotation: string | undefined = undefined;

  public isLoading: boolean = false;

  private root!: am5.Root;
  private chartRef!: am5radar.RadarChart;
  private annotationLabel?: am5.Label;
  private annotationWrap: string = `<div class='radar-annotation' style="width: 80%;display:
  flex;margin: 0 auto; overflow: visible; min-height: 30px; align-items: center;">
  {{content}}</div>`;

  constructor(@Inject(PLATFORM_ID) private platformId: string, private zone: NgZone) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ((changes && changes.data) || (changes && changes.annotation)) {
      this.changeData();
    }
  }

  // Run the function only in the browser
  public browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  public ngAfterViewInit() {
    // Chart code goes in here
    this.browserOnly(() => {
      this.initChart();
    });
  }

  public initChart() {
    if (this.root) {
      this.root.dispose();
    }

    const root = am5.Root.new(this.chartName);

    // remove footer logo
    // eslint-disable-next-line no-underscore-dangle
    root._logo?.children.clear();

    // Set themes
    const myTheme = am5.Theme.new(root);
    myTheme.rule('Grid').setAll({
      strokeOpacity: 0
    });

    root.setThemes([am5themesAnimated.new(root), myTheme]);

    const padding = this.config.padding || -10;
    const chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        innerRadius: am5.percent(this.config.innerRadius || 85),
        startAngle: -90,
        endAngle: 270,
        paddingBottom: padding,
        paddingTop: padding,
        paddingLeft: padding,
        paddingRight: padding,
        marginBottom: 0,
        marginTop: 0,
        marginLeft: 0,
        marginRight: 0
      })
    );

    // Create axes and their renderers
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
    const xRenderer = am5radar.AxisRendererCircular.new(root, {
      strokeOpacity: 0,
      inversed: this.config.inversed
    });

    xRenderer.labels.template.setAll({
      visible: false
    });

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: xRenderer,
        min: 0,
        max: this.config.maxValue || 100
      })
    );

    const yRenderer = am5radar.AxisRendererRadial.new(root, {
      minGridDistance: 20
    });

    yRenderer.labels.template.setAll({
      visible: false
    });

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'category',
        renderer: yRenderer
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
    this.buildSeries(chart, root, xAxis, yAxis);

    this.setOrBuildAnnotation(chart, root);

    const data = this.data;
    yAxis.data.setAll(data);

    // Animate chart and series in
    // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
    chart.appear(1000, 100);

    this.chartRef = chart;
    this.root = root;
  }

  private buildSeries(chart: am5radar.RadarChart, root: am5.Root, xAxis: IXYAxis, yAxis: IXYAxis): void {
    this.config.series.forEach((se: RadialSeriesSettings) => {
      const series = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
          stacked: se.stacked === undefined ? true : se.stacked,
          clustered: se.clustered === undefined ? true : se.clustered,
          name: se.name,
          xAxis,
          yAxis,
          valueXField: se.valueField,
          categoryYField: this.config.categoryField
        })
      );

      series.set('fill', se.color ? am5.color(se.color) : root.interfaceColors.get('fill'));
      series.columns.template.setAll({
        width: am5.p100,
        strokeOpacity: 0
      });

      series.data.setAll(this.data);
      if (se.setAppear) {
        series.appear(se.setAppear);
      }
    });
  }

  private changeData(): void {
    if (this.chartRef) {
      this.chartRef.xAxes.each((axis: am5xy.Axis<am5xy.AxisRenderer>) => {
        axis.data.setAll(this.data);
      });
      this.chartRef.yAxes.each((axis: am5xy.Axis<am5xy.AxisRenderer>) => {
        axis.data.setAll(this.data);
      });
      this.chartRef.series.each((se: am5xy.XYSeries) => {
        se.data.setAll(this.data);
      });

      this.setOrBuildAnnotation(this.chartRef, this.root);
    }
  }

  private setOrBuildAnnotation(chart: am5radar.RadarChart, root: am5.Root): void {
    if (this.annotation !== undefined) {
      if (this.annotationLabel) {
        this.annotationLabel.set('html', this.annotationWrap.replace('{{content}}', this.annotation));
      } else {
        this.annotationLabel = chart.radarContainer.children.push(
          am5.Label.new(root, {
            html: this.annotationWrap.replace('{{content}}', this.annotation),
            centerX: am5.p50,
            centerY: am5.p50,
            oversizedBehavior: 'fit'
          })
        );
      }
    }
  }
}
