// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceText',
    standalone: true
})
export class ReplaceTextPipe implements PipeTransform {
  public transform(txt: string, from: string, to: string): string {
    return txt.replace(new RegExp(from, 'g'), to);
  }
}
