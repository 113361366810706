// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  computed,
  effect,
  inject,
  input,
  signal
} from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { PlatformEnum } from '@app/shared/interface/common.enum';
import { MenuItems } from '@app/shared/interface/common';
import { getPersistedQueryParameter } from '@app/shared/utils/cubejs-helpers';
import { fadeIn, widthIncreaser } from '@app/shared/global-animations';
import { ProjectNewService } from '@app/shared/services/project-new.service';
import { foundationMenuItems, projectMenuItems } from './side-nav.service';
import { isPlatformEnabled, isProdAndGerrit } from '@app/shared/services/project-utils.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { LoadingComponent } from '../../../shared/components/loading/loading.component';
import { NgTemplateOutlet, NgClass, NgIf } from '@angular/common';
import { ProjectSearchComponent } from '../../../shared/components/project-search/project-search.component';

@Component({
  selector: 'lfx-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [fadeIn, widthIncreaser],
  standalone: true,
  imports: [
    ProjectSearchComponent,
    NgTemplateOutlet,
    LoadingComponent,
    RouterLink,
    RouterLinkActive,
    NgClass,
    NgIf,
    FaIconComponent
  ]
})
export class SideNavComponent implements OnInit {
  @Output() public readonly toggleCollapsed = new EventEmitter<any>();
  @Output() public readonly collapsed = new EventEmitter<any>();

  isFoundation = input(false);

  foundationMenu = computed<MenuItems[]>(this.loadFoundationMenus.bind(this));
  // Need to use writable signals here instead of computed
  projectMenu = signal<MenuItems[]>([]);
  menus = computed<MenuItems[]>(() => (this.isFoundation() ? this.foundationMenu() : this.projectMenu()));
  public isCollapsed = signal(false);
  public isPopupMenuOpened = signal(false);

  public projectNewService: ProjectNewService = inject(ProjectNewService);
  private router: Router = inject(Router);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  constructor() {
    effect(this.loadProjectMenus.bind(this), { allowSignalWrites: true });
  }

  public ngOnInit(): void {
    this.onResize(window.innerWidth);
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  public onResize(width: number) {
    const prev = this.isCollapsed();
    this.isCollapsed.set(width <= 1440);
    this.isPopupMenuOpened.set(!this.isCollapsed());
    if (prev !== this.isCollapsed()) {
      this.toggleCollapsed.emit();
    }
  }

  public onMouseEnter() {
    if (this.isCollapsed()) {
      this.isPopupMenuOpened.set(true);
    }
  }

  public onMouseLeave() {
    if (this.isCollapsed()) {
      this.isPopupMenuOpened.set(false);
    }
  }

  public backToGlobal() {
    this.projectNewService.clearSelectedFoundation();
    this.isCollapsed.set(false);
    this.collapsed.emit();
    const queryParams = getPersistedQueryParameter(this.activatedRoute.snapshot.queryParamMap);
    this.router.navigate(['/'], {
      queryParams
    });
  }

  public toggleSubMenu(menuName: string) {
    this.projectMenu.update((menus) => {
      return menus.map((menu) => (menu.name === menuName ? { ...menu, isSubMenuOpen: !menu.isSubMenuOpen } : menu));
    });
  }

  public menuActiveChange(isActivated: boolean) {
    if (isActivated) {
      this.collapseAllMenus();
    }
  }

  private loadFoundationMenus(): MenuItems[] {
    const foundation = this.projectNewService.selectedFoundation();
    return foundation ? foundationMenuItems(foundation.slug || '') : [];
  }

  private loadProjectMenus() {
    const project = this.projectNewService.selectedProject();

    const isGitOnly = isPlatformEnabled(project, PlatformEnum.git, true);

    if (project) {
      this.projectMenu.set(projectMenuItems(project, isGitOnly, isProdAndGerrit(project)));
    }
  }

  private collapseAllMenus() {
    this.projectMenu.update((menus) => {
      return menus.map((menu) => ({ ...menu, isSubMenuOpen: false }));
    });
  }
}
