<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->
<div class="font-sans text-gray-dark relative">
  {{ beforeVariationText }}
  <span class="font-bold" *ngIf="variation !== undefined; else loadingTemplate">
    {{ (variationBoldText ? variationBoldText : '') + variationDirection }}</span
  >
  <span class="status-icon" *ngIf="variation !== undefined && variation !== 0 && !hideCommentaryIcon">
    <lfx-commentary-icon
      *ngIf="isReversedIcons ? variation < 0 : variation > 0"
      [icon]="increasedIcon"
      classes="bg-green"></lfx-commentary-icon>
    <lfx-commentary-icon
      *ngIf="isReversedIcons ? variation > 0 : variation < 0"
      [icon]="decreasedIcon"
      classes="bg-red-dark"></lfx-commentary-icon>
  </span>
  {{ afterVariationText }}
</div>
<ng-template #loadingTemplate>
  <lfx-loading class="inline-block w-8" [minHeight]="20"></lfx-loading>
</ng-template>
