// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { UntilDestroy } from '@ngneat/until-destroy';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { isPlatformBrowser, NgStyle } from '@angular/common';
import {
  ClusteredColumnChartConfig,
  ClusteredColumnChartData
} from '@shared/components/clustered-column-chart/clustered-column-chart.types';
import { ClusteredColumnChartService } from './clustered-column-chart.service';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lfx-clustered-column-chart',
    templateUrl: './clustered-column-chart.component.html',
    styleUrls: ['./clustered-column-chart.component.scss'],
    standalone: true,
    imports: [NgStyle]
})
export class ClusteredColumnChartComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() public config: ClusteredColumnChartConfig;
  @Input() public data!: ClusteredColumnChartData[];
  @ViewChild('chartElement') chartElement: ElementRef<HTMLElement>;

  private root!: am5.Root;
  private noDateFoundModal: am5.Modal;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private zone: NgZone,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngAfterViewInit(): void {
    this.browserOnly(() => {
      am5.ready(() => {
        this.initChart();
      });
    });
  }

  public ngOnDestroy(): void {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes?.data?.currentValue) {
      this.initChart();
    }
  }

  private browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  private initChart() {
    if (this.root) {
      this.root.dispose();
    }
    if (this.noDateFoundModal?.isOpen()) {
      this.noDateFoundModal.close();
    }

    // check if the container exists
    if (!this.chartElement?.nativeElement) return;

    const root = am5.Root.new(this.chartElement.nativeElement);
    this.root = root;
    this.noDateFoundModal = am5.Modal.new(root, {
      content: 'No data to display'
    });
    const myTheme = am5.Theme.new(root);
    if (this.config.hideGrid) {
      myTheme.rule('Grid').setAll({
        strokeOpacity: 0
      });
    }
    root.setThemes([myTheme]);
    // eslint-disable-next-line no-underscore-dangle
    root._logo?.children.clear();

    const data = ClusteredColumnChartService.dataSerializer(this.data);
    if (this.data.length === 0) {
      this.noDateFoundModal.open();
    } else {
      this.noDateFoundModal.close();
    }

    // Create chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        arrangeTooltips: false,
        pinchZoomY: true,
        layout: root.verticalLayout
      })
    );

    chart.zoomOutButton.set('forceHidden', true);

    // Create axes
    const xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9
    });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'category',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    xRenderer.grid.template.setAll({
      location: 1
    });

    xAxis.data.setAll(data);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          fill: am5.color('#807f83'),
          stroke: am5.color('#807f83'),
          minGridDistance: 25,
          strokeOpacity: 1,
          strokeWidth: 1
        })
      })
    );

    // Add legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      })
    );

    // Add series
    function makeSeries(fieldName: string, name: string | undefined, color: string | undefined) {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          xAxis,
          yAxis,
          valueYField: fieldName,
          categoryXField: 'category',
          ...(name ? { name } : {}),
          ...(color ? { fill: am5.color(color) } : {})
        })
      );

      series.columns.template.setAll({
        tooltipText: `{valueY.formatNumber('#.00')}`,
        width: am5.percent(50),
        tooltipY: 0,
        strokeOpacity: 0
      });

      series.data.setAll(data);

      // Make stuff animate on load
      series.appear();

      series.bullets.push(() =>
        am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            text: '{valueY}',
            fill: root.interfaceColors.get('alternativeText'),
            centerY: 0,
            centerX: am5.p50,
            populateText: true
          })
        })
      );
      if (name) {
        legend.data.push(series);
      }
    }

    this.data.forEach((s) => {
      s.data.forEach((d, index) => {
        makeSeries(`value_${index + 1}`, d?.label, d?.color);
      });
    });

    chart.appear(1000, 100);
  }
}
