<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
-->
<div class='table-number-delta-container'>
  {{(value | validVarType) !== 'N/A' ? (value | lfxShortNumber) : 'N/A'}}
  <span
    *ngIf="delta !== undefined"
    [ngClass]="{
        'text-green': delta && delta > 0,
        'text-red-dark': delta && delta < 0
      }">
      {{ (delta | validVarType) !== 'N/A' ? '(' + (delta && delta > 0 ? '+' : '') + (delta | lfxShortNumber) + ')' : '(N/A)' }}
  </span>
</div>
