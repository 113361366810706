import { BFFServiceBasic } from '@services/api/bff.basic';
import { BffService } from '@services/bff.service';
import { ICacheKey, InjectInfiniteQuery, InjectQuery } from '@services/api/api.types';
import { InsightsFiltersNew } from '@app/shared/services/filter/filter.types';
import { InsightProject } from 'lfx-insights';
import {
  getOverviewActiveDaysQuery,
  getOverviewContributionsOutsideWorkHoursQuery,
  getOverviewGeoDistributionQuery
} from '@app/shared/queries/overview/queries';
import { BffConstants } from '../bff.constants';

export interface IActiveDaysContributors {
  ACTIVE_DAYS: number;
  PREV_ACTIVE_DAYS: number;
  AVG_CONTRIBUTIONS: number;
  PREV_AVG_CONTRIBUTIONS: number;
}

export interface IOverviewGeoDistribution {
  COUNTRY: string;
  COUNTRY_CONTRIBUTORS: number;
  CONTRIBUTORS: number;
  PREV_CONTRIBUTORS: number;
  CONTRIBUTORS_CHANGE: number;
}

export interface IOverviewContributionsOutsideWorkHours {
  DAY: number;
  HOUR: number;
  COMMITS: number;
  ALL_COMMITS: number;
}

export class OverviewBFFApiClass extends BFFServiceBasic {
  protected useInfiniteQuery;
  protected useQuery: InjectQuery;

  constructor(bffService: BffService, useInfiniteQuery: InjectInfiniteQuery, useQuery: InjectQuery) {
    super(bffService);
    this.useInfiniteQuery = useInfiniteQuery;
    this.useQuery = useQuery;
  }

  getOverviewActiveDays(filters: InsightsFiltersNew, project: InsightProject, isGerrit: boolean) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_ACTIVE_DAYS_CONTRIBUTORS, project, filters, isGerrit],
      queryFn: () =>
        this.loadData<IActiveDaysContributors[]>(
          BffConstants.GLOBAL_ACTIVE_DAYS,
          getOverviewActiveDaysQuery(project.id, filters, isGerrit)
        ),
      enabled: !!project
    });
  }

  getOverviewGeoDistribution(filters: InsightsFiltersNew, project: InsightProject, isGerrit: boolean) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_CONTRIBUTORS_COUNTRY, project, filters, isGerrit],
      queryFn: () =>
        this.loadData<IOverviewGeoDistribution[]>(
          BffConstants.GLOBAL_GEO_DISTRIBUTION,
          getOverviewGeoDistributionQuery(project.id, filters, isGerrit)
        ),
      enabled: !!project
    });
  }

  getOverviewContributionsOutsideWorkHours(filters: InsightsFiltersNew, project: InsightProject) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_CONTRIBUTIONS_OUTSIDE_WORK_HOURS, project, filters],
      queryFn: () =>
        this.loadData<IOverviewContributionsOutsideWorkHours[]>(
          BffConstants.GLOBAL_CONTRIBUTIONS_OUTSIDE_WORK_HOURS,
          getOverviewContributionsOutsideWorkHoursQuery(project.id, filters)
        ),
      enabled: !!project
    });
  }
}
