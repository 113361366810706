// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input, OnInit } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, NgStyle } from '@angular/common';

@Component({
    selector: 'lfx-coming-soon',
    templateUrl: './coming-soon.component.html',
    styleUrls: ['./coming-soon.component.scss'],
    standalone: true,
    imports: [NgIf, NgStyle, FaIconComponent]
})
export class ComingSoonComponent implements OnInit {
  @Input() public bgImagePath: string;
  @Input() public minHeight = 400;
  @Input() public useMicroSize = false;
  public thumbs = 56;
  constructor() {}

  public ngOnInit() {}

  public increaseThumbs() {
    this.thumbs++;
  }
}
