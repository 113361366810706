// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BiDirectionalConfig, BiDirectionalData } from 'lfx-insights';
import { sortBy } from 'lodash';
import { DownloadStyleDirective } from '../../directives/download-style.directive';
import { NgIf } from '@angular/common';

@Component({
    selector: 'lfx-bi-directional-progress',
    templateUrl: './bi-directional-progress.component.html',
    styleUrls: ['./bi-directional-progress.component.scss'],
    standalone: true,
    imports: [NgIf, DownloadStyleDirective]
})
export class BiDirectionalProgressComponent implements OnInit {
  @Input() public config!: BiDirectionalConfig;
  @Input() public data: BiDirectionalData;

  @Output() public readonly labelClicked: EventEmitter<number> = new EventEmitter<number>();

  private labelHTML: string = '<span class="font-bold">{{value}}</span>&nbsp;{{label}}';

  public ngOnInit(): void {
    if (!this.config) {
      throw new Error(`Attribute 'config' is required`);
    }
  }

  public get leftColor(): string {
    if (this.config && Array.isArray(this.config.colorLeft)) {
      // compute for the threshold
      let color = '';
      sortBy(this.config.colorLeft, 'value').forEach((ct) => {
        if (this.leftValue <= ct.value) {
          color = ct.color;
          return;
        }
      });

      return color;
    }

    return this.config && this.config.colorLeft && this.config.colorLeft !== '' ? this.config.colorLeft.toString() : '#0068fa';
  }

  public get rightColor(): string {
    if (this.config && Array.isArray(this.config.colorRight)) {
      // compute for the threshold
      let color = '';
      sortBy(this.config.colorRight, 'value').forEach((ct) => {
        if (this.rightValue <= ct.value) {
          color = ct.color;
          return;
        }
      });

      return color;
    }

    return this.config && this.config.colorRight && this.config.colorRight !== '' ? this.config.colorRight.toString() : '#bd6bff';
  }

  public get leftValue(): number {
    return this.data.leftValue;
  }

  public get rightValue(): number {
    return this.data.rightValue;
  }

  public get leftPercentage(): number {
    // const total = this.leftValue + this.rightValue;
    // return total > 0 ? Math.floor((this.leftValue / total) * 100) : 0;
    return this.data.leftPercentage;
  }

  public get rightPercentage(): number {
    // const total = this.leftValue + this.rightValue;
    // return total > 0 ? Math.ceil((this.rightValue / total) * 100) : 0;
    return this.data.rightPercentage;
  }

  public get leftLabel(): string {
    return this.labelHTML.replace('{{value}}', this.leftValue.toString()).replace('{{label}}', this.config ? this.config.seriesLabel : '');
  }

  public get rightLabel(): string {
    return this.labelHTML.replace('{{value}}', this.rightValue.toString()).replace('{{label}}', this.config ? this.config.seriesLabel : '');
  }

  public get showLabels(): boolean {
    return this.config ? this.config.showLabels : false;
  }

  public onLabelClicked(idx: number): void {
    this.labelClicked.emit(idx);
  }
}
