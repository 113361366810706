// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { PlatformEnum } from '@app/shared/interface/common.enum';
import { InsightProject } from 'lfx-insights';
import { ShortNumberPipe } from '../../pipes/short-number.pipe';
import { DownloadStyleDirective } from '../../directives/download-style.directive';
import { isPlatformEnabled } from '@app/shared/services/project-utils.service';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'lfx-project-stats-row',
  templateUrl: './project-stats-row.component.html',
  styleUrls: ['./project-stats-row.component.scss'],
  standalone: true,
  imports: [DownloadStyleDirective, ShortNumberPipe, TooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectStatsRowComponent {
  public project = input.required<InsightProject>();
  public hasGithubSource = computed(() => isPlatformEnabled(this.project(), PlatformEnum.github));
  public hasGerritSource = computed(() => isPlatformEnabled(this.project(), PlatformEnum.gerrit));

  public isStatsLoading = computed(() => this.project().projectStat?.lastActivity === '');
}
