<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<!-- TODO: replace the tooltip usage here -->
<div data-html2canvas-ignore class="controls relative">
  <lfx-project-sources-icons [project]="project()" [isOnboarding]="isOnboarding()" />

  <div class="right-controls">
    <span
      class="bg-gray-100 py-0.5 px-2 text-gray-dark text-xs text-center rounded-md"
      *ngIf="project().projectCategory"
      >{{ project().projectCategory }}</span
    >
    <fa-icon
      class="cursor-pointer text-base text-gray text-end hover:text-primary"
      (click)="downloadCard()"
      [icon]="'arrow-to-bottom'"></fa-icon>
  </div>
</div>
