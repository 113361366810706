// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'firstLetterCapitalcase',
    standalone: true
})
export class FirstLetterCapitalcasePipe implements PipeTransform {
  public transform(str: string | undefined): string {
    // prepositions
    const regex = /(^|\b(?!(and?|at?|the|for|to|but|by|of|per)\b))\w+/g;

    if (!str) {
      return '';
    }
    // return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    return str.toLowerCase().replace(regex, (s) => s[0].toUpperCase() + s.slice(1));
  }
}
