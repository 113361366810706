// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { ShortNumberPipe } from '../../../../pipes/short-number.pipe';

@Component({
    selector: 'lfx-table-rank',
    templateUrl: './table-rank.component.html',
    styleUrls: ['./table-rank.component.scss'],
    standalone: true,
    imports: [ShortNumberPipe]
})
export class TableRankComponent {
  @Input() public rank: number | null | undefined;
}
