// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, effect, inject, input, signal } from '@angular/core';
import { Router } from '@angular/router';
import { FilterService } from '@app/shared/services/filter.service';
import { InsightProject } from 'lfx-insights';
import { MessageService, SharedModule } from 'primeng/api';
import { AutoCompleteCompleteEvent, AutoCompleteSelectEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { IProjectSearchOption, mapToProjectsOptions } from './project-search.service';
import { getProjectOverviewRoutedFrom, getProjectOverviewUrl } from '@app/shared/services/project-utils.service';
import { ProjectNewService } from '@app/shared/services/project-new.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'lfx-project-search',
  templateUrl: './project-search.component.html',
  styleUrls: ['./project-search.component.scss'],
  standalone: true,
  imports: [AutoCompleteModule, SharedModule, NgClass, FaIconComponent, NgIf]
})
export class ProjectSearchComponent {
  isCompact = input(false);
  filtered = signal<IProjectSearchOption[]>([]);
  isLoading = signal(true);

  private projectsAll: IProjectSearchOption[] = [];

  private filterService: FilterService = inject(FilterService);
  private projectNewService: ProjectNewService = inject(ProjectNewService);
  private router: Router = inject(Router);
  private messageService: MessageService = inject(MessageService);

  constructor() {
    effect(
      () => {
        const foundations = this.projectNewService.foundationsAndProjects();
        if (foundations) {
          this.projectsAll = mapToProjectsOptions(foundations);
          this.isLoading.set(false);
        }
      },
      { allowSignalWrites: true }
    );
  }

  public selectFoundationOrProject(event: AutoCompleteSelectEvent): void {
    const item = event.value.value;

    if (item.isFoundation) {
      this.filterService.applyFilterPartially({ project: '', repositories: [] });
      this.router.navigate(['/foundation/', item.slug], { queryParamsHandling: 'merge' });
    } else {
      this.onCardNavigate(item);
    }
  }

  public onAutoComplete(event: AutoCompleteCompleteEvent): void {
    const filtered = this.projectsAll.filter(
      (proj) =>
        proj.label.toLowerCase().indexOf(event.query.toLowerCase()) >= 0 ||
        proj.value.projects?.some((p) => p.name.toLowerCase().indexOf(event.query.toLowerCase()) >= 0)
    );

    this.filtered.set(filtered || []);
  }

  private onCardNavigate(item: InsightProject): void {
    let foundationSlug = item.foundationSlug ? item.foundationSlug : item.parentSlug;
    if (item.foundationSlug) {
      foundationSlug = item.foundationSlug;
    } else if (item.parentSlug) {
      foundationSlug = item.parentSlug;
    } else {
      foundationSlug = item.slug;
    }
    if (!foundationSlug) {
      this.messageService.add({
        key: 'toast',
        severity: 'error',
        summary: 'Error',
        detail: `this project doesn't have slug`
      });
      return;
    }
    this.selectProjectUpdateFilter(item, foundationSlug);
    this.router.navigate([`${getProjectOverviewUrl(item, foundationSlug)}`], {
      queryParams: {
        project: item.slug,
        routedFrom: getProjectOverviewRoutedFrom(item),
        bestPractice: false
      },
      queryParamsHandling: 'merge'
    });
  }

  private selectProjectUpdateFilter(project: InsightProject, foundationSlug: string) {
    this.filterService.applyFilterPartially({
      ...this.filterService.defaultFilters,
      project: project.slug,
      foundationSlug
    });

    this.projectNewService.setSelectedProjectSlug(project.slug);
  }
}
