enum RepoColumnFilters {
  segmentId = 'segment_id',
  repoOrganization = 'repo_organization',
  repositoryUrl = 'repository_url',
  platform = 'platform',
  subprojectSlug = 'subproject_slug',
  projectSlug = 'project_slug',
  projectGroupSlug = 'project_group_slug',
  subprojectName = 'subproject_name',
  projectName = 'project_name',
  projectGroupName = 'project_group_name'
}

enum RepoOrderBy {
  segmentId = 'segment_id',
  repoOrganization = 'repo_organization',
  repositoryUrl = 'repository_url',
  platform = 'platform',
  subprojectSlug = 'subproject_slug',
  projectSlug = 'project_slug',
  projectGroupSlug = 'project_group_slug',
  subprojectName = 'subproject_name',
  projectName = 'project_name',
  projectGroupName = 'project_group_name'
}

interface IProjectRepositoryRequest {
  cacheByPass?: boolean;
  fields?: string;
  keys?: string[];
  column: RepoColumnFilters;
  orderBy: RepoOrderBy;
  asc: boolean;
  orderBy2: RepoOrderBy;
  asc2: boolean;
  limit?: number;
  offset?: number;
}
export const buildRepositoryBySlugQuery = (slug: string, cacheByPass?: boolean) => {
  const req: IProjectRepositoryRequest = {
    cacheByPass,
    fields: 'REPOSITORY_URL,PLATFORM',
    column: RepoColumnFilters.subprojectSlug,
    keys: [slug],
    orderBy: RepoOrderBy.repositoryUrl,
    asc: true,
    orderBy2: RepoOrderBy.platform,
    asc2: true,
    limit: 1000000,
    offset: 0
  };

  return req;
};
