// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

/* eslint-disable @typescript-eslint/naming-convention */
export const cubeRepositoryReport = {
  dimensions: {
    repository: 'SnowRepositoryReport.repository',
    source: 'SnowRepositoryReport.source',
    run_at: 'SnowRepositoryReport.run_at',
    project: 'SnowRepositoryReport.project',
    projectSlug: 'SnowRepositoryReport.projectSlug',
    documentationReport: 'SnowRepositoryReport.documentationReport',
    licenseReport: 'SnowRepositoryReport.licenseReport',
    bestPracticesReport: 'SnowRepositoryReport.bestPracticesReport',
    securityReport: 'SnowRepositoryReport.securityReport',
    legalReport: 'SnowRepositoryReport.legalReport'
  },
  measures: {
    count: 'SnowRepositoryReport.count',
    clo_count: 'SnowRepositoryReport.clo_count',
    scc_count: 'SnowRepositoryReport.scc_count',
    globalScore: 'SnowRepositoryReport.globalScore',
    cocomoInDollars: 'SnowRepositoryReport.cocomoInDollars',
    documentationScore: 'SnowRepositoryReport.documentationScore',
    legalScore: 'SnowRepositoryReport.legalScore',
    securityScore: 'SnowRepositoryReport.securityScore',
    bestPracticesScore: 'SnowRepositoryReport.bestPracticesScore',
    licenseScore: 'SnowRepositoryReport.licenseScore',
    lastUpdate: 'SnowRepositoryReport.lastupdate',
    last_scc_update: 'SnowRepositoryReport.last_scc_update',
    last_clo_update: 'SnowRepositoryReport.last_clo_update'
  },
  segments: {
    bestPracticeSource: 'SnowRepositoryReport.bestPracticeSource',
    cocomoSource: 'SnowRepositoryReport.cocomoSource'
  }
};
